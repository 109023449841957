import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({ name: 'highlightText' })
@Injectable({
  providedIn: 'root',
})
export class HighlightTextPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(text: any, highlightKey?: string): any {
    const highlightedText = highlightKey
      ? this._highlightText(text, highlightKey)
      : text;
    return highlightedText;
  }
  _highlightText(text: any, highLightKey: string) {
    highLightKey = highLightKey.replace('\\', '\\\\');
    highLightKey = highLightKey.replace(/([.*+?^$|(){}\[\]])/g, "\\$1");
    if (
      text &&
      text.trim() !== '' &&
      highLightKey &&
      highLightKey.trim() !== ''
    ) {
      const searchKeys = highLightKey.split(' ').join('|');
      if (searchKeys.length === 0) {
        return text;
      }
      return text.replace(new RegExp(searchKeys, 'gi'), (match) => {
        return '<mark>' + match + '</mark>';
      });
    }
    return text;
  }
}
