import { Injectable } from '@angular/core';
import { MDMService } from 'src/app/mdm/mdm.service';
import { BehaviorSubject } from 'rxjs';
import { MDMCommonConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'


@Injectable({
  providedIn: 'root'
})
export class PayerService {

  mergeArray$ = new BehaviorSubject([]);
  matches$ = new BehaviorSubject([]);
  profileDetails$ = new BehaviorSubject([]);
  LineageDetails$ = new BehaviorSubject({ golden: '', profiles: '' });
  matchesLoading$ = new BehaviorSubject(true);
  profileLoading$ = new BehaviorSubject(true);

  constructor(private service: MDMService,
    private toaster: ToasterNotificationService,) {
  }
  addToNewArray(item) {
    var index = this.mergeArray$.value.findIndex(x => {
      return x.MDM_ID.value == item.MDM_ID.value
    })
    if (index === -1) {
      this.mergeArray$.next(this.mergeArray$.getValue().concat(item));
    }
  }

  setMergeArray() {
    this.mergeArray$.next([]);
  }
  removeFromMergeArray(index) {
    const remArr: any[] = this.mergeArray$.getValue();
    remArr.splice(index, 1);
    this.mergeArray$.next(remArr);
  }

  getPBMSuspectedMatches(_id) {
    this.matchesLoading$.next(true);
    this.service.getPBMSuspectedMatches(_id).subscribe((data) => {
      this.matches$.next(data);
      this.matchesLoading$.next(false);

    })
  }
  getMCOSuspectedMatches(_id) {
    this.matchesLoading$.next(true);
    this.service.getMCOSuspectedMatches(_id).subscribe((data) => {
      this.matches$.next(data);
      this.matchesLoading$.next(false);
    })
  }
  getPAYERSuspectedMatches(_id) {
    this.matchesLoading$.next(true);
    this.service.getPAYERSuspectedMatches(_id).subscribe((data) => {
      this.matches$.next(data);
      this.matchesLoading$.next(false);
    })
  }
  getPLANSuspectedMatches(_id) {
    this.matchesLoading$.next(true);
    this.service.getPLANSuspectedMatches(_id).subscribe((data) => {
      this.matches$.next(data);
      this.matchesLoading$.next(false);
    })
  }
  getPBMLineageData(id) {
    this.profileLoading$.next(true);
    this.service.getPBMLineageData(id, MDMCommonConstants.Y).subscribe((data) => {
      const profiles = data.filter((el) => {
        return el.IsGolden.value == 'N'
      })
      const golden = data.find((item) => {
        return item.IsGolden.value == 'Y'
      })
      this.LineageDetails$.next({ golden: golden, profiles: profiles })
      this.profileLoading$.next(false);
    })
  }
  getMCOLineageData(id) {
    this.profileLoading$.next(true);
    this.service.getMCOLineageData(id, MDMCommonConstants.Y).subscribe((data) => {
      const profiles = data.filter((el) => {
        return el.IsGolden.value == 'N'
      })
      const golden = data.find((item) => {
        return item.IsGolden.value == 'Y'
      })
      this.LineageDetails$.next({ golden: golden, profiles: profiles })
      this.profileLoading$.next(false);
    })
  }
  getPAYERLineageData(id) {
    this.profileLoading$.next(true);
    this.service.getPAYERLineageData(id, MDMCommonConstants.Y).subscribe((data) => {
      const profiles = data.filter((el) => {
        return el.IsGolden.value == 'N'
      })
      const golden = data.find((item) => {
        return item.IsGolden.value == 'Y'
      })
      this.LineageDetails$.next({ golden: golden, profiles: profiles })
      this.profileLoading$.next(false);
    })
  }
  getPLANLineageData(id) {
    this.profileLoading$.next(true);
    this.service.getPLANLineageData(id, MDMCommonConstants.Y).subscribe((data) => {
      const profiles = data.filter((el) => {
        return el.IsGolden.value == 'N'
      })
      const golden = data.find((item) => {
        return item.IsGolden.value == 'Y'
      })
      this.LineageDetails$.next({ golden: golden, profiles: profiles })
      this.profileLoading$.next(false);
    })
  }

  getPBMProfileByid(id) {
    this.service.getPBMDetailsById(id).subscribe((data) => {
      this.profileDetails$.next(data);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  getMCOProfileByid(id) {
    this.service.getMCODetailsById(id).subscribe((data: any) => {
      this.profileDetails$.next(data);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  getPAYERProfileByid(id) {
    this.service.getPAYERDetailsById(id).subscribe((data: any) => {
      this.profileDetails$.next(data);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  getPLANProfileByid(id) {
    this.service.getPLANDetailsById(id).subscribe((data: any) => {
      this.profileDetails$.next(data);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
}
