import { Injectable } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class DateUtils {

    constructor(public datePipe: DatePipe) { }

    getCurrentDate() {
        return new Date();
    }

    getTransformedDate(str, format) {
        if (format === 'dd/MM/yyyy') {
            const dateArray = str.split('/');
            return new Date(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
        }
        return new Date(this.datePipe.transform(str, format));
    }

    addDaysToDateString(dateString, numberOfDays) {
        const localDate = new Date(dateString);
        return new Date(localDate.setDate(localDate.getDate() + numberOfDays)).toISOString();
    }
}
