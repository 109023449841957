import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges,
  OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild
} from '@angular/core';
import { MDMCommonConstants, ProfileDetailsTableConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { startWith, switchMap } from 'rxjs/operators';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { interval } from 'rxjs';
import { mdm_labels } from 'src/app/mdm/constants/ui-translation';

declare var jQuery: any;

@Component({
  selector: 'mdm-profile-details-table',
  templateUrl: './details-table.component.html',
  styleUrls: ['./details-table.component.less']
})
export class DetailsTableComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, AfterViewChecked {
  refreshIndicator$: any;
  refreshIndicator = false;
  @Input() tableCssClass: string;
  @Input() tableId: string;
  @Input() showLineage: boolean;
  @Input() keys: string[];
  @Input() golden: any;
  @Input() lineage: any[];
  @Input() onMergePage = false;
  @Input() onUnmergePage = false;
  @Input() wrapperElement: ElementRef;
  private _profiles: any[];
  @Input()
  set profiles(vals: any[]) {
    this._profiles = vals;
  }
  get profiles() {
    return this._profiles;
  }

  @Output() mergeClicked = new EventEmitter<any>();
  @Output() notamatchClicked = new EventEmitter<any>();
  @Output() unmergeClicked = new EventEmitter<any>();

  @ViewChild('mergeUnmergeTable') table: ElementRef;

  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.DETAILS_TABLE;
  primaryLabel: string;
  translated_labels: any;
  error_label: string;
  commmon_labels = commmon_labels;
  detailTableMapping = new Map<string, string>();
  environment: EnvironmentConfig;
  mdmConstants = MDMCommonConstants;
  isDraggingStarted = false;
  list: any;
  draggingElement: Element;
  draggingRowIndex: number;
  placeholder: ElementRef;
  draggingElementX: number;
  draggingElementY: number;
  mouseMoveHandler: any;
  mouseUpHandler: any;
  mouseDownHandler: any;
  newProfileAdded = false;

  constructor(private service: MDMService, private _translate: TranslateService,
    private config: AppConfig, private _toasterDataService: ToasterDataService, private _renderer: Renderer2) {
    this.translated_labels = this._translate.instant(
      [
        this.ui_labels.PRIMARY,
        this.commmon_labels.ERROR_WITH_COLON,
        this.ui_labels.MDM_ID,
        this.ui_labels.ACCOUNT_NAME,
        this.ui_labels.ALTERNATE_ACCOUNT_NAME,
        this.ui_labels.ACCOUNT_TYPE,
        this.ui_labels.ACCOUNT_SUB_TYPE,
        this.ui_labels.ACCOUNT_STATUS,
        this.ui_labels.CLASS_OF_TRADE_CODE,
        this.ui_labels.COT_CLASSIFICATION,
        this.ui_labels.COT_CLASSIFICATION_ID,
        this.ui_labels.COT_FACILITY_TYPE_ID,
        this.ui_labels.COT_FACILITY_TYPE,
        this.ui_labels.COT_SPECIALTY_ID,
        this.ui_labels.COT_SPECIALTY_DESCRIPTION,
        this.ui_labels.SOURCE,
        this.ui_labels.SOURCE_ID,
        this.ui_labels.FIRST_NAME,
        this.ui_labels.LAST_NAME,
        this.ui_labels.MIDDLE_NAME,
        this.ui_labels.PREFIX,
        this.ui_labels.SUFFIX,
        this.ui_labels.GENDER,
        this.ui_labels.TITLE,
        this.ui_labels.DATE_OF_BIRTH,
        this.ui_labels.DATE_OF_DEATH,
        this.ui_labels.PROFESSIONAL_DESIGNATION_CODE,
        this.ui_labels.PROFESSIONAL_DESIGNATION_DESCRIPTION,
        this.ui_labels.CUSTOMER_STATUS,
        this.ui_labels.CUSTOMER_TYPE,
        this.ui_labels.PRIMARY_SPECIALTY,
        this.ui_labels.PRIMARY_SPECIALTY_DESCRIPTION,
        this.ui_labels.SECONDARY_SPECIALTY,
        this.ui_labels.SECONDARY_SPECIALTY_DESCRIPTION,
        this.ui_labels.TERTIARY_SPECIALTY,
        this.ui_labels.TERTIARY_SPECIALTY_DESCRIPTION,
        this.ui_labels.NO_CONTACT_FLAG,
        this.ui_labels.PDRP_FLAG,
        this.ui_labels.PDRP_EFFECTIVE_DATE,
        this.ui_labels.TARGET_FLAG,
        this.ui_labels.DMR_FLAG,
        this.ui_labels.KOL_FLAG,
        this.ui_labels.KAISER_FLAG,
        this.ui_labels.AMA_DEAD_FLAG
      ]);
    this.primaryLabel = this.translated_labels[this.ui_labels.PRIMARY];
    this.error_label = this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
    this.detailTableMapping[ProfileDetailsTableConstants.MDM_ID] = this.translated_labels[this.ui_labels.MDM_ID];
    this.detailTableMapping[ProfileDetailsTableConstants.ACCOUNT_NAME] = this.translated_labels[this.ui_labels.ACCOUNT_NAME];
    this.detailTableMapping[ProfileDetailsTableConstants.ALTERNATE_ACCOUNT_NAME] =
      this.translated_labels[this.ui_labels.ALTERNATE_ACCOUNT_NAME];
    this.detailTableMapping[ProfileDetailsTableConstants.ACCOUNT_TYPE] = this.translated_labels[this.ui_labels.ACCOUNT_TYPE];
    this.detailTableMapping[ProfileDetailsTableConstants.ACCOUNT_SUB_TYPE] = this.translated_labels[this.ui_labels.ACCOUNT_SUB_TYPE];
    this.detailTableMapping[ProfileDetailsTableConstants.ACCOUNT_STATUS] = this.translated_labels[this.ui_labels.ACCOUNT_STATUS];
    this.detailTableMapping[ProfileDetailsTableConstants.CLASS_OF_TRADE_CODE] = this.translated_labels[this.ui_labels.CLASS_OF_TRADE_CODE];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_CLASSIFICATION] = this.translated_labels[this.ui_labels.COT_CLASSIFICATION];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_CLASSIFICATION_ID] =
      this.translated_labels[this.ui_labels.COT_CLASSIFICATION_ID];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_FACILITY_TYPE_ID] =
      this.translated_labels[this.ui_labels.COT_FACILITY_TYPE_ID];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_FACILITY_TYPE] = this.translated_labels[this.ui_labels.COT_FACILITY_TYPE];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_SPECIALTY_ID] = this.translated_labels[this.ui_labels.COT_SPECIALTY_ID];
    this.detailTableMapping[ProfileDetailsTableConstants.COT_SPECIALTY_DESCRIPTION] =
      this.translated_labels[this.ui_labels.COT_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[ProfileDetailsTableConstants.SOURCE] = this.translated_labels[this.ui_labels.SOURCE];
    this.detailTableMapping[ProfileDetailsTableConstants.SOURCE_ID] = this.translated_labels[this.ui_labels.SOURCE_ID];
    this.detailTableMapping[ProfileDetailsTableConstants.FIRST_NAME] = this.translated_labels[this.ui_labels.FIRST_NAME];
    this.detailTableMapping[ProfileDetailsTableConstants.LAST_NAME] = this.translated_labels[this.ui_labels.LAST_NAME];
    this.detailTableMapping[ProfileDetailsTableConstants.MIDDLE_NAME] = this.translated_labels[this.ui_labels.MIDDLE_NAME];
    this.detailTableMapping[ProfileDetailsTableConstants.PREFIX] = this.translated_labels[this.ui_labels.PREFIX];
    this.detailTableMapping[ProfileDetailsTableConstants.SUFFIX] = this.translated_labels[this.ui_labels.SUFFIX];
    this.detailTableMapping[ProfileDetailsTableConstants.GENDER] = this.translated_labels[this.ui_labels.GENDER];
    this.detailTableMapping[ProfileDetailsTableConstants.TITLE] = this.translated_labels[this.ui_labels.TITLE];
    this.detailTableMapping[ProfileDetailsTableConstants.DATE_OF_BIRTH] = this.translated_labels[this.ui_labels.DATE_OF_BIRTH];
    this.detailTableMapping[ProfileDetailsTableConstants.DATE_OF_DEATH] = this.translated_labels[this.ui_labels.DATE_OF_DEATH];
    this.detailTableMapping[ProfileDetailsTableConstants.PROFESSIONAL_DESIGNATION_CODE] =
      this.translated_labels[this.ui_labels.PROFESSIONAL_DESIGNATION_CODE];
    this.detailTableMapping[ProfileDetailsTableConstants.PROFESSIONAL_DESIGNATION_DESCRIPTION] =
      this.translated_labels[this.ui_labels.PROFESSIONAL_DESIGNATION_DESCRIPTION];
    this.detailTableMapping[ProfileDetailsTableConstants.CUSTOMER_STATUS] = this.translated_labels[this.ui_labels.CUSTOMER_STATUS];
    this.detailTableMapping[ProfileDetailsTableConstants.CUSTOMER_TYPE] = this.translated_labels[this.ui_labels.CUSTOMER_TYPE];
    this.detailTableMapping[ProfileDetailsTableConstants.PRIMARY_SPECIALTY] = this.translated_labels[this.ui_labels.PRIMARY_SPECIALTY];
    this.detailTableMapping[ProfileDetailsTableConstants.PRIMARY_SPECIALTY_DESCRIPTION] =
      this.translated_labels[this.ui_labels.PRIMARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[ProfileDetailsTableConstants.SECONDARY_SPECIALTY] = this.translated_labels[this.ui_labels.SECONDARY_SPECIALTY];
    this.detailTableMapping[ProfileDetailsTableConstants.SECONDARY_SPECIALTY_DESCRIPTION] =
      this.translated_labels[this.ui_labels.SECONDARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[ProfileDetailsTableConstants.TERTIARY_SPECIALTY] = this.translated_labels[this.ui_labels.TERTIARY_SPECIALTY];
    this.detailTableMapping[ProfileDetailsTableConstants.TERTIARY_SPECIALTY_DESCRIPTION] =
      this.translated_labels[this.ui_labels.TERTIARY_SPECIALTY_DESCRIPTION];
    this.detailTableMapping[ProfileDetailsTableConstants.NO_CONTACT_FLAG] = this.translated_labels[this.ui_labels.NO_CONTACT_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.PDRP_FLAG] = this.translated_labels[this.ui_labels.PDRP_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.PDRP_EFFECTIVE_DATE] = this.translated_labels[this.ui_labels.PDRP_EFFECTIVE_DATE];
    this.detailTableMapping[ProfileDetailsTableConstants.TARGET_FLAG] = this.translated_labels[this.ui_labels.TARGET_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.DMR_FLAG] = this.translated_labels[this.ui_labels.DMR_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.KOL_FLAG] = this.translated_labels[this.ui_labels.KOL_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.KAISER_FLAG] = this.translated_labels[this.ui_labels.KAISER_FLAG];
    this.detailTableMapping[ProfileDetailsTableConstants.AMA_DEAD_FLAG] = this.translated_labels[this.ui_labels.AMA_DEAD_FLAG];
    this.environment = this.config.getConfigObject();
  }

  ngOnInit() {
    this.refreshIndicator$ = interval(10 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.service.getRefreshIndicator())
      )
      .subscribe(res => {
        this.refreshIndicator = res;
      }, exception => {
        this._toasterDataService.addMessageToToaster(exception.error.message);
      });
    this.setMouseDownHandler();
    this.setMouseUpHandler();
    this.setMouseMoveHandler();
  }

  ngAfterViewInit() {
    this.addMouseDownEventListner();
  }

  ngAfterViewChecked(): void {
    if (this.newProfileAdded) {
      this.addMouseDownEventListner();
      this.newProfileAdded = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.profiles && !changes.profiles.firstChange) {
      this.newProfileAdded = true;
    }
  }

  ngOnDestroy() {
    this.refreshIndicator$.unsubscribe();
    this.destroyEventListners();
  }

  destroyEventListners() {
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  splitToNewlines(val: string) {
    return val ? val
      .replace(`${MDMCommonConstants.ME}:`, `<strong>${MDMCommonConstants.ME}</strong>`)
      .replace(`${MDMCommonConstants.NPI}:`, `<strong>${MDMCommonConstants.NPI}:</strong>`)
      .replace(`${MDMCommonConstants.SLN}:`, `<strong>${MDMCommonConstants.SLN}:</strong>`)
      .replace(`${MDMCommonConstants.DEA}:`, `<strong>${MDMCommonConstants.DEA}:</strong>`)
      .replace(',', '<br>') : val;
  }

  doMerge(profile: any) {
    this.mergeClicked.emit(profile);
  }

  doNotAMatch(profile: any) {
    this.notamatchClicked.emit(profile);
  }

  doUnmerge(profile: any) {
    this.unmergeClicked.emit(profile);
  }

  profileDeselected(profile: any, e: any) {
    if (this.onMergePage) {
      const index = this.profiles.indexOf(profile);
      if (index >= 0) {
        this.profiles.splice(index, 1);
      }
    }
    this.addMouseDownEventListner();
    e.stopPropagation();
  }

  addMouseDownEventListner() {
    if (this.table) {
      this.removePreviousEventListners();
      this.table.nativeElement.querySelectorAll('th').forEach((headerCell, index) => {
        if (index > 0 && this.profiles.length > 1) {
          this._renderer.addClass(headerCell, 'draggable');
          headerCell.addEventListener('mousedown', this.mouseDownHandler);
        }
      });
    }
  }

  removePreviousEventListners() {
    this.table.nativeElement.querySelectorAll('th').forEach((headerCell) => {
      headerCell.classList.remove('draggable');
      headerCell.removeEventListener('mousedown', this.mouseDownHandler);
    });
  }

  setMouseDownHandler() {
    const handler = function (e) {
      if (![...e.target.classList].includes('zs-icon-close') && this.profiles.length > 1) {
        this.draggingColumnIndex = [].slice.call(this.table.nativeElement.querySelectorAll('th')).indexOf(e.target);
        if (this.draggingColumnIndex < 0) {
          this.draggingColumnIndex =
            [].slice.call(this.table.nativeElement.querySelectorAll('th')).indexOf(e.target.parentElement.parentElement);
        }
        if (this.draggingColumnIndex > -1) {
          this.draggingElementX = e.clientX;
          this.draggingElementY = e.clientY - e.offsetTop;
          setTimeout(() => {
            document.addEventListener('mousemove', this.mouseMoveHandler);
            document.addEventListener('mouseup', this.mouseUpHandler);
          }, 500);
        }
      }
    };
    this.mouseDownHandler = handler.bind(this);
  }

  setMouseMoveHandler() {
    const that = this;
    const handler = function (e) {
      if (!that.isDraggingStarted) {
        this.isDraggingStarted = true;
        this.cloneTable();
        this.hideOverflowingTables();
        this.draggingEle = [].slice.call(this.list.children)[this.draggingColumnIndex];
        this._renderer.addClass(this.draggingEle, 'dragging');
        this.placeholder = this._renderer.createElement('div');
        this._renderer.addClass(this.placeholder, 'placeholder');
        this.draggingEle.parentNode.insertBefore(this.placeholder, this.draggingEle.nextSibling);
        this.placeholder.style.width = `${this.draggingEle.offsetWidth}px`;
        this.placeholder.style.display = 'inline-block';
      }
      if (this.draggingEle) {
        this.draggingEle.style.position = 'absolute';
        this.draggingEle.style.zIndex = MDMCommonConstants.DRAG_Z_INDEX;
        this.draggingEle.style.top = `${this.draggingEle.offsetTop + e.clientY - this.draggingElementY}px`;
        this.draggingEle.style.left = `${this.draggingEle.offsetLeft + e.clientX - this.draggingElementX}px`;
        this.draggingElementX = e.clientX;
        this.draggingElementY = e.clientY;
        const prevEle = this.draggingEle.previousElementSibling;
        const nextEle = this.placeholder.nextElementSibling;
        if (prevEle && ![...prevEle.firstChild.firstChild.firstChild.classList].includes('golden-profile')
          && this.isOnLeft(this.draggingEle, prevEle)) {
          this.swap(this.placeholder, this.draggingEle);
          this.swap(this.placeholder, prevEle);
          return;
        }
        if (nextEle && ![...nextEle.firstChild.firstChild.firstChild.classList].includes('golden-profile')
          && this.isOnLeft(nextEle, this.draggingEle)) {
          this.swap(nextEle, this.placeholder);
          this.swap(nextEle, this.draggingEle);
        }
      }
    };
    this.mouseMoveHandler = handler.bind(this);
  }

  setMouseUpHandler() {
    const that = this;
    const handler = function () {
      if (this.placeholder && this.placeholder.parentNode) {
        this.placeholder.parentNode.removeChild(this.placeholder);
      }
      if (this.draggingEle) {
        this.draggingEle.classList.remove('dragging');
        this.draggingEle.style.removeProperty('top');
        this.draggingEle.style.removeProperty('left');
        this.draggingEle.style.removeProperty('position');
        const endColumnIndex = [].slice.call(this.list.children).indexOf(this.draggingEle);
        this.table.nativeElement.querySelectorAll('tr').forEach((row) => {
          const cells = [].slice.call(row.querySelectorAll('th, td'));
          this.draggingColumnIndex > endColumnIndex
            ? cells[endColumnIndex].parentNode.insertBefore(cells[this.draggingColumnIndex], cells[endColumnIndex])
            : cells[endColumnIndex].parentNode.insertBefore(cells[this.draggingColumnIndex], cells[endColumnIndex].nextSibling);
        });
      }
      that.isDraggingStarted = false;
      if (this.list.parentNode) {
        this.list.parentNode.removeChild(this.list);
      }
      this.table.nativeElement.style.visibility = 'visible';
      this.destroyEventListners();
    };
    this.mouseUpHandler = handler.bind(this);
  }

  cloneTable() {
    const rect = this.table.nativeElement.getBoundingClientRect();
    this.list = this._renderer.createElement('div');
    this._renderer.addClass(this.list, 'clone-list');
    this.list.style.position = 'absolute';
    this.list.style.left = `${rect.left}px`;
    this.list.style.top = `${rect.top}px`;
    this.table.nativeElement.parentNode.insertBefore(this.list, this.table.nativeElement);
    this.table.nativeElement.style.visibility = 'hidden';
    const originalCells = [].slice.call(this.table.nativeElement.querySelectorAll('tbody td'));
    const originalHeaderCells = [].slice.call(this.table.nativeElement.querySelectorAll('th'));
    const numberOfColumns = originalHeaderCells.length;
    originalHeaderCells.forEach((headerCell, headerIndex) => {
      const width = parseInt(window.getComputedStyle(headerCell).width, 10);
      // Create a new table from given row
      const item = this._renderer.createElement('div');
      this._renderer.addClass(item, 'draggable');
      item.style.display = 'inline-block';
      item.style['vertical-align'] = 'top';
      const newTable = this._renderer.createElement('table');
      newTable.setAttribute('class', 'clone-table');
      newTable.style.width = `${width}px`;
      const th = headerCell.cloneNode(true);
      let newRow = this._renderer.createElement('tr');
      newRow.appendChild(th);
      newTable.appendChild(newRow);
      const cells = originalCells.filter((c, idx) => {
        return (idx - headerIndex) % numberOfColumns === 0;
      });
      cells.forEach((cell) => {
        const newCell = cell.cloneNode(true);
        newCell.style.width = `${width}px`;
        newRow = this._renderer.createElement('tr');
        newRow.appendChild(newCell);
        newTable.appendChild(newRow);
      });
      item.appendChild(newTable);
      this.list.appendChild(item);
    });
  }

  swap(nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
    nodeB.parentNode.insertBefore(nodeA, nodeB);
    parentA.insertBefore(nodeB, siblingA);
  }

  isOnLeft(nodeA, nodeB) {
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();
    return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
  }

  hideOverflowingTables() {
    const wrapperPosition = this.wrapperElement.nativeElement.getBoundingClientRect();
    const rightMargin = wrapperPosition.x + wrapperPosition.width;
    this.wrapperElement.nativeElement.querySelectorAll('.clone-table').forEach(table => {
      const tablePosition = table.getBoundingClientRect();
      const overflowRight = rightMargin < (tablePosition.x + tablePosition.width) ? true : false;
      const overflowLeft = wrapperPosition.x > tablePosition.x ? true : false;
      if (overflowRight || overflowLeft) {
        this._renderer.addClass(table, 'hidden');
      }
    });
  }
}
