import { Component, OnInit } from '@angular/core';
import { PayerWrapperConstants } from '../../../constants/mdm-common-constants';
import { TabConfig } from 'src/assets/listtabs/Tab.js'

@Component({
  selector: 'app-payer-record-wrapper',
  templateUrl: './payer-record-wrapper.component.html',
  styleUrls: ['./payer-record-wrapper.component.less']
})
export class PayerRecordWrapperComponent implements OnInit {
  isVisible=TabConfig.Payer_Tabs;

  constructor() { }
  PayerWrapperConstants = PayerWrapperConstants;
  chartFilters = [
    { value: PayerWrapperConstants.PAYER, Visiblity: this.isVisible.PAYER },
    { value: PayerWrapperConstants.PLAN, Visiblity: this.isVisible.PLAN },
    { value: PayerWrapperConstants.PBM, Visiblity: this.isVisible.PBM },
    { value: PayerWrapperConstants.MCO, Visiblity: this.isVisible.MCO },
  ];
  activeFilter = this.chartFilters.filter(obj => obj.Visiblity);
  activeTab = this.activeFilter[0].value;
  ngOnInit(): void {
  }
  onTabClick(event) {
    this.activeTab = event;
  }
}
