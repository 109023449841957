<div class="details-table-wrapper">
    <table class="zs-width-100 zs-table" #mergeUnmergeTable [id]="tableId">
        <thead>
            <tr>
                <th *ngIf="golden?.SRC_NM?.value?.length > 0" class="golden-profile">
                    <h5 translate> SOURCES </h5>
                    <span *ngFor="let source of golden.SRC_NM.value"
                        [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
                        class="badge zs-badge-counter zs-border-rounded-0">{{ source.trim().toUpperCase() }}
                    </span>
                </th>
                <th *ngFor="let item of profiles; let i = index" class="profile-details-data profiles-header">
                    <span class="close-drag-icon">
                        <span *ngIf="onMergePage" class="zs-padding-1-0-1-0 zs-icon zs-icon-close zs-size-xl"
                            (click)="profileDeselected(item, $event,i)"></span>
                        <span *ngIf="profiles?.length > 0 " class="zs-icon zs-icon-item-drag"></span>
                    </span>
                    <div>
                        <h5 translate> SOURCES </h5>
                    </div>

                    <ng-container *ngIf="item?.SRC_NM?.value?.length > 0">
                        <span>
                            <span *ngFor="let src of item?.SRC_NM?.value"
                                [ngStyle]="{'background-color': environment.mdmSourceColorCodes[src.trim().toLowerCase()]}"
                                class="badge zs-badge-counter zs-border-rounded-0">
                                {{ src.trim().toUpperCase() }}
                            </span>
                        </span>
                    </ng-container>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="onMergePage && profiles?.length > 0">
                <!-- Merge/Not A Match buttons row when on Merge page -->
                <tr>
                    <td class="golden-profile"></td>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div>
                            <button class="zs-button zs-button-solid solid-buttons zs-border-rounded-0" type="button"
                                (click)="doMerge(item,index)" [disabled]="refreshIndicator" translate>
                                MERGE
                            </button>
                            <button [disabled]="refreshIndicator" *ngIf="!item?.searched"
                                class="zs-button solid-buttons zs-button-solid zs-border-rounded-0" type="button"
                                (click)="doNotAMatch(item,index)" translate>
                                NOT A MATCH
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="onUnmergePage && profiles?.length > 1">
                <!-- Unmerge button row when on Unmerge page -->
                <tr>
                    <td class="golden-profile"></td>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <button [disabled]="refreshIndicator"
                            class="zs-button zs-button-solid solid-buttons zs-border-rounded-0" type="button"
                            (click)="doUnmerge(item,index)" translate>
                            UNMERGE
                        </button>
                    </td>
                </tr>
            </ng-container>

            <tr *ngIf="golden?.MDM_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            MDM ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.MDM_ID">
                        {{golden.MDM_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>MDM ID </h5>
                        </div>
                        <div>
                            {{item?.MDM_ID?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.SRC_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            Source ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.SRC_ID">
                        {{golden?.SRC_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>Source ID </h5>
                        </div>
                        <div>
                            {{item?.SRC_ID?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PBM_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PBM ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PBM_ID">
                        {{golden?.PBM_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PBM ID </h5>
                        </div>
                        <div>
                            {{item?.PBM_ID?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.MCO_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            MCO ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.MCO_ID">
                        {{golden?.MCO_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>MCO ID </h5>
                        </div>
                        <div>
                            {{item?.MCO_ID?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.MCO_NAME">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            MCO NAME
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.MCO_NAME">
                        {{golden?.MCO_NAME.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>MCO NAME </h5>
                        </div>
                        <div>
                            {{item?.MCO_NAME?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PAYER_NAME">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PAYER NAME
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PAYER_NAME">
                        {{golden?.PAYER_NAME.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PAYER NAME </h5>
                        </div>
                        <div>
                            {{item?.PAYER_NAME?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PAYER_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PAYER ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PAYER_ID">
                        {{golden?.PAYER_ID?.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PAYER ID</h5>
                        </div>
                        <div>
                            {{item?.PAYER_ID?.value.join(', ')}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PAYER_CHANNEL">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PAYER CHANNEL
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PAYER_CHANNEL">
                        {{golden?.PAYER_CHANNEL?.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PAYER CHANNEL</h5>
                        </div>
                        <div>
                            {{item?.PAYER_CHANNEL?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PBM_NAME">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            Name
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PBM_NAME">
                        {{golden?.PBM_NAME?.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>Name</h5>
                        </div>
                        <div>
                            {{item?.PBM_NAME?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PBM_CHANNEL">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            CHANNEL
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PBM_CHANNEL">
                        {{golden?.PBM_CHANNEL?.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>CHANNEL</h5>
                        </div>
                        <div>
                            {{item?.PBM_CHANNEL?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PLAN_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PLAN ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PLAN_ID">
                        {{golden.PLAN_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PLAN ID </h5>
                        </div>
                        <div>
                            {{item?.PLAN_ID?.value.join(', ')}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PLAN_NAME">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PLAN NAME
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PLAN_NAME">
                        {{golden.PLAN_NAME.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PLAN NAME </h5>
                        </div>
                        <div>
                            {{item?.PLAN_NAME?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.PLAN_TYPE">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            PLAN TYPE
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.PLAN_TYPE">
                        {{golden.PLAN_TYPE.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>PLAN TYPE </h5>
                        </div>
                        <div>
                            {{item?.PLAN_TYPE?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.CHANNEL">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            CHANNEL
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.CHANNEL">
                        {{golden.CHANNEL.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>CHANNEL </h5>
                        </div>
                        <div>
                            {{item?.CHANNEL?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.BENIFIT_CONTROLLER_ID">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            BENIFIT CONTROLLER ID
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.BENIFIT_CONTROLLER_ID">
                        {{golden.BENIFIT_CONTROLLER_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>BENIFIT CONTROLLER ID </h5>
                        </div>
                        <div>
                            {{item?.BENIFIT_CONTROLLER_ID?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="golden?.BENIFIT_CONTROLLER_NAME">
                <td class="golden-profile">
                    <div class="zs-display-flex">
                        <h5 translate>
                            BENIFIT CONTROLLER NAME
                        </h5>

                    </div>
                    <ng-container *ngIf="golden.BENIFIT_CONTROLLER_NAME">
                        {{golden.BENIFIT_CONTROLLER_ID.value}}
                    </ng-container>
                </td>
                <ng-container>
                    <td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
                        class="profile-details-data">
                        <div class="zs-display-flex">
                            <h5 translate>BENIFIT CONTROLLER NAME </h5>
                        </div>
                        <div>
                            {{item?.BENIFIT_CONTROLLER_NAME?.value}}
                        </div>
                    </td>
                </ng-container>
            </tr>

        </tbody>
    </table>
</div>