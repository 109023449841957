import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterTable', pure: false })
export class FilterTablePipe implements PipeTransform {
    json = JSON;
    transform(columnData: Array<any>, attributesToCheck: string[], value: string = '') {
        value = value.toLowerCase();
        const filteredResults = [];
        for (let i = 0; i < columnData.length; i++) {
            for (let j = 0; j < attributesToCheck.length; j++) {
                if (columnData[i][attributesToCheck[j]] !== undefined && columnData[i][attributesToCheck[j]] !== null) {
                    if ((typeof columnData[i][attributesToCheck[j]] === 'string' &&
                        columnData[i][attributesToCheck[j]].toLowerCase().includes(value)) ||
                        columnData[i][attributesToCheck[j]].toString().toLowerCase().includes(value)) {
                        filteredResults.push(columnData[i]);
                        break;
                    }
                }
            }
        }
        return filteredResults;
    }
}
