import * as _ from 'lodash';

import {
  ActivityLogColumList,
  ActivityLogNavigateColumnList,
  FilterActivityColumList,
  MDMActivityLog,
  MDMActivityLogHeaderTableName,
  MDMCommonConstants,
  SortalbleActivityColumList,
} from '../constants/mdm-common-constants';
import {
  AppliedFiltersData,
  FilterData,
  selectedFilterColumnData,
  selectedFilterOptionData,
} from 'src/app/revo-core/table-column-filters/table-column-filters-models';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DateTimeConstants,
  MDMActivityLogAppliedFilterConstants,
} from 'src/app/shared/constants';
import { common_labels, common_labels as ui_common } from '../../shared/constants/ui_labels_translation_mapping';

import { ActivitiesCellRendererComponent } from './activities-cell-renderer/activities-cell-renderer.component';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { AppConfig } from 'src/app/app.config';
import { DatePipe } from '@angular/common';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { FilterOpenService } from 'src/app/revo-core/table-column-filters/table-column-filters.service';
import { MDMService } from '../mdm.service';
import { Subscription } from 'rxjs';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from 'src/app/app.utils';
import { ZsMultiSelectDropdownComponent } from '../../revo-core/zs-multi-select-dropdown/zs-multi-select-dropdown.component';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../constants/ui-translation';

@Component({
  selector: 'mdm-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivitiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedPast: string;

  activitiesLoading: boolean;
  activities: any[];
  allActivities: any[];
  error_label: string;
  translated_labels: any;
  commmon_labels = commmon_labels;
  ui_commmon = ui_common;
  commonLabels = common_labels;
  environment: EnvironmentConfig;
  dateTimeConstants = DateTimeConstants;
  ui_labels = mdm_labels.SUSPECTED_MATCHES.ACTIVITIES;
  activity_ui_labels = mdm_labels.ALL_RECORDS.PROFILE.ACTIVITIES;
  profileViewLabel: string;
  profileMergedLabel: string;
  profileUnmergedLabel: string;
  profileNotAMatchLabel: string;
  unknownLabel: string;
  profileColumnHeaderLabel: string;
  typeColumnHeaderLabel: string;
  userColumnHeaderLabel: string;
  timestampColumnHeaderLabel: string;
  activityColumnHeaderLabel: string;
  dataDefinitionMap = new Map<string, string>();
  loadingColumns: boolean;
  frameworkComponents: any;
  columnDefs: any[];
  rowData: any[];
  gridApi: any;
  gridColumnApi: any;
  loadData = { value: false };
  isCalledFirstTimeLoader = false;
  loadingLabel: any;
  clearAllFilterSubscription: Subscription;
  filterSelectedOptionSubscription: Subscription;
  filterOpenSubscription: Subscription;
  filterSelectSubscription: Subscription;
  appliedFilters: Array<AppliedFiltersData> = [];
  preAppliedFilters: Array<AppliedFiltersData> = [];
  filters: Array<AppliedFiltersData>;
  filterData = {
    ATVY_TYPE: [],
    USR_NM: [],
    ATVY_DESC: [],
  };

  isRowSelectable: any;
  gridOptions = {};
  sortByColumn = MDMActivityLogHeaderTableName.MODF_DT;
  sortByOrder = MDMCommonConstants.DESC;
  noMDMActivities = false;
  suppressNoRow = true;
  newGridLoaded = true;
  noRowsTemplate: string;
  threeDotConstant = "...";

  @Input() page: number;
  @Input() pageSize: number;

  @Output() setPaginationItemCount = new EventEmitter<{ itemCount: number, page: number }>();

  constructor(
    private service: MDMService,
    private _translate: TranslateService,
    private config: AppConfig,
    private _datePipe: DatePipe,
    private _zsFilterOpenService: FilterOpenService,
    private _toasterDataService: ToasterDataService
  ) {
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant([
      this.activity_ui_labels.PROFILE_VIEW,
      this.activity_ui_labels.PROFILE_MERGED,
      this.activity_ui_labels.PROFILE_UNMERGED,
      this.activity_ui_labels.PROFILE_NOT_A_MATCH,
      this.activity_ui_labels.UNKNOWN,
      this.commmon_labels.ERROR_WITH_COLON,
      this.commmon_labels.USER,
      this.commmon_labels.TIMESTAMP,
      this.activity_ui_labels.PROFILE,
      this.activity_ui_labels.TYPE,
      this.activity_ui_labels.ACTIVITY,
      this.ui_commmon.YOUR_RESULTS_ARE_LOADING,
      this.commonLabels.NO_RECORDS_TO_DISPLAY
    ]);
    this.profileViewLabel = this.translated_labels[
      this.activity_ui_labels.PROFILE_VIEW
    ];
    this.profileMergedLabel = this.translated_labels[
      this.activity_ui_labels.PROFILE_MERGED
    ];
    this.profileUnmergedLabel = this.translated_labels[
      this.activity_ui_labels.PROFILE_UNMERGED
    ];
    this.profileNotAMatchLabel = this.translated_labels[
      this.activity_ui_labels.PROFILE_NOT_A_MATCH
    ];
    this.unknownLabel = this.translated_labels[this.activity_ui_labels.UNKNOWN];
    this.error_label = this.translated_labels[
      this.commmon_labels.ERROR_WITH_COLON
    ];
    this.profileColumnHeaderLabel = this.translated_labels[
      this.activity_ui_labels.PROFILE
    ];
    this.typeColumnHeaderLabel = this.translated_labels[
      this.activity_ui_labels.TYPE
    ];
    this.userColumnHeaderLabel = this.translated_labels[
      this.commmon_labels.USER
    ];
    this.timestampColumnHeaderLabel = this.translated_labels[
      this.commmon_labels.TIMESTAMP
    ];
    this.activityColumnHeaderLabel = this.translated_labels[
      this.activity_ui_labels.ACTIVITY
    ];
    this.loadingLabel = this.translated_labels[
      this.ui_commmon.YOUR_RESULTS_ARE_LOADING
    ];
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.NAME] = this.profileColumnHeaderLabel;
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.ATVY_TYPE] = this.typeColumnHeaderLabel;
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.USR_NM] = this.userColumnHeaderLabel;
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.MODF_DT] = this.timestampColumnHeaderLabel;
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.ATVY_DESC] = this.activityColumnHeaderLabel;
    this.dataDefinitionMap[MDMActivityLogHeaderTableName.MDM_ID] = '';
    this.noRowsTemplate = Utils.generateNoRowsHtml(this.translated_labels[this.commonLabels.NO_RECORDS_TO_DISPLAY]);

    this.filterOpenSubscription = this._zsFilterOpenService
      .getFilterStatus()
      .subscribe((filterOpen: selectedFilterColumnData) => {
        this.selectedFilterColumnEvent(filterOpen);
      });

    this.filterSelectSubscription = this._zsFilterOpenService
      .getLoadFilterDataStatus()
      .subscribe((filterOpen: selectedFilterOptionData) => {
        this.selectedFilterOption(filterOpen);
      });

    this.clearAllFilterSubscription = this._zsFilterOpenService
      .getClearAllFilterStatus()
      .subscribe((clearFilterObject) => {
        this.clearFilters(clearFilterObject);
      });
  }

  ngOnInit() {
    this.gridOptions = {
      suppressHorizontalScroll: true,
      headerHeight: 50,
      rowHeight: 50,
      enableCellTextSelection: true,
      suppressCellSelection: true,
      suppressLoadingOverlay: true,
    };
    this.frameworkComponents = {
      customFilter: ZsMultiSelectDropdownComponent,
      matchCellRenderer: ActivitiesCellRendererComponent,
    };
  }

  ngOnDestroy() {
    this.filterOpenSubscription.unsubscribe();
    this.clearAllFilterSubscription.unsubscribe();
    this.filterSelectSubscription.unsubscribe();
  }

  getActivityDesc(code: string) {
    switch (code.toLowerCase()) {
      case MDMActivityLog.PROFILE:
        return this.profileViewLabel;
      case MDMActivityLog.MERGE:
        return this.profileMergedLabel;
      case MDMActivityLog.UNMERGE:
        return this.profileUnmergedLabel;
      case MDMActivityLog.NOT_A_MATCH:
        return this.profileNotAMatchLabel;
      default:
        return this.unknownLabel;
    }
  }

  getActivityImg(code: string) {
    switch (code.toLowerCase()) {
      case MDMActivityLog.PROFILE:
        return 'zs-icon-user-deligate';
      case MDMActivityLog.MERGE:
        return 'zs-icon-group';
      case MDMActivityLog.UNMERGE:
        return 'zs-icon-ungroup';
      case MDMActivityLog.NOT_A_MATCH:
        return 'zs-icon-stop';
      default:
        return 'zs-icon-rejected-approval';
    }
  }

  loadActivities(page = 1) {
    this.activitiesLoading = true;
    if (this.preAppliedFilters.length) {
      this.appliedFilters = _.cloneDeep(this.preAppliedFilters);
    }
    this.service
      .getActivitiesData(
        this.page,
        this.pageSize,
        this.selectedPast,
        this.appliedFilters,
        this.sortByColumn,
        this.sortByOrder
      )
      .subscribe(
        (result) => {
          if (!this.activities) {
            this.activities = [];
          }
          if (result && result.data && result.data.length > 0) {
            this.activities = [...result.data];
          } else {
            this.activities = [];
          }
          this.setPaginationItemCount.emit({ itemCount: result.totalRecords, page: this.page });
          this.setRowData();
          this.activitiesLoading = false;
          if (!this.appliedFilters.length && !result.data.length) {
            this.noMDMActivities = true;
          } else {
            this.noMDMActivities = false;
          }
        },
        (exception) => {
          this.activities = [];
          this.activitiesLoading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  setColumnDefs() {
    for (const [key, value] of Object.entries(this.dataDefinitionMap)) {
      /* Key is the field we will use for the agGrid column def and
      value is the translated column header that should be displayed. */
      const activityLogColumnDef = {
        [AgGridColumnDefsConstants.HEADER_NAME]: value,
        [AgGridColumnDefsConstants.FIELD]: key,
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
        [AgGridColumnDefsConstants.MIN_WIDTH]: 50,
        [AgGridColumnDefsConstants.SORTABLE]: SortalbleActivityColumList.includes(
          key
        )
          ? true
          : false,
        [AgGridColumnDefsConstants.UNSORT_ICON]: SortalbleActivityColumList.includes(
          key
        ),
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: value,
        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: (cellParams) =>
          cellParams.value,
        [AgGridColumnDefsConstants.CELL_STYLE]: { 'text-overflow': 'ellipsis', 'overflow': 'hidden' },
        [AgGridColumnDefsConstants.COMPARATOR]: (
          valueA,
          valueB,
          nodeA,
          nodeB,
          isInverted
        ) => {
          return 0;
        },
        [AgGridColumnDefsConstants.HIDE]: ActivityLogColumList.includes(key)
          ? false
          : true,

        [AgGridColumnDefsConstants.CELL_RENDERER]: ActivityLogNavigateColumnList.includes(
          key
        )
          ? 'matchCellRenderer'
          : '',
        [AgGridColumnDefsConstants.CELL_RENDERER_PARAMS]: {
          filter: MDMActivityLogHeaderTableName.ATVY_TYPE,
          mdmId: key === MDMActivityLogHeaderTableName.MDM_ID,
        },
        [AgGridColumnDefsConstants.FILTER]: FilterActivityColumList.includes(key) ? 'customFilter' : '',
        [AgGridColumnDefsConstants.FILTER_PARAMS]: () => {
          this.loadData.value = true;
          return {
            loadData: this.loadData,
            isFilter: true,
            dataSource: this.filterData[key],
            displayLabelKey: 'Label',
            minOptionsForSearch: 3,
            filterId: key,
            filterName: value,
            showClearAllOption: true,
            PreSelectedOptionValues: [],
          };
        },
      };
      this.columnDefs.push(activityLogColumnDef);
    }
  }

  setRowData() {
    this.rowData = [];
    this.activities.forEach((activity) => {
      const activityLogEntry = {};
      for (const [key, value] of Object.entries(this.dataDefinitionMap)) {
        if (key === MDMActivityLogHeaderTableName.ATVY_DESC) {
          activityLogEntry[key] = this.getActivityDesc(activity[key]);
        } else if (key === MDMActivityLogHeaderTableName.MODF_DT) {
          activityLogEntry[key] = this._datePipe.transform(
            activity[key] + DateTimeConstants.Z,
            this.environment.dateTimeFormat.timeFormats
          );
        } else {
          activityLogEntry[key] = activity[key];
        }
      }
      this.rowData.push(activityLogEntry);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  ngOnChanges() {
    this.columnDefs = [];
    this.allActivities = [];
    this.activities = [];
    this.loadActivities();
    this.setColumnDefs();
  }

  selectedFilterColumnEvent(value: selectedFilterColumnData) {
    this.service
      .getMdmActivityLogFilterValues(value.columnId, this.selectedPast)
      .subscribe((result) => {
        for (const val of result) {
          const data = new FilterData();
          if (value.columnId === MDMActivityLogHeaderTableName.ATVY_DESC) {
            data.Value = val[0];
            data.Label = this.getActivityDesc(val[0]);
          } else {
            data.Value = val[0];
            data.Label = val[0];
          }
          data.IsSelected = this.preAppliedFilters.filter((filter) =>
            filter.value_list.includes(val[0])).length > 0 ? true : false;
          this.filterData[value.columnId].push(data);
        }
        const preAppliedFilter = this.preAppliedFilters.filter(
          (filter) => filter.column_name === value.columnId
        );
        if (preAppliedFilter.length) {
          const valueList = preAppliedFilter[0].value_list;
          for (const item of valueList) {
            const filterValue = item;
            const filter = this.filterData[value.columnId].filter(
              (data) => data.Value === filterValue
            );
            if (!filter.length) {
              const data = new FilterData();
              data.Value = filterValue;
              data.Label = filterValue;
              if (value.columnId === MDMActivityLogHeaderTableName.ATVY_DESC) {
                data.Label = this.getActivityDesc(filterValue);
              }
              data.IsSelected = true;
              this.filterData[value.columnId].push(data);
            }
          }
        }
        if (this.preAppliedFilters.length) {
          this.appliedFilters = _.cloneDeep(this.preAppliedFilters);
        }
      },
      (exception) => {
        this._toasterDataService.addMessageToToaster(exception.error.message);
      }
    );
  }

  selectedFilterOption(value) {
    this.preAppliedFilters = this.appliedFilters;
    if (value.IsSelected) {
      const option = this.appliedFilters.filter(
        (data) => data.column_name === value.filterId
      );
      if (option.length > 0) {
        option[0].value_list.push(value.item.Value);
      } else {
        const filterObj = new AppliedFiltersData();
        filterObj.column_name = value.filterId;
        filterObj.value_list = [value.item.Value];
        this.appliedFilters.push(filterObj);
      }
    } else {
      const option = this.appliedFilters.filter(
        (data) => data.column_name === value.filterId
      );
      if (option.length > 0) {
        const filterIndex = this.appliedFilters.indexOf(option[0]);
        const unselectedOptionIndex = this.appliedFilters[
          filterIndex
        ].value_list.indexOf(value.item.Value);
        this.appliedFilters[filterIndex].value_list.splice(
          unselectedOptionIndex,
          1
        );
      }
    }
    this.page = 1;
    this.loadActivities();
  }

  clearFilters(value) {
    if (value.clearFilter) {
      this.appliedFilters = this.appliedFilters.filter(
        (filter) =>
          filter[MDMActivityLogAppliedFilterConstants.FilterColumnNameKey] !==
          value.filterName
      );
      this.preAppliedFilters = _.cloneDeep(this.appliedFilters);
      this.loadActivities();
    }
  }

  onSort($event) {
    if (this.gridApi) {
      const columnsSortState = $event.columnApi.getColumnState();
      let columnName = MDMActivityLogHeaderTableName.MODF_DT;
      let columnSortOrder = MDMCommonConstants.DESC;
      for (const column of columnsSortState) {
        if (column.sort) {
          columnName = column.colId;
          columnSortOrder = column.sort;
          break;
        }
      }
      this.sortByColumn = columnName;
      this.sortByOrder = columnSortOrder;

      this.loadActivities();
    }
  }

  resetFilter() {
    this.gridApi.destroyFilter(MDMActivityLogHeaderTableName.ATVY_TYPE);
    this.gridApi.destroyFilter(MDMActivityLogHeaderTableName.USR_NM);
    this.gridApi.destroyFilter(MDMActivityLogHeaderTableName.ATVY_DESC);
    this.preAppliedFilters = _.cloneDeep(this.appliedFilters);
    this.appliedFilters.length = 0;
    this.filterData[MDMActivityLogHeaderTableName.ATVY_TYPE].length = 0;
    this.filterData[MDMActivityLogHeaderTableName.USR_NM].length = 0;
    this.filterData[MDMActivityLogHeaderTableName.ATVY_DESC].length = 0;
  }
}
