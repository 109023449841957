import * as _ from 'lodash';

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DashboardMatchesCommonColumnList,
  ExcludeMatchColumnList,
  HcoNameColumn,
  HcpNameColumn,
  MDMCommonConstants,
  MDMDashboardPeriodList,
  MDMDashboardSuspectedMatchesApiResponseConstants,
  MDMWrapperConstants,
  MaxWidthColumnList,
  SortableColumnList,
} from '../../constants/mdm-common-constants';
import { EventEmitter, ViewEncapsulation } from '@angular/core';

import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { AppConfig } from 'src/app/app.config';
import { DatePipe } from '@angular/common';
import { DateTimeConstants } from 'src/app/shared/constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { FilterTablePipe } from 'src/app/revo-core/filterTablePipe';
import { MDMService } from 'src/app/mdm/mdm.service';
import { MatchesCellRendererComponent } from './matches-cell-renderer/matches-cell-renderer.component';
import { MultiSelectDropDown } from 'src/app/shared/models/multiSelectDropDown';
import { MultiSelectDropdownComponent } from 'src/app/revo-core/multi-select-dropdown/multi-select-dropdown.component';
import { Output } from '@angular/core';
import { PaggingPipe } from './mdm-specific-pipes/pagging.pipe';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from 'src/app/app.utils';
import { common_labels } from '../../../shared/constants/ui_labels_translation_mapping';
import { mdm_labels } from '../../constants/ui-translation';
import { url } from '../../constants/api-urls';
import { TabConfig }  from 'src/assets/listtabs/Tab.js';
import { containsSpecialCharacters } from '../../../shared/utils/string-check-utils';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MatchesComponent implements OnInit {
  chartFilters = [
    { value: MDMCommonConstants.HCP, label: MDMCommonConstants.HCP, isVisible: TabConfig.Entity_Type.HCP },
    { value: MDMCommonConstants.HCO, label: MDMCommonConstants.HCO, isVisible: TabConfig.Entity_Type.HCO },
    { value: MDMCommonConstants.PAYER, label: MDMCommonConstants.PAYER, isVisible: TabConfig.Entity_Type.PAYER },
  ];

  visibleChartFilters = this.chartFilters.filter(obj => obj.isVisible);

  @Output() selectedFilterChanged = new EventEmitter<any>();
  matchesLoading = true;
  matches: any[];
  sortedField = 6;
  sortOrder: string = MDMCommonConstants.DESC;
  page = 1;
  pageSize: number;
  totalRecords = 0;
  searchBy = '';

  error_label: string;
  translated_labels: any;
  viewLabel: string;
  commmon_labels = common_labels;
  showNoSuspectedMatchesRecordFound = true;
  environment: EnvironmentConfig;
  dateTimeConstants = DateTimeConstants;
  ui_labels = mdm_labels.SUSPECTED_MATCHES.DASHBOARD.MATCHES;
  searchSuspectedMatches: string;
  MDMCommonConstants = MDMCommonConstants;
  suspectedMatchesLabel: string;

  @ViewChild('pastFilterSelect', { static: true })
  pastFilterSelect: MultiSelectDropdownComponent;
  periodList = [];
  suspectedMatchesApiResponseConstants = MDMDashboardSuspectedMatchesApiResponseConstants;
  allLabel: string;
  past24HoursLabel: string;
  past7DaysLabel: string;
  past30DaysLabel: string;
  past6monthsLabel: string;
  pastYearLable: string;
  MDMDashboardPastFilterConstant = new Map<string, string>();
  selectPeriodLabel: string;
  selectedPeriodValue: string = MDMCommonConstants.ALL;
  colDefObject = {};
  colDefs = [];
  gridOptions: object;
  defaultColDef: object;
  mdmAllRecordsHcoProfile = url.allRecordsHcoProfile;
  mdmAllRecordsHcpProfile = url.allRecordsHcpProfile;
  frameworkComponents: object;
  gridApi: any;
  gridColumnApi: any;
  filteredMatches: any[];
  hcoColumns = [...DashboardMatchesCommonColumnList, HcoNameColumn];
  hcpColumns = [...DashboardMatchesCommonColumnList, HcpNameColumn];
  filterTablePipe = new FilterTablePipe();
  pagingPipe = new PaggingPipe();
  rowData: any[];
  sortByColumn: string;
  loadingOverlayComponent: string;
  disableGridSorting = false;
  showAgGridLoader = true;
  loadingLabel: string;
  searchApplied = false;
  firstCall = true;
  noRowsTemplate: string;
  threeDotConstant = "...";
  selectedFilter: any;

  checkSpecialCharacter: boolean = false ;

  constructor(
    private service: MDMService,
    private _translate: TranslateService,
    private config: AppConfig,
    private _datePipe: DatePipe,
    private _toasterDataService: ToasterDataService
  ) {
    const last = sessionStorage.getItem(
      MDMCommonConstants.LAST_VISITED_PROFILE_TYPE
    );
    if (last) {
      this.selectedFilter = this.visibleChartFilters.find((f) => f.value === last);
    } else {
      sessionStorage.setItem(
        MDMCommonConstants.LAST_VISITED_PROFILE_TYPE,
        this.selectedFilter.value
      );
    }
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant([
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels.SEARCH_SUSPECTED_MATCHES,
      this.ui_labels.ALL,
      this.ui_labels.PAST_24_HOURS,
      this.ui_labels.PAST_30_DAYS,
      this.ui_labels.PAST_6_MONTHS,
      this.ui_labels.PAST_YEAR,
      this.ui_labels.PAST_7_DAYS,
      this.ui_labels.VIEW,
      this.ui_labels.MDM_ID,
      this.ui_labels.NAME,
      this.ui_labels.FIRST_MATCH_DATE,
      this.ui_labels.LAST_MATCH_DATE,
      this.ui_labels.RECORD_COUNT,
      this.ui_labels.HIGHEST_MATCH_PERCENTAGE,
      this.ui_labels.SUSPECT_SOURCES,
      this.commmon_labels.YOUR_RESULTS_ARE_LOADING,
      this.commmon_labels.NO_RECORDS_TO_DISPLAY
    ]);
    this.error_label = this.translated_labels[
      this.commmon_labels.ERROR_WITH_COLON
    ];
    this.searchSuspectedMatches = this.translated_labels[
      this.ui_labels.SEARCH_SUSPECTED_MATCHES
    ];
    this.allLabel = this.translated_labels[this.ui_labels.ALL];
    this.past24HoursLabel = this.translated_labels[
      this.ui_labels.PAST_24_HOURS
    ];
    this.past7DaysLabel = this.translated_labels[this.ui_labels.PAST_7_DAYS];
    this.past30DaysLabel = this.translated_labels[this.ui_labels.PAST_30_DAYS];
    this.past6monthsLabel = this.translated_labels[
      this.ui_labels.PAST_6_MONTHS
    ];
    this.pastYearLable = this.translated_labels[this.ui_labels.PAST_YEAR];
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.ALL
    ] = this.allLabel;
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.PAST_24_HOURS
    ] = this.past24HoursLabel;
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.PAST_7_DAYS
    ] = this.past7DaysLabel;
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.PAST_30_DAYS
    ] = this.past30DaysLabel;
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.PAST_6_MONTHS
    ] = this.past6monthsLabel;
    this.MDMDashboardPastFilterConstant[
      MDMDashboardPeriodList.PAST_YEAR
    ] = this.pastYearLable;
    this.selectPeriodLabel = this.translated_labels[this.ui_labels.ALL];
    this.viewLabel = this.translated_labels[this.ui_labels.VIEW];

    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.MSTR_MDM_ID
    ] = this.translated_labels[this.ui_labels.MDM_ID];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.MSTR_NAME
    ] = this.translated_labels[this.ui_labels.NAME];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.MSTR_ACCT_NM
    ] = this.translated_labels[this.ui_labels.NAME];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.FIRST_MATCH_DATE
    ] = this.translated_labels[this.ui_labels.FIRST_MATCH_DATE];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.LAST_MATCH_DATE
    ] = this.translated_labels[this.ui_labels.LAST_MATCH_DATE];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.COUNT
    ] = this.translated_labels[this.ui_labels.RECORD_COUNT];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.PERCENT
    ] = this.translated_labels[this.ui_labels.HIGHEST_MATCH_PERCENTAGE];
    this.colDefObject[
      this.suspectedMatchesApiResponseConstants.SOURCES
    ] = this.translated_labels[this.ui_labels.SUSPECT_SOURCES];
    this.loadingLabel = this.translated_labels[this.commmon_labels.YOUR_RESULTS_ARE_LOADING];
    this.noRowsTemplate = Utils.generateNoRowsHtml(this.translated_labels[this.commmon_labels.NO_RECORDS_TO_DISPLAY]);
  }

  setTotalPages(total) {
    this.totalRecords = total;
    const suspectedMatches = this._translate.instant(
      this.ui_labels.SUSPECTED_MATCHES_LABEL,
      {
        selected_filter: this.selectedFilter.label,
        total_records: this.totalRecords,
      }
    );
    this.suspectedMatchesLabel = suspectedMatches;
  }

  ngOnInit() {
    this.selectedFilter = this.visibleChartFilters[0];

    this.pageSize = this.environment.pageSize;

    this.initializePeriodList();

    this.frameworkComponents = {
      matchCellRenderer: MatchesCellRendererComponent
    };

    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };

    this.gridOptions = {
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      [AgGridColumnDefsConstants.FRAMEWORK_COMPONENTS]: this
        .frameworkComponents,
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.setColumnDefs();
    this.filterChanged();
  }

  initializePeriodList() {
    const temp = [];
    for (const [key, value] of Object.entries(
      this.MDMDashboardPastFilterConstant
    )) {
      const selectOption = new MultiSelectDropDown();
      selectOption.displayLabel = value;
      selectOption.value = key;
      temp.push(selectOption);
    }
    this.periodList = temp;
    // this.pastFilterSelect.configure();
  }

  resetToHighestMatchPercentage() {
    this.sortedField = 6;
    this.sortOrder = MDMCommonConstants.DESC;
  }

  resetData() {
    this.matches = [];
    this.searchBy = '';
    this.selectedPeriodValue = MDMCommonConstants.ALL;
    this.resetToHighestMatchPercentage();
    this.initializePeriodList();
  }

  filterChanged() {
    this.checkSpecialCharacter = false;
    const suspectedMatches = this._translate.instant(
      this.ui_labels.SUSPECTED_MATCHES_LABEL,
      {
        selected_filter: this.selectedFilter.label,
        total_records: this.totalRecords,
      }
    );
    this.suspectedMatchesLabel = suspectedMatches;
    this.selectedFilterChanged.emit(this.selectedFilter);
    this.service.setEntityTypeFlagChangedStatus(true);
    this.service.setFilterChangedStatus(this.selectedFilter);
    sessionStorage.setItem(
      MDMCommonConstants.LAST_VISITED_PROFILE_TYPE,
      this.selectedFilter.value
    );
    if (this.selectedFilter.value != 'PAYER') {
      this.resetData();
      this.page = 1;
      this.sortByColumn = undefined;
      this.setColumnDefs();
      this.loadMatches();
      this.initialSorting();
      this.disableGridSorting = false;
    } else {
      this.matchesLoading = false;
    }
  }

  sortMatches(col, direction, fields) {
    this.sortedField = col;
    this.sortOrder =
      direction === ''
        ? MDMCommonConstants.ASC
        : direction === MDMCommonConstants.ASC
          ? MDMCommonConstants.DESC
          : MDMCommonConstants.ASC;

    if (this.sortOrder === MDMCommonConstants.ASC) {
      this.matches = _.sortBy(this.matches, fields);
    } else {
      this.matches = _.reverse(_.sortBy(this.matches, fields));
    }
    this.loadFilteredData();
  }

  resetSearch() {
    this.page = 1;
    this.searchBy = '';
    this.loadMatches();
  }

  pageChanged(e) {
    this.page = e;
    this.searchBy = this.searchApplied ? this.searchBy : '';
    this.loadMatches();
  }

  defaultSortOnPercentScore() {
    if (this.selectedFilter.value === MDMCommonConstants.HCP) {
      this.resetToHighestMatchPercentage();
      this.matches = _.orderBy(
        this.matches,
        [
          this.suspectedMatchesApiResponseConstants.PERCENT,
          this.suspectedMatchesApiResponseConstants.MSTR_NAME,
        ],
        [MDMCommonConstants.DESC, MDMCommonConstants.ASC]
      );
    } else if (this.selectedFilter.value === MDMCommonConstants.HCO) {
      this.resetToHighestMatchPercentage();
      this.matches = _.orderBy(
        this.matches,
        [
          this.suspectedMatchesApiResponseConstants.PERCENT,
          this.suspectedMatchesApiResponseConstants.MSTR_ACCT_NM,
        ],
        [MDMCommonConstants.DESC, MDMCommonConstants.ASC]
      );
    }
  }

  applyDatePipeOnMatchData() {
    for (const item of this.matches) {
      item[
        this.suspectedMatchesApiResponseConstants.FIRST_MATCH_DATE
      ] = this._datePipe.transform(
        item[this.suspectedMatchesApiResponseConstants.FIRST_MATCH_DATE] +
        DateTimeConstants.Z,
        this.environment.dateTimeFormat.dateFormats
      );
      item[
        this.suspectedMatchesApiResponseConstants.LAST_MATCH_DATE
      ] = this._datePipe.transform(
        item[this.suspectedMatchesApiResponseConstants.LAST_MATCH_DATE] +
        DateTimeConstants.Z,
        this.environment.dateTimeFormat.dateFormats
      );
    }
  }

  loadMatches() {
    this.matchesLoading = true;
    this.showAgGridLoader = true;
    this.service
      .getMatchesDataForDashboard(
        this.selectedFilter.value,
        this.selectedPeriodValue,
        this.sortByColumn,
        this.sortOrder,
        this.searchBy,
        this.page,
        this.pageSize
      )
      .subscribe(
        (data) => {
          this.matchesLoading = false;
          this.showNoSuspectedMatchesRecordFound = true;
          this.matches = data.data;
          this.applyDatePipeOnMatchData();
          this.totalRecords = data.totalRecords;
          this.loadFilteredData();
          this.showAgGridLoader = false;
        },
        (exception) => {
          this.matchesLoading = false;
          this.showAgGridLoader = false;
          this.showNoSuspectedMatchesRecordFound = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  OnChangeFilter(event) {
    this.page = 1;
    if (event.length && event[0].value !== this.selectedPeriodValue) {
      this.selectedPeriodValue = event[0].value;
      this.loadMatches();
    }
  }

  setColumnDefs() {
    this.colDefs = [];
    for (const [key, value] of Object.entries(this.colDefObject)) {
      if (
        (this.selectedFilter.label === MDMCommonConstants.HCO &&
          key === this.suspectedMatchesApiResponseConstants.MSTR_NAME) ||
        (this.selectedFilter.label === MDMCommonConstants.HCP &&
          key === this.suspectedMatchesApiResponseConstants.MSTR_ACCT_NM)
      ) {
        continue;
      }
      const matchesColDef = {
        [AgGridColumnDefsConstants.HEADER_NAME]: value,
        [AgGridColumnDefsConstants.FIELD]: key,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: value,
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.MAX_WIDTH]: MaxWidthColumnList.includes(key)
          ? 150
          : 250,
        [AgGridColumnDefsConstants.SORTABLE]: SortableColumnList.includes(key),
        [AgGridColumnDefsConstants.UNSORT_ICON]: SortableColumnList.includes(
          key
        ),
        [AgGridColumnDefsConstants.CELL_STYLE]: key === this.suspectedMatchesApiResponseConstants.MSTR_NAME ?
          { 'text-overflow': 'ellipsis', 'overflow': 'hidden' }
          [AgGridColumnDefsConstants.COMPARATOR] : (
            valueA,
            valueB,
            nodeA,
            nodeB,
            isInverted
          ) => {
            return 0;
          },
        [AgGridColumnDefsConstants.TOOLTIP_FIELD]: ['Description'].includes(
          key
        )
          ? key
          : '',
        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: this.getCellTooltip(),
        [AgGridColumnDefsConstants.CELL_RENDERER]: ExcludeMatchColumnList.includes(
          key
        )
          ? MatchesCellRendererComponent
          : '',
        [AgGridColumnDefsConstants.CELL_RENDERER_PARAMS]: {
          sources:
            key === MDMDashboardSuspectedMatchesApiResponseConstants.SOURCES,
          percent:
            key === MDMDashboardSuspectedMatchesApiResponseConstants.PERCENT,
          routeLink:
            this.selectedFilter.label === MDMCommonConstants.HCO
              ? this.mdmAllRecordsHcoProfile
              : this.mdmAllRecordsHcpProfile,
          filter: this.selectedFilter.label,
          environment: this.environment,
          mdmId:
            key ===
            MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_MDM_ID,
        },
      };
      this.colDefs.push(matchesColDef);
    }
  }

  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }

  initialSorting() {
    this.disableGridSorting = true;
    const colState = [];
    for (const [key, value] of Object.entries(this.colDefObject)) {
      colState.push(
        {
          colId: key,
          sort: key === this.suspectedMatchesApiResponseConstants.PERCENT ? MDMCommonConstants.DESC : null,
        }
      );
    }
    const columnState = {
      state: colState
    };
    this.gridColumnApi?.applyColumnState(columnState);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.initialSorting();

    /* Edge 17039 changes starts
      in case of routing from Suspected Matches Grid, Setting activeTab to "Suspected Matches" */
    this.service.setActiveTab(MDMWrapperConstants.SUSPECTED_MATCHES);
    // Edge 17039 changes ends
  }

  clearSearch(value) {
    if (!value && !this.firstCall) {
      this.loadMatches();
    }
    this.searchApplied = false;
    this.firstCall = false;
    this.checkSpecialCharacter = false;
  }

  searchByChanged() {

    this.checkSpecialCharacter = containsSpecialCharacters(this.searchBy);
    if(!this.checkSpecialCharacter){
      this.searchApplied = true;
      this.page = 1;
      if (this.searchBy !== '') {
        this.loadMatches();
      }
    }
  }

  loadFilteredData() {
    this.filteredMatches = this.matches;
    this.loadRowData();
    this.setTotalPages(this.totalRecords);
  }

  loadRowData() {
    this.rowData = this.pagingPipe.transform(
      this.filteredMatches,
      1,
      this.pageSize
    );
  }

  onSort(event) {
    if (!this.disableGridSorting) {
      const columnsSortState = event.columnApi.getColumnState();
      let colId: number;
      let colName = '';
      let columnSortOrder = '';
      let i = 0;
      for (const column of columnsSortState) {
        if (column.sort) {
          colId = i + 1;
          colName = column.colId;
          columnSortOrder = column.sort;
          break;
        }
        i++;
      }
      this.sortOrder = columnSortOrder;
      this.sortByColumn = colName;
      this.loadMatches();
    }
    this.disableGridSorting = false;
  }


}
