import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { AppConfig } from 'src/app/app.config';
import {
  MDMCommonConstants,
  PayerWrapperConstants,
} from 'src/app/mdm/constants/mdm-common-constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
@Component({
  selector: 'app-payer-cell-renderer',
  templateUrl: './payer-cell-renderer.component.html',
  styleUrls: ['./payer-cell-renderer.component.less'],
})
export class PayerCellRendererComponent implements OnInit {
  params: any;
  data: any;
  id: any;
  MDMCommonConstants = MDMCommonConstants;
  PayerWrapperConstants = PayerWrapperConstants;
  sources: any;
  environment: EnvironmentConfig;
  name: any;
  percent: any;

  constructor(private config: AppConfig, private router: Router) {
    this.environment = this.config.getConfigObject();
  }

  ngOnInit(): void { }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;
    this.id = this.params.MSTR_MDM_ID;
    this.name = this.params.MSTR_PAYER_NAME
    this.sources = this.params.SOURCES;
    this.data.suspected_sources = this.data.suspected_sources;
    this.percent = this.params.PERCENT;
  }

  refresh(params) {
    return true;
  }
  navigate(id) {
    this.router.navigate(['/mdm/all-records/payer/profile/', id], {
      state: { entity: PayerWrapperConstants.PAYER, return_page: 'suspected' },
    });
  }
}
