import { RouterModule, Routes } from '@angular/router';

import { AppRoles } from './constants';
import { AuthGuard } from './auth.guard';
import { NavigationTabs } from './shared/constants';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '', redirectTo: 'mdm', pathMatch: 'full',
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      navigationTabName: [NavigationTabs.HOME]
    }
  },
  {
    path: 'mdm', loadChildren: () => import('./mdm/mdm.module').then(m => m.MDMModule),
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.MasterDataSteward, AppRoles.ADMIN],
      visibility: true,
      navigationTabName: [NavigationTabs.MASTER]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
