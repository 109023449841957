export const MDMCommonConstants = {
  HCO: 'HCO',
  HCP: 'HCP',
  PAYER: 'PAYER',
  DCR: 'DCR',
  Y: 'Y',
  N: 'N',
  NPI: 'NPI',
  ME: 'ME',
  SLN: 'SLN',
  DEA: 'DEA',
  UNKNOWN: 'unknown',
  COPY_MOVE: 'copyMove',
  LAST_VISITED_PROFILE_TYPE: 'lastVisitedProfileType',
  LAST_VISITED_HCP: 'lastVisitedHCP',
  LAST_VISITED_HCO: 'lastVisitedHCO',
  HIN: 'HIN',
  ASC: 'asc',
  DESC: 'desc',
  PROFILE_BREADCRUM_SHOW_LIMIT: 30,
  MDM_ID: 'MDM ID',
  ALL: 'ALL',
  NAME: 'Name',
  HCO_WITHIN_URL: '/hco/',
  HOME: 'home',
  ADDRESSES: 'ADDRESSES',
  COMMUNICATIONS: 'Communication',
  IDENTIFIERS: 'Identifiers',
  ADDRESS: 'Address',
  DRAG_Z_INDEX: 1501,
};

export const MDMActivityLog = {
  PROFILE: 'profile',
  MERGE: 'merge',
  UNMERGE: 'unmerge',
  NOT_A_MATCH: 'notamatch',
};

export const DetailsConstants = {
  ATTRIBUTES: 'ATTRIBUTES',
  GOLDEN_VALUE: 'GOLDEN_VALUE',
  SOURCE_VALUES: 'SOURCE_VALUES',
  SURVIVORSHIP_RULES: 'SURVIVORSHIP_RULES',
  MAX_LENGTH_FOR_ELLIPSES: 30,
  MAX_LENGTH_FOR_ELLIPSES_IN_MATCHES: 18,
};

export const MDMWrapperConstants = {
  SUSPECTED_MATCHES: 'suspected-matches',
  PROFILE: 'profile',
  SEARCH: 'search',
  DESK_RESEARCH: 'desk-research',
  ALL_RECORDS: 'all-records',
  ADVANCED_SEARCH: 'advanced-search',
  DCR_MANAGEMENT: 'dcr-management',
  ACTIVITY_LOG: 'activity-log',
  ADMIN: 'admin',
};
export const PayerWrapperConstants = {
  PLAN: 'PLAN',
  PAYER: 'PAYER',
  PBM: 'PBM',
  MCO: 'MCO'
};

export const PBMTableConstant = {
  MDM_ID: 'MSTR_MDM_ID',
  PBM_NAME: 'MSTR_PBM_NAME',
  PBM_CHANNEL: 'PBM_CHANNEL',
  START_DATE: 'START_DATE',
  RECORD_COUNT: 'COUNT',
  HIGHEST_MATCH_PERCENTAGE: 'PERCENT',
  SUSPECTED_SOURCES: 'SOURCES'
}
export const MCOTableConstant = {
  MDM_ID: 'MSTR_MDM_ID',
  MCO_NAME: 'MSTR_MCO_NAME',
  START_DATE: 'START_DATE',
  RECORD_COUNT: 'COUNT',
  HIGHEST_MATCH_PERCENTAGE: 'PERCENT',
  SUSPECTED_SOURCES: 'SOURCES'
}
export const PLANTableConstant = {
  MDM_ID: 'MSTR_MDM_ID',
  PLAN_NAME: 'MSTR_PLAN_NAME',
  START_DATE: 'START_DATE',
  RECORD_COUNT: 'COUNT',
  HIGHEST_MATCH_PERCENTAGE: 'PERCENT',
  SUSPECTED_SOURCES: 'SOURCES'
}
export const PAYERTableConstant = {
  MDM_ID: 'MSTR_MDM_ID',
  PAYER_NAME: 'MSTR_PAYER_NAME',
  PAYER_CHANNEL: 'MSTR_PAYER_CHANNEL',
  START_DATE: 'START_DATE',
  RECORD_COUNT: 'COUNT',
  HIGHEST_MATCH_PERCENTAGE: 'PERCENT',
  SUSPECTED_SOURCES: 'SOURCES'
}
export const SortablePBMColumnList = [
  PBMTableConstant.MDM_ID,
  PBMTableConstant.PBM_NAME,
  PBMTableConstant.PBM_CHANNEL,
  PBMTableConstant.START_DATE,
  PBMTableConstant.RECORD_COUNT,
  PBMTableConstant.HIGHEST_MATCH_PERCENTAGE,
  PBMTableConstant.SUSPECTED_SOURCES,
]
export const LineageTableConstant = {
  ATTRIBUTES: 'Attributes',
  GOLDEN_VALUE: 'Golden Value',
  SOURCE_VALUES: 'Source Values',
  SURVIVORSHIP_RULES: 'Survivorship Rules'
}
export const LineageConstants = {
  ADDRESSES: 'ADDRESSES',
  COMMUNICATIONS: 'COMMUNICATIONS',
  IDENTIFIERS: 'IDENTIFIERS',
  SRC_NM: 'SRC_NM',
  SRC_ID: 'SRC_ID',
  PRI_ADDR_FLG: 'PRI_ADDR_FLG',
  ATTRIBUTES: 'ATTRIBUTES',
  GOLDEN_VALUE: 'GOLDEN_VALUE',
  SURVIVORSHIP_RULES: 'SURVIVORSHIP_RULES',
  COMM_VAL: 'COMM_VAL',
  COMM_TYPE: 'COMM_TYPE',
  SPLIT_CONSTANT: '_split_identifier_',
};

export const MDMCompare = {
  G1: 'g1',
  G2: 'g2',
  GCSE_TYPE: 'text/javascript',
  GCSE_SRC_URL: 'https://cse.google.com/cse.js?cx=',
  SLAVE_ID: 'slaveId',
  ID: 'id',
  TYPE: 'type',
  VALUE: 'value',
  ID1: 'id1',
  ID2: 'id2',
  CURRENT_PROFILE_ID: 'current_profile_id',
  GSC_SEARCH_BUTTON: 'gsc-search-button-v2',
  DEFAULT_ID: '900000090',
  DEFAULT_RETURN_ID: '900025686',
  SEARCH_BOX_ID_1: 'gsc-i-id1',
  SEARCH_BOX_ID_2: 'gsc-i-id2',
  MDM: 'mdm',
  ALL_RECORDS: 'all-records',
  PROFILE: 'profile',
};

export const MDMServiceConstants = {
  FILTER: 'filter',
  MASTER_ID: 'masterId',
  PAGE: 'page',
  PAGE_SiZE: 'pageSize',
  SEARCH: 'search',
  ETY_SUB_TYPE: 'ety_sub_type',
  SLAVE: 'SLAVE_',
  VIEW: 'view',
  TIMELINE: 'timeline',
  FORMAT: 'format',
  PAST_FILTER: 'pastFilter',
  AFFILIATION_TYPE: 'affiliation_type',
  MDM_ID: 'mdm_id',
  COLUMN_ID: 'column_id',
  FILTERS: 'filters',
  SORT_COLUMN: 'sort_by_column',
  SORT_ORDER: 'sort_by_order',
  DCR_ID: 'dcr_id',
  REQUEST_TYPE: 'request_type',
  DCR_NAME: 'dcr_name',
  ENTITY_TPE: 'entity_type',
  SUB_TYPE: 'sub_type',
  FROM: 'from',
  TO: 'to',
  STATUS: 'status'
};

export const MDMDashboardPeriodList = {
  ALL: 'ALL',
  PAST_24_HOURS: 'PAST_24_HOURS',
  PAST_7_DAYS: 'PAST_7_DAYS',
  PAST_30_DAYS: 'PAST_30_DAYS',
  PAST_6_MONTHS: 'PAST_6_MONTHS',
  PAST_YEAR: 'PAST_YEAR',
};
export const GetMdmApiRequest = {
  paginationData: 'pagination_data',
  sortByColumn: 'sort_by_column',
  sortByOrder: 'sort_by_order',
  searchText: 'search',
  page: 'page',
  pageSize: 'pageSize',
};
export const MDMDashboardSuspectedMatchesApiResponseConstants = {
  COUNT: 'COUNT',
  FIRST_MATCH_DATE: 'FIRST_MATCH_DATE',
  LAST_MATCH_DATE: 'LAST_MATCH_DATE',
  MSTR_FRST_NM: 'MSTR_FRST_NM',
  MSTR_LAST_NM: 'MSTR_LAST_NM',
  MSTR_MDM_ID: 'MSTR_MDM_ID',
  MSTR_NPI_NUM: 'MSTR_NPI_NUM',
  PERCENT: 'PERCENT',
  SOURCES: 'SOURCES',
  MSTR_ACCT_NM: 'MSTR_ACCT_NM',
  MSTR_DEA_HCO: 'MSTR_DEA_HCO',
  MSTR_HIN_NUM: 'MSTR_HIN_NUM',
  MSTR_NAME: 'MSTR_NAME',
  NAME: 'NAME',
  FULL_COUNT: 'full_count',
};

export const ProfileCompareConstants = {
  NAME: 'Name',
  AGE: 'Age',
  SPECIALTY: 'Specialty',
  EDUCATION: 'Education',
  COLLEGE: 'College',
  YEAR_OF_PASSING: 'Year of Passing',
  WORKING_LOC: 'Working Location',
  RESIDENTIAL_LOC: 'Residential Location',
  NPI: 'NPI',
  ATTRIBUTES: 'attributes',
};

export const ProfileDetailsTableConstants = {
  MDM_ID: 'MDM ID',
  ACCOUNT_NAME: 'Account Name',
  ALTERNATE_ACCOUNT_NAME: 'Alternate Account Name',
  ACCOUNT_TYPE: 'Account Type',
  ACCOUNT_SUB_TYPE: 'Account Sub Type',
  ACCOUNT_STATUS: 'Account Status',
  CLASS_OF_TRADE_CODE: 'Class of Trade Code',
  COT_CLASSIFICATION: 'COT Classification',
  COT_CLASSIFICATION_ID: 'COT Classification ID',
  COT_FACILITY_TYPE_ID: 'COT Facility Type ID',
  COT_FACILITY_TYPE: 'COT Facility Type',
  COT_SPECIALTY_ID: 'COT Specialty ID',
  COT_SPECIALTY_DESCRIPTION: 'COT Specialty Description',
  SOURCE: 'Source',
  SOURCE_ID: 'Source ID',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  MIDDLE_NAME: 'Middle Name',
  PREFIX: 'Prefix',
  SUFFIX: 'Suffix',
  GENDER: 'Gender',
  TITLE: 'Title',
  DATE_OF_BIRTH: 'Date of Birth',
  DATE_OF_DEATH: 'Date of Death',
  PROFESSIONAL_DESIGNATION_CODE: 'Professional Designation Code',
  PROFESSIONAL_DESIGNATION_DESCRIPTION: 'Professional Designation Description',
  CUSTOMER_STATUS: 'Customer Status',
  CUSTOMER_TYPE: 'Customer Type',
  PRIMARY_SPECIALTY: 'Primary Specialty',
  PRIMARY_SPECIALTY_DESCRIPTION: 'Primary Specialty Description',
  SECONDARY_SPECIALTY: 'Secondary Specialty',
  SECONDARY_SPECIALTY_DESCRIPTION: 'Secondary Specialty Description',
  TERTIARY_SPECIALTY: 'Tertiary Specialty',
  TERTIARY_SPECIALTY_DESCRIPTION: 'Tertiary Specialty Description',
  NO_CONTACT_FLAG: 'No Contact Flag',
  PDRP_FLAG: 'PDRP Flag',
  PDRP_EFFECTIVE_DATE: 'PDRP Effective Date',
  TARGET_FLAG: 'Target Flag',
  DMR_FLAG: 'DMR Flag',
  KOL_FLAG: 'KOL Flag',
  KAISER_FLAG: 'Kaiser Flag',
  AMA_DEAD_FLAG: 'AMA Dead Flag',
  STRT_DT: 'Start Date',
  END_DT: 'End Date',
  ADDR_MDM_ID: 'Address MDM ID',
  ADDR_LN1: 'Address Line 1',
  ADDR_LN2: 'Address Line 2',
  CITY: 'City',
  ST: 'State',
  CNTRY: 'Country',
  LAT: 'Latitude',
  LNGTD: 'Longitude',
  ZIP_CD: 'ZIP Code',
  ZIP_CD_EXTN: 'ZIP Code Extension',
  PHONE: 'PHONE',
  FAX: 'FAX',
  SLN_NM: 'SLN Number',
  DEA_NM: 'DEA Number',
  NPI_NM: 'NPI',
  ME_NM: 'ME Number',
};

export const ProfileMatchesIncludedKeysConstants = {
  TITLE: 'Title',
  MDM_ID: 'MDM ID',
  HCP_MDM_ID: 'HCP MDM ID',
};

export const StatsMetricKeyMappitgConstants = {
  AUTO_MERGED: 'auto merged',
  SINGLE: 'single',
  SUSPECTED: 'suspected',
  NEW: 'new',
  UPDATE: 'update',
  AUTO_MERGED_UPPER_CASE: 'AUTO MERGED',
  SINGLE_UPPER_CASE: 'SINGLE',
  SUSPECTED_UPPER_CASE: 'SUSPECTED',
  NEW_UPPER_CASE: 'NEW',
  UPDATE_UPPER_CASE: 'UPDATE',
  PERIOD_VALUE: 'periodValue',
};

export const MDMAddressFieldConstants = {
  ADDR_LN1: 'ADDR_LN1',
  ADDR_LN2: 'ADDR_LN2',
  CITY: 'CITY',
  ST: 'ST',
  ZIP_CD: 'ZIP_CD',
  ZIP_CD_EXTN: 'ZIP_CD_EXTN',
  CNTRY: 'CNTRY',
  LAT: 'LAT',
  LNGTD: 'LNGTD',
  VERFN_STAT: 'VERFN_STAT',
  PRI_ADDR_FLG: 'PRI_ADDR_FLG',
};

export const MDMActivityLogHeaderConstants = {
  NAME: 'Profile',
  ATVY_TYPE: 'Type',
  USR_NM: 'User',
  MODF_DT: 'Timestamp',
  ATVY_DESC: 'Activity',
};

export const MDMActivityLogHeaderTableName = {
  NAME: 'NAME',
  ATVY_TYPE: 'ATVY_TYPE',
  USR_NM: 'USR_NM',
  MODF_DT: 'MODF_DT',
  ATVY_DESC: 'ATVY_DESC',
  MDM_ID: 'MDM_ID',
};

export const MDMRoutes = {
  HOME: '/mdm/home',
  ALL_RECORDS: '/mdm/all-records/search',
  ADVANCED_SEARCH: '/mdm/advanced-search',
  LOG: '/mdm/activity-log',
  ADMIN: '/mdm/admin',
  DCR_MANAGEMENT: '/mdm/dcr-management',
};
export const DCRStatus = {
  PENDING: 'Pending_vod',
  SUBMITTED: 'Submitted_vod',
  CANCELLED: 'Cancelled_vod',
  APPROVED: 'Approved_vod',
  PROCESSED: 'Processed_vod',
  HOMEOFFICE: 'home_office',
  DATASTEWARD: 'data_steward'
}

export const ExcludeMatchColumnList = [
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_MDM_ID,
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_NAME,
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_ACCT_NM,
  MDMDashboardSuspectedMatchesApiResponseConstants.SOURCES,
  MDMDashboardSuspectedMatchesApiResponseConstants.PERCENT,
];

export const MaxWidthColumnList = [
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_MDM_ID,
  MDMDashboardSuspectedMatchesApiResponseConstants.COUNT,
];

export const SortableColumnList = [
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_MDM_ID,
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_NAME,
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_ACCT_NM,
  MDMDashboardSuspectedMatchesApiResponseConstants.COUNT,
  MDMDashboardSuspectedMatchesApiResponseConstants.PERCENT,
  MDMDashboardSuspectedMatchesApiResponseConstants.FIRST_MATCH_DATE,
  MDMDashboardSuspectedMatchesApiResponseConstants.LAST_MATCH_DATE,
];

export const DashboardMatchesCommonColumnList = [
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_MDM_ID,
  MDMDashboardSuspectedMatchesApiResponseConstants.FIRST_MATCH_DATE,
  MDMDashboardSuspectedMatchesApiResponseConstants.LAST_MATCH_DATE,
  MDMDashboardSuspectedMatchesApiResponseConstants.COUNT,
  MDMDashboardSuspectedMatchesApiResponseConstants.PERCENT,
  MDMDashboardSuspectedMatchesApiResponseConstants.SOURCES,
];

export const DetailsSortableColumnList = [
  DetailsConstants.ATTRIBUTES,
  DetailsConstants.GOLDEN_VALUE,
  DetailsConstants.SURVIVORSHIP_RULES,
];

export const ExcludeDetailsColumnList = [
  DetailsConstants.SOURCE_VALUES,
  DetailsConstants.SURVIVORSHIP_RULES,
];

export const MaxDetailsWidthColumnList = [
  DetailsConstants.ATTRIBUTES,
  DetailsConstants.GOLDEN_VALUE,
];

export const HcoNameColumn =
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_ACCT_NM;

export const HcpNameColumn =
  MDMDashboardSuspectedMatchesApiResponseConstants.MSTR_NAME;

export const SortalbleActivityColumList = [
  MDMActivityLogHeaderTableName.NAME,
  MDMActivityLogHeaderTableName.MODF_DT,
];

export const FilterActivityColumList = [
  MDMActivityLogHeaderTableName.ATVY_TYPE,
  MDMActivityLogHeaderTableName.USR_NM,
  MDMActivityLogHeaderTableName.ATVY_DESC,
];

export const ActivityLogColumList = [
  MDMActivityLogHeaderTableName.NAME,
  MDMActivityLogHeaderTableName.MODF_DT,
  MDMActivityLogHeaderTableName.ATVY_TYPE,
  MDMActivityLogHeaderTableName.USR_NM,
  MDMActivityLogHeaderTableName.ATVY_DESC,
];

export const ActivityLogNavigateColumnList = [
  MDMActivityLogHeaderTableName.NAME,
];
export const PayerRequestConstants = {
  MASTER_ID: 'masterId'
}
