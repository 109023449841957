import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MDMCommonConstants } from '../../constants/mdm-common-constants';
import { mdm_labels } from '../../constants/ui-translation';
import { commmon_labels } from 'src/app/common-label';
import { TranslateService } from '@ngx-translate/core';
import { MDMService } from '../../mdm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParams, MDMAffiliationTranslationMessage,
    MDMAffiliationTypesConstants, MDMProfileTabNameConstants } from '../../constants/profile-constants';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';

@Component({
  selector: 'app-affiliation-details',
  templateUrl: './affiliation-details.component.html',
  styleUrls: ['./affiliation-details.component.less']
})
export class AffiliationDetailsComponent implements OnInit, OnDestroy {
  @Input() profileType = '';
  @Input() profileDetail: '';
   loading: boolean;
   _id = '';
   isHco = false;
   affiliationType = '';
   affiliationData: any[] = [];
   affiliationTab = MDMCommonConstants.HCO;
   ui_labels = mdm_labels.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS;
   translated_labels: any;
   primaryLabel: string;
   error_label: string;
   commmon_labels = commmon_labels;
   noHCOHierarchyFound: string;
   noHCPHierarchyFound: string;
   noHCPDetailsFound: string;
   noHCODetailsFound: string;
   translationMessagesMapping = {};
   refreshAffiliationTabSubscriber: any;
   environment: EnvironmentConfig;

    constructor(private service: MDMService, private route: ActivatedRoute,
    private _translate: TranslateService, private config: AppConfig,
    private _toasterDataService: ToasterDataService) {
        this.environment = this.config.getConfigObject();
        this.translated_labels = this._translate.instant(
            [
                this.ui_labels.HCO_HIERARCHY_NOT_FOUND,
                this.ui_labels.HCP_HIERARCHY_NOT_FOUND,
                this.ui_labels.HCP_DETAILS_NOT_FOUND,
                this.ui_labels.HCO_DETAILS_NOT_FOUND,
                this.ui_labels.PRIMARY,
                this.commmon_labels.ERROR_WITH_COLON
            ]);
        this.noHCOHierarchyFound = this.translated_labels[this.ui_labels.HCO_HIERARCHY_NOT_FOUND];
        this.noHCPHierarchyFound = this.translated_labels[this.ui_labels.HCP_HIERARCHY_NOT_FOUND];
        this.noHCPDetailsFound = this.translated_labels[this.ui_labels.HCP_DETAILS_NOT_FOUND];
        this.noHCODetailsFound = this.translated_labels[this.ui_labels.HCO_DETAILS_NOT_FOUND];
        this.primaryLabel = this.translated_labels[this.ui_labels.PRIMARY];
        this.error_label = this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
        this.getMessages();
    }

    getMessages() {
       this.translationMessagesMapping = {
            [MDMAffiliationTypesConstants.HCP_HCP]: this.noHCPHierarchyFound,
            [MDMAffiliationTypesConstants.HCO_HCO]: this.noHCOHierarchyFound,
            [MDMAffiliationTypesConstants.HCP_HCO]: this.noHCODetailsFound,
            [MDMAffiliationTypesConstants.HCO_HCP]: this.noHCPDetailsFound
        };
    }

  ngOnInit() {
      this.isHco = window.location.href.indexOf('/hco/') >= 0;
      this.route.params.subscribe(params => {
          this._id = params[QueryParams.ID];
        });
        this.refreshAffiliationTabSubscriber = this.service.refreshAffiliationTab$.subscribe(mdmID => {
            this.getAffiliationType();
            this.loadAffiliationDetails(mdmID);
        }
        );
        this.getAffiliationType();
        this.loadAffiliationDetails(this._id);
  }

    getAffiliationType() {
        this.affiliationType = this.isHco ?
        `${MDMCommonConstants.HCO}-${this.profileType}` : `${MDMCommonConstants.HCP}-${this.profileType}`;
    }

    loadAffiliationDetails(mdmID) {
        this.loading = true;
        this.service.getAffilationsDetails(mdmID, this.affiliationType).subscribe(result => {
        this.loading = false;
        this.affiliationData = result;
        }, exception => {
            this.loading = false;
            this._toasterDataService.addMessageToToaster(exception.error.message);
        });
   }

    ngOnDestroy() {
       if (this.refreshAffiliationTabSubscriber) {
           this.refreshAffiliationTabSubscriber.unsubscribe();
        }
    }
}
