import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { MDMCommonConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';

@Component({
  selector: 'app-matches-cell-renderer',
  templateUrl: './matches-cell-renderer.component.html',
  styleUrls: ['./matches-cell-renderer.component.less']
})
export class MatchesCellRendererComponent implements ICellRendererAngularComp {

  params: any;
  data: any;
  isSourceColumn: boolean;
  isMdmId: boolean;
  routeLink: string;
  filter: string;
  percent: boolean;
  MDMCommonConstants = MDMCommonConstants;
  environment: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;
    this.isSourceColumn = this.params.sources;
    this.isMdmId = this.params.mdmId;
    this.routeLink = this.params.routeLink;
    this.filter = this.params.filter;
    this.percent = this.params.percent;
    this.environment = this.params.environment;
  }

  refresh(params) {
    return true;
  }
}
