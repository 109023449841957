import { RDHGenericMessage } from './rdh_generic_message';
import { TranslateService } from '@ngx-translate/core';
import {
  APIResponseMessageType,
  APIResponseMessageConstants,
} from '../constants/ap-user-messages-constants';
import { RDHJsonSerializedMessage } from './rdh_json_serialized_message';
import { RDHMultipleMessages } from './rdh_multiple_messages';
import { RDHAsyncJobsErrorMessage } from './rdh_async_jobs_error_message';
import { RDHRequestValidationErrorMessage } from './rdh_request_validation_error_message';

export class RDHMessageFactory {
  constructor(private _translate: TranslateService, public message) {
    this._translate = _translate;
    this.message = message;
  }
  getMessage() {
    if (this.message && this.message.hasOwnProperty(APIResponseMessageConstants.MESSAGE_TYPE)) {
      if (
        this.message.message_type === APIResponseMessageType.RDH_GENERIC_MESSAGE
      ) {
        return new RDHGenericMessage(this._translate, this.message);
      } else if (
        this.message.message_type ===
        APIResponseMessageType.RDH_MULTIPLE_MESSAGE
      ) {
        return new RDHMultipleMessages(this._translate, this.message.message);
      } else if (
        this.message.message_type ===
        APIResponseMessageType.RDH_ASYNC_JOBS_Error_Message
      ) {
        return new RDHAsyncJobsErrorMessage(this._translate, this.message);
      } else if (
        this.message.message_type ===
        APIResponseMessageType.RDH_VALIDATION_ERROR_MESSAGE
      ) {
        return new RDHRequestValidationErrorMessage(this._translate);
      }
    } else {
      return new RDHJsonSerializedMessage(this._translate, this.message);
    }
  }
}
