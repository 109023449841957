import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EditableTableService, FilterOpenService } from './revo-core/table-column-filters/table-column-filters.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CookieModule } from 'ngx-cookie';
import { CustomLoadingOverlayComponent } from './custom-loading-overlay/custom-loading-overlay.component';
import { DatePipe } from '@angular/common';
import { DomSanitizerPipe } from './shared/custom-pipes/dom-sanitizer-pipe';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MDMModule } from './mdm/mdm.module';
import { RdhInterceptorService } from './RdhInterceptor';
import { RevoCoreModule } from './revo-core/revo-core.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { TimeDurationPipe } from './shared/custom-pipes/time-duration.pipe';
import { ToasterDataService } from './revo-core/toaster-service/toaster-data.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZsFilterService } from './revo-core/zs-multi-select-dropdown/zs-filter.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CustomLoadingOverlayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RevoCoreModule,
    MDMModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppConfig,
    ToasterDataService,
    EditableTableService,
    ZsFilterService,
    FilterOpenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RdhInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
    DatePipe,
    DomSanitizerPipe,
    TimeDurationPipe
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
