<div
  class="zs-display-flex zs-flex-align-center zs-flex-justify-flex-end zs-pagination-wrapper"
  *ngIf="itemCount && itemCount > 0"
>
  <span class="font-size" *ngIf="paginationLabelRequired">
    {{ paginationStatusLabel }}
  </span>
  <zs-pagination
    [ngStyle]="customStyle"
    class="zs-pagination zs-margin-2-0-2-2"
    mode="action"
    [itemsCount]="itemCount"
    [pageSize]="pageSize"
    [currentPage]="currentPage"
    (pagechange)="onPageChange($event)"
    [pageSizeForDropdown]="pageSizeForDropdown"
  >
  </zs-pagination>
</div>
