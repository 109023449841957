import { Pipe, PipeTransform } from '@angular/core';
import { MDMActivityLog } from '../constants/mdm-common-constants';

@Pipe({
  name: 'getActivityImage'
})
export class GetActivityImagePipe implements PipeTransform {

  transform(code: string, ...args: unknown[]): unknown {
    switch (code.toLowerCase()) {
      case MDMActivityLog.PROFILE:
				return 'zs-icon-user';
			case MDMActivityLog.MERGE:
				return 'zs-icon-item-group';
			case MDMActivityLog.UNMERGE:
				return 'zs-icon-item-ungroup';
			case MDMActivityLog.NOT_A_MATCH:
				return 'zs-icon-stop-circle';
			default:
				return 'zs-icon-close';
		}
  }

}
