import { TranslateService } from '@ngx-translate/core';
import { BackendUserMessages } from '../constants/ui_labels_translation_mapping';

export class RDHRequestValidationErrorMessage {
  uiLabels = BackendUserMessages;
  defaultErrorMessage: string;
  badRequestErrorMessage: string;
  translatedLabels: any;
  constructor(private _translate: TranslateService) {
    this.translatedLabels = this._translate.instant([
      this.uiLabels.SOME_ERROR_OCCURED,
      this.uiLabels.BAD_REQUEST,
    ]);
    this.defaultErrorMessage =
      this.translatedLabels[this.uiLabels.SOME_ERROR_OCCURED];
    this.badRequestErrorMessage =
      this.translatedLabels[this.uiLabels.BAD_REQUEST];
  }

  translate() {
    try {
      return this.badRequestErrorMessage;
    } catch {
      return this.defaultErrorMessage;
    }
  }
}
