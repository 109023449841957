import { Component, OnInit, Input, Output, EventEmitter, HostBinding, SimpleChanges, OnChanges, OnDestroy, ViewChild,
  ElementRef, ViewEncapsulation } from '@angular/core';
import { FormValidationService } from '../form-validation-service/form-validation-service';
import { Subscription } from 'rxjs';
import { revo_core_labels, common_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-zs-select',
  templateUrl: './zs-select.component.html',
  styleUrls: ['./zs-select.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ZsSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dataSource: any;
  @Input() labelInverse = false;
  @Input() selected: any;
  @Input() isLabelHidden: string;
  @Input() isRequired = false;
  @Input() showOptionalText = false;
  @Input() inErrorState: Boolean;
  @Input() disabled: Boolean;
  @Input() label: string;
  @Input() isAddSelectValue: boolean;
  @Input() customStyle: object;
  @Input() emptyValue = 'select';
  @Input() errorSpanStyle: object;
  @Input() touched = false;
  @Input() formValidationCallbackFunction: Function;
  @Input() lengthLimit = 33;
  @Input() validateFunction: Function;
  @Input() validateFunctionArgs = [];
  @Input() flippable = false;
  @Input() hideOnScroll = false;
  @Input() infoLabel = '';
  @Input() showLabelTooltip = false;
  @Input() labelEllipsisConstant = 10;

  selectedText: any;

  @Output() selectedChange = new EventEmitter(true);
  @Output() inErrorStateChange = new EventEmitter(true);
  @Output() selectedTextChange = new EventEmitter(true);

  @ViewChild('zsSelect') zsSelect: ElementRef;

  formValidationSubscription: Subscription;

  @HostBinding('class.zs-error') public inputClass = '';

  selectedOptions = [];
  source = [];
  default: any;
  errorClass = 'zs-error';
  errorMessage = '';
  textOnHover = '';

  customClass = '';

  translated_labels: any;
  validation_labels = revo_core_labels.VALIDATION_LABELS;
  common_labels = common_labels;
  noRecordsText: string;

  constructor(private _formValidationService: FormValidationService,
    private _translate: TranslateService) {
    this.translated_labels = this._translate.instant(
      [
        this.validation_labels.VALUE_REQUIRED,
        this.common_labels.SELECT,
        this.common_labels.NO_ITEMS_FOUND_FOR_SEARCH
      ]
    );
    this.noRecordsText = this.translated_labels[this.common_labels.NO_ITEMS_FOUND_FOR_SEARCH];
  }

  ngOnInit() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription = this._formValidationService.getMessage().subscribe(
        message => {
          this.touched = true;
          if (!this.disabled) {
            this.validate(true);
          }
          this.formValidationCallbackFunction(this.inErrorState);
        }
      );
    }
    const $this = this;
    if ($this.selected === undefined && this.isAddSelectValue) {
      $this.selected = 'select';
    }
    if (!this.disabled) {
      $this.validate(true);
    }
  }

  configure() {
    this.source = [];
    if (this.isAddSelectValue && this.default) {
      this.source.push(this.default);
    }
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach(element => {
        this.source.push(element);
      });
    }
    this.selectedOptions = [];
    if (this.selected) {
      this.selectedOptions.push(this.selected);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // added this condition because there is a problem in default select
    if (changes['emptyValue'] && this.isAddSelectValue) {
      this.default = {
        displayLabel: this.translated_labels[this.common_labels.SELECT],
        value: this.emptyValue
      };
    }
    if (changes['dataSource'] && this.dataSource ) {
      this.configure();
    }
    if (changes['touched']) {
      this.validate(true);
    }
    if (this.disabled) {
      this.resetSelect();
    }
    if (changes['selected'] && this.selected) {
      this.selectedOptions = [this.selected];
    }
  }


  isValueRequired() {
    // if selected is an array then adding length check
    if (this.isRequired && (this.selected === this.emptyValue || this.selected === '' || this.selected.length === 0 )) {
      return true;
    }
    return false;
  }

  resetSelect() {
    this.inputClass = '';
    this.errorMessage = '';
    this.inErrorState = false;
  }

  validate(isEmitEnabled) {
    const $this = this;
    if (this.isValueRequired()) {
      this.errorMessage = this.translated_labels[this.validation_labels.VALUE_REQUIRED];
    } else {
      this.resetSelect();
    }
    if (this.validateFunction) {
      if (this.validateFunctionArgs && this.validateFunctionArgs.length > 0) {
        this.errorMessage = this.validateFunction(this.selected, this.validateFunctionArgs);
      } else {
        this.errorMessage = this.validateFunction(this.selected);
      }
    }
    if (this.errorMessage) {
      this.inErrorState = true;
      this.inputClass = this.errorClass;
    }
    if (isEmitEnabled) {
      // setTimeout(function () {
      $this.inErrorStateChange.emit($this.inErrorState);
      $this.selectedChange.emit($this.selected);
      $this.selectedTextChange.emit($this.selectedText);
      // });
    }
  }

  beforeOpen() {
    this.touched = true;
  }

  onChange(event) {
    this.selected = this.zsSelect.nativeElement.getValue();
    this.selectedText = event.target.selected.outerText;
    this.textOnHover = this.zsSelect.nativeElement.getOverlayText();
    this.validate(true);
  }

  onFocusOut(event) {
    this.touched = true;
    this.validate(false);
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }
}
