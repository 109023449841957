import { ColDef, ColumnApi, GridApi } from '@ag-grid-community/core';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MultiSelectDropdownComponent } from 'src/app/revo-core/multi-select-dropdown/multi-select-dropdown.component';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { DCRStatus, MDMCommonConstants, MDMWrapperConstants } from '../constants/mdm-common-constants';
import { SearchConstants } from '../constants/search-constants';
import { mdm_labels } from '../constants/ui-translation';
import { MDMService } from 'src/app/mdm/mdm.service';
import { DcrCellRendererComponent } from './dcr-cell-renderer/dcr-cell-renderer.component';
import { DatePipe } from '@angular/common';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dcr-management',
  templateUrl: './dcr-management.component.html',
  styleUrls: ['./dcr-management.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DcrManagementComponent implements OnInit {
  loading: boolean;
  showTags = false;
  ui_labels = mdm_labels.ALL_RECORDS.SEARCH;
  searchConstants = SearchConstants;
  DCRstatus = DCRStatus;
  datePipe = new DatePipe('en-US');
  defaultFromDate = this.datePipe.transform(new Date().setDate(new Date().getDate() - 60), 'MM/dd/yyyy') //  current date minus 60 days
  defaultToDate = this.datePipe.transform(new Date, 'MM/dd/yyyy');; // current date
  selectedFromDate: Date;
  selectedToDate: Date;
  gridOptions: object;
  frameworkComponents: any;
  page = 1;
  pageSize: number = 10;
  selected_request_type: any = 'ALL';
  selected_entity_type: any = 'ALL';
  selected_sub_type: any = 'ALL';
  dcr_summary: any;
  selected_status: any;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  @ViewChild('pastFilterSelect', { static: true })
  pastFilterSelect: MultiSelectDropdownComponent;
  domLayout = "autoHeight";
  colDefs: ColDef[] = [
    {
      headerName: 'Change Request ID', field: 'CHANGE_REQUEST_ID', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
        DCR_NAME: true
      }
    },
    { headerName: 'Requested Date', field: 'REQUEST_CREATED_DATE', sortingOrder: ['asc', 'desc'] },
    {
      headerName: 'Status', field: 'DCR_STATUS', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
        DCR_STATUS: true
      }
    },
    // { headerName: 'Attribute', field: 'attribute', sortingOrder: ['asc', 'desc'] },
    {
      headerName: 'Request Type', field: 'CHANGE_REQUEST_TYPE', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
        CHANGE_REQUEST_TYPE: true
      }
    },
    { headerName: 'Account Name', field: 'ACCOUNT_NAME', sortingOrder: ['asc', 'desc'] },
    {
      headerName: 'MDM ID', field: 'MDM_ID', sortingOrder: ['asc', 'desc'], cellRendererParams: {
        MDM_ID: true
      }
    },
    {
      headerName: 'MDM Sync', field: 'MDM_SYNC_FLAG', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
        MDM_SYNC_FLAG: true
      }
    },
    // {
    //   headerName: 'CRM Sync', field: 'crm_sync_flag', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
    //     crm_sync_flag: true
    //   }
    // },
    {
      headerName: 'Requested By', field: 'REQUEST_CREATED_BY', sortingOrder: ['asc', 'desc'], cellRenderer: DcrCellRendererComponent, cellRendererParams: {
        REQUEST_CREATED_BY: true
      }
    },
    { headerName: 'User Geo', field: 'USER_GEO', sortingOrder: ['asc', 'desc'] },
    { headerName: 'Manager Name', field: 'MANAGER_NAME', sortingOrder: ['asc', 'desc'] },
    { headerName: 'Requesters comments', field: 'REQUESTERS_COMMENTS', sortingOrder: ['asc', 'desc'] },
    { headerName: 'Data Stewards Comments', field: 'COMMENTS', sortingOrder: ['asc', 'desc'] },

  ];
  rowData: any[] = [];
  rowCopy: any[] = [];
  noRowsTemplate: string;
  dcr_id: string;
  rowCount: any;
  showAgGridLoader = true;
  showExcelLoader = false;
  showSyncLoader = false;
  dcrSub: Subscription;
  countSub: Subscription;
  summarySub: Subscription;

  filters: any[] = [
    {
      displayLabel: MDMCommonConstants.DCR,
      label: MDMCommonConstants.DCR,
      value: MDMCommonConstants.DCR,
      applied: true,
      show: true,
      display: true,
      selected: true,
      fields: [],
      selectedValue: undefined,
      checked: true,
    }
  ];
  card_details: any[] = [
    { heading: 'Total DCRs', value: 0, filterValue: 'total_dcr', isSelected: true },
    { heading: 'Approved', value: 0, filterValue: 'approved', isSelected: false },
    { heading: 'Processed', value: 0, filterValue: 'processed', isSelected: false },
    { heading: 'Pending', value: 0, filterValue: 'pending', isSelected: false, others: [{ heading: 'DataSteward', value: 0 }, { heading: 'HomeOffice', value: 0 }] },
    { heading: 'Rejected', value: 0, filterValue: 'rejected', isSelected: false, },
  ];
  request_type: { isSelected: boolean; displayLabel: any; label: string; value: string; applied: boolean; disable: boolean; show: boolean; display: boolean; fields: any[]; selected: any; selectedValue: any; }[];
  entity_type: { isSelected: boolean; displayLabel: string; label: string; value: string; applied: boolean; disable: boolean; show: boolean; display: boolean; fields: any[]; selected: any; selectedValue: any; }[];
  defaultColDef: { [x: string]: string | boolean; };
  sub_type: { isSelected: boolean; displayLabel: string; label: string; value: string; applied: boolean; disable: boolean; show: boolean; display: boolean; fields: any[]; selected: any; selectedValue: any; }[];
  get appliedFilter(): any[] {
    return this.filters.filter((f) => f.applied);
  }
  minDate: '';
  constructor(private service: MDMService, private _toasterDataService: ToasterDataService,

  ) {
    this.setUpFilterData();
  }

  ngOnInit(): void {
    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      [AgGridColumnDefsConstants.WRAP_HEADER_TEXT]: true,
      [AgGridColumnDefsConstants.AUTO_HEADER_HEIGHT]: true,
      defaultColDef: {
        sortable: true,
        unSortIcon: false
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.frameworkComponents = {
      custumizedId: DcrCellRendererComponent,
    };
    this.setColumnDef();

  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    // this.gridApi.sizeColumnsToFit();
    /* Edge 17039 changes starts
     in case of routing from Suspected Matches Grid, Setting activeTab to "Suspected Matches" */
    this.service.setActiveTab(MDMWrapperConstants.DCR_MANAGEMENT);
    // Edge 17039 changes ends
  }
  setColumnDef() {
    this.colDefs.forEach((item) => {
      item[AgGridColumnDefsConstants.TOOLTIP_FIELD] = '';
      item[AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER] = this.getCellTooltip();
      item[AgGridColumnDefsConstants.WRAP_TEXT] = true;
      // item[AgGridColumnDefsConstants.FLEX] = 2;
      item[AgGridColumnDefsConstants.AUTO_HEIGHT] = true;
      // item[AgGridColumnDefsConstants.MAX_WIDTH] = 140
      item[AgGridColumnDefsConstants.MAX_WIDTH] = item['field'] == 'DCR_STATUS' ? 110 : item['field'] == 'ACCOUNT_NAME' ? 200 : 140;
      // item[AgGridColumnDefsConstants.MAX_WIDTH] = item['field'] == 'request_created_by' ? 500 : item['field'] == 'dcr_name' ? 180 : item['field'] == 'data_steward_comments' ? 200 : 110;
    }
    )
  }
  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }
  setUpFilterData() {
    this.request_type = [
      {
        isSelected: true,
        displayLabel: SearchConstants.ALL,
        label: SearchConstants.REQUEST_TYPE,
        value: SearchConstants.ALL,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: 'EDIT',
        label: SearchConstants.REQUEST_TYPE,
        value: SearchConstants.EDIT,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: 'NEW',
        label: SearchConstants.REQUEST_TYPE,
        value: SearchConstants.NEW,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      }
    ];
    this.entity_type = [
      {
        isSelected: true,
        displayLabel: SearchConstants.ALL,
        label: SearchConstants.ENTITY_TYPE,
        value: SearchConstants.ALL,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: 'HCP',
        label: SearchConstants.ENTITY_TYPE,
        value: SearchConstants.HCP,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: 'HCO',
        label: SearchConstants.ENTITY_TYPE,
        value: SearchConstants.HCO,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      }
    ];
    this.sub_type = [
      {
        isSelected: true,
        displayLabel: SearchConstants.ALL,
        label: SearchConstants.SUBTYPE,
        value: SearchConstants.ALL,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: SearchConstants.ADDRESS,
        label: SearchConstants.SUBTYPE,
        value: SearchConstants.ADDRESS,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: SearchConstants.AFFILIATION,
        label: SearchConstants.SUBTYPE,
        value: SearchConstants.AFFILIATION,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: SearchConstants.PROFILE,
        label: SearchConstants.SUBTYPE,
        value: SearchConstants.PROFILE,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      }
    ];
    this.filters[0].fields = [
      {
        type: SearchConstants.SELECT_DROPDOWN,
        displayLabel: 'Change Request Type',
        label: SearchConstants.FLAGS,
        applied: true,
        datasource: this.request_type,
        selectedValue: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.SELECT_DROPDOWN,
        displayLabel: 'Entity Type',
        label: SearchConstants.FLAGS,
        applied: true,
        datasource: this.entity_type,
        selectedValue: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.SELECT_DROPDOWN,
        displayLabel: 'Sub type',
        label: SearchConstants.FLAGS,
        applied: true,
        datasource: this.sub_type,
        selectedValue: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.DATE_PICKER,
        displayLabel: 'From',
        label: SearchConstants.ADDRESS,
        applied: true,
        selectedValue: this.defaultFromDate,
        keepTagVisible: false,
        min: ''
      },
      {
        type: SearchConstants.DATE_PICKER,
        displayLabel: 'To',
        label: SearchConstants.ADDRESS,
        applied: true,
        selectedValue: this.defaultToDate,
        keepTagVisible: false,
        min: this.defaultFromDate
      }
    ];
  }

  inputFieldChanged(field) {
    if (!field.keepTagVisible) {
      this.showTags = false;
    } else {
      field.keepTagVisible = false;
    }
  }
  OnChangeFilter(event) {
    this.page = 1;
    event = event[0];
    if (event.label === 'Request Type') {
      this.selected_request_type = event.value;
      if (this.selected_request_type != this.searchConstants.EDIT  && this.selected_request_type != this.searchConstants.NEW) {
        this.selected_sub_type = 'ALL';
      }
      if (this.selected_request_type != this.searchConstants.EDIT && this.selected_request_type != this.searchConstants.NEW) {
        const NA_Subtype = [
          {
            isSelected: true,
            displayLabel: "NA",
            label: SearchConstants.SUBTYPE,
            value: SearchConstants.ALL,
            applied: true,
            disable: false,
            show: true,
            display: true,
            fields: [],
            selected: undefined,
            selectedValue: undefined,
          }]
        this.filters[0].fields.forEach((item) => {
          if (item.displayLabel == 'Sub type' && this.selected_request_type != this.searchConstants.EDIT && this.selected_request_type != this.searchConstants.NEW) {
            item.datasource = NA_Subtype
          }
        })
      } else {
        this.filters[0].fields.forEach((item) => {
          if (item.displayLabel == 'Sub type' && (this.selected_request_type == this.searchConstants.EDIT || this.selected_request_type == this.searchConstants.NEW)) {
            item.datasource = this.sub_type
          }
        })
      }
    } else if (event.label === 'Entity Type') {
      this.selected_entity_type = event.value;
    } else if (event.label === 'Sub Type') {
      this.selected_sub_type = event.value.toUpperCase();
    }
    this.loadMatches();
  }

  loadMatches() {
    this.showAgGridLoader = true;
    if (this.dcrSub) {
      this.dcrSub?.unsubscribe();
    }
    this.dcrSub = this.service
      .getDcrmanagment(
        this.selected_request_type,
        this.selected_entity_type,
        this.selected_sub_type,
        this.page,
        this.pageSize,
        this.selectedFromDate,
        this.selectedToDate,
        this.selected_status
      )
      .subscribe(
        (data) => {
          this.rowData = data.data;
          if (this.rowData && this.rowData.length != 0) {
            this.rowData.forEach((ele) => {
              for (var prop in ele) {
                if (ele[prop] == null || ele[prop] == '') {
                  ele[prop] = '-'
                  if ((prop == 'mdm_id' || prop == 'npi') && (ele.change_request_type == 'New_vod')) {
                    ele[prop] = 'NA'
                  }
                }
              }
            })
          }

          this.rowCopy = data.data;
          // this.rowCount = data.totalRecords;
          this.getDcrCount();
          this.showAgGridLoader = false;
          this.getDCRSummary();
        },
        (exception) => {
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }
  getDcrCount() {
    if (this.countSub) {
      this.countSub?.unsubscribe();
    }
    this.countSub = this.service.getDcrTableCount(
      this.selected_request_type,
      this.selected_entity_type,
      this.selected_sub_type,
      this.selectedFromDate,
      this.selectedToDate,
      this.selected_status
    ).subscribe((data) => {
      this.rowCount = data[0].full_count;
    });
  }
  getFilterData() {

  }

  selectedDateChange() {
    this.selectedFromDate = this.filters[0].fields.find((item) => {
      return item.displayLabel == 'From'
    }).selectedValue;
    this.selectedToDate = this.filters[0].fields.find((item) => {
      return item.displayLabel == 'To'
    }).selectedValue;

    if (this.selectedFromDate && this.selectedToDate) {
      this.loadMatches();
    }
  }
  clearFilter() {
    this.filters[0].fields.forEach(item => {
      if (item.displayLabel == 'From') {
        item.selectedValue = this.defaultFromDate;
      } else if (item.displayLabel == 'To') {
        item.selectedValue = this.defaultToDate;
      }
    });
    this.loadMatches();
  }

  pageChanged(e) {
    this.page = e;
    this.loadMatches();
  }

  filterRow(status) {

    if (status == 'total_dcr') {
      this.selected_status = '';
    } else if (status == 'approved') {
      this.selected_status = DCRStatus.APPROVED;
    }
    else if (status == 'rejected') {
      this.selected_status = DCRStatus.CANCELLED;
    }
    else if (status == 'pending') {
      this.selected_status = DCRStatus.PENDING;
    }
    else if (status == 'processed') {
      this.selected_status = DCRStatus.PROCESSED;
    }
    else if (status == 'HomeOffice') {
      this.selected_status = DCRStatus.HOMEOFFICE;
    }
    else if (status == 'DataSteward') {
      this.selected_status = DCRStatus.DATASTEWARD;
    }
    this.card_details.forEach(item => {
      if (item.filterValue == status) {
        item.isSelected = true;
      } else if ((status == 'HomeOffice' || status == 'DataSteward') && item.filterValue == 'pending') {
        item.isSelected = true;
      }
      else {
        item.isSelected = false;
      }
    })
    this.loadMatches();

  }

  getDCRSummary() {
    if (this.summarySub) {
      this.summarySub.unsubscribe();
    }
    this.summarySub = this.service.getDCRSummary(
      this.selected_request_type,
      this.selected_entity_type,
      this.selected_sub_type,
      this.selectedFromDate,
      this.selectedToDate,
      this.selected_status
    )
      .subscribe(
        (data) => {
          this.dcr_summary = data[0];
          // this.rowCount = this.dcr_summary.full_count
          this.updateSummary();
        },
        (exception) => {
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );

  }

  updateSummary() {
    this.card_details.forEach((element) => {
      if (element.filterValue == 'approved') {
        element.value = this.dcr_summary.approved;
      } else if (element.filterValue == 'pending') {
        element.others.forEach(item => {
          if (item.heading == 'DataSteward') {
            item.value = this.dcr_summary.data_steward;
          } else if (item.heading == 'HomeOffice')
            item.value = this.dcr_summary.home_office;
        });
        element.value = this.dcr_summary.data_steward + this.dcr_summary.home_office;
      } else if (element.filterValue == 'rejected') {
        element.value = this.dcr_summary.cancelled;
      } else if (element.filterValue == 'total_dcr') {
        element.value = this.dcr_summary.full_count;
      } else if (element.filterValue == 'processed') {
        element.value = this.dcr_summary.processed;
      }

    })
  }

  //get all dcr headers for export excel
  getExcelHeader() {
    const headers = this.colDefs.map((item) => {
      return item.headerName;
    })
    return headers
  }

  exportToExcel() {
    this.showExcelLoader = true;
    this.service.getAllDCR(
      this.selected_request_type,
      this.selected_sub_type,
      this.selected_entity_type,
      this.selectedFromDate,
      this.selectedToDate,
      this.selected_status
    ).subscribe(
      (data) => {
        this.showExcelLoader = false;
        let timestamp = Date.now();
        this.service.exportAsExcelFile(data, `DCR-management-${timestamp}`, this.getExcelHeader())
      },
      (exception) => {
        this._toasterDataService.addMessageToToaster(exception.error.message);
      })
  }
  synCRMData() {
    this.showSyncLoader = true;
    this.service.syncDCR().subscribe((data) => {
      this._toasterDataService.MessageToToaster('Data Synced Successfully', ToasterTypeConstants.SUCCESS);
      this.showSyncLoader = false;
      this.loadMatches();
    }, (exception) => {
      this._toasterDataService.addMessageToToaster(exception.error.message);
      this.showSyncLoader = false;
    })
  }
  dateChange(event, label) {
    this.filters[0].fields.forEach((e) => {
      if (label == 'From') {
        this.minDate = event;
      }
      if (e.displayLabel == 'To') {
        e.min = this.minDate
      }

    })
  }
  ngOnDestroy() {
    this.dcrSub?.unsubscribe();
    this.summarySub?.unsubscribe();
    this.countSub?.unsubscribe();
  }
}
