export const environmentConstant = {
    baseUrl: 'baseUrl',
    prefix: 'prefix',
    uiPollingInterval: 'uiPollingInterval',
    paginationValues: 'paginationValues',
    defaultAvgTimeForBlock: 'defaultAvgTimeForBlock',
    ddFileUploadPollingInterval: 'ddFileUploadPollingInterval',
    groupPaginationValues: 'groupPaginationValues',
    idmLogOutUrl: 'idmLogOutUrl',
    production: 'production',
    metadataSchema: 'metadataSchema',
    rdh_transient_cluster_hive_db_name: 'rdh_transient_cluster_hive_db_name',
    should_export_audit_columns_for_redshift: 'should_export_audit_columns_for_redshift',
    dateTimeFormat: 'dateTimeFormat',
    supportedLanguage: 'supportedLanguage',
    languageMapping: 'languageMapping',
    maxNavigationTabsCount: 'maxNavigationTabsCount',
    defaultAvgTimeForIdataRun: 'defaultAvgTimeForIdataRun',
    expirationCookieName: 'expirationCookieName',
    hostDomainBlacklistingPattern: 'hostDomainBlacklistingPattern',
    isMDMAdvancedSearchEnabled: 'isMDMAdvancedSearchEnabled',
    heapAppId: 'heapAppId',
    ddUploadCsvMaxFileSize: 'ddUploadCsvMaxFileSize',
    ddUploadExcelMaxFileSize: 'ddUploadExcelMaxFileSize',
    footer: 'footer',
    icp: 'icp',
    icpNumber: 'number',
    icpUrl: 'url',
    icpSuffix: 'suffix',
    cyberSecurity: 'cyberSecurity',
    cyberSecurityNumber: 'number',
    cyberSecurityUrl: 'url',
    logoName: 'logoName',
    termsOfUseUrl: 'termsOfUseUrl',
    privacyPolicyUrl: 'privacyPolicyUrl',
    mdmAffiliationTabsList: 'mdmAffiliationTabsList',
    mdmMaxAddressesToDisplayCount: 'mdmMaxAddressesToDisplayCount',
    AWSRegionNamesList: 'AWSRegionNamesList',
    maxPrimaryAttributeToDisplayPerGroup: 'maxPrimaryAttributeToDisplayPerGroup',
    isChinaInstance: 'isChinaInstance',
    application: 'application',
    mdmSourceColorCodes: 'mdmSourceColorCodes',
    hiveToRedshiftDataTypeMapping: 'hiveToRedshiftDataTypeMapping',
    mdmMaxCacheAge: 'mdmMaxCacheAge',
    pageSize: 'pageSize',
    dagCompilationBuffer: 'dagCompilationBuffer',
    defaultAverageDurationForProcessExecution: 'defaultAverageDurationForProcessExecution',
    defaultAverageDurationForFlowExecution: 'defaultAverageDurationForFlowExecution',
    blacklistedDataCenterQueryTableNames: 'blacklistedDataCenterQueryTableNames',
    supportMailId: 'supportMailId',
    debounceInterval: 'debounceInterval',
    disablePaginationDropdownRecordLimit: 'disablePaginationDropdownRecordLimit',
    showLinearPaginationDropdownThreshold: 'showLinearPaginationDropdownThreshold',
    defaultPaginationDropdownLimit: 'defaultPaginationDropdownLimit'
};
