export const SiteAdminConstants = {
  ACTION: 'Action',
  DUMMY_PASSWORD: '',
  IDATA_CONFIG_URL: '/site-admin/idata-config',
  TOP_LEVEL_FILTER_URL: '/site-admin/top-level-filter',
  IDATA_DOMAIN_URL: '/site-admin/idata-domain',
  MDM_CONFIG_URL: '/site-admin/mdm-config',
  INFRA_DETAILS_URL: '/site-admin/infra-details',
  ENVIRONMENT_URL: '/site-admin/environment',
  MDM_URL: '/site-admin/mdm',
  EDIT_ENVIRONMENT_URL: '/site-admin/environment/edit-environment',
  NEW_ENVIRONMENT_URL: '/site-admin/environment/new-environment',
  EDIT_INFRA_DETAILS_URL: '/site-admin/infra-details/edit-infra-detail',
  EDIT_IDATA_DOMAIN_URL: '/site-admin/idata-domain/edit-idata-domain',
  NEW_IDATA_DOMAIN_URL: '/site-admin/idata-domain/new-idata-domain',
  EDIT_IDATA_CONFIG_URL: '/site-admin/idata-config/edit-idata-config',
  EDIT_MDM_CONFIG_URL: '/site-admin/mdm/edit-mdm-config',
  ADD_TOP_LEVEL_FILTER: '/site-admin/top-level-filter/add-top-level-filter',
  ENVIRONMENTS_LABEL: 'Environments',
  NO_ENVIRONMENTS: 'No Environments to display',
};

export const IdataDomainConstants = {
  NAME: 'Name',
  INFERENCE_MODEL_PATH: 'Inference Model Path',
  ADD_IDATA_DOMAIN: 'Add New iData Domain',
  EDIT_IDATA_DOMAIN: 'Edit iData Domain ',
  SELECT_ENVIRONMENT: 'Select Environment',
  IDATA_DOMAIN: 'iData Domain',
  IDATA_DOMAIN_EDIT_SUCCESS: 'IData Domain edited successfully',
  IDATA_DOMAIN_ADD_SUCCESS: 'IData Domain added successfully',
  IDATA_DOMAIN_CONFIGURATION: 'iData Domain Configuration',
  BACK_TO_IDATA_DOMAIN_LISTING: 'Back to IData Domain Listing',
  UNSAVED_CHANGES_ALERT:
    'You have unsaved changes on this page. Are you sure you want to leave the page?',
};

export const EnvironmentConstants = {
  SELECT: 'Select',
  ENVIRONMENT: 'Environment',
  DESCRIPTION: 'Description',
  ACTION: 'Action',
  CLUSTER_IPS: 'Cluster IPs',
  ADD_NEW_ENVIRONMENT: 'Add New Environment',
  EDIT_ENVIRONMENT: 'Edit Environment',
  SYNC: 'Sync',
  SYNC_SUCCESS_MESSAGE: 'The clusters have been synced successfully.',
  DISPLAY_NAME: 'Display Name',
  PROJECT_ID: 'Project ID',
  SELECT_CLUSTER_IPS: 'Select Cluster IPs',
  SELECT_TRANSIENT_CLUSTER_IPS: 'Select Transient Cluster IP',
  HIVE_DATABASE: 'Hive Database',
  DEFAULT_CLUSTER_IP: 'Default Client Cluster IP',
  VALUE_REQUIRED: 'Value Required',
  ENVIRONMENT_CONFIGURATION_EDITED_SUCCESSFULLY:
    'Environment configuration edited successfully.',
  ENVIRONMENT_CONFIGURATION_ADDED_SUCCESSFULLY:
    'Environment configuration added successfully.',
  BACK_CONFIRMATION_MESSAGE:
    'You have unsaved changes on this page. Are you sure you want to leave the page?',
  NO_ENVIRONMENT_TO_DISPLAY: 'No Environments to display',
  DELETE_ENVIRONMENT_CONFIRM_START: 'Are you sure you want to delete ',
  DELETE_ENVIRONMENT_CONFIRM_END: ' ?',
  DELETE_ENVIRONMENT: 'Delete Environment',
  ENVIRONMENT_DELETED_SUCCESSFULLY: 'Environment deleted successfully.',
  BACK_TO_ENVIRONMENT_LISTING: 'Back to Environment Listing',
};

export const InfraDetailsConstants = {
  REGION: 'Region',
  S3_BUCKET: 'S3 Bucket',
  CROSS_ACCOUNT_ARN: 'Cross Account Arn',
  DD_LOCAL_HOST: 'Driver Data Local Host',
  DD_USERNAME: 'Driver Data Username',
  DD_PASSWORD: 'Driver Data Password',
  DD_DATABASE_NAME: 'Driver Data Database Name',
  DD_SCHEMA_NAME: 'Driver Data Schema Name',
  DRIVER_DATA_RDS_CONNECTION_STRING_LABEL: 'Driver Data RDS Connection String',
  BACK_MESSAGE: 'Back to Infra Details Listing',
  INFRA_DETAILS: 'Infra Details',
  EDIT_INFRA_DETAILS: 'Edit Infra Details',
  INFRA_DETAILS_EDIT_SUCCESS: 'Infra Details edited successfully',
};

export const TopLevelFilterConstants = {
  ENVIRONMENT: 'Environment',
  SELECT_ENVIRONMENT: 'Select Environment',
  ADD_NEW_TOP_LEVEL_FILTER: 'Add New Top Level Filter',
  TOP_LEVEL_FILTER: 'Top Level Filters',
  ADD_TOP_LEVEL_FILTER: 'Add Top Level Filter',
  TOP_LEVEL_FILTER_DETAILS: 'Top Level Filter Details',
  UNSAVED_CHANGES: 'Unsaved changes',
  ERROR_WITH_COLON: 'Error:',
  TOP_LEVEL_FILTER_FOR_TEXTBOX: 'Top Level Filter',
  MAX_LIMIT_FOR_TOP_LEVEL_FILTER_WARNING_MESSAGE:
    'Maximum character length should be less than 50 characters',
  TOP_LEVEL_FILTER_SHOULD_NOT_CONTAIN_SPECIAL_CHARS:
    'Top level filter should not contain special characters and numeric values.',
};

export const CommonConstants = {
  ENVIRONMENT: 'environment',
  TOP_LEVEL_FILTER: 'top-level-filter',
  LOCAL_ADAPTOR_WITH_IAM_ROLE: 'local-adapter-with-IAM-Role',
  INFRA_DETAILS: 'infra-details',
  USERS: 'users',
  MDM: 'mdm',
  IDATA_CONFIGURATION: 'idata-config',
  IDATA_DOMAIN: 'idata-domain',
  ACTIVE_URL: '/site-admin/',
  ACTIVE_TAB: 'activeTab',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SUCCESS: 'Success',
  OK: 'Ok',
  DELETE: 'Delete',
  ERROR_WITH_COLON: 'Error:',
  BACK: 'Back',
  UNSAVED_CHANGES_ALERT:
    'You have unsaved changes on this page. Are you sure you want to leave the page?',
  ENVIRONMENT_ID: 'environmentId',
  FORM_SUBMITTED: 'Form Submitted',
};

export const LabelConstants = {
  SITE_ADMIN: 'Site Admin',
  ENVIRONMENT: 'Environment',
  TOP_LEVEL_FILTERS: 'Top Level Filters',
  LOCAL_ADAPTOR: 'Local Adaptor',
  USERS: 'Users',
  INFRA_DETAILS: 'Infra Details',
  MDM: 'MDM',
  IDATA: 'IData',
  IDATA_DOMAIN: 'IData Domain',
  LAST_SYNC: 'Last sync',
  ID: 'ID',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  ENVIRONMENTS: 'Environments',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  SYNC_USERS: 'Sync Users',
  VALUE: 'value',
  DISPLAY_LABEL: 'displayLabel',
  PROJECT_ID: 'project_id',
  DESCRIPTION: 'description',
  SEARCH: 'Search',
  ADD_ENVIRONMENTS: 'Add Environments',
  USER_SYNCED_SUCCESSFULLY: 'Users synced successfully',
  IS_SELECTED: 'isSelected',
  DATE_FORMAT: 'MM/dd/yyyy',
  TIME_FORMAT: 'hh:mm a',
};

export const ErrorMessages = {
  DUPLICATE_VALUE: 'Duplicate value',
  MAX_LIMIT_EXCEEDED: 'Maximum primary attributes allowed are ',
  VALUE_REQUIRED: 'Value Required',
};

export const LocalAdaptor = {
  IAM_ROLE: 'IAM Role',
  BUCKET_NAME: 'Bucket Name',
  REGION_NAME: 'Region Name',
  LOCAL_ADAPTOR_SAVED_SUCCESSFULLY: 'Local Adaptor saved successfully.',
  SELECT: 'Select Environment',
  UPDATE: 'Update',
  DROP_DOWN_DEFAULT_VALUE: 'dropdowndefaultvalue',
  ADAPTOR_TYPE: 'AdaptorType',
};
