<div class="matches-component">
    <div cdkDropListGroup>
        <div class="profile-matches-group-one zs-width-100">
            <div class="zs-display-flex search-bar">
                <span class="zs-display-flex">
                    <app-zs-textbox class="search-box" [(modelValue)]="searchValue" [enableSearch]="'true'"
                        [placeholder]="searchPlaceholder" (modelValueChange)="searchBoxValueChange()">
                    </app-zs-textbox>
                </span>
                <span class="zs-display-flex search-button-custom">
                    <button type="button" [hidden]="searchResults && searchResults.length > 0"
                        [disabled]="searchValue.trim().length === 0" class="
              zs-button zs-button-action
              only-search-button-class
              zs-border-rounded-0
            " (click)="searchMatches(false)" translate>
                        SEARCH
                    </button>
                    <button [hidden]="searchResults && searchResults.length === 0" type="button"
                        [disabled]="page === totalPages" class="
                                  zs-button zs-button-action
                                  only-show-more-button-class
                                  zs-border-rounded-0
                                " (click)="searchMatches(true)" translate>
                        SHOW MORE
                    </button>
                </span>
            </div>
        </div>
        <div (clickedOutside)="hideSearchItems($event)" class="autocomplete-items"
            [hidden]="!showSearchItems || isBlank">
            <div *ngFor="let result of searchResults" (click)="searchItemSelected(result)"
                [ngClass]="{ 'disable-div': addProfileLoading }">
                <span>{{ result.name }}</span>
            </div>
            <div *ngIf="showNoResultsFoundLabel" class="filter-select-list" translate>
                No Results Found
            </div>
        </div>
        <div *ngIf="
                (!matches || matches.length === 0) && !matchesLoading
              " class="no-suspected-matches">
            <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
            No Matches Found
        </div>
        <div appZsLoader [isVisible]="matchesLoading" class="profile-matches-group-two zs-col-sm-11 advanced-search">
            <div *ngIf="matches && matches.length > 0" [ngClass]="
          matches.length < 10
            ? 'suspected-matches-with-auto-height'
            : 'suspected-matches'
        " cdkDropList cdkDropListOrientation="horizontal" id="dragList" cdkDropListConnectedTo="dropList">

                <div [cdkDragDisabled]="false" cdkDrag (cdkDragStarted)="drag()" (cdkDragEnded)="drag()"
                    [cdkDragData]="item" *ngFor="let item of matches | slice: 0:matchesToShow; let i = index">
                    <div class="profile-checkboxes">
                        <div [ngClass]="{
                'cursor-move': onMergeScreen,
                'search-background': item.searched,
                'checked-background': item.isChecked
              }" class="zs-card zs-padding-2 zs-display-flex zs-flex-align-center" tabindex="0">

                            <span class="left-cell">
                                <div class="profile-name-tag" [ngClass]="{ 'zs-flex-direction-column': item.searched }">
                                    <div class="zs-col-sm-6">
                                        <span class="zs-icon zs-icon-dragger" [hidden]="!onMergeScreen"></span>
                                        <span [ngClass]="
                        isHco
                          ? 'zs-icon zs-icon-field-unit'
                          : 'zs-icon zs-icon-user'
                      "></span>
                                        <span *ngIf="item?.PBM_NAME?.value" class="profile-matches-show-profile-fn"
                                            (click)="showProfile(item?.MDM_ID?.value)">{{ item?.PBM_NAME?.value |
                                            applyEllipses : maxLengthForEllipses }}</span>
                                        <span *ngIf="item?.MCO_NAME?.value" class="profile-matches-show-profile-fn"
                                            (click)="showProfile(item?.MDM_ID?.value)">{{ item?.MCO_NAME?.value |
                                            applyEllipses : maxLengthForEllipses }}</span>
                                        <span *ngIf="item?.PAYER_NAME?.value" class="profile-matches-show-profile-fn"
                                            (click)="showProfile(item?.MDM_ID?.value)">{{ item?.PAYER_NAME?.value |
                                            applyEllipses : maxLengthForEllipses }}</span>
                                        <span *ngIf="item?.PLAN_NAME?.value" class="profile-matches-show-profile-fn"
                                            (click)="showProfile(item?.MDM_ID?.value)">{{ item?.PLAN_NAME?.value |
                                            applyEllipses : maxLengthForEllipses }}</span>
                                        <zs-tooltip class="tooltip-index tooltip-padding" position="left" offset-x="10"
                                            *ngIf="!dragging">
                                            <table class="zs-table zs-size-s">
                                                <thead>
                                                    <tr>
                                                        <th translate> ATTRIBUTES </th>
                                                        <th translate> VALUE </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let key of keys">
                                                        <td *ngIf="item[key].to_display !== keyDisplayNo">
                                                            {{
                                                            item[key].display != ''
                                                            ? includedKeysMapping.hasOwnProperty(
                                                            item[key].display.trim()
                                                            )
                                                            ? includedKeysMapping[
                                                            item[key].display.trim()
                                                            ]
                                                            : item[key].display
                                                            : key
                                                            }}
                                                        </td>
                                                        <td *ngIf="item[key].to_display !== keyDisplayNo">
                                                            {{ item[key].value }}
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.PBM_NAME?.value">
                                                        <td translate> NAME </td>
                                                        <td>
                                                            <span>{{item?.PBM_NAME?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.PLAN_NAME?.value">
                                                        <td translate> NAME </td>
                                                        <td>
                                                            <span>{{item?.PLAN_NAME?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.PAYER_NAME?.value">
                                                        <td translate> NAME </td>
                                                        <td>
                                                            <span>{{item?.PAYER_NAME?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.MCO_NAME?.value">
                                                        <td translate> NAME </td>
                                                        <td>
                                                            <span>{{item?.MCO_NAME?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td translate> MDM ID </td>
                                                        <td>
                                                            <span>{{item?.MDM_ID?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.PBM_CHANNEL?.value">
                                                        <td translate> CHANNEL </td>
                                                        <td>
                                                            <span>{{item?.PBM_CHANNEL?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="item?.PAYER_CHANNEL?.value">
                                                        <td translate> CHANNEL </td>
                                                        <td>
                                                            <span>{{item?.PAYER_CHANNEL?.value }}
                                                            </span>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td translate> SOURCES </td>
                                                        <td>
                                                            <span *ngFor="let src of item.SRC_NM.value" [ngStyle]="{
                                  'background-color':
                                    environment.mdmSourceColorCodes[
                                      src.trim().toLowerCase()
                                    ]
                                }" class="zs-badge-counter">
                                                                {{ src.trim().toUpperCase() }}
                                                            </span>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </zs-tooltip>
                                    </div>
                                    <div class="zs-col-sm-6 word-break">
                                        <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 0
                      " [ngStyle]="{
                        'background-color':
                          environment.mdmSourceColorCodes[
                            item.SRC_NM.value[0].trim().toLowerCase()
                          ]
                      }" [ngClass]="{ 'search-margin': item.searched }" class="zs-badge-counter zs-border-rounded-0">
                                            {{ item.SRC_NM.value[0].trim().toUpperCase() }}
                                        </span>
                                        <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 1
                      " [ngStyle]="{
                        'background-color':
                          environment.mdmSourceColorCodes[
                            item.SRC_NM.value[1].trim().toLowerCase()
                          ]
                      }" class="zs-badge-counter zs-border-rounded-0">
                                            {{ item.SRC_NM.value[1].trim().toUpperCase() }}
                                        </span>
                                        <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 2
                      ">...</span>
                                    </div>
                                </div>
                                <div *ngIf="item.FUZZY_SCORE && item.FUZZY_SCORE.value" class="fuzzy-score">
                                    <span class="match-text">{{ item.FUZZY_SCORE.value }}% </span><span
                                        class="match-text" translate>
                                        match</span>
                                </div>
                            </span>
                            <span class="right-cell zs-icon zs-size-large zs-icon-close"
                                (click)="searchItemRemoved(item)" *ngIf="item.searched" [hidden]="onMergeScreen">
                            </span>
                            <span class="right-cell zs-icon zs-icon-item-drag" [hidden]="!onMergeScreen"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>