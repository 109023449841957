import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addKeyToObject'
})
export class AddKeyToObjectPipe implements PipeTransform {

  transform(obj: any, property: any, value: any): any {
    if (!obj.hasOwnProperty(property)) {
      obj[property] = value;
    }
    return obj;
  }

}
