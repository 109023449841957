import ActionFieldValidation from '../../../../node_modules/zsui/src/validation/actionFieldValidation.m.js';

export default class ActionFieldWithValidation extends ActionFieldValidation {
	constructor(...args) {
		const _ = super(...args);
		return _;
	}

  onclick(event) {
    super.onclick(event);
    if (event.target === this.clearIconElement) {
        this.value = '';
        this.fire('input');
      }
  }
}
