import { Pipe, PipeTransform } from '@angular/core';
import { ServiceUtils } from 'src/app/service-utils';

@Pipe({
  name: 'timeSecondsFormatter'
})

export class TimeSecondsFormatterPipe implements PipeTransform {
  constructor(private _serviceUtils: ServiceUtils){

  }
  transform(valueInSeconds: any): any {
    return this._serviceUtils.getTimeFromSeconds(valueInSeconds);
  }

}
