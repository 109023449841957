import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import Dialog from '../../../../node_modules/zsui/src/dialog/dialog.m.js';

Dialog.register('zs-dialog');

@Component({
  selector: 'app-modaldialog',
  templateUrl: './modaldialog.component.html',
  styleUrls: ['./modaldialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ModaldialogComponent implements OnChanges {

  @Input() showmodal: boolean;
  @Input() titletext: string;
  @Input() customstyle: object;
  @Input() headerCustomStyle: object;
  @Input() showTooltipForTitle = false;
  @Input() isCrossIconEnabled: boolean;
  @Input() customzIndex: boolean;
  @Input() onCloseCallBackFunction: Function;
  @Input() dialogZIndex = 10000;
  @Output() showmodalChange = new EventEmitter();

  @ViewChild('dialog') dialogs: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.customzIndex) {
        this.dialogs.nativeElement.zIndex = 1999;
      }

      if (this.dialogs) {
        if (changes.showmodal && changes.showmodal.currentValue === true) {
          this.dialogs.nativeElement.backdrop.classList.add('zs-bg-overlay-60');
          this.dialogs.nativeElement.showModal();
        } else if (changes.showmodal && !changes.showmodal.currentValue) {
          this.dialogs.nativeElement.close();
        }
      }
    }, 0);
  }

  hideModal() {
    this.dialogs.nativeElement.close();
  }

  onClose(event) {
    this.showmodal = false;
    this.showmodalChange.emit(this.showmodal);
    if (this.onCloseCallBackFunction) {
      this.onCloseCallBackFunction();
    }
  }
}
