<div class="zs-display-flex select zs-select-wrapper" [ngClass]="labelInverse ? 'zs-text-inverse': 'zs-text-headline'">
  <label *ngIf="label" class="select-label">
    <span *ngIf="!showLabelTooltip; else tooltipHint">{{ label }}</span>
    <ng-template #tooltipHint>
      {{label | applyEllipses:labelEllipsisConstant}}
      <zs-tooltip *ngIf="label.length > labelEllipsisConstant" handle-transform>
        <zs-tooltip-content class="zs-size-xs tooltip-content">
            {{label}}
        </zs-tooltip-content>
      </zs-tooltip>
    </ng-template>
    <span *ngIf="!isRequired && enableSearch !== 'true' && showOptionalText" class="zs-font-weight-normal">
      <i translate>{{ common_labels.OPTIONAL_IN_PARANTHESIS }}</i>
    </span>
  </label>
  <p-is-zs-multiselect mode="action"
    class="zs-margin-0 zs-action-field zs-select zs-master-style background-color-class" #zsSelect
    [labelKey]="'displayLabel'" [valueKey]="'value'" [options]="source" [selectedValues]="selectedOptions"
    [ngClass]="touched ? inputClass : ''" [style]="customStyle" [flippable]="flippable"
    [hideOnScroll]="flippable || hideOnScroll" [disabled]="disabled" (change)="onChange($event)"
    (beforeopen)="beforeOpen()" (onfocus)="onFocusOut($event)" [noRecordsText]="noRecordsText" clear>
  </p-is-zs-multiselect>
  <p *ngIf="touched && inErrorState" [ngClass]="inputClass" class="message-margin zs-message">{{errorMessage}}</p>
</div>