import { Directive, ElementRef, AfterViewChecked } from '@angular/core';
import { IDM_CONSTANTS, IDM_Class_CONSTANTS } from '../shared/constants';
import { AppConfig } from '../app.config';
import { EnvironmentConfig } from '../shared/models/environment';
import { ZSLB } from 'src/assets/override/launchbar.js';
import { DatePipe } from '@angular/common';
declare var ZSLB: any;

@Directive({
  selector: '[appIdmBar]'
})


export class IdmBarDirective implements AfterViewChecked {
  environment: EnvironmentConfig;
  launchBarDiv: any;
  lastLoginDiv: any;
  isLegacy: boolean;
  lastLoginVal: any;
  constructor(private el: ElementRef, private config: AppConfig, public datepipe: DatePipe) {
    this.environment = this.config.getConfigObject();
  }

  ngAfterViewChecked() {
    this.check_if_user_bar_loaded();
  }


  private check_if_user_bar_loaded() {
      this.launchBarDiv = this.el.nativeElement.querySelector(IDM_Class_CONSTANTS.LEGACY_DIV);
      this.lastLoginDiv = this.el.nativeElement.querySelector(IDM_Class_CONSTANTS.LAST_LOGIN_DIV);
        if (this.lastLoginDiv !== undefined && this.lastLoginDiv !== null) {
          this.lastLoginDiv.style = 'color:#DEDCDE; float:right; padding-right:0.5em';
        }
    if (this.launchBarDiv !== undefined && this.launchBarDiv !== null
      && this.launchBarDiv.classList.contains(IDM_Class_CONSTANTS.RENDERED_CHECK_CLASS) === false) {
        const logoutDiv = document.getElementById(IDM_Class_CONSTANTS.LOGOUT_DIV) as HTMLBaseElement;
        const currentLocation = location.href;
        const idmLogoutUrl = this.environment.idmLogOutUrl;
        const isUserTrigerredArg = IDM_CONSTANTS.IS_USER_TRIGGERED + '=true';
        const ReturnUrlArg = IDM_CONSTANTS.RETURN_URL + '=' + encodeURIComponent(currentLocation);
        logoutDiv.href = `${idmLogoutUrl}?${isUserTrigerredArg}&${ReturnUrlArg}`;
        ZSLB.displayLegacyLaunchbar(this.launchBarDiv);
        this.el.nativeElement.querySelector(IDM_Class_CONSTANTS.LEGACY_DIV).classList.add(IDM_Class_CONSTANTS.RENDERED_CHECK_CLASS);
    }
  }
}
