import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MDMCommonConstants, MDMRoutes, MDMWrapperConstants } from '../constants/mdm-common-constants';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMService } from '../mdm.service';
import { QueryParams } from '../constants/profile-constants';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../constants/ui-translation';
import { IdmDetailsService } from 'src/app/idm-details.service';
import  {TabConfig} from 'src/assets/listtabs/Tab.js' 

@Component({
  selector: 'app-mdm-wrapper',
  templateUrl: './mdm-wrapper.component.html',
  styleUrls: ['./mdm-wrapper.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MdmWrapperComponent implements OnInit {
  activeTab = MDMWrapperConstants.SUSPECTED_MATCHES;
  user: any;
  isSearch = true;
  subscription: Subscription;
  uiLabels = mdm_labels.MDM_WRAPPER;
  MDMWrapperConstants = MDMWrapperConstants;
  isProfile = false;
  environment: EnvironmentConfig;
  showViewRecordsStatistics = false;
  uiCommonLabels = commmon_labels;
  chartFilters = [{ value: MDMCommonConstants.HCP, label: MDMCommonConstants.HCP },
  { value: MDMCommonConstants.HCO, label: MDMCommonConstants.HCO }];
  selectedFilter = this.chartFilters[0];
  translatedLabels: any;
  recordStatisticsLabel: string;
  isAdmin: boolean;
  IsVisible= TabConfig.MDM_Tabs;

  constructor(private router: Router, private route: ActivatedRoute,
    private config: AppConfig,
    private _translate: TranslateService,
    private _mdmService: MDMService,
    private _idmDetailsService: IdmDetailsService) {
    this.environment = this.config.getConfigObject();
    this.translatedLabels = this._translate.instant(
      [
        this.uiLabels.RECORD_STATISTICS
      ]);
    if (this.route.snapshot.routeConfig.path === MDMCommonConstants.HOME) {
      this._mdmService.setMatchesWithSearchData();
    }
    this.recordStatisticsLabel = this.translatedLabels[this.uiLabels.RECORD_STATISTICS];
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get(QueryParams.ID);
    if (id) {
      this.activeTab = MDMWrapperConstants.ALL_RECORDS;
      this.isProfile = true;
    }
    else if (this.router.routerState.snapshot.url === MDMRoutes.ALL_RECORDS) {
      this.activeTab = MDMWrapperConstants.ALL_RECORDS;
      this.isProfile = false;
    }
    else if (this.router.routerState.snapshot.url === MDMRoutes.DCR_MANAGEMENT) {
      this.activeTab = MDMWrapperConstants.DCR_MANAGEMENT;
      this.isProfile = false;
    }
    else if (this.route.snapshot.queryParamMap.get(QueryParams.IS_PROFILE_EXISTS) === 'false') {
      this.activeTab = MDMWrapperConstants.ALL_RECORDS;
      this.isProfile = false;
    } else if (this.router.routerState.snapshot.url.includes(MDMRoutes.ADVANCED_SEARCH) &&
      this.environment.isMDMAdvancedSearchEnabled) {
      this.activeTab = MDMWrapperConstants.ADVANCED_SEARCH;
    } else if (this.router.routerState.snapshot.url.includes(MDMRoutes.ADVANCED_SEARCH) &&
      !this.environment.isMDMAdvancedSearchEnabled) {
      this.activeTab = MDMWrapperConstants.SUSPECTED_MATCHES;
    } else if (this.router.routerState.snapshot.url === MDMRoutes.LOG) {
      this.activeTab = MDMWrapperConstants.ACTIVITY_LOG;
      this.isProfile = false;
    } else if (this.router.routerState.snapshot.url === MDMRoutes.ADMIN) {
      this.activeTab = MDMWrapperConstants.ADMIN;
      this.isProfile = false;
    }
    console.log(this._idmDetailsService.get_roles());
    this.checkUIAccess()

  }
  checkUIAccess() {
    const roles = this._idmDetailsService.get_roles();
    console.log(roles)
    if (roles['SiteAdmin']) {
      this.isAdmin = true;
    }
  }
  setSelectedTab(selectedTabName) {
    if (this.activeTab !== selectedTabName) {
      this.activeTab = selectedTabName;
      if (selectedTabName === MDMWrapperConstants.SUSPECTED_MATCHES) {
        this.router.navigate([MDMRoutes.HOME]);
      } else if (selectedTabName === MDMWrapperConstants.ALL_RECORDS) {
        this.router.navigate([MDMRoutes.ALL_RECORDS]);
      } else if (selectedTabName === MDMWrapperConstants.ADVANCED_SEARCH) {
        this.router.navigate([MDMRoutes.ADVANCED_SEARCH]);
      } else if (selectedTabName === MDMWrapperConstants.DCR_MANAGEMENT) {
        this.router.navigate([MDMRoutes.DCR_MANAGEMENT]);
      } else if (selectedTabName === MDMWrapperConstants.ACTIVITY_LOG) {
        this.router.navigate([MDMRoutes.LOG]);
      } else if (selectedTabName === MDMWrapperConstants.ADMIN) {
        this.router.navigate([MDMRoutes.ADMIN]);
      }
    }
  }

  showViewRecordsStatisticsPopup() {
    this.showViewRecordsStatistics = true;
  }

  hideViewRecordsStatisticsPopup() {
    this.showViewRecordsStatistics = false;
  }

  selectedFilterChanged(selectedFilter) {
    this.selectedFilter = selectedFilter;
  }
}
