import { Component, Input, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AppConfig } from 'src/app/app.config';
import {
  PayerWrapperConstants,
  PAYERTableConstant
} from 'src/app/mdm/constants/mdm-common-constants';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { PayerCellRendererComponent } from '../../payer/payer-cell-renderer/payer-cell-renderer.component';
import { MDMCommonConstants, MDMWrapperConstants } from '../../constants/mdm-common-constants';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { Subscription } from 'rxjs';
import { containsSpecialCharacters } from 'src/app/shared/utils/string-check-utils';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.less'],
})
export class PayerComponent implements OnInit {
  selectedFilter = { value: 'PAYER' };
  gridOptions: object;
  defaultColDef: { [x: string]: string | boolean };
  environment: EnvironmentConfig;
  widthList = ['MSTR_MDM_ID', 'START_DATE', 'COUNT'];
  @Input('tableColumns') tableColumns: any;
  constructor(private config: AppConfig,
    private service: MDMService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();
  }
  colDefs: ColDef[] = [];
  payerRowData: any[];
  page = 1;
  pageSize: number;
  totalRecords: number;
  frameworkComponents: any;
  sortOrder: string = MDMCommonConstants.DESC;
  sortByColumn: string;
  searchBy: string;
  showAgGridLoader: boolean;
  payerSub: Subscription;
  checkSpecialCharacter: boolean = false ;

  ngOnInit(): void {
    this.service.setActiveTab(MDMWrapperConstants.SUSPECTED_MATCHES);
    this.pageSize = this.environment.pageSize;
    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      defaultColDef: {
        sortable: true,
        unSortIcon: true
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.frameworkComponents = {
      custumizedId: PayerCellRendererComponent,
    };
    this.setColumns();
    this.getPAYERTableDetails();
  }

  pageChanged(event) {
    this.page = event;
    this.getPAYERTableDetails();
  }
  onSort(event) {
    const columnsSortState = event.columnApi.getColumnState();
    let colId: number;
    let colName = '';
    let columnSortOrder = '';
    let i = 0;
    for (const column of columnsSortState) {
      if (column.sort) {
        colId = i + 1;
        colName = column.colId;
        columnSortOrder = column.sort;
        break;
      }
      i++;
    }
    this.sortOrder = columnSortOrder;
    this.sortByColumn = colName;
    this.getPAYERTableDetails();
  }
  setColumns() {
    this.showAgGridLoader = true;
    for (const column in this.tableColumns) {
      this.colDefs.push({
        headerName: column.replace(/_/g, ' '),
        field: this.tableColumns[column],
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: column.replace(/_/g, ' '),
        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: this.getCellTooltip(),
        [AgGridColumnDefsConstants.MAX_WIDTH]: this.widthList.includes(this.tableColumns[column])
          ? 180 : this.tableColumns[column] === 'MSTR_PAYER_NAME' ? 500
            : 250,
        sortingOrder: ['asc', 'desc'],
        ...((this.tableColumns[column] == PAYERTableConstant.MDM_ID
          ? true
          : this.tableColumns[column] == PAYERTableConstant.SUSPECTED_SOURCES
            ? true
            : this.tableColumns[column] == PAYERTableConstant.PAYER_NAME ?
              true
              : this.tableColumns[column] == PAYERTableConstant.HIGHEST_MATCH_PERCENTAGE
                ? true
                : false) && { cellRenderer: PayerCellRendererComponent }),
        cellRendererParams: {
          [this.tableColumns[column]]: true,
          routerlink: '/mdm/all-records/payer/profile/',
          state: {
            entity: PayerWrapperConstants.PAYER,
            return_page: 'suspected',
          },
        },
      });
    }
  }

  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }

  getPAYERTableDetails() {
    this.showAgGridLoader = true;
    if (this.payerSub) {
      this.payerSub.unsubscribe();
    }
    this.payerSub = this.service.getPAYERMatches(this.sortByColumn,
      this.sortOrder,
      this.searchBy,
      this.page,
      this.pageSize).subscribe((data) => {
        this.payerRowData = data.data;
        this.totalRecords = data.totalRecords;
        this.showAgGridLoader = false;
      }, (exception) => {
        this.showAgGridLoader = false;
        this.toaster.showError(exception.error.message, '');

      })
  }

  searchPayerOnChanged() {
    this.checkSpecialCharacter = containsSpecialCharacters(this.searchBy);
    if(!this.checkSpecialCharacter){
      this.getPAYERTableDetails();
    }

  }
  clearSearch(value) {
    if (!value) {
      this.getPAYERTableDetails();
    }
    this.checkSpecialCharacter = false;
  }
  ngOnDestroy(): void {
    this.payerSub.unsubscribe();
  }
}
