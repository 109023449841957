export const ExcludedKeys = {
    IS_GOLDEN: "IsGolden",
    ADDRESSES: "ADDRESSES",
    COMMUNICATIONS: "COMMUNICATIONS",
    IDENTIFIERS: "IDENTIFIERS",
    ETY_TYPE: "ETY_TYPE",
    ETY_SUB_TYPE: "ETY_SUB_TYPE",
    CHECKED: "checked",
    FUZZY_SCORE: "FUZZY_SCORE",
    SRC_NM: "SRC_NM",
    PRI_ADDR_FLG: "PRI_ADDR_FLG"
};

export const QueryParams = {
    RETURN_ID: "returnId",
    RETURN_TO: "returnTo",
    ID: "id",
    IS_PROFILE_EXISTS: "isProfileExists",
    RETURN_NAME: 'returnName'
};

export const ProfileAPIResponseConstants = {
    VALUE: "value",
    DISPLAY: "display",
    DESC: "desc",
    TO_DISPLAY: "to_display"
};

export const MDMProfileCommonConstants = {
    SRC_NM: "SRC_NM",
    SRC_ID: "SRC_ID"
};

export const IncludedKeys = {
    ID: "ID",
    NAME: "NAME",
    HCP_MDM_ID: "HCP_MDM_ID",
    MDM_ID: "MDM_ID",
    TITL: "TITL"
};

export const MDMProfileDOMActionConstants = {
    HOVERED: "hovered",
    SMOOTH: "smooth"
};

export const MDMProfileTabNameConstants = {
    PROFILES: 'profiles',
    HCP_DETAILS: 'hcp-details',
    HIERARCHY: 'hierarchy',
    ACTIVITY_LOG: 'activity-log',
    HCP_HIERARCHY: 'hcp-hierarchy',
    HCO_HIERARCHY: 'hco-hierarchy',
    HCO_DETAILS: 'hco-details',
    HCP_HCP: 'HCP-HCP',
    HCP_HCO: 'HCP-HCO',
    HCO_HCP: 'HCO-HCP',
    HCO_HCO: 'HCO-HCO',
    SUSPECTED_MATCHES: 'Suspected Matches'
};

export class MDMAffiliationTranslationMessage {
    errorMessage: string;
    informationLabel: string;

    constructor(errorMessage, informationLabel) {
        this.errorMessage = errorMessage;
        this.informationLabel = informationLabel;
    }
}

export const MDMAffiliationTypesConstants = {
    HCP_HCP: 'HCP-HCP',
    HCO_HCO: 'HCO-HCO',
    HCP_HCO: 'HCP-HCO',
    HCO_HCP: 'HCO-HCP'
}
