import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { common_labels } from '../constants/ui_labels_translation_mapping';

@Injectable({
  providedIn: 'root'
})
export class AgGridSortService {

  byLabel: string;
  translatedLabels: any;
  commonLabels = common_labels;
  constructor(private _translate: TranslateService) {
    this.translatedLabels = this._translate.instant(
      [
        this.commonLabels.BY
      ]
    );
    this.byLabel = this.translatedLabels[this.commonLabels.BY];
  }

  checkIfValueIsNullOrUndefined(value) {
    if (value === undefined || value === null) {
      return true;
    } else {
      return false;
    }
  }

  validateValue(valueOne, valueTwo) {
    const isValueOneValid = this.checkIfValueIsNullOrUndefined(valueOne);
    const isValueTwoValid = this.checkIfValueIsNullOrUndefined(valueTwo);

    if (isValueOneValid && isValueTwoValid) {
      return 0;
    } if (isValueOneValid) {
      return -1;
    } if (isValueTwoValid) {
      return 1;
    } return null;
  }

  dateValueComparator(valueOne, valueTwo, globalizedColumnMapper) {
    const isValuevalid = this.validateValue(valueOne, valueTwo);
    if (isValuevalid !== null) {
      return isValuevalid;
    }
    valueOne = new Date(globalizedColumnMapper[valueOne.split(this.byLabel)[0].trim()]);
    valueTwo = new Date(globalizedColumnMapper[valueTwo.split(this.byLabel)[0].trim()]);

    if (valueOne === valueTwo) {
      return 0;
    }
    return (valueOne > valueTwo ? 1 : -1);
  }

  stringValueComparator(valueOne, valueTwo) {
    const isValuevalid = this.validateValue(valueOne, valueTwo);
    if (isValuevalid !== null) {
      return isValuevalid;
    }
    valueOne = String(valueOne).toLowerCase().trim();
    valueTwo = String(valueTwo).toLowerCase().trim();
    if (valueOne === valueTwo) {
      return 0;
    }
    return (valueOne > valueTwo ? 1 : -1);
  }

  integerValueComparator(valueOne, valueTwo) {
    const isValuevalid = this.validateValue(valueOne, valueTwo);
    if (isValuevalid !== null) {
      return isValuevalid;
    }
    return valueOne - valueTwo;
  }
}
