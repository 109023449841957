import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MDMService } from 'src/app/mdm/mdm.service';
import { chart } from 'highcharts';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { RDHMessageFactory } from 'src/app/shared/rdh-api-messages-translation/rdh-message-factory';
import {
  ChartColors,
  DashboardTableColumnsConstants,
  ExcludeRecordStatisticsColumnsList,
  StatsConstants,
} from 'src/app/mdm/constants/dashboard-stats-constants';
import { mdm_labels } from '../../constants/ui-translation';
import { StatsMetricKeyMappitgConstants } from '../../constants/mdm-common-constants';
import { DatePipe } from '@angular/common';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { Router } from '@angular/router';
import { RecordStatisticsTableRendererComponent } from './record-statistics-table-renderer/record-statistics-table-renderer.component';
import { Pagination } from '../../../shared/models/pagination.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class StatsComponent implements OnInit, OnDestroy {
  error_label: string;
  translated_labels: any;
  commmon_labels = commmon_labels;
  ui_labels = mdm_labels.SUSPECTED_MATCHES.DASHBOARD.STATS;
  height = StatsConstants.height;
  showChartContentError: string;
  showStatsProfileContentError: string;

  @ViewChild('chartContainer', { read: ElementRef, static: true })
  chartContainer: ElementRef;
  private _selectedFilter: any;
  colors = ChartColors;
  filterSubscription: Subscription;
  entityTypeFlagSubscription: Subscription;

  @Input() set selectedFilter(val: any) {
    if (val) {
      this._selectedFilter = val;
    }
  }
  get selectedFilter() {
    return this._selectedFilter;
  }

  statsLoading = true;
  totals: number[] = [];
  sourceKeys: any[];
  metricKeys: any[];

  chart: Highcharts.Chart;
  stats: any[];

  flag: number;
  // time blocks to view in the drop down
  chartPeriods = [];
  selectedPeriod = { value: '', label: '', format: '' };
  chartMetric = '';
  chartCategory = '';
  chartSource = '';
  chartData: any;
  errorMessage: any;
  isLoaded = false;
  paginationData: Pagination = {
    ItemCount: 0,
    CurrentPage: 0,
    CustomStyle: ''
  };

  profileType: string;
  showprofileStatisticsDetailsTable = false;
  metricKeysMapping = [];
  globalizedMatricKey = new Map<string, string>();
  environment: EnvironmentConfig;
  metriKeysUpperCaseMapping = new Map<string, string>();
  valuesLabel: string;
  globalizedDateMapping = {};
  columnDefinitionList = [];
  columnDefinitionMapper = new Map<string, string>();
  defaultColDef: object;
  gridApi: any;
  frameworkComponents: object;

  constructor(
    private service: MDMService,
    private _translate: TranslateService,
    private _datePipe: DatePipe,
    private config: AppConfig,
    private _router: Router
  ) {
    this.environment = this.config.getConfigObject();
    this.flag = 0;
    this.translated_labels = this._translate.instant([
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels.LAST_1_RUN,
      this.ui_labels.LAST_2_RUN,
      this.ui_labels.LAST_3_RUN,
      this.ui_labels.LAST_4_RUN,
      this.ui_labels.AUTO_MERGED,
      this.ui_labels.SINGLE,
      this.ui_labels.SUSPECTED,
      this.ui_labels.NEW,
      this.ui_labels.UPDATE,
      this.ui_labels.AUTO_MERGED_UPPER_CASE,
      this.ui_labels.SINGLE_UPPER_CASE,
      this.ui_labels.SUSPECTED_UPPER_CASE,
      this.ui_labels.NEW_UPPER_CASE,
      this.ui_labels.UPDATE_UPPER_CASE,
      this.ui_labels.VALUES,
      this.ui_labels.MDM_ID,
      this.ui_labels.NAME,
      this.ui_labels.SOURCES,
    ]);
    this.error_label = this.translated_labels[
      this.commmon_labels.ERROR_WITH_COLON
    ];
    this.valuesLabel = this.translated_labels[this.ui_labels.VALUES];

    this.globalizedMatricKey[
      StatsMetricKeyMappitgConstants.AUTO_MERGED
    ] = this.translated_labels[this.ui_labels.AUTO_MERGED];
    this.globalizedMatricKey[
      StatsMetricKeyMappitgConstants.SINGLE
    ] = this.translated_labels[this.ui_labels.SINGLE];
    this.globalizedMatricKey[
      StatsMetricKeyMappitgConstants.SUSPECTED
    ] = this.translated_labels[this.ui_labels.SUSPECTED];
    this.globalizedMatricKey[
      StatsMetricKeyMappitgConstants.NEW
    ] = this.translated_labels[this.ui_labels.NEW];
    this.globalizedMatricKey[
      StatsMetricKeyMappitgConstants.UPDATE
    ] = this.translated_labels[this.ui_labels.UPDATE];

    this.metriKeysUpperCaseMapping[
      StatsMetricKeyMappitgConstants.AUTO_MERGED_UPPER_CASE
    ] = this.translated_labels[this.ui_labels.AUTO_MERGED_UPPER_CASE];
    this.metriKeysUpperCaseMapping[
      StatsMetricKeyMappitgConstants.SINGLE_UPPER_CASE
    ] = this.translated_labels[this.ui_labels.SINGLE_UPPER_CASE];
    this.metriKeysUpperCaseMapping[
      StatsMetricKeyMappitgConstants.SUSPECTED_UPPER_CASE
    ] = this.translated_labels[this.ui_labels.SUSPECTED_UPPER_CASE];
    this.metriKeysUpperCaseMapping[
      StatsMetricKeyMappitgConstants.NEW_UPPER_CASE
    ] = this.translated_labels[this.ui_labels.NEW_UPPER_CASE];
    this.metriKeysUpperCaseMapping[
      StatsMetricKeyMappitgConstants.UPDATE_UPPER_CASE
    ] = this.translated_labels[this.ui_labels.UPDATE_UPPER_CASE];

    this.chartPeriods = [
      {
        value: '1',
        label: this.translated_labels[this.ui_labels.LAST_1_RUN],
        format: StatsConstants.formatValue,
        displayLabel: this.translated_labels[this.ui_labels.LAST_1_RUN],
        isSelected: false,
      },
      {
        value: '2',
        label: this.translated_labels[this.ui_labels.LAST_2_RUN],
        format: StatsConstants.formatValue,
        displayLabel: this.translated_labels[this.ui_labels.LAST_2_RUN],
        isSelected: false,
      },
      {
        value: '3',
        label: this.translated_labels[this.ui_labels.LAST_3_RUN],
        format: StatsConstants.formatValue,
        displayLabel: this.translated_labels[this.ui_labels.LAST_3_RUN],
        isSelected: true,
      },
      {
        value: '4',
        label: this.translated_labels[this.ui_labels.LAST_4_RUN],
        format: StatsConstants.formatValue,
        displayLabel: this.translated_labels[this.ui_labels.LAST_4_RUN],
        isSelected: false,
      },
    ];
    this.selectedPeriod = this.chartPeriods[2];
    this.metricKeysMapping = [
      {
        displayLabel: this.translated_labels[this.ui_labels.AUTO_MERGED],
        value: StatsMetricKeyMappitgConstants.AUTO_MERGED,
      },
      {
        displayLabel: this.translated_labels[this.ui_labels.SINGLE],
        value: StatsMetricKeyMappitgConstants.SINGLE,
      },
      {
        displayLabel: this.translated_labels[this.ui_labels.SUSPECTED],
        value: StatsMetricKeyMappitgConstants.SUSPECTED,
      },
      {
        displayLabel: this.translated_labels[this.ui_labels.NEW],
        value: StatsMetricKeyMappitgConstants.NEW,
      },
      {
        displayLabel: this.translated_labels[this.ui_labels.UPDATE],
        value: StatsMetricKeyMappitgConstants.UPDATE,
      },
    ];
    this.columnDefinitionMapper[
      DashboardTableColumnsConstants.MDM_ID
    ] = this.translated_labels[this.ui_labels.MDM_ID];
    this.columnDefinitionMapper[
      DashboardTableColumnsConstants.NAME
    ] = this.translated_labels[this.ui_labels.NAME];
    this.columnDefinitionMapper[
      DashboardTableColumnsConstants.SOURCES
    ] = this.translated_labels[this.ui_labels.SOURCES];
  }

  ngOnInit() {
    sessionStorage.setItem(StatsMetricKeyMappitgConstants.PERIOD_VALUE, null);
    this.paginationData.CurrentPage = 1;
    this.paginationData.PageSize = this.environment.pageSize;
    this.profileType = this.selectedFilter.value.toLowerCase();
    this.entityTypeFlagSubscription = this.service
      .getEntityTypeFlagChangedStatus()
      .subscribe((isEntityTypeChangedFlag) => {
        if (isEntityTypeChangedFlag) {
          this.showprofileStatisticsDetailsTable = isEntityTypeChangedFlag;
        }
      });
    this.filterSubscription = this.service
      .getFilterChangedStatus()
      .subscribe((selectedFilter) => {
        this.selectedFilter = selectedFilter;
        this.resetData();
        this.loadStats();
      });
    this.defaultColDef = {
      flex: 1,
    };
    this.frameworkComponents = {
      recordStatisticsTableRenderer: RecordStatisticsTableRendererComponent,
    };
  }

  setDashboardTableColumnDefs() {
    this.columnDefinitionList = [];
    for (const [key, value] of Object.entries(this.columnDefinitionMapper)) {
      const dashboardTableColumnDefinition = {
        [AgGridColumnDefsConstants.HEADER_NAME]: value,
        [AgGridColumnDefsConstants.FIELD]: key,
        [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
        [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
        [AgGridColumnDefsConstants.MAX_WIDTH]: key === DashboardTableColumnsConstants.MDM_ID ? 200 : 700,
        [AgGridColumnDefsConstants.CELL_STYLE]:
          key === DashboardTableColumnsConstants.SOURCES
            ? { 'padding-top': '0.5', 'padding-left': '0.5em' }
            : { 'padding-left': '0.8em' },
        [AgGridColumnDefsConstants.CELL_RENDERER]: ExcludeRecordStatisticsColumnsList.includes(
          key
        )
          ? 'recordStatisticsTableRenderer'
          : '',
        [AgGridColumnDefsConstants.CELL_RENDERER_PARAMS]: {
          isMdmIdColumn:
            key === DashboardTableColumnsConstants.MDM_ID ? true : false,
          isNameColumn:
            key === DashboardTableColumnsConstants.NAME ? true : false,
          isSourcesColumn:
            key === DashboardTableColumnsConstants.SOURCES ? true : false,
          profileType: this.profileType,
          environment: this.environment,
        },
      };
      this.columnDefinitionList.push(dashboardTableColumnDefinition);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  getData(data) {
    this.chartData = data;
  }

  resetData() {
    this.globalizedDateMapping = {};
    this.showprofileStatisticsDetailsTable = true;
    this.totals = [];
    this.stats = [];
    this.chart = chart(this.chartContainer.nativeElement, {
      title: { text: null },
      credits: { enabled: false },
    });
    this.sourceKeys = [];
    this.metricKeys = [];
  }

  loadStats() {
    this.statsLoading = true;

    this.service
      .getStatsData(
        this.selectedPeriod.value,
        this.selectedFilter.value,
        this.selectedPeriod.format
      )
      .subscribe(
        (data) => {
          this.showChartContentError = '';
          this.statsLoading = false;
          this.stats = null;
          if (!_.isEmpty(data)) {
            this.stats = data;
            const categories = Object.keys(data);
            if (categories && categories.length > 0) {
              this.sourceKeys = Object.keys(data[categories[0]]).map((k) => {
                return { value: k, isSelected: true, displayLabel: k };
              });
              this.metricKeys = Object.keys(
                data[categories[0]][this.sourceKeys[0].value]
              )
                .filter((k) => k !== 'input')
                .map((k) => {
                  return { key: k, selected: true };
                });
              this.renderChart(this.selectedPeriod.value);
            }
          }
        },
        (exception) => {
          this.statsLoading = false;
          const obj = new RDHMessageFactory(
            this._translate,
            exception.error.message
          ).getMessage();
          this.showChartContentError = obj.translate();
        }
      );
  }
  keySelected() {
    this.showprofileStatisticsDetailsTable = true;
    this.renderChart(this.selectedPeriod.value);
  }

  closeErrorToaster() {
    this.showChartContentError = '';
  }

  applyDatePipe(categories) {
    const globalizedCategoriesList = [];
    for (const item of categories) {
      const globalizedDate = this._datePipe.transform(
        item,
        this.environment.dateTimeFormat.dateFormats
      );
      this.globalizedDateMapping[globalizedDate] = item;
      globalizedCategoriesList.push(globalizedDate);
    }
    return globalizedCategoriesList;
  }

  renderChart(run) {
    const data = this.stats;
    const categories = Object.keys(data);
    if (!categories || categories.length === 0) { return; }

    let gp = 0.2;
    let pl;

    if (categories.length === 1) {
      pl = [];
    } else if (categories.length === 2) {
      pl = [
        {
          color: '#000000',
          width: 1,
          value: 0.5,
          dashStyle: 'longdashdot',
        },
      ];
    } else if (categories.length === 3) {
      gp = 1.0;
      pl = [
        {
          color: '#000000',
          width: 1,
          value: 0.5,
          dashStyle: 'longdashdot',
        },
        {
          color: '#000000',
          width: 1,
          value: 1.5,
          dashStyle: 'longdashdot',
        },
      ];
    } else if (categories.length === 4) {
      gp = 1.0;
      pl = [
        {
          color: '#000000',
          width: 1,
          value: 0.5,
          dashStyle: 'longdashdot',
        },
        {
          color: '#000000',
          width: 1,
          value: 1.5,
          dashStyle: 'longdashdot',
        },
        {
          color: '#000000',
          width: 1,
          value: 2.5,
          dashStyle: 'longdashdot',
        },
      ];
    }
    const options = {
      chart: {
        type: 'column',
        styleMode: true,
      },
      title: { text: null },
      xAxis: [
        {
          categories: this.applyDatePipe(categories),
          labels: { y: 40 },
          plotLines: pl,
        },
      ],
      yAxis: {
        stackLabels: {
          enabled: true,
          crop: false,
          verticalAlign: 'top',
          overflow: 'justify',
          allowOverlap: true,
          y: 200,
          formatter: function () {
            return this.stack;
          },
        },
        endOnTick: false,
        title: {
          enabled: true,
          text: this.valuesLabel,
          style: {
            fontWeight: 'normal',
          },
        },
      },
      legend: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal',
          cursor: 'pointer',
          pointWidth: 24,
        },
        series: {
          gapSize: 1,
          groupPadding: gp,
        },
      },
      series: [],
      credits: { enabled: false },
    };

    let temp;
    temp = [];
    this.sourceKeys
      .filter((f) => f.isSelected)
      .forEach((sourceKey, index) => {
        this.metricKeys
          .filter((f) => f.selected)
          .forEach((metricKey) => {
            temp = [];
            categories.forEach((category) => {
              if (data[category][sourceKey.value] === undefined) { return; }
              temp.push(data[category][sourceKey.value][metricKey.key]);
            });

            const that = this;
            options.series.push({
              name: this.globalizedMatricKey[metricKey.key],
              events: {
                click: function (event) {
                  that.loadChartData(
                    event.point.category,
                    this.name,
                    sourceKey.value,
                    false
                  );
                },
              },
              color: this.colors[metricKey.key.toUpperCase()] || '#ed8b00',
              type: 'column',
              stack: sourceKey.value,
              data: temp,
            });
          });
      });

    this.chart = chart(this.chartContainer.nativeElement, <any>options);
  }

  delegateCall() {
    this.loadChartData(
      this.chartCategory,
      this.chartMetric,
      this.chartSource,
      true
    );
  }

  setTotal(total) {
    this.paginationData.ItemCount = total;
    this.flag++;
  }

  closeStatsProfileErrorToaster() {
    this.showStatsProfileContentError = '';
  }

  loadChartData(
    category: string,
    metric: string,
    sources: string,
    isFromPagination: boolean
  ): any {
    this.paginationData.CurrentPage = isFromPagination
      ? this.paginationData.CurrentPage
      : 1;
    const metricKey = this.metricKeysMapping.find(
      (e) => e.displayLabel === metric
    );
    this.chartCategory = category;
    this.chartMetric = metricKey === undefined ? metric : metricKey.value;
    this.chartSource = sources;
    this.statsLoading = true;
    this.service
      .getChartData(
        this.globalizedDateMapping.hasOwnProperty(category)
          ? this.globalizedDateMapping[category]
          : category,
        this.chartMetric,
        sources,
        this.selectedPeriod.value,
        this.selectedFilter.value,
        this.paginationData.CurrentPage,
        this.paginationData.PageSize
      )
      .subscribe(
        (data) => {
          this.showprofileStatisticsDetailsTable = false;
          this.showStatsProfileContentError = '';
          this.statsLoading = false;
          this.chartData = data.data;
          this.isLoaded = true;
          this.setTotal(data.totalRecords);
          this.profileType = this.selectedFilter.value.toLowerCase();
          this.setDashboardTableColumnDefs();
        },
        (exception) => {
          this.showprofileStatisticsDetailsTable = true;
          this.statsLoading = false;
          const obj = new RDHMessageFactory(
            this._translate,
            exception.error.message
          ).getMessage();
          this.showStatsProfileContentError = obj.translate();
        }
      );
  }

  getSelectedSourceKeys() {
    this.showprofileStatisticsDetailsTable = true;
    this.renderChart(this.selectedPeriod.value);
  }

  pageChange(newPage) {
    this.paginationData.CurrentPage = newPage;
    this.loadChartData(
      this.chartCategory,
      this.chartMetric,
      this.chartSource,
      true
    );
  }

  resetPage() {
    this.paginationData.CurrentPage = 1;
  }

  filterChanged($event) {
    if ($event.length && $event[0].value !== this.selectedPeriod.value) {
      this.selectedPeriod = $event[0];
      this.resetData();
      this.loadStats();
    }
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
    this.entityTypeFlagSubscription.unsubscribe();
  }
}
