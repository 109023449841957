import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
name: 'pagging'
})
export class PaggingPipe implements PipeTransform {
    transform(vals, page, pageSize): any {
        if (vals && page && pageSize) {
            const from = page <= 1 ? 0 : (page * pageSize) - pageSize;
            const to = (page * pageSize) > vals.length ? vals.length : (page * pageSize);
            return vals.slice(from, to);
        }
        return vals;
    }
}
