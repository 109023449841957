import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-lineage-cell-renderer',
  templateUrl: './lineage-cell-renderer.component.html',
  styleUrls: ['./lineage-cell-renderer.component.less'],
})
export class LineageCellRendererComponent {
  params: any;
  data: any;
  isSource: boolean;
  isRules: boolean;
  environment: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;
    this.isSource = this.params.source_values;
    this.isRules = this.params.survivorship_values;
    this.environment = this.params.environment;
  }

}
