<div class="toasters zs-master-style zs-layer-5">
    <div *ngFor="let toaster of toasters">
        <ng-container *ngTemplateOutlet="toasterTemplate;context:{toaster:toaster}"></ng-container>
    </div>
</div>

<ng-template #toasterTemplate class="zs-display-none" let-toaster="toaster">
    <div class="zs-layout-flex zs-flex-align-center zs-shadow-blur-2 zs-padding-1-2-1-2 zs-margin-0-0-0.4-0 zs-border" 
        [ngClass]="toaster.backgroundStyle">
        <span class="zs-icon zs-size-l" [ngClass]="toaster.icon"></span>
        <p class="zs-margin-0-0-0-1 zs-width-100 toaster-message-wrap">
            <span *ngIf="toaster.title"
                class="zs-font-weight-semi-bold zs-display-block zs-margin-0-0-0.5-0 zs-body">{{toaster.title}}</span>
            <span class="zs-width-100 zs-body" [innerHTML]="toaster.description"></span>
        </p>
        <a *ngIf="toaster.showClose" class="zs-icon zs-size-xl zs-icon-close zs-interactive-secondary"
            [ngClass]="toaster.iconStyle" (click)="closeToaster(toaster)"></a>
    </div>
</ng-template>
