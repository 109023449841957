import { Component, OnDestroy, OnInit } from '@angular/core';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ActivatedRoute } from '@angular/router';
import { MDMActivityLog, MDMCommonConstants } from '../../constants/mdm-common-constants';
import { QueryParams } from '../../constants/profile-constants';
import { TranslateService } from '@ngx-translate/core';
import { mdm_labels } from '../../constants/ui-translation';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { AppConfig } from 'src/app/app.config';
import { DateTimeConstants } from 'src/app/shared/constants';
import { commmon_labels } from 'src/app/common-label';
import { url } from '../../constants/api-urls';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';

@Component({
  selector: "mdm-profile-activities",
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.less']
})
export class ActivitiesProfileComponent implements OnInit, OnDestroy {
  activitiesLoading: boolean;
  activities: any[];
  page = 1;
  pageSize = 10;
  totalPages = 0;
  _id = '';
  isHco = false;

  translated_labels: any;
  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.ACTIVITIES;
  profileViewLabel: string;
  profileMergedLabel: string;
  profileUnmergedLabel: string;
  profileNotAMatchLabel: string;
  unknownLabel: string;
  noProfileActivityLabel: string;
  environment: EnvironmentConfig;
  dateTimeConstants = DateTimeConstants;
  commmon_labels = commmon_labels;
  error_label: string;
  MDMCommonConstants = MDMCommonConstants;
  refreshActivityTabSubscr: any;
  mdm_urls = url;

  getActivityDesc(code: string) {
    switch (code.toLowerCase()) {
      case MDMActivityLog.PROFILE:
        return this.profileViewLabel;
      case MDMActivityLog.MERGE:
        return this.profileMergedLabel;
      case MDMActivityLog.UNMERGE:
        return this.profileUnmergedLabel;
      case MDMActivityLog.NOT_A_MATCH:
        return this.profileNotAMatchLabel;
      default:
        return this.unknownLabel;
    }
  }

  constructor(private service: MDMService, private route: ActivatedRoute,
    private _translate: TranslateService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService) {
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant(
      [
        this.ui_labels.PROFILE_VIEW,
        this.ui_labels.PROFILE_MERGED,
        this.ui_labels.PROFILE_UNMERGED,
        this.ui_labels.PROFILE_NOT_A_MATCH,
        this.ui_labels.UNKNOWN,
        this.ui_labels.NO_PROFILE_ACTIVITIES_YET,
        this.commmon_labels.ERROR_WITH_COLON
      ]);
    this.profileViewLabel = this.translated_labels[this.ui_labels.PROFILE_VIEW];
    this.profileMergedLabel = this.translated_labels[this.ui_labels.PROFILE_MERGED];
    this.profileUnmergedLabel = this.translated_labels[this.ui_labels.PROFILE_UNMERGED];
    this.profileNotAMatchLabel = this.translated_labels[this.ui_labels.PROFILE_NOT_A_MATCH];
    this.unknownLabel = this.translated_labels[this.ui_labels.UNKNOWN];
    this.noProfileActivityLabel = this.translated_labels[this.ui_labels.NO_PROFILE_ACTIVITIES_YET];
    this.error_label = this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
  }

  ngOnInit() {

    this.refreshActivityTabSubscr = this.service.refreshActivityTab$.subscribe(() => this.loadActivities());

    this.isHco = window.location.href.indexOf('/hco/') >= 0;
    this.route.params.subscribe(params => {
      this._id = params[QueryParams.ID];
      this.loadActivities();
    });
  }

  loadMoreActivities() {
    if (this.page < this.totalPages) {
      this.page += 1;
      this.loadActivities(this.page, true);
    }
  }

  loadActivities(page = 1, showMore=false) {
    this.page = page;
    this.activitiesLoading = true;
    this.service.getProfileActivitiesData(this._id, this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP,
      this.page, this.pageSize).subscribe(result => {
        this.activitiesLoading = false;
        this.totalPages = Math.ceil(result.totalRecords / this.pageSize);
        if (this.activities === undefined) {
          this.activities = [];
        }
        if (result && result.data && result.data.length > 0) {
          if (!showMore) {
            this.activities = [...result.data];
          } else {
            this.activities = [...this.activities, ...result.data];
          }
        }
      }, exception => {
        this.activities = [];
        this.activitiesLoading = false;
        this._toasterDataService.addMessageToToaster(exception.error.message);
      });
  }

  ngOnDestroy() {
    if (this.refreshActivityTabSubscr) {
      this.refreshActivityTabSubscr.unsubscribe();
    }
  }
}
