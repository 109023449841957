import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primaryAddress'
})
export class PrimaryAddressPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value && args){
      return value.filter(v => {
				return v.PRI_ADDR_FLG.value == args;
			});
    }

    return false;
  }

}
