import Select from '../../../assets/zsui/select.m';
// TODO: Move import to node modules after upgrade to zsui version 5.7.0+
// Importing it from assets(using zsui 5.8.0 select.m.js) to fix the issue with flippable dropdown on scroll
export default class OCSelect extends Select {
    constructor(...args) {
        // Constructor caveat https://github.com/WebReflection/document-register-element/
        const _ = super(...args);
        return _;
    }

    /**
     * Add options property 
     */
    static get properties() {
        return {
            labelKey: {
                type: String, default: 'text'
            },
            valueKey: {
                type: String, default: 'value'
            },
            options: {
                type: Array, default: [], onChange: function (oldValue, newValue) {
                    if (this.selectEl && newValue) {
                        this.updateSelect(this.selectEl, newValue);
                        this.render();
                    }
                }

            },

            selectedValues: {
                type: Array, default: [], onChange: function (oldValue, newValue) {
                    if (this.selectEl && this.options) {
                        this.updateSelect(this.selectEl, this.options);
                        this.render();
                    }
                }
            }
        }
    }

    propertyChangedCallback(name, oldValue, newValue) {
        if (name === 'multiple' && this.selectEl) {
            this.selectEl.multiple = newValue;
        } else {
            super.propertyChangedCallback(name, oldValue, newValue)
        }
    }

    // isSelected(option) {
    //     if (!this.value) { return false; }
    //     if (!this.multiple && this.value == option.value) {
    //         return true;
    //     } else if (this.multiple && Array.isArray(this.value) && this.value.indexOf(option.value) != -1) {
    //         return true;
    //     }
    //     return false;
    // }
    // templateOption(option) {
    //     return `${option.options ? `<optgroup label="${option.text}">${this.templateOptions(option.options)}</optgroup>` : `<option ${option.selected ? 'selected ' : ''} value="${option.value}">${this.sanitizeInput(option.text)}</option>`}`;
    // }

    // templateOptions(options) {
    //     if (!Array.isArray(options)) { return ''; }
    //     return options.reduce((html, option) => html + this.templateOption(option), '');
    // }

    // updateSelect(select, options) {
    //     if (this.multiple) {
    //         select.setAttribute('multiple', '');
    //     } else {
    //         select.removeAttribute('multiple');
    //     }
    //     select.innerHTML = this.templateOptions(this._labelKey, this._valueKey, options);
    // }

    updateSelect(select, options) {
        if (this.multiple) {
            select.setAttribute('multiple', '');
        } else {
            select.removeAttribute('multiple');
        }
        select.innerHTML = this.templateOptions(this._labelKey, this._valueKey, options);
    }

    templateOption(label, value, option) {
        return `${option.options ?
            `<optgroup label='${option[label]}'>${this.templateOptions(label, value, option.options)}</optgroup>` :
            `<option ${this.checkSelectedValues(option[value]) ? 'selected ' : ''} ${option.placeholder ? 'placeholder ' : ''} title='${option[label]}' value='${option[value]}'>${option[label]}</option>`}`;
    }

    checkSelectedValues(id) {
        if (!Array.isArray(this._selectedValues)) { return false }
        return this._selectedValues.includes(id);
    }

    templateOptions(label, value, options) {
        if (!Array.isArray(options)) { return ''; }
        return options.reduce((html, option) => html + this.templateOption(label, value, option), '');
    }

    renderSelect() {
        var el = this.selectEl;
        if (!el) {
            el = document.createElement('select');

            this.appendChild(el);
            this.updateSelect(el, this.options);
        }
    }

    render() {
        if (this.options) {
            this.renderSelect();
        }
        super.render();
    }

    get selectEl() {
        this._selectEl = this._selectEl || document.createElement('select');
        if (!this._selectEl.parentElement) {
            this._selectEl.style.display = 'none';
            this.root.appendChild(this._selectEl);
        }
        return this._selectEl;
    }

    disconnectedCallback() {
        // this.cleanUp();
        var parent = this.parentElement;
        while (parent && parent != document.body) {
            parent = parent.parentElement;
        }
        if (!parent) {
            window.requestAnimationFrame(() => {
                this.cleanUp();
            })
        }
    }
}

OCSelect.is = "oc-select";
