import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-record-statistics-table-renderer',
  templateUrl: './record-statistics-table-renderer.component.html',
  styleUrls: ['./record-statistics-table-renderer.component.less']
})
export class RecordStatisticsTableRendererComponent {

  constructor() { }

  mdmId: number;
  mdmName: string;
  mdmSources: any;
  params;
  isMdmIdColumn: boolean;
  isNameColumn: boolean;
  isSourcesColumn: boolean;
  profileType: string;
  environment: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.mdmId = params.data.MDM_ID;
    this.mdmName = params.data.NAME;
    this.mdmSources = params.data.SOURCES;
    this.isMdmIdColumn = this.params.isMdmIdColumn;
    this.isNameColumn = this.params.isNameColumn;
    this.isSourcesColumn = this.params.isSourcesColumn;
    this.profileType = this.params.profileType;
    this.environment = this.params.environment;
  }
}
