import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivitiesCellRendererComponent } from './activities/activities-cell-renderer/activities-cell-renderer.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivitiesProfileComponent } from './profile/activities/activities.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { AddressComponent } from './profile/details/details-table/address.component';
import { AffiliationDetailsComponent } from './profile/affiliation-details/affiliation-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { CommunicationComponent } from './profile/details/details-table/communication.component';
import { CompareComponent } from './compare/compare.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailsComponent } from './profile/details/details.component';
import { DetailsTableComponent } from './profile/details/details-table/details-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GetActivityImagePipe } from './pipe/get-activity-image.pipe';
import { GoldenValueAddressCellRendererComponent } from './profile/details/golden-value-address-cell-renderer/golden-value-address-cell-renderer.component';
import { IdentifierComponent } from './profile/details/details-table/identifier.component';
import { LineageCellRendererComponent } from './profile/details/lineage-cell-renderer/lineage-cell-renderer.component';
import { MDMRoutingModule } from './mdm-routing.module';
import { MatchesCellRendererComponent } from './dashboard/matches/matches-cell-renderer/matches-cell-renderer.component';
import { MatchesComponent } from './dashboard/matches/matches.component';
import { MatchesProfileComponent } from './profile/matches/matches.component';
import { MdmConfigDetailsComponent } from './mdm-config-details/mdm-config-details.component';
import { MdmWrapperComponent } from './mdm-wrapper/mdm-wrapper.component';
import { MergeUnmergeComponent } from './profile/merge-unmerge/merge-unmerge.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaggingPipe } from './dashboard/matches/mdm-specific-pipes/pagging.pipe';
import { PrimaryAddressPipe } from './profile/details/details-table/primary-address.pipe';
import { ProfileComponent } from './profile/profile.component';
import { RecordStatisticsTableRendererComponent } from './dashboard/stats/record-statistics-table-renderer/record-statistics-table-renderer.component';
import { RevoCoreModule } from '../revo-core/revo-core.module';
import { SearchComponent } from './search/search.component';
import { SharedModule } from '../shared/shared.module';
import { SliceDataPipe } from './search/slice-data.pipe';
import { StatsComponent } from './dashboard/stats/stats.component';
import { TranslateModule } from '@ngx-translate/core';
import { TreeComponent } from './profile/tree/tree.component';
import { TruncatePipe } from './search/truncate.pipe';
import { DcrManagementComponent } from './dcr-management/dcr-management.component';
import { DcrDetailsComponent } from './dcr-management/dcr-details/dcr-details.component';
import { DcrCellRendererComponent } from './dcr-management/dcr-cell-renderer/dcr-cell-renderer.component';
import { PayerCellRendererComponent } from './payer/payer-cell-renderer/payer-cell-renderer.component';
import { PayerComponent } from './payer/payer/payer.component';
import { PayerWrapperComponent } from './payer/payer-wrapper/payer-wrapper.component';
import { PlanComponent } from './payer/plan/plan.component';
import { PbmComponent } from './payer/pbm/pbm.component';
import { McoComponent } from './payer/mco/mco.component';
import { PayerRecordsComponent } from './payer/records/payer-records/payer-records.component';
import { PayerRecordWrapperComponent } from './payer/records/payer-record-wrapper/payer-record-wrapper.component';
import { PlanRecordsComponent } from './payer/records/plan-records/plan-records.component';
import { PbmRecordsComponent } from './payer/records/pbm-records/pbm-records.component';
import { McoRecordsComponent } from './payer/records/mco-records/mco-records.component';
import { PbmCellRendererComponent } from './payer/pbm-cell-renderer/pbm-cell-renderer.component';
import { PlanCellRendererComponent } from './payer/plan-cell-renderer/plan-cell-renderer.component';
import { McoCellRendererComponent } from './payer/mco-cell-renderer/mco-cell-renderer.component';
import { PayerProfileComponent } from './payer/profile/payer-profile/payer-profile.component';
import { PayerProfileDetailsComponent } from './payer/profile/payer-profile-details/payer-profile-details.component';
import { PayerMergeUnmergeComponent } from './payer/profile/payer-merge-unmerge/payer-merge-unmerge.component';
import { PayerMatchesComponent } from './payer/profile/payer-matches/payer-matches.component';
import { PayerMergeUnmergeDetailsComponent } from './payer/profile/payer-merge-unmerge-details/payer-merge-unmerge-details.component';

@NgModule({
  imports: [
    CommonModule,
    MDMRoutingModule,
    FormsModule,
    ReactiveFormsModule, NgSelectModule,
    TranslateModule,
    RevoCoreModule,
    SharedModule,
    DragDropModule,
    AgGridModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    MdmWrapperComponent,
    DashboardComponent,
    ProfileComponent,
    CompareComponent,
    DashboardComponent,
    CompareComponent,
    MatchesComponent,
    StatsComponent,
    PaggingPipe,
    ActivitiesComponent,
    TruncatePipe,
    SearchComponent,
    DetailsComponent,
    DetailsTableComponent,
    PrimaryAddressPipe,
    IdentifierComponent,
    CommunicationComponent,
    AddressComponent,
    MatchesProfileComponent,
    ActivitiesProfileComponent,
    SliceDataPipe,
    TreeComponent,
    AffiliationDetailsComponent,
    MergeUnmergeComponent,
    RecordStatisticsTableRendererComponent,
    MatchesCellRendererComponent,
    GetActivityImagePipe,
    LineageCellRendererComponent,
    ActivityLogComponent,
    ActivitiesCellRendererComponent,
    GoldenValueAddressCellRendererComponent,
    MdmConfigDetailsComponent,
    DcrManagementComponent,
    DcrDetailsComponent,
    DcrCellRendererComponent,
    PayerCellRendererComponent,
    PayerComponent,
    PayerWrapperComponent,
    PlanComponent,
    PbmComponent,
    McoComponent,
    PayerRecordsComponent,
    PayerRecordWrapperComponent,
    PlanRecordsComponent,
    PbmRecordsComponent,
    McoRecordsComponent,
    PbmCellRendererComponent,
    PlanCellRendererComponent,
    McoCellRendererComponent,
    PayerProfileComponent,
    PayerProfileDetailsComponent,
    PayerMergeUnmergeComponent,
    PayerMatchesComponent,
    PayerMergeUnmergeDetailsComponent
  ],
})
export class MDMModule { }
