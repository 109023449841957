export const ingest_extract_labels = {
  PROCESS_LIST: {
    INGESTION: 'INGEST_EXTRACT.PROCESS_LIST.INGESTION',
    ALL_INGESTIONS: 'INGEST_EXTRACT.PROCESS_LIST.ALL_INGESTIONS',
    LOG: 'INGEST_EXTRACT.PROCESS_LIST.LOG',
    TABLE_NAME: 'INGEST_EXTRACT.PROCESS_LIST.TABLE_NAME',
    INGEST_EXTRACT_NAME: 'INGEST_EXTRACT.PROCESS_LIST.INGEST_EXTRACT_NAME',
    STATUS: 'INGEST_EXTRACT.PROCESS_LIST.STATUS',
    LAST_EXECUTION: 'INGEST_EXTRACT.PROCESS_LIST.LAST_EXECUTION',
    DURATION: 'INGEST_EXTRACT.PROCESS_LIST.DURATION',
    NEXT_EXECUTION: 'INGEST_EXTRACT.PROCESS_LIST.NEXT_EXECUTION',
    CREATED_BY: 'INGEST_EXTRACT.PROCESS_LIST.CREATED_BY',
    EXECUTED_BY: 'INGEST_EXTRACT.PROCESS_LIST.EXECUTED_BY',
    START_TIME: 'INGEST_EXTRACT.PROCESS_LIST.START_TIME',
    INGESTION_NAME: 'INGEST_EXTRACT.PROCESS_LIST.INGESTION_NAME',
    RECURRENCE: 'INGEST_EXTRACT.PROCESS_LIST.RECURRENCE',
    ACTIONS: 'INGEST_EXTRACT.PROCESS_LIST.ACTIONS',
    ADD_INGEST_EXTRACT: 'INGEST_EXTRACT.PROCESS_LIST.ADD_INGEST_EXTRACT',
    DRAFT_IN_PARANTHESIS: 'INGEST_EXTRACT.PROCESS_LIST.DRAFT_IN_PARANTHESIS',
    AWS_EMR: 'INGEST_EXTRACT.PROCESS_LIST.AWS_EMR',
    AWS_REDSHIFT: 'INGEST_EXTRACT.PROCESS_LIST.AWS_REDSHIFT',
    VIEW_ERRORS: 'INGEST_EXTRACT.PROCESS_LIST.VIEW_ERRORS',
    BY_USERNAME: 'INGEST_EXTRACT.PROCESS_LIST.BY_USERNAME',
    NO_RECENT_PROCESSES_AVAILABLE:
      'INGEST_EXTRACT.PROCESS_LIST.NO_RECENT_PROCESSES_AVAILABLE',
    DELETE_CONFIRMATION: 'INGEST_EXTRACT.PROCESS_LIST.DELETE_CONFIRMATION',
    NO_ERRORS_FOUND: 'INGEST_EXTRACT.PROCESS_LIST.NO_ERRORS_FOUND',
    ERROR_FOR_PROCESS: 'INGEST_EXTRACT.PROCESS_LIST.ERROR_FOR_PROCESS',
    FAILED_TO_DOWNLOAD_FILE:
      'INGEST_EXTRACT.PROCESS_LIST.FAILED_TO_DOWNLOAD_FILE',
    PROCESS_DELETED: 'INGEST_EXTRACT.PROCESS_LIST.PROCESS_DELETED',
    DELETE_PROCESS: 'INGEST_EXTRACT.PROCESS_LIST.DELETE_PROCESS',
    PROCESS: 'INGEST_EXTRACT.PROCESS_LIST.PROCESS',
    CUSTOM_SQL: 'INGEST_EXTRACT.PROCESS_LIST.CUSTOM_SQL',
    NO_PROCESS_LOGS_AVAILABLE:
      'INGEST_EXTRACT.PROCESS_LIST.NO_PROCESS_LOGS_AVAILABLE',
    ALL_ADAPTORS: 'INGEST_EXTRACT.PROCESS_LIST.ALL_ADAPTORS',
    NAME: 'INGEST_EXTRACT.PROCESS_LIST.NAME',
    DATA_CENTER: 'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER',
    ADD_DATA_SOURCE: 'INGEST_EXTRACT.PROCESS_LIST.ADD_DATA_SOURCE',
    CREATE_EXTRACTION: 'INGEST_EXTRACT.PROCESS_LIST.CREATE_EXTRACTION',
    DATA_QUERY: 'INGEST_EXTRACT.PROCESS_LIST.DATA_QUERY',
    LOGS: 'INGEST_EXTRACT.PROCESS_LIST.LOGS',
    DATA_QUERIES: 'INGEST_EXTRACT.PROCESS_LIST.DATA_QUERIES',
    ADD_DATA_QUERY: 'INGEST_EXTRACT.PROCESS_LIST.ADD_DATA_QUERY',
    ALL_DATA_QUERY: 'INGEST_EXTRACT.PROCESS_LIST.ALL_DATA_QUERY',
    EDIT_DATA_QUERY: 'INGEST_EXTRACT.PROCESS_LIST.EDIT_DATA_QUERY',
    NO_RECENT_FLOWS_AVAILABLE:
      'INGEST_EXTRACT.PROCESS_LIST.NO_RECENT_FLOWS_AVAILABLE',
    RUN_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.RUN_TOOLTIP',
    STOP_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.STOP_TOOLTIP',
    KILLING_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.KILLING_TOOLTIP',
    EDIT_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.EDIT_TOOLTIP',
    CLONE_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.CLONE_TOOLTIP',
    DOWNLOAD_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.DOWNLOAD_TOOLTIP',
    DELETE_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.DELETE_TOOLTIP',
    DELETE_TOOLTIP_MESSAGE: 'INGEST_EXTRACT.PROCESS_LIST.DELETE_TOOLTIP_MESSAGE',
    EDIT_TOOLTIP_MESSAGE: 'INGEST_EXTRACT.PROCESS_LIST.EDIT_TOOLTIP_MESSAGE',
    EDIT_CONFIGURATION: 'INGEST_EXTRACT.PROCESS_LIST.EDIT_CONFIGURATION',
    UPLOADED_FILE: 'INGEST_EXTRACT.PROCESS_LIST.UPLOADED_FILE',
    PARAMETER_CONFIGURATION: 'INGEST_EXTRACT.PROCESS_LIST.PARAMETER_CONFIGURATION',
    FLOW: 'INGEST_EXTRACT.PROCESS_LIST.FLOW',
    CYCLE: 'INGEST_EXTRACT.PROCESS_LIST.CYCLE',
    EXECUTION_TYPE: 'INGEST_EXTRACT.PROCESS_LIST.EXECUTION_TYPE',
    START_OVER: 'INGEST_EXTRACT.PROCESS_LIST.START_OVER',
    DATA_QUERY_DELETED: 'INGEST_EXTRACT.PROCESS_LIST.DATA_QUERY_DELETED',
    DATA_CENTER_DELETED: 'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER_DELETED',
    DATA_CENTER_SAVED_SUCCESSFULLY: 'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER_SAVED_SUCCESSFULLY',
    DATA_CENTER_CREATED_SUCCESSFULLY:'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER_CREATED_SUCCESSFULLY',
    DATA_CENTER_RETRIGGERED_SUCCESSFULLY: 'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER_RETRIGGERED_SUCCESSFULLY',
    DATA_CENTER_TRIGGERED_SUCCESSFULLY:'INGEST_EXTRACT.PROCESS_LIST.DATA_CENTER_TRIGGERED_SUCCESSFULLY',
    RUNNING_TOOLTIP:'INGEST_EXTRACT.PROCESS_LIST.RUNNING_TOOLTIP',
    VERIFY_EXECUTION_DETAILS:'INGEST_EXTRACT.PROCESS_LIST.VERIFY_EXECUTION_DETAILS',
    RETRYING_TOOLTIP: 'INGEST_EXTRACT.PROCESS_LIST.RETRYING_TOOLTIP',
    DATA_QUERY_RETRIGGERED_SUCCESSFULLY: 'INGEST_EXTRACT.PROCESS_LIST.DATA_QUERY_RETRIGGERED_SUCCESSFULLY',
    PROCESS_COULD_NOT_BE_TRIGGERED: 'INGEST_EXTRACT.PROCESS_LIST.PROCESS_COULD_NOT_BE_TRIGGERED'

  },
  PROCESS_RUN: {
    START_TIME: 'INGEST_EXTRACT.PROCESS_RUN.START_TIME',
    STATUS: 'INGEST_EXTRACT.PROCESS_RUN.STATUS',
    DURATION: 'INGEST_EXTRACT.PROCESS_RUN.DURATION',
    VIEW_ERRORS: 'INGEST_EXTRACT.PROCESS_RUN.VIEW_ERRORS',
    NO_PROCESS_RUNS_AVAILABLE:
      'INGEST_EXTRACT.PROCESS_RUN.NO_PROCESS_RUNS_AVAILABLE',
    NO_ERRORS_FOUND: 'INGEST_EXTRACT.PROCESS_RUN.NO_ERRORS_FOUND',
    ERROR: 'INGEST_EXTRACT.PROCESS_RUN.ERROR',
    TYPE: 'INGEST_EXTRACT.PROCESS_RUN.TYPE',
    DOMAIN: 'INGEST_EXTRACT.PROCESS_RUN.DOMAIN',
    ANALYSIS: 'INGEST_EXTRACT.PROCESS_RUN.ANALYSIS',
    VIEW: 'INGEST_EXTRACT.PROCESS_RUN.VIEW',
    PM_PROCESSES: 'INGEST_EXTRACT.PROCESS_RUN.PM_PROCESSES',
    ALL: 'INGEST_EXTRACT.PROCESS_RUN.ALL',
    PAST_24_HOURS: 'INGEST_EXTRACT.PROCESS_RUN.PAST_24_HOURS',
    PAST_7_DAYS: 'INGEST_EXTRACT.PROCESS_RUN.PAST_7_DAYS',
    PAST_30_DAYS: 'INGEST_EXTRACT.PROCESS_RUN.PAST_30_DAYS',
    FLOW_NAME: 'INGEST_EXTRACT.PROCESS_RUN.FLOW_NAME',
    USER: 'INGEST_EXTRACT.PROCESS_RUN.USER',
    PAST_RUNS: 'INGEST_EXTRACT.PROCESS_RUN.PAST_RUNS',
    TRUNCATED_DATA: 'INGEST_EXTRACT.PROCESS_RUN.TRUNCATED_DATA',
    NO_PAST_RUNS_AVAILABLE_FOR_PROCESS_NAME:
      'INGEST_EXTRACT.PROCESS_RUN.NO_PAST_RUNS_AVAILABLE_FOR_PROCESS_NAME',
    FLOW_COULD_NOT_BE_TRIGGERED: 'INGEST_EXTRACT.PROCESS_RUN.FLOW_COULD_NOT_BE_TRIGGERED',
    RETRYING_FLOW_SUBMISSION: 'INGEST_EXTRACT.PROCESS_RUN.RETRYING_FLOW_SUBMISSION'
  },
  INGESTION_TYPES: {
    ALL: 'INGEST_EXTRACT.INGESTION_TYPES.ALL',
    ALL_STATUS: 'INGEST_EXTRACT.INGESTION_TYPES.ALL_STATUS',
    INGESTION: 'INGEST_EXTRACT.INGESTION_TYPES.INGESTION',
    SMART_INGESTION: 'INGEST_EXTRACT.INGESTION_TYPES.SMART_INGESTION',
    SMART_ANALYSIS: 'INGEST_EXTRACT.INGESTION_TYPES.SMART_ANALYSIS',
  },
  TABLE_FILTER: {
    SHOW: 'INGEST_EXTRACT.TABLE_FILTER.SHOW',
    CREATED: 'INGEST_EXTRACT.TABLE_FILTER.CREATED',
    CLEAR_FILTERS: 'INGEST_EXTRACT.TABLE_FILTER.CLEAR_FILTERS',
  },
  INGEST_EXTRACT_WRAPPER: {
    NEW_INGESTION: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.NEW_INGESTION',
    EDIT_INGESTION: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.EDIT_INGESTION',
    NEW_EXTRACTION: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.NEW_EXTRACTION',
    EDIT_EXTRACTION: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.EDIT_EXTRACTION',
    EDIT_PROCESS_NAME:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.EDIT_PROCESS_NAME',
    PAST_RUNS: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.PAST_RUNS',
    STEP_1: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.STEP_1',
    STEP_2: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.STEP_2',
    STEP_3: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.STEP_3',
    STEP_4: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.STEP_4',
    DEFINE_DATA_SOURCE:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.DEFINE_DATA_SOURCE',
    TRANSACTIONAL_INFO:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.TRANSACTIONAL_INFO',
    DEFINE_SCHEMA: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.DEFINE_SCHEMA',
    INGESTION_LOCATION:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.INGESTION_LOCATION',
    INGESTION_SAVED_SUCCESSFULLY:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.INGESTION_SAVED_SUCCESSFULLY',
    SAVE_INGESTION_UNSUCCESSFUL:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.SAVE_INGESTION_UNSUCCESSFUL',
    EXTRACTION_LOCATION:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.EXTRACTION_LOCATION',
    EXTRACTION_SAVED_SUCCESSFULLY:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.EXTRACTION_SAVED_SUCCESSFULLY',
    SAVE_EXTRACTION_UNSUCCESSFUL:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.SAVE_EXTRACTION_UNSUCCESSFUL',
    CREATE_NEW_INGESTION:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.CREATE_NEW_INGESTION',
    DATA_CENTER: 'APP.DATA_CENTER',
    DATA_QUERY: 'APP.DATA_QUERY',
    DATA_QUERIES: 'APP.DATA_QUERIES',
    STEP_NUMBER_OF: 'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.STEP_NUMBER_OF',
    SOME_ERROR_OCCURED_WHILE_GETTING_PROCESS_DETAILS:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.SOME_ERROR_OCCURED_WHILE_GETTING_PROCESS_DETAILS',
    CANNOT_SAVE_DRAFT:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.CANNOT_SAVE_DRAFT',
    DEFINE_EXTRACTION_LOCATION:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.DEFINE_EXTRACTION_LOCATION',
    SOME_ERROR_OCCURED_WHILE_GETTING_EXTRACTION_PROCESS_DETAILS:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.SOME_ERROR_OCCURED_WHILE_GETTING_EXTRACTION_PROCESS_DETAILS',
    PLEASE_SELECT_ATLEAST_ONE_COLUMN:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.PLEASE_SELECT_ATLEAST_ONE_COLUMN',
    LOCAL_FILE_UPLOAD:
      'INGEST_EXTRACT.INGEST_EXTRACT_WRAPPER.LOCAL_FILE_UPLOAD',
  },
  DATA_SOURCE: {
    LOAD_SETTINGS: 'INGEST_EXTRACT.DATA_SOURCE.LOAD_SETTINGS',
    CREATE_NEW_INGESTION_OR_LOAD_SAVED_SETTING_INGESTION:
      'INGEST_EXTRACT.DATA_SOURCE.CREATE_NEW_INGESTION_OR_LOAD_SAVED_SETTING_INGESTION',
    CREATE_NEW_INGESTION_OR_LOAD_SAVED_SETTING_EXTRACTION:
      'INGEST_EXTRACT.DATA_SOURCE.CREATE_NEW_INGESTION_OR_LOAD_SAVED_SETTING_EXTRACTION',
    LOAD_SETTING: 'INGEST_EXTRACT.DATA_SOURCE.LOAD_SETTING',
    DEFINE_DATA_SOURCE: 'INGEST_EXTRACT.DATA_SOURCE.DEFINE_DATA_SOURCE',
    DATA_SOURCE_LOCATION_MESSAGE:
      'INGEST_EXTRACT.DATA_SOURCE.DATA_SOURCE_LOCATION_MESSAGE',
    SAVE_SETTINGS: 'INGEST_EXTRACT.DATA_SOURCE.SAVE_SETTINGS',
    TEST_CONNECTION: 'INGEST_EXTRACT.DATA_SOURCE.TEST_CONNECTION',
    UPLOADED_FILE_FILENAME: 'INGEST_EXTRACT.DATA_SOURCE.UPLOADED_FILE_FILENAME',
    SETTING_NAME: 'INGEST_EXTRACT.DATA_SOURCE.SETTING_NAME',
    TEST_CONNECTION_FAILED: 'INGEST_EXTRACT.DATA_SOURCE.TEST_CONNECTION_FAILED',
    VALID_NAME_ERROR: 'INGEST_EXTRACT.DATA_SOURCE.VALID_NAME_ERROR',
    FILE_SIZE_ERROR: 'INGEST_EXTRACT.DATA_SOURCE.FILE_SIZE_ERROR',
    LOCAL_FILE_UPLOAD_ERROR:
      'INGEST_EXTRACT.DATA_SOURCE.LOCAL_FILE_UPLOAD_ERROR',
    ADAPTOR_ACCESS_DENIED: 'INGEST_EXTRACT.DATA_SOURCE.ADAPTOR_ACCESS_DENIED',
    LOAD_EXISTING: 'INGEST_EXTRACT.DATA_SOURCE.LOAD_EXISTING',
    FILE_UPLOAD: 'INGEST_EXTRACT.DATA_SOURCE.FILE_UPLOAD',
    TABLE_NAME_ALREADY_EXISTS:
      'INGEST_EXTRACT.DATA_SOURCE.TABLE_NAME_ALREADY_EXISTS',
    INGESTION_NAME_ALREADY_EXISTS:
      'INGEST_EXTRACT.DATA_SOURCE.INGESTION_NAME_ALREADY_EXISTS',
    FILE_IS_UPLOADING: 'INGEST_EXTRACT.DATA_SOURCE.FILE_IS_UPLOADING',
    CHOOSE_FILE: 'INGEST_EXTRACT.DATA_SOURCE.CHOOSE_FILE',
    PERCENTAGE_FILE_UPLOAD: 'INGEST_EXTRACT.DATA_SOURCE.PERCENTAGE_FILE_UPLOAD',
    TABLE_NAME_NOT_ALLOWED: 'INGEST_EXTRACT.DATA_SOURCE.TABLE_NAME_NOT_ALLOWED',
  },
  I_DATA: {
    RUN_SMART_DATA_ANALYSIS_CHECK:
      'INGEST_EXTRACT.I_DATA.RUN_SMART_DATA_ANALYSIS_CHECK',
    INPUT_FILE_DOMAIN: 'INGEST_EXTRACT.I_DATA.INPUT_FILE_DOMAIN',
    RUN: 'INGEST_EXTRACT.I_DATA.RUN',
    SMART_ANALYSIS_QUEUED: 'INGEST_EXTRACT.I_DATA.SMART_ANALYSIS_QUEUED',
    SMART_ANALYSIS_RUNNING: 'INGEST_EXTRACT.I_DATA.SMART_ANALYSIS_RUNNING',
    SMART_ANALYSIS_SUCCESSFUL:
      'INGEST_EXTRACT.I_DATA.SMART_ANALYSIS_SUCCESSFUL',
    SMART_ANALYSIS_FAILED: 'INGEST_EXTRACT.I_DATA.SMART_ANALYSIS_FAILED',
    SMART_ANALYSIS_PROGRESS: 'INGEST_EXTRACT.I_DATA.SMART_ANALYSIS_PROGRESS',
    SELECT_DOMAIN: 'INGEST_EXTRACT.I_DATA.SELECT_DOMAIN',
  },
  DATA_VISUALIZATION: {
    CHART_TYPE: 'INGEST_EXTRACT.DATA_VISUALIZATION.CHART_TYPE',
    TRENDANALYSIS: 'INGEST_EXTRACT.DATA_VISUALIZATION.TRENDANALYSIS',
    CARDINALITY: 'INGEST_EXTRACT.DATA_VISUALIZATION.CARDINALITY',
    CORRELATION_MATRIX: 'INGEST_EXTRACT.DATA_VISUALIZATION.CORRELATION_MATRIX',
    CONTROL_SUMMARY: 'INGEST_EXTRACT.DATA_VISUALIZATION.CONTROL_SUMMARY',
    SELECT_COLUMNS: 'INGEST_EXTRACT.DATA_VISUALIZATION.SELECT_COLUMNS',
    ADD_ATTRIBUTES: 'INGEST_EXTRACT.DATA_VISUALIZATION.ADD_ATTRIBUTES',
    GENERATE_CHARTS: 'INGEST_EXTRACT.DATA_VISUALIZATION.GENERATE_CHARTS',
    TIME_SERIES_ANALYSIS:
      'INGEST_EXTRACT.DATA_VISUALIZATION.TIME_SERIES_ANALYSIS',
    COLUMNS_LIMIT_ERROR:
      'INGEST_EXTRACT.DATA_VISUALIZATION.COLUMNS_LIMIT_ERROR',
    CONTROL_COLUMNS_LIMIT_ERROR:
      'INGEST_EXTRACT.DATA_VISUALIZATION.CONTROL_COLUMNS_LIMIT_ERROR',
    SELECT_CHART: 'INGEST_EXTRACT.DATA_VISUALIZATION.SELECT_CHART',
    SELECT_COLUMN: 'INGEST_EXTRACT.DATA_VISUALIZATION.SELECT_COLUMN',
    SELECT_ATTRIBUTES: 'INGEST_EXTRACT.DATA_VISUALIZATION.SELECT_ATTRIBUTES',
    CHARTS: 'INGEST_EXTRACT.DATA_VISUALIZATION.CHARTS',
    DATA_NOT_AVAILABLE: 'INGEST_EXTRACT.DATA_VISUALIZATION.DATA_NOT_AVAILABLE',
  },
  TRANSACTIONAL_INFO: {
    FORMAT: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FORMAT',
    FILE_TYPE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FILE_TYPE',
    DELIMITER: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DELIMITER',
    OPTIONAL_IN_PARANTHESIS:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.OPTIONAL_IN_PARANTHESIS',
    FILE_LOAD_TYPE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FILE_LOAD_TYPE',
    SOURCE_FILE_LOCATION:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.SOURCE_FILE_LOCATION',
    DATA_SOURCE_NAME: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DATA_SOURCE_NAME',
    DESCRIPTION: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DESCRIPTION',
    QUOTE_CHARACTER: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.QUOTE_CHARACTER',
    ESCAPE_CHARACTER: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.ESCAPE_CHARACTER',
    SKIP_HEADER: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SKIP_HEADER',
    GET_PREVIEW: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.GET_PREVIEW',
    TABLE_PREVIEW: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TABLE_PREVIEW',
    RDBMS: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.RDBMS',
    TABLE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TABLE',
    SHEET_NAME: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SHEET_NAME',
    NULL_CHAR: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.NULL_CHAR',
    SKIP_HEADER_TOOLTIP:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.SKIP_HEADER_TOOLTIP',
    UNABLE_TO_LOAD_DATA:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.UNABLE_TO_LOAD_DATA',
    NO_PREVIEW_AVAILABLE:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.NO_PREVIEW_AVAILABLE',
    PREVIEW_ERROR: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.PREVIEW_ERROR',
    GET_DATA_SUMMARY: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.GET_DATA_SUMMARY',
    DATAVISUALIZATION: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DATAVISUALIZATION',
    SELECT_LOAD_TYPE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SELECT_LOAD_TYPE',
    FULL: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FULL',
    INCREMENTAL_UPDATE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.INCREMENTAL_UPDATE',
    SELECT_FILE_TYPE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SELECT_FILE_TYPE',
    FIXED_WIDTH: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FIXED_WIDTH',
    SELECT_FILE_FORMAT: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SELECT_FILE_FORMAT',
    TEXT_FILE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TEXT_FILE',
    EXCEL: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.EXCEL',
    PARQUET: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.PARQUET',
    CSV: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.CSV',
    TABLES: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TABLES',
    DATA_SUMMARY: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DATA_SUMMARY',
    TABLE_ACCESS_DENIED:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.TABLE_ACCESS_DENIED',
    SELECT_TABLE: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.SELECT_TABLE',
    COLUMNS: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.COLUMNS',
    ROWS: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.ROWS',
    NUMERICAL_COLUMNS: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.NUMERICAL_COLUMNS',
    CATEGORICAL_COLUMNS:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.CATEGORICAL_COLUMNS',
    TIMESTAMP_COLUMNS: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TIMESTAMP_COLUMNS',
    DATA_PREVIEW: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.DATA_PREVIEW',
    FAILED_TO_LOAD_DATA_SUMMARY:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.FAILED_TO_LOAD_DATA_SUMMARY',
    VIEW_MORE_DATA: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.VIEW_MORE_DATA',
    VIEW_LESS_DATA: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.VIEW_LESS_DATA',
    PLEASE_SELECT_FILE_FORMAT:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.PLEASE_SELECT_FILE_FORMAT',
    PLEASE_SELECT_INPUT_DOMAIN_ID:
      'INGEST_EXTRACT.TRANSACTIONAL_INFO.PLEASE_SELECT_INPUT_DOMAIN_ID',
    FILE_FORMAT: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.FILE_FORMAT',
    TRANSACTIONAL_INFO: 'INGEST_EXTRACT.TRANSACTIONAL_INFO.TRANSACTIONAL_INFO',
  },
  DEFINE_SCHEMA: {
    DEFINE_SCHEMA: 'INGEST_EXTRACT.DEFINE_SCHEMA.DEFINE_SCHEMA',
    RESERVED_COLUMN_NAME_MESSAGE:
      'INGEST_EXTRACT.DEFINE_SCHEMA.RESERVED_COLUMN_NAME_MESSAGE',
    PRIMARY_KEY: 'INGEST_EXTRACT.DEFINE_SCHEMA.PRIMARY_KEY',
    DATA_PASS_CHECK_LABEL: 'INGEST_EXTRACT.DEFINE_SCHEMA.DATA_PASS_CHECK_LABEL',
    SELECT: 'INGEST_EXTRACT.DEFINE_SCHEMA.SELECT',
    COLUMN_NAME: 'INGEST_EXTRACT.DEFINE_SCHEMA.COLUMN_NAME',
    ALIAS: 'INGEST_EXTRACT.DEFINE_SCHEMA.ALIAS',
    TYPE: 'INGEST_EXTRACT.DEFINE_SCHEMA.TYPE',
    SOURCE_FORMAT: 'INGEST_EXTRACT.DEFINE_SCHEMA.SOURCE_FORMAT',
    INCREMENTAL_JOIN_KEY: 'INGEST_EXTRACT.DEFINE_SCHEMA.INCREMENTAL_JOIN_KEY',
    COLUMN_WIDTH: 'INGEST_EXTRACT.DEFINE_SCHEMA.COLUMN_WIDTH',
    QUALITY_CHECKS: 'INGEST_EXTRACT.DEFINE_SCHEMA.QUALITY_CHECKS',
    ADD_COLUMN: 'INGEST_EXTRACT.DEFINE_SCHEMA.ADD_COLUMN',
    VALID_SOURCE_FORMAT_MESSAGE:
      'INGEST_EXTRACT.DEFINE_SCHEMA.VALID_SOURCE_FORMAT_MESSAGE',
    ENTER_ALPHA_NUMERIC_MESSAGE:
      'INGEST_EXTRACT.DEFINE_SCHEMA.ENTER_ALPHA_NUMERIC_MESSAGE',
    DUPLICATE_FIELD: 'INGEST_EXTRACT.DEFINE_SCHEMA.DUPLICATE_FIELD',
    ADD_QUALITY_CHECKS_COLUMN_NAME:
      'INGEST_EXTRACT.DEFINE_SCHEMA.ADD_QUALITY_CHECKS_COLUMN_NAME',
    EDIT_QUALITY_CHECKS_COLUMN_NAME:
      'INGEST_EXTRACT.DEFINE_SCHEMA.EDIT_QUALITY_CHECKS_COLUMN_NAME',
    SEQUENCE: 'INGEST_EXTRACT.DEFINE_SCHEMA.SEQUENCE',
    CONSTRAINTS: 'INGEST_EXTRACT.DEFINE_SCHEMA.CONSTRAINTS',
    DATA_DOES_NOT_PASS: 'INGEST_EXTRACT.DEFINE_SCHEMA.DATA_DOES_NOT_PASS',
    ADD_CHECK: 'INGEST_EXTRACT.DEFINE_SCHEMA.ADD_CHECK',
    TABLE: 'INGEST_EXTRACT.DEFINE_SCHEMA.TABLE',
    IPROFILER_METRICS: 'INGEST_EXTRACT.DEFINE_SCHEMA.IPROFILER_METRICS',
    PREDICTED_COLUMN_NAME: 'INGEST_EXTRACT.DEFINE_SCHEMA.PREDICTED_COLUMN_NAME',
    REFRESH_SCHEMA: 'INGEST_EXTRACT.DEFINE_SCHEMA.REFRESH_SCHEMA',
    SELECT_LABEL: 'INGEST_EXTRACT.DEFINE_SCHEMA.SELECT_LABEL',
    SELECT_VALUE_LABEL: 'INGEST_EXTRACT.DEFINE_SCHEMA.SELECT_VALUE_LABEL',
    CONTINUE_WITH_ERROR_DISPLAY_LABEL:
      'INGEST_EXTRACT.DEFINE_SCHEMA.CONTINUE_WITH_ERROR_DISPLAY_LABEL',
    REMOVE_AND_CONTINUE_DISPLAY_LABEL:
      'INGEST_EXTRACT.DEFINE_SCHEMA.REMOVE_AND_CONTINUE_DISPLAY_LABEL',
    DEFINE_SCHEMA_HELPER_MESSAGE:
      'INGEST_EXTRACT.DEFINE_SCHEMA.DEFINE_SCHEMA_HELPER_MESSAGE',
    SCHEMA_AND_DQMS: 'INGEST_EXTRACT.DEFINE_SCHEMA.SCHEMA_AND_DQMS',
    IPROFILER_DATA_VISUALIZATION:
      'INGEST_EXTRACT.DEFINE_SCHEMA.IPROFILER_DATA_VISUALIZATION',
    IPROFILER_CUSTOMIZED_COLUMN:
      'INGEST_EXTRACT.DEFINE_SCHEMA.IPROFILER_CUSTOMIZED_COLUMN',
    SOME_ERROR_OCCURED_WHILE_REFRESHING_SCHEMA:
      'INGEST_EXTRACT.DEFINE_SCHEMA.SOME_ERROR_OCCURED_WHILE_REFRESHING_SCHEMA',
    SOME_ERROR_OCCURED_WHILE_GETTING_IPROFILER_DETAILS:
      'INGEST_EXTRACT.DEFINE_SCHEMA.SOME_ERROR_OCCURED_WHILE_GETTING_IPROFILER_DETAILS',
    INCLUDE_AUDIT_COLUMNS: 'INGEST_EXTRACT.DEFINE_SCHEMA.INCLUDE_AUDIT_COLUMNS',
    AUDIT_COLUMNS_TOOLTIP_TEXT:
      'INGEST_EXTRACT.DEFINE_SCHEMA.AUDIT_COLUMNS_TOOLTIP_TEXT',
  },
  LOCATION: {
    INGESTION_LOCATION: 'INGEST_EXTRACT.LOCATION.INGESTION_LOCATION',
    INGESTION_NAME: 'INGEST_EXTRACT.LOCATION.INGESTION_NAME',
    CLUSTER_TYPE: 'INGEST_EXTRACT.LOCATION.CLUSTER_TYPE',
    DATABASE: 'INGEST_EXTRACT.LOCATION.DATABASE',
    TABLE_NAME: 'INGEST_EXTRACT.LOCATION.TABLE_NAME',
    SCHEDULE_RECURRING_INGESTION_MESSAGE:
      'INGEST_EXTRACT.LOCATION.SCHEDULE_RECURRING_INGESTION_MESSAGE',
    ADAPTOR: 'INGEST_EXTRACT.LOCATION.ADAPTOR',
    SCHEMA: 'INGEST_EXTRACT.LOCATION.SCHEMA',
    SELECT_DQM: 'INGEST_EXTRACT.LOCATION.SELECT_DQM',
    SELECT_FLOW: 'INGEST_EXTRACT.LOCATION.SELECT_FLOW',
    SELECT_FLOW_LABEL: 'INGEST_EXTRACT.LOCATION.SELECT_FLOW_LABEL',
    ADD_FLOW_LABEL: 'INGEST_EXTRACT.LOCATION.ADD_FLOW_LABEL',
    REMOVE_PROCESS_LABEL: 'INGEST_EXTRACT.LOCATION.REMOVE_PROCESS_LABEL',
    PROCESS_ADDED_LABEL: 'INGEST_EXTRACT.LOCATION.PROCESS_ADDED_LABEL',
    NOTE_LABEL: 'INGEST_EXTRACT.LOCATION.NOTE_LABEL',
    MAXIMUM_LABEL: 'INGEST_EXTRACT.LOCATION.MAXIMUM_LABEL',
    PROCESSES_CAN_BE_ADDED_LABEL:
      'INGEST_EXTRACT.LOCATION.PROCESSES_CAN_BE_ADDED_LABEL',
    MAX_ALLOWED_LENGTH_OF_PM_PROCESSES_EXCEEDED_LABEL:
      'INGEST_EXTRACT.LOCATION.MAX_ALLOWED_LENGTH_OF_PM_PROCESSES_EXCEEDED_LABEL',
    DUPLICATE_PM_PROCESS_SELECTED_ERROR:
      'INGEST_EXTRACT.LOCATION.DUPLICATE_PM_PROCESS_SELECTED_ERROR',
    RUN_FLOW: 'INGEST_EXTRACT.LOCATION.RUN_FLOW',
    CLUSTER_ACCESS_DENIED: 'INGEST_EXTRACT.LOCATION.CLUSTER_ACCESS_DENIED',
    SCHEMA_ACCESS_DENIED: 'INGEST_EXTRACT.LOCATION.SCHEMA_ACCESS_DENIED',
    DUPLICATE_FLOW_SELECTED_ERROR: 'INGEST_EXTRACT.LOCATION.DUPLICATE_FLOW_SELECTED_ERROR',
    NO_FLOW_LINKED: 'INGEST_EXTRACT.LOCATION.NO_FLOW_LINKED'
  },
  RECURRENCE: {
    TOP_MESSAGE_INGESTION: 'INGEST_EXTRACT.RECURRENCE.TOP_MESSAGE_INGESTION',
    TOP_MESSAGE_EXTRACTION: 'INGEST_EXTRACT.RECURRENCE.TOP_MESSAGE_EXTRACTION',
    START_DATE: 'INGEST_EXTRACT.RECURRENCE.START_DATE',
    RECURRENCE_PATTERN: 'INGEST_EXTRACT.RECURRENCE.RECURRENCE_PATTERN',
    DAILY: 'INGEST_EXTRACT.RECURRENCE.DAILY',
    WEEKLY: 'INGEST_EXTRACT.RECURRENCE.WEEKLY',
    MONTHLY: 'INGEST_EXTRACT.RECURRENCE.MONTHLY',
    YEARLY: 'INGEST_EXTRACT.RECURRENCE.YEARLY',
    EVERY_DAY: 'INGEST_EXTRACT.RECURRENCE.EVERY_DAY',
    EVERY_WEEKDAY: 'INGEST_EXTRACT.RECURRENCE.EVERY_WEEKDAY',
    RANGE_OF_RECURRENCE: 'INGEST_EXTRACT.RECURRENCE.RANGE_OF_RECURRENCE',
    NO_END_DATE: 'INGEST_EXTRACT.RECURRENCE.NO_END_DATE',
    END_DT: 'INGEST_EXTRACT.RECURRENCE.END_DT',
    RECUR_EVERY_WEEK_ON: 'INGEST_EXTRACT.RECURRENCE.RECUR_EVERY_WEEK_ON',
    MONDAY: 'INGEST_EXTRACT.RECURRENCE.MONDAY',
    TUESDAY: 'INGEST_EXTRACT.RECURRENCE.TUESDAY',
    WEDNESDAY: 'INGEST_EXTRACT.RECURRENCE.WEDNESDAY',
    THURSDAY: 'INGEST_EXTRACT.RECURRENCE.THURSDAY',
    FRIDAY: 'INGEST_EXTRACT.RECURRENCE.FRIDAY',
    SATURDAY: 'INGEST_EXTRACT.RECURRENCE.SATURDAY',
    SUNDAY: 'INGEST_EXTRACT.RECURRENCE.SUNDAY',
    START_TIME: 'INGEST_EXTRACT.RECURRENCE.START_TIME',
    END_AFTER: 'INGEST_EXTRACT.RECURRENCE.END_AFTER',
    OCCURENCES: 'INGEST_EXTRACT.RECURRENCE.OCCURENCES',
    RECURRENCE_OPTIONAL: 'INGEST_EXTRACT.RECURRENCE.RECURRENCE_OPTIONAL',
    OCCURRENCES_COMPLETED: 'INGEST_EXTRACT.RECURRENCE.OCCURRENCES_COMPLETED',
    RECUR_ON: 'INGEST_EXTRACT.RECURRENCE.RECUR_ON',
  },
  DATA_CENTER_UPLOAD: {
    FILE_UPLOADING: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.FILE_UPLOADING',
    ERROR_IN_FILE_UPLOADING:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.ERROR_IN_FILE_UPLOADING',
    FILE_UPLOADED_SUCCESSFULLY:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.FILE_UPLOADED_SUCCESSFULLY',
    UPLOAD_FILE: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.UPLOAD_FILE',
    COMPLETED: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.COMPLETED',
    FILE_UPLOAD_CRITERIA:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.FILE_UPLOAD_CRITERIA',
    FILE_FORMAT_CRITERIA:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.FILE_FORMAT_CRITERIA',
    TRANSACTIONAL_INFORMATION:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.TRANSACTIONAL_INFORMATION',
    VIEW_DETAILS: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.VIEW_DETAILS',
    SCHEMA_CRITERIA: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.SCHEMA_CRITERIA',
    INCORRECT_FILE_FORMAT:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.INCORRECT_FILE_FORMAT',
    DRAG_AND_DROP_YOUR_FILE_HERE_OR:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.DRAG_AND_DROP_YOUR_FILE_HERE_OR',
    CHOOSE_FILE: 'INGEST_EXTRACT.DATA_CENTER_UPLOAD.CHOOSE_FILE',
    MAX_FILE_SIZE_ERROR:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.MAX_FILE_SIZE_ERROR',
    SINGLE_FILE_ALLOWED:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.SINGLE_FILE_ALLOWED',
    SCHEMA_VALIDATION_FIXED_WIDTH:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.SCHEMA_VALIDATION_FIXED_WIDTH',
    SCHEMA_CRITERIA_FIXED_WIDTH:
      'INGEST_EXTRACT.DATA_CENTER_UPLOAD.SCHEMA_CRITERIA_FIXED_WIDTH',
  },
  EXTRACTION_DATA_SOURCE: {
    DEFINE_DATA_SOURCE:
      'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.DEFINE_DATA_SOURCE',
    PREVIEW: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.PREVIEW',
    TABLE_PREVIEW: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.TABLE_PREVIEW',
    CLUSTER_TYPE: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.CLUSTER_TYPE',
    DATABASE: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.DATABASE',
    CUSTOM_SQL: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.CUSTOM_SQL',
    TABLE: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.TABLE',
    ADAPTOR: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.ADAPTOR',
    SCHEMA_AND_TABLE: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.SCHEMA_AND_TABLE',
    NO_PREVIEW_AVAILABLE:
      'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.NO_PREVIEW_AVAILABLE',
    PREVIEW_ERROR: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.PREVIEW_ERROR',
    CLUSTER_ACCESS_DENIED:
      'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.CLUSTER_ACCESS_DENIED',
    TABLE_ACCESS_DENIED:
      'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.TABLE_ACCESS_DENIED',
    SELECT_TABLE: 'INGEST_EXTRACT.EXTRACTION_DATA_SOURCE.SELECT_TABLE',
  },
  EXTRACTION_DEFINE_SCHEMA: {
    DEFINE_SCHEMA: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.DEFINE_SCHEMA',
    SELECT: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.SELECT',
    COLUMN_NAME: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.COLUMN_NAME',
    TYPE: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.TYPE',
    FILTER: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.FILTER',
    ADD_CONDITION: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.ADD_CONDITION',
    COLUMN: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.COLUMN',
    VALUE: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.VALUE',
    CONDITION: 'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.CONDITION',
    NO_FILTER_APPLIED:
      'INGEST_EXTRACT.EXTRACTION_DEFINE_SCHEMA.NO_FILTER_APPLIED',
  },
  EXTRACTION_TRANSACTION_INFO: {
    NAME: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.NAME',
    FILE_LOCATION: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.FILE_LOCATION',
    TARGET: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.TARGET',
    FILE_NAME: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.FILE_NAME',
    FILE_FORMAT: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.FILE_FORMAT',
    DELIMITER: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.DELIMITER',
    QUOTE_CHARACTER:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.QUOTE_CHARACTER',
    ESCAPE_CHARACTER:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.ESCAPE_CHARACTER',
    NUMBER_OF_PART_FILES:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.NUMBER_OF_PART_FILES',
    DB_TABLE_MAPPING:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.DB_TABLE_MAPPING',
    SCHEDULE_RECURRING_EXTRACTION:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.SCHEDULE_RECURRING_EXTRACTION',
    UNABLE_TO_LOAD_DATA:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.UNABLE_TO_LOAD_DATA',
    REGEX_ERROR_MESSAGE:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.REGEX_ERROR_MESSAGE',
    TABLE_ACCESS_DENIED:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.TABLE_ACCESS_DENIED',
    INSERT: 'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.INSERT',
    INSERT_OVERWRITE:
      'INGEST_EXTRACT.EXTRACTION_TRANSACTION_INFO.INSERT_OVERWRITE',
  },
  SCHEDULER_TOGGLE: {
    RECURRENCE_STOPPED: 'INGEST_EXTRACT.SCHEDULER_TOGGLE.RECURRENCE_STOPPED',
    RECURRENCE_STARTED: 'INGEST_EXTRACT.SCHEDULER_TOGGLE.RECURRENCE_STARTED',
  },
  ADAPTOR_LIST: {
    CLEAR_FILTERS: 'INGEST_EXTRACT.ADAPTOR_LIST.CLEAR_FILTERS',
    ADD_NEW_ADAPTOR: 'INGEST_EXTRACT.ADAPTOR_LIST.ADD_NEW_ADAPTOR',
    ADAPTOR_NAME: 'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTOR_NAME',
    ADAPTOR_TYPE: 'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTOR_TYPE',
    CREATED_BY: 'INGEST_EXTRACT.ADAPTOR_LIST.CREATED_BY',
    LAST_MODIFIED_BY: 'INGEST_EXTRACT.ADAPTOR_LIST.LAST_MODIFIED_BY',
    ACTIONS: 'INGEST_EXTRACT.ADAPTOR_LIST.ACTIONS',
    NO_ADAPTOR_DATA_TO_DISPLAY:
      'INGEST_EXTRACT.ADAPTOR_LIST.NO_ADAPTOR_DATA_TO_DISPLAY',
    DEFINE_DATA_SOURCE: 'INGEST_EXTRACT.ADAPTOR_LIST.DEFINE_DATA_SOURCE',
    WHERE_IS_THE_DATA_SOURCE_LOCATED:
      'INGEST_EXTRACT.ADAPTOR_LIST.WHERE_IS_THE_DATA_SOURCE_LOCATED',
    TEST_CONNECTION: 'INGEST_EXTRACT.ADAPTOR_LIST.TEST_CONNECTION',
    SUBMIT_FOR_APPROVAL: 'INGEST_EXTRACT.ADAPTOR_LIST.SUBMIT_FOR_APPROVAL',
    VALUE_REQUIRED: 'INGEST_EXTRACT.ADAPTOR_LIST.VALUE_REQUIRED',
    NEW_ADAPTOR_REQUEST: 'INGEST_EXTRACT.ADAPTOR_LIST.NEW_ADAPTOR_REQUEST',
    EDIT_ADAPTOR_REQUEST: 'INGEST_EXTRACT.ADAPTOR_LIST.EDIT_ADAPTOR_REQUEST',
    COMMENT_FOR_APPROVAL: 'INGEST_EXTRACT.ADAPTOR_LIST.COMMENT_FOR_APPROVAL',
    DELETE_ADATOR_REQUEST: 'INGEST_EXTRACT.ADAPTOR_LIST.DELETE_ADATOR_REQUEST',
    TEST_CONECTION_FAILED: 'INGEST_EXTRACT.ADAPTOR_LIST.TEST_CONECTION_FAILED',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE:
      'INGEST_EXTRACT.ADAPTOR_LIST.ARE_YOU_SURE_YOU_WANT_TO_DELETE',
    BY_USERNAME: 'INGEST_EXTRACT.ADAPTOR_LIST.BY_USERNAME',
    REQUEST_HAS_BEEN_SENT_SUCCESSFULLY:
      'INGEST_EXTRACT.ADAPTOR_LIST.REQUEST_HAS_BEEN_SENT_SUCCESSFULLY',
    COMMENT_CHAR_LIMIT: 'INGEST_EXTRACT.ADAPTOR_LIST.COMMENT_CHAR_LIMIT',
    CREATE_LABEL: 'INGEST_EXTRACT.ADAPTOR_LIST.CREATE_LABEL',
    NEW_ADAPTOR: 'INGEST_EXTRACT.ADAPTOR_LIST.NEW_ADAPTOR',
    EDIT_ADAPTOR: 'INGEST_EXTRACT.ADAPTOR_LIST.EDIT_ADAPTOR',
    DELETE_ADAPTOR: 'INGEST_EXTRACT.ADAPTOR_LIST.DELETE_ADAPTOR',
    COMMENTS_TO_APPROVER: 'INGEST_EXTRACT.ADAPTOR_LIST.COMMENTS_TO_APPROVER',
    CREATED_ON: 'INGEST_EXTRACT.ADAPTOR_LIST.CREATED_ON',
    LAST_MODIFIED_ON: 'INGEST_EXTRACT.ADAPTOR_LIST.LAST_MODIFIED_ON',
    ALL: 'INGEST_EXTRACT.ADAPTOR_LIST.ALL',
    CREATE_NEW_ADAPTOR: 'INGEST_EXTRACT.ADAPTOR_LIST.CREATE_NEW_ADAPTOR',
    UPDATE: 'INGEST_EXTRACT.ADAPTOR_LIST.UPDATE',
    ADAPTOR_ADDED_SUCCESSFULLY:
      'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTOR_ADDED_SUCCESSFULLY',
    ADAPTOR_UPDATED_SUCCESSFULLY:
      'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTOR_UPDATED_SUCCESSFULLY',
    ADAPTOR_DELETED_SUCCESSFULLY:
      'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTOR_DELETED_SUCCESSFULLY',
    ADAPTORS: 'INGEST_EXTRACT.ADAPTOR_LIST.ADAPTORS',
    INTEGER_REQUIRED: 'INGEST_EXTRACT.ADAPTOR_LIST.INTEGER_REQUIRED',
  },
  DEFINE_ADAPTOR: {
    HOST_NAME: 'INGEST_EXTRACT.DEFINE_ADAPTOR.HOST_NAME',
    PORT_NUMBER: 'INGEST_EXTRACT.DEFINE_ADAPTOR.PORT_NUMBER',
    USERNAME: 'INGEST_EXTRACT.DEFINE_ADAPTOR.USERNAME',
    PASSWORD: 'INGEST_EXTRACT.DEFINE_ADAPTOR.PASSWORD',
    RDBMS_TYPE: 'INGEST_EXTRACT.DEFINE_ADAPTOR.RDBMS_TYPE',
    VERSION: 'INGEST_EXTRACT.DEFINE_ADAPTOR.VERSION',
    SCHEMA: 'INGEST_EXTRACT.DEFINE_ADAPTOR.SCHEMA',
    ORCALE_INSTANCE: 'INGEST_EXTRACT.DEFINE_ADAPTOR.ORCALE_INSTANCE',
    DATABASE: 'INGEST_EXTRACT.DEFINE_ADAPTOR.DATABASE',
    READER_ENDPOINT: 'INGEST_EXTRACT.DEFINE_ADAPTOR.READER_ENDPOINT',
    BUCKET_NAME: 'INGEST_EXTRACT.DEFINE_ADAPTOR.BUCKET_NAME',
    REGION_NAME: 'INGEST_EXTRACT.DEFINE_ADAPTOR.REGION_NAME',
    AWS_CREDENTIALS: 'INGEST_EXTRACT.DEFINE_ADAPTOR.AWS_CREDENTIALS',
    IAM_ROLE: 'INGEST_EXTRACT.DEFINE_ADAPTOR.IAM_ROLE',
    ACCESS_KEY: 'INGEST_EXTRACT.DEFINE_ADAPTOR.ACCESS_KEY',
    SECRET_KEY: 'INGEST_EXTRACT.DEFINE_ADAPTOR.SECRET_KEY',
    AUTHENTICATION_TYPE: 'INGEST_EXTRACT.DEFINE_ADAPTOR.AUTHENTICATION_TYPE',
    CLUSTER_IDENTIFIER: 'INGEST_EXTRACT.DEFINE_ADAPTOR.CLUSTER_IDENTIFIER',
  },
  PROCESS_ANALYSIS: {
    SMART_ANALYSIS: 'INGEST_EXTRACT.PROCESS_ANALYSIS.SMART_ANALYSIS',
    BACK: 'INGEST_EXTRACT.PROCESS_ANALYSIS.BACK',
    GRAPH_VISUALIZATION: 'INGEST_EXTRACT.PROCESS_ANALYSIS.GRAPH_VISUALIZATION',
    IPROFILER_METRICS: 'INGEST_EXTRACT.PROCESS_ANALYSIS.IPROFILER_METRICS',
    DATA_SUMMARY: 'INGEST_EXTRACT.PROCESS_ANALYSIS.DATA_SUMMARY',
    RECORDS: 'INGEST_EXTRACT.PROCESS_ANALYSIS.RECORDS',
  },
  TARGET_SYSTEM: {
    CLUSTER_TYPE: 'INGEST_EXTRACT.TARGET_SYSTEM.CLUSTER_TYPE',
    CLUSTER_ACCESS_DENIED_INGESTION_REDSHIFT:
      'INGEST_EXTRACT.TARGET_SYSTEM.CLUSTER_ACCESS_DENIED_INGESTION_REDSHIFT',
    CLUSTER_ACCESS_DENIED_INGESTION_EMR:
      'INGEST_EXTRACT.TARGET_SYSTEM.CLUSTER_ACCESS_DENIED_INGESTION_EMR',
    CLUSTER_ACCESS_DENIED_EXTRACTION_REDSHIFT:
      'INGEST_EXTRACT.TARGET_SYSTEM.CLUSTER_ACCESS_DENIED_EXTRACTION_REDSHIFT',
    CLUSTER_ACCESS_DENIED_EXTRACTION_EMR:
      'INGEST_EXTRACT.TARGET_SYSTEM.CLUSTER_ACCESS_DENIED_EXTRACTION_EMR',
  },
  DQM_CHECKS: {
    LESS_THAN: 'INGEST_EXTRACT.DQM_CHECKS.LESS_THAN',
    GREATER_THAN: 'INGEST_EXTRACT.DQM_CHECKS.GREATER_THAN',
    EQUAL_TO: 'INGEST_EXTRACT.DQM_CHECKS.EQUAL_TO',
    NOT_EQUAL_TO: 'INGEST_EXTRACT.DQM_CHECKS.NOT_EQUAL_TO',
    GREATER_THAN_EQUAL_TO: 'INGEST_EXTRACT.DQM_CHECKS.GREATER_THAN_EQUAL_TO',
    LESS_THAN_EQUAL_TO: 'INGEST_EXTRACT.DQM_CHECKS.LESS_THAN_EQUAL_TO',
  },
  TOOL_TIP_CONTSTANTS: {
    UPLOAD: 'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.UPLOAD',
    DOWNLOAD: 'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.DOWNLOAD',
    EDIT: 'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.EDIT',
    RUN: 'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.RUN',
    DELETE: 'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.DELETE',
    PLAESE_WAIT_UNTIL_THE_DATA_QUERY_IS_READY_TO_EXECUTE:
      'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.PLAESE_WAIT_UNTIL_THE_DATA_QUERY_IS_READY_TO_EXECUTE',
    PLEASE_WAIT_UNTIL_THE_DATA_QUERY_IS_READY_TO_DOWNLOAD:
      'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.PLEASE_WAIT_UNTIL_THE_DATA_QUERY_IS_READY_TO_DOWNLOAD',
    PLAESE_WAIT_UNTIL_THE_PROCESS_IS_READY_TO_EXECUTE:
      'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.PLAESE_WAIT_UNTIL_THE_PROCESS_IS_READY_TO_EXECUTE',
      PLEASE_WAIT_UNTIL_THE_INGESTION_IS_COMPLETE:
      'INGEST_EXTRACT.TOOL_TIP_CONTSTANTS.PLEASE_WAIT_UNTIL_THE_INGESTION_IS_COMPLETE',
  },
};

export const common_labels = {
    CANCEL: 'COMMON_LABELS.CANCEL',
    DELETE: 'COMMON_LABELS.DELETE',
    PREVIOUS: 'COMMON_LABELS.PREVIOUS',
    NEXT: 'COMMON_LABELS.NEXT',
    FINISH: 'COMMON_LABELS.FINISH',
    CLOSE: 'COMMON_LABELS.CLOSE',
    SAVE: 'COMMON_LABELS.SAVE',
    SAVE_CHANGES: 'COMMON_LABELS.SAVE_CHANGES',
    SAVE_AS_DRAFT: 'COMMON_LABELS.SAVE_AS_DRAFT',
    NEW: 'COMMON_LABELS.NEW',
    FILE_UPLOAD_NAME_VALIDATION_MSG: 'COMMON_LABELS.FILE_UPLOAD_NAME_VALIDATION_MSG',
    ERROR_WITH_COLON: 'COMMON_LABELS.ERROR_WITH_COLON',
    OK: 'COMMON_LABELS.OK',
    SUCCESS_WITH_COLON: 'COMMON_LABELS.SUCCESS_WITH_COLON',
    OPTIONAL_IN_PARANTHESIS: 'COMMON_LABELS.OPTIONAL_IN_PARANTHESIS',
    SOME_ERROR_OCCURRED: 'COMMON_LABELS.SOME_ERROR_OCCURRED',
    THEN: 'COMMON_LABELS.THEN',
    ELSE: 'COMMON_LABELS.ELSE',
    INCLUDE: 'COMMON_LABELS.INCLUDE',
    EXCLUDE: 'COMMON_LABELS.EXCLUDE',
    SELECT: 'COMMON_LABELS.SELECT',
    DATA_VALIDATIONS_FAILED: 'COMMON_LABELS.DATA_VALIDATIONS_FAILED',
    APPLY: 'COMMON_LABELS.APPLY',
    SELECT_VALUE: 'COMMON_LABELS.SELECT_VALUE_LABEL',
    SEARCH: 'COMMON_LABELS.SEARCH',
    BY: 'COMMON_LABELS.BY',
    NO_ROWS_TO_SHOW: 'COMMON_LABELS.NO_ROWS_TO_SHOW',
    YOUR_RESULTS_ARE_LOADING: 'COMMON_LABELS.YOUR_RESULTS_ARE_LOADING',
    SUCCESS: 'COMMON_LABELS.SUCCESS',
    YES: 'COMMON_LABELS.YES',
    NO: 'COMMON_LABELS.NO',
    SHOW: 'COMMON_LABELS.SHOW',
    NO_ITEMS_FOUND_FOR_SEARCH: 'COMMON_LABELS.NO_ITEMS_FOUND_FOR_SEARCH',
    EXECUTE: 'COMMON_LABELS.EXECUTE',
    SAVE_AND_CONTINUE: 'COMMON_LABELS.SAVE_AND_CONTINUE',
    TWENTY_FOUR_HOUR_FORMAT: 'COMMON_LABELS.TWENTY_FOUR_HOUR_FORMAT',
    SAVE_AND_EXIT: 'COMMON_LABELS.SAVE_AND_EXIT',
    SAVE_AND_CONFIGURE_PARAMETERS: 'COMMON_LABELS.SAVE_AND_CONFIGURE_PARAMETERS',
    CONTINUE_TO_CONFIGURE_PARAMETERS: 'COMMON_LABELS.CONTINUE_TO_CONFIGURE_PARAMETERS',
    CONTINUE_EDITING: 'COMMON_LABELS.CONTINUE_EDITING',
    YES_QUIT: 'COMMON_LABELS.YES_QUIT',
    PROCEED: 'COMMON_LABELS.PROCEED',
    DOWNLOAD_THE_LINKED_FLOWS: 'COMMON_LABELS.DOWNLOAD_THE_LINKED_FLOWS',
    BACK: 'COMMON_LABELS.BACK',
    DOWNLOAD: 'COMMON_LABELS.DOWNLOAD',
    READY_TO_DOWNLOAD: 'COMMON_LABELS.READY_TO_DOWNLOAD',
    RUN: 'COMMON_LABELS.RUN',
    CONFIRM_DELETE: 'COMMON_LABELS.CONFIRM_DELETE',
    EDIT: 'COMMON_LABELS.EDIT',
    STEP_OF: 'COMMON_LABELS.STEP_OF',
    COPY: 'COMMON_LABELS.COPY',
    SAVE_WORKFLOW_AND_EXIT: 'COMMON_LABELS.SAVE_WORKFLOW_AND_EXIT',
    NO_RECORDS_TO_DISPLAY: 'COMMON_LABELS.NO_RECORDS_TO_DISPLAY'
};

export const revo_core_labels = {
  HOME_LABELS: {
    RECENT_DATA_CENTER_AND_DATA_QUERY:
      'REVO_CORE.HOME_LABELS.RECENT_DATA_CENTER_AND_DATA_QUERY',
    RECENT_FLOWS: 'REVO_CORE.HOME_LABELS.RECENT_FLOWS',
    MODIFIED: 'REVO_CORE.HOME_LABELS.MODIFIED',
    LATEST_TIME_PERIOD: 'REVO_CORE.HOME_LABELS.LATEST_TIME_PERIOD',
    DRIVER_DATA_OVERVIEW: 'REVO_CORE.HOME_LABELS.DRIVER_DATA_OVERVIEW',
    CREATE_NEW: 'REVO_CORE.HOME_LABELS.CREATE_NEW',
    INGESTIONS: 'REVO_CORE.HOME_LABELS.INGESTIONS',
    EXTRACTIONS: 'REVO_CORE.HOME_LABELS.EXTRACTIONS',
    FLOW: 'REVO_CORE.HOME_LABELS.FLOW',
    DRIVER_DATA: 'REVO_CORE.HOME_LABELS.DRIVER_DATA',
  },
  JOB_STATUS: {
    IN_QUEUE: 'REVO_CORE.JOB_STATUS.IN_QUEUE',
    RUNNING: 'REVO_CORE.JOB_STATUS.RUNNING',
    FAILED: 'REVO_CORE.JOB_STATUS.FAILED',
    SUCCEEDED: 'REVO_CORE.JOB_STATUS.SUCCEEDED',
    ALL: 'REVO_CORE.JOB_STATUS.ALL',
    SUCCESSFUL: 'REVO_CORE.JOB_STATUS.SUCCESSFUL',
    NEW_STATUS: 'REVO_CORE.JOB_STATUS.NEW_STATUS',
    STOPPED: 'REVO_CORE.JOB_STATUS.STOPPED',
    STOPPING: 'REVO_CORE.JOB_STATUS.STOPPING',
    ERROR_STATUS: 'REVO_CORE.JOB_STATUS.ERROR_STATUS',
  },
  VALIDATION_LABELS: {
    VALUE_REQUIRED: 'REVO_CORE.VALIDATION_LABELS.VALUE_REQUIRED',
    MISMATCH_IN_PARANTHESIS:
      'REVO_CORE.VALIDATION_LABELS.MISMATCH_IN_PARANTHESIS',
    SEMICOLON_NOT_ALLOWED: 'REVO_CORE.VALIDATION_LABELS.SEMICOLON_NOT_ALLOWED',
    LIMIT_EXCEED: 'REVO_CORE.VALIDATION_LABELS.LIMIT_EXCEED',
    ILLEGAL_CHARS: 'REVO_CORE.VALIDATION_LABELS.ILLEGAL_CHARS',
    GLOBAL_PARAMETER_PASSWORD_EXCEPTION:
      'REVO_CORE.VALIDATION_LABELS.GLOBAL_PARAMETER_PASSWORD_EXCEPTION',
  },
  MULTI_SELECT_LABELS: {
    CLEAR_ALL: 'REVO_CORE.MULTI_SELECT_LABELS.CLEAR_ALL',
  },
  PAGINATION: {
    SHOW: 'REVO_CORE.PAGINATION.SHOW',
  },
  TOGGLE: {
    ON: 'REVO_CORE.TOGGLE.ON',
    OFF: 'REVO_CORE.TOGGLE.OFF',
  },
  DD_OBJECT_DQM_MESSAGES: {
    THIS_COLUMN_CAN_NOT_BE_EMPTY:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.THIS_COLUMN_CAN_NOT_BE_EMPTY',
    GREATER_THAN_LENGTH_ONLY:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_LENGTH_ONLY',
    LESS_THAN_LENGTH_ONLY:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.LESS_THAN_LENGTH_ONLY',
    GREATER_THAN_EQUAL_TO_LENGTH_ONLY:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_EQUAL_TO_LENGTH_ONLY',
    LESS_THAN_EQUAL_TO_LENGTH_ONLY:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.LESS_THAN_EQUAL_TO_LENGTH_ONLY',
    ACCEPTED_TYPE: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.ACCEPTED_TYPE',
    TIMESTAMP_FORMAT: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.TIMESTAMP_FORMAT',
    INCLUSION: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.INCLUSION',
    EXCLUSION: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.EXCLUSION',
    MAX_ONLY: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.MAX_ONLY',
    MIN_ONLY: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.MIN_ONLY',
    MIN_MAX_BOTH: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.MIN_MAX_BOTH',
    RANGE_MAIN: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.RANGE_MAIN',
    RANGE_INDIVIDUAL_MESSAGE:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.RANGE_INDIVIDUAL_MESSAGE',
    LENGTH_EQUALS_TO: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.LENGTH_EQUALS_TO',
    LENGTH_NOT_EQUALS_TO:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.LENGTH_NOT_EQUALS_TO',
    GREATER_THAN_LESS_THAN_EQUALS_TO:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_LESS_THAN_EQUALS_TO',
    GREATER_THAN_EQUAL_TO_LESS_THAN:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_EQUAL_TO_LESS_THAN',
    GREATER_THAN_EQUAL_TO_LESS_THAN_EQUALS_TO:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_EQUAL_TO_LESS_THAN_EQUALS_TO',
    GREATER_THAN_LESS_THAN:
      'REVO_CORE.DD_OBJECT_DQM_MESSAGES.GREATER_THAN_LESS_THAN',
    OR: 'REVO_CORE.DD_OBJECT_DQM_MESSAGES.OR',
  },
};

export const utils_constants = {
  GET_RELATIVE_TIME: {
    SECONDS: 'UTILS.GET_RELATIVE_TIME.SECONDS',
    ONE_MINUTE_AGO: 'UTILS.GET_RELATIVE_TIME.ONE_MINUTE_AGO',
    ONE_MINUTE_FROM_NOW: 'UTILS.GET_RELATIVE_TIME.ONE_MINUTE_FROM_NOW',
    MINUTES: 'UTILS.GET_RELATIVE_TIME.MINUTES',
    ONE_HOUR_AGO: 'UTILS.GET_RELATIVE_TIME.ONE_HOUR_AGO',
    ONE_HOUR_FROM_NOW: 'UTILS.GET_RELATIVE_TIME.ONE_HOUR_FROM_NOW',
    HOURS: 'UTILS.GET_RELATIVE_TIME.HOURS',
    YESTERDAY: 'UTILS.GET_RELATIVE_TIME.YESTERDAY',
    TOMORROW: 'UTILS.GET_RELATIVE_TIME.TOMORROW',
    DAYS: 'UTILS.GET_RELATIVE_TIME.DAYS',
    LAST_WEEK: 'UTILS.GET_RELATIVE_TIME.LAST_WEEK',
    NEXT_WEEK: 'UTILS.GET_RELATIVE_TIME.NEXT_WEEK',
    WEEKS: 'UTILS.GET_RELATIVE_TIME.WEEKS',
    LAST_MONTH: 'UTILS.GET_RELATIVE_TIME.LAST_MONTH',
    NEXT_MONTH: 'UTILS.GET_RELATIVE_TIME.NEXT_MONTH',
    MONTHS: 'UTILS.GET_RELATIVE_TIME.MONTHS',
    LAST_YEAR: 'UTILS.GET_RELATIVE_TIME.LAST_YEAR',
    NEXT_YEAR: 'UTILS.GET_RELATIVE_TIME.NEXT_YEAR',
    YEARS: 'UTILS.GET_RELATIVE_TIME.YEARS',
    LAST_CENTURY: 'UTILS.GET_RELATIVE_TIME.LAST_CENTURY',
    NEXT_CENTURY: 'UTILS.GET_RELATIVE_TIME.NEXT_CENTURY',
    CENTURIES: 'UTILS.GET_RELATIVE_TIME.CENTURIES',
    AGO: 'UTILS.GET_RELATIVE_TIME.AGO',
    JUST_NOW: 'UTILS.GET_RELATIVE_TIME.JUST_NOW',
    FROM_NOW: 'UTILS.GET_RELATIVE_TIME.FROM_NOW',
  },
  GET_TIME_FROM_MINUTE: {
    NA: 'UTILS.GET_TIME_FROM_MINUTE.NA',
    DAY: 'UTILS.GET_TIME_FROM_MINUTE.DAY',
    DAYS: 'UTILS.GET_TIME_FROM_MINUTE.DAYS',
    HOURS: 'UTILS.GET_TIME_FROM_MINUTE.HOURS',
    HOUR: 'UTILS.GET_TIME_FROM_MINUTE.HOUR',
    MIN: 'UTILS.GET_TIME_FROM_MINUTE.MIN',
    SECONDS: 'UTILS.GET_TIME_FROM_MINUTE.SECONDS',
  },
  DATE_TIME_FORMAT: {
    TIME_FORMATS: 'UTILS.DATE_TIME_FORMAT.TIME_FORMATS',
    DATE_FORMATS: 'UTILS.DATE_TIME_FORMAT.DATE_FORMATS',
  },
};

export const app_labels = {
  CHANGE_ENVIRONMENT: 'APP.CHANGE_ENVIRONMENT',
  HOME: 'APP.HOME',
  DRIVER_DATA: 'APP.DRIVER_DATA',
  BUSINESS_RULES: 'APP.BUSINESS_RULES',
  INGESTION: 'APP.INGESTION',
  EXTRACTION: 'APP.EXTRACTION',
  PROCESS_MONITORING: 'APP.PROCESS_MONITORING',
  AUDIT_LOG: 'APP.AUDIT_LOG',
  ADMIN: 'APP.ADMIN',
  SITE_ADMIN: 'APP.SITE_ADMIN',
  IMPORT_EXPORT: 'APP.IMPORT_EXPORT',
  MY_APPROVAL_REQUESTS: 'APP.MY_APROVAL_REQUESTS',
  TECHNICAL_SUPPORT: 'APP.TECHNICAL_SUPPORT',
  ICP_NO: 'APP.ICP_NO',
  CYBERSECURITY_NO: 'APP.CYBER_SECURITY_NO',
  COPYRIGHT: 'APP.COPYRIGHT',
  PRIVACY_POLICY: 'APP.PRIVACY_POLICY',
  TERMS_OF_USE: 'APP.TERMS_OF_USE',
  ENVIRONMENT_CHANGE_WARNING: 'APP.ENVIRONMENT_CHANGE_WARNING',
  NO_ENVIRONMENTS_AVAILABLE: 'APP.NO_ENVIRONMENTS_AVAILABLE',
  DISCARD_CHANGES: 'APP.DISCARD_CHANGES',
  UNSUPPORTED_BROWSER: 'APP.UNSUPPORTED_BROWSER',
  ENVIRONMENT_CHANGE_TITLE: 'APP.ENVIRONMENT_CHANGE_TITLE',
  UNSAVED_CHANGES_TITLE: 'APP.UNSAVED_CHANGES_TITLE',
  UNSUPPORTED_BROWSER_TITLE: 'APP.UNSUPPORTED_BROWSER_TITLE',
  ENGLISH: 'APP.ENGLISH',
  ENGLISH_US: 'APP.ENGLISH_US',
  SPANISH: 'APP.SPANISH',
  SPANISH_LATIN_AMERICA: 'APP.SPANISH_LATIN_AMERICA',
  GERMAN: 'APP.GERMAN',
  GERMAN_DE: 'APP.GERMAN_DE',
  JAPANESE: 'APP.JAPANESE',
  SELECT_LANGUAGE: 'APP.SELECT_LANGUAGE',
  CHINESE_SIMPLIFIED: 'APP.CHINESE_SIMPLIFIED',
  PORTUGUESE_BRAZIL: 'APP.PORTUGUESE_BRAZIL',
  APPROVAL: 'APP.APPROVAL',
  MY_REQUESTS: 'APP.MY_REQUESTS',
  MASTER: 'APP.MASTER',
  DATA: 'APP.DATA',
  SETTINGS: 'APP.SETTINGS',
  PROCESSES: 'APP.PROCESSES',
  DATA_QUERY: 'APP.DATA_QUERY',
  MASTER_DATA: 'APP.MASTER_DATA',
  DATA_CENTER: 'APP.DATA_CENTER',
  DATA_MENU: 'APP.DATA_MENU',
  ADAPTORS: 'APP.ADAPTORS',
  SELECT_ENVIRONMENT: 'APP.SELECT_ENVIRONMENT',
  HELP_CENTER_TITLE: 'APP.HELP_CENTER_TITLE',
  USER_GUIDE: 'APP.USER_GUIDE',
  USER_GUIDE_ERROR: 'APP.USER_GUIDE_ERROR',
  UNAUTHORISED_ACCESS_ERROR_MESSAGE: 'APP.UNAUTHORISED_ACCESS_ERROR_MESSAGE',
  RAS: 'APP.RAS',
  BUSINESS_WORKFLOWS: 'APP.BUSINESS_WORKFLOWS'
};

export const ProcessParameters = {
  KEY: 'PROCESS_MONITORING.PROCESS_PARAMETER.KEY',
  VALUE: 'PROCESS_MONITORING.PROCESS_PARAMETER.VALUE',
  DEFINE_PARAMETERS: 'PROCESS_MONITORING.PROCESS_PARAMETER.DEFINE_PARAMETERS',
  MESSAGES: {
    INVALID_DATE_FORMAT_MESSAGE:
      'PROCESS_MONITORING.PROCESS_PARAMETER.PROCESS_PARAMS_MESSAGES.INVALID_DATE_FORMAT_MESSAGE',
    PARAM_CURRENT_CYCLE_MESSAGE:
      'PROCESS_MONITORING.PROCESS_PARAMETER.PROCESS_PARAMS_MESSAGES.PARAM_CURRENT_CYCLE_MESSAGE',
  },
};

export const shared = {
  FLOW_PARAMETERS: {
    PROVIDE_VALUES_FOR_PARAMETER:
      'SHARED.FLOW_PARAMETER.PROVIDE_VALUES_FOR_PARAMETER',
    NUMBER_OF_PARAMETERS: 'SHARED.FLOW_PARAMETER.NUMBER_OF_PARAMETERS',
    SELECT_CYCLE: 'SHARED.CYCLE_SELECTION.SELECT_CYCLE',
    CUSTOM_CYCLE_NAME: 'SHARED.CYCLE_SELECTION.CUSTOM_CYCLE_NAME',
    NEW_CYCLE: 'SHARED.CYCLE_SELECTION.NEW_CYCLE',
    DEFINE_PARAMETERS: 'SHARED.DEFINE_PARAMETER.DEFINE_PARAMETERS',
    DEFINE_PARAMETER_VALUES: 'SHARED.DEFINE_PARAMETER.DEFINE_PARAMETER_VALUES',
    PROCESS_LABEL: 'SHARED.DEFINE_PARAMETER.PROCESS_LABEL'
  },
};

export const ParametersStaticKeyValueMapping = {
  PARAMETERS: 'SHARED.PARAMETERS_STATIC_KEY_VALUE_MAPPING.PARAMETERS',
  VALUE: 'SHARED.PARAMETERS_STATIC_KEY_VALUE_MAPPING.VALUE',
  PROCESS_NAME: 'SHARED.PARAMETERS_STATIC_KEY_VALUE_MAPPING.PROCESS_NAME',
  PROCESSES: 'SHARED.PARAMETERS_STATIC_KEY_VALUE_MAPPING.PROCESSES'
};

export const BackendUserMessages = {
  SOME_ERROR_OCCURED: 'BackendUserMessages.SOME_ERROR_OCCURED',
  BAD_REQUEST: 'BackendUserMessages.BAD_REQUEST',
  CONNECTION_SUCCESSFUL: 'BackendUserMessages.CONNECTION_SUCCESSFUL',
  FAILED_ON_CREDENTIALS: 'BackendUserMessages.FAILED_ON_CREDENTIALS',
  FAILED_ON_TIMEOUT: 'BackendUserMessages.FAILED_ON_TIMEOUT',
  FAILED_SERVER_TERMINATED_CONNECTION:
    'BackendUserMessages.FAILED_SERVER_TERMINATED_CONNECTION',
  FAILED_ON_INVALID_HOST_INFO:
    'BackendUserMessages.FAILED_ON_INVALID_HOST_INFO',
  FAILED_INVALID_CONNECTION_DETAILS:
    'BackendUserMessages.FAILED_INVALID_CONNECTION_DETAILS',
  FAILED_CONNECTION_GENERIC: 'BackendUserMessages.FAILED_CONNECTION_GENERIC',
  NO_DATABASE_SELECTED: 'BackendUserMessages.NO_DATABASE_SELECTED',
  UNAUTHORIZED_ACCESS: 'BackendUserMessages.UNAUTHORIZED_ACCESS',
  MISSING_CLUSTER_ID: 'BackendUserMessages.MISSING_CLUSTER_ID',
  FAILED_GET_STEP_ERRORS: 'BackendUserMessages.FAILED_GET_STEP_ERRORS',
  BACKDATED_END_DATE: 'BackendUserMessages.BACKDATED_END_DATE',
  NO_ENVIRONMENT_SELECTED: 'BackendUserMessages.NO_ENVIRONMENT_SELECTED',
  ADHOC_JOB_SUBMIT_FAILED: 'BackendUserMessages.ADHOC_JOB_SUBMIT_FAILED',
  NO_JOB_OCCURENCE_FOR_GIVEN_PERIOD:
    'BackendUserMessages.NO_JOB_OCCURENCE_FOR_GIVEN_PERIOD',
  INTERNAL_ERROR_INVALID_SCHEDULER:
    'BackendUserMessages.INTERNAL_ERROR_INVALID_SCHEDULER',
  NO_SCHEDULER_FOUND: 'BackendUserMessages.NO_SCHEDULER_FOUND',
  UNABLE_TO_FETCH_IDM_USERS: 'BackendUserMessages.UNABLE_TO_FETCH_IDM_USERS',
  UNABLE_TO_FETCH_IDM_USERS_ATTRIBUTE_VALUES:
    'BackendUserMessages.UNABLE_TO_FETCH_IDM_USERS_ATTRIBUTE_VALUES',
  UNABLE_TO_FETCH_IDM_USERS_FOR_A_ROLE:
    'BackendUserMessages.UNABLE_TO_FETCH_IDM_USERS_FOR_A_ROLE',
  FAILED_TO_SYNC_USERS: 'BackendUserMessages.FAILED_TO_SYNC_USERS',
  NO_FILTERS_GIVEN_FOR_DELETE:
    'BackendUserMessages.NO_FILTERS_GIVEN_FOR_DELETE',
  EMPTY_FILE_PROVIDED: 'BackendUserMessages.EMPTY_FILE_PROVIDED',
  NO_RECORD_WITH_THIS_DD_OBJECT_ID:
    'BackendUserMessages.NO_RECORD_WITH_THIS_DD_OBJECT_ID',
  INVALID_VALUE_FOR_UPLOAD_TYPE:
    'BackendUserMessages.INVALID_VALUE_FOR_UPLOAD_TYPE',
  FAILED_TO_FETCH_VERSIONS: 'BackendUserMessages.FAILED_TO_FETCH_VERSIONS',
  NO_DRIVER_DATA_OBJECT_FOUND:
    'BackendUserMessages.NO_DRIVER_DATA_OBJECT_FOUND',
  NO_ACCESS_ON_DATA: 'BackendUserMessages.NO_ACCESS_ON_DATA',
  NO_WRITE_ACCESS_ON_DATA: 'BackendUserMessages.NO_WRITE_ACCESS_ON_DATA',
  NOT_ENOUGH_PERMISSIONS_TO_DELETE_SELECTED_DATA:
    'BackendUserMessages.NOT_ENOUGH_PERMISSIONS_TO_DELETE_SELECTED_DATA',
  DD_OBJECT_ID_NOT_PROVIDED: 'BackendUserMessages.DD_OBJECT_ID_NOT_PROVIDED',
  INPUT_PARAMETER_NOT_PROVIDED:
    'BackendUserMessages.INPUT_PARAMETER_NOT_PROVIDED',
  SUBJECT_AREA_EXISTS: 'BackendUserMessages.SUBJECT_AREA_EXISTS',
  SUBJECT_AREA_DOES_NOT_EXIST:
    'BackendUserMessages.SUBJECT_AREA_DOES_NOT_EXIST',
  SUBJECT_AREA_IN_USE: 'BackendUserMessages.SUBJECT_AREA_IN_USE',
  DUPLICATE_SUBJECT_AREA_NAME:
    'BackendUserMessages.DUPLICATE_SUBJECT_AREA_NAME',
  ENTITY_EXISTS: 'BackendUserMessages.ENTITY_EXISTS',
  ENTITY_ALIAS_MAPPING_EXISTS:
    'BackendUserMessages.ENTITY_ALIAS_MAPPING_EXISTS',
  ENTITY_DOES_NOT_EXIST: 'BackendUserMessages.ENTITY_DOES_NOT_EXIST',
  ENTITY_IN_USE: 'BackendUserMessages.ENTITY_IN_USE',
  DUPLICATE_ENTITY: 'BackendUserMessages.DUPLICATE_ENTITY',
  ENTITY_COLUMN_EXISTS: 'BackendUserMessages.ENTITY_COLUMN_EXISTS',
  ENTITY_COLUMN_DOES_NOT_EXIST:
    'BackendUserMessages.ENTITY_COLUMN_DOES_NOT_EXIST',
  DUPLICATE_ENTITY_COLUMN: 'BackendUserMessages.DUPLICATE_ENTITY_COLUMN',
  ENTITY_COLUMN_IN_USE: 'BackendUserMessages.ENTITY_COLUMN_IN_USE',
  MISSING_ENTITY_NAME: 'BackendUserMessages.MISSING_ENTITY_NAME',
  MISSING_ENTITY_COLUMN_NAME: 'BackendUserMessages.MISSING_ENTITY_COLUMN_NAME',
  MISSING_SOURCE_TABLE_NAME: 'BackendUserMessages.MISSING_SOURCE_TABLE_NAME',
  MISSING_SOURCE_COLUMN_NAME: 'BackendUserMessages.MISSING_SOURCE_COLUMN_NAME',
  MISSING_SOURCE_COLUMN_DATATYPE:
    'BackendUserMessages.MISSING_SOURCE_COLUMN_DATATYPE',
  DQM_ERROR_CONFLICTING_PK_HEADER:
    'BackendUserMessages.DQM_ERROR_CONFLICTING_PK_HEADER',
  DQM_ERROR_ACCESS_DENIED_ON_RECORD:
    'BackendUserMessages.DQM_ERROR_ACCESS_DENIED_ON_RECORD',
  DUPLICATE_PRIMARY_KEYS_FOUND:
    'BackendUserMessages.DUPLICATE_PRIMARY_KEYS_FOUND',
  CANNOT_PROCEED_WITH_OPERATION_TABLE_LOCKED:
    'BackendUserMessages.CANNOT_PROCEED_WITH_OPERATION_TABLE_LOCKED',
  NO_CHANGES_TO_PUBLISH: 'BackendUserMessages.NO_CHANGES_TO_PUBLISH',
  INCORRECT_EXECUTION_ID_PROVIDED:
    'BackendUserMessages.INCORRECT_EXECUTION_ID_PROVIDED',
  ASYNC_JOB_ID_NOT_FOUND: 'BackendUserMessages.ASYNC_JOB_ID_NOT_FOUND',
  INCOMPLETE_REQUEST_PARAMETERS_IN_GET_QUERIES:
    'BackendUserMessages.INCOMPLETE_REQUEST_PARAMETERS_IN_GET_QUERIES',
  MISSING_ENVIRONMENT_ERROR: 'BackendUserMessages.MISSING_ENVIRONMENT_ERROR',
  NULL_ID_PROVIDED_FOR_UPDATE:
    'BackendUserMessages.NULL_ID_PROVIDED_FOR_UPDATE',
  FAIL_TO_CREATE_OBJECT: 'BackendUserMessages.FAIL_TO_CREATE_OBJECT',
  CANNOT_DELETE_CATEGORIES: 'BackendUserMessages.CANNOT_DELETE_CATEGORIES',
  COULD_NOT_CREATE_QUERY: 'BackendUserMessages.COULD_NOT_CREATE_QUERY',
  NO_SUCH_RULE: 'BackendUserMessages.NO_SUCH_RULE',
  NO_SUCH_GROUP_FOUND: 'BackendUserMessages.NO_SUCH_GROUP_FOUND',
  UNABLE_TO_FETCH_USER_RULE_GROUP_MAPPING_LIST:
    'BackendUserMessages.UNABLE_TO_FETCH_USER_RULE_GROUP_MAPPING_LIST',
  UNABLE_TO_DELETE_CATEGORY_GROUP_MAPPING:
    'BackendUserMessages.UNABLE_TO_DELETE_CATEGORY_GROUP_MAPPING',
  FAILED_TO_FIND_ENVIRONMENT_DETAILS:
    'BackendUserMessages.FAILED_TO_FIND_ENVIRONMENT_DETAILS',
  MISSING_SOURCE_DATABASE_NAME:
    'BackendUserMessages.MISSING_SOURCE_DATABASE_NAME',
  TIME_PERIOD_DUPLICATE_NAME_ERROR:
    'BackendUserMessages.TIME_PERIOD_DUPLICATE_NAME_ERROR',
  NO_UPDATE_IN_RULE_DATA_ERROR_MESSGAE:
    'BackendUserMessages.NO_UPDATE_IN_RULE_DATA_ERROR_MESSGAE',
  UNABLE_TO_FETCH_FROM_DATE_OR_TO_DATE:
    'BackendUserMessages.UNABLE_TO_FETCH_FROM_DATE_OR_TO_DATE',
  MISSING_DEPENDENT_DRIVER_DATA_OBJECTS:
    'BackendUserMessages.MISSING_DEPENDENT_DRIVER_DATA_OBJECTS',
  MISSING_DEPENDENT_DRIVER_DATA_COLUMNS:
    'BackendUserMessages.MISSING_DEPENDENT_DRIVER_DATA_COLUMNS',
  INVALID_DEPENDENT_DRIVER_DATA_OBJECT_DETAILS:
    'BackendUserMessages.INVALID_DEPENDENT_DRIVER_DATA_OBJECT_DETAILS',
  TOP_LEVEL_FILTER_MISSING: 'BackendUserMessages.TOP_LEVEL_FILTER_MISSING',
  MAPPING_OBJECT_REFERENCE_PRESENT:
    'BackendUserMessages.MAPPING_OBJECT_REFERENCE_PRESENT',
  BLOCK_NOT_FOUND: 'BackendUserMessages.BLOCK_NOT_FOUND',
  CYCLE_NOT_FOUND: 'BackendUserMessages.CYCLE_NOT_FOUND',
  PROCESS_VERSION_NOT_FOUND: 'BackendUserMessages.PROCESS_VERSION_NOT_FOUND',
  INVALID_RULE_CONDITION: 'BackendUserMessages.INVALID_RULE_CONDITION',
  SOURCE_COLUMN_ERROR_MESSAGE:
    'BackendUserMessages.SOURCE_COLUMN_ERROR_MESSAGE',
  FAILED_TO_RUN_PM_PROCESS: 'BackendUserMessages.FAILED_TO_RUN_PM_PROCESS',
  FAILED_TO_CREATE_EXECUTION_SEQUENCE_FOR_PM_PROCESS_RUN:
    'BackendUserMessages.FAILED_TO_CREATE_EXECUTION_SEQUENCE_FOR_PM_PROCESS_RUN',
  FAILED_TO_FETCH_PROCESS_VERSION_BY_ID:
    'BackendUserMessages.FAILED_TO_FETCH_PROCESS_VERSION_BY_ID',
  FAILED_TO_FETCH_PROCESS_RUN_BY_ID:
    'BackendUserMessages.FAILED_TO_FETCH_PROCESS_RUN_BY_ID',
  FAILED_TO_FETCH_LATEST_PROCESS_RUN_ID_ERROR_MESSAGE:
    'BackendUserMessages.FAILED_TO_FETCH_LATEST_PROCESS_RUN_ID_ERROR_MESSAGE',
  FAILED_TO_FETCH_LATEST_PROCESS_RUN_DETAILS:
    'BackendUserMessages.FAILED_TO_FETCH_LATEST_PROCESS_RUN_DETAILS',
  FAILED_TO_FETCH_LATEST_PROCESS_RUN_DETAILS_BY_VERSION_ID:
    'BackendUserMessages.FAILED_TO_FETCH_LATEST_PROCESS_RUN_DETAILS_BY_VERSION_ID',
  PROCESS_NO_LONGER_IN_PROGRESS:
    'BackendUserMessages.PROCESS_NO_LONGER_IN_PROGRESS',
  AZKABAN_JOB_SUBMISSION_FAILED:
    'BackendUserMessages.AZKABAN_JOB_SUBMISSION_FAILED',
  ALL_BLOCKS_ARE_DISABLED_FOR_PROCESS_ERROR_MESSAGE:
    'BackendUserMessages.ALL_BLOCKS_ARE_DISABLED_FOR_PROCESS_ERROR_MESSAGE',
  BACKGROUND_PROCESS_RUN_ERROR_MESSAGE:
    'BackendUserMessages.BACKGROUND_PROCESS_RUN_ERROR_MESSAGE',
  UNIQUE_CYCLE_ERROR_MESSAGE: 'BackendUserMessages.UNIQUE_CYCLE_ERROR_MESSAGE',
  BLOCK_TOGGLE_FAILURE_AS_PROCESS_IS_IN_PROGRESS:
    'BackendUserMessages.BLOCK_TOGGLE_FAILURE_AS_PROCESS_IS_IN_PROGRESS',
  PROCESS_TEMPLATE_ERROR: 'BackendUserMessages.PROCESS_TEMPLATE_ERROR',
  START_PROCESS_EXECUTION_ERROR:
    'BackendUserMessages.START_PROCESS_EXECUTION_ERROR',
  PROCESS_VERSION_SAVE_ERROR: 'BackendUserMessages.PROCESS_VERSION_SAVE_ERROR',
  PROCESS_SAVE_ERROR: 'BackendUserMessages.PROCESS_SAVE_ERROR',
  PROCESS_TEMPLATE_SAVE_ERROR:
    'BackendUserMessages.PROCESS_TEMPLATE_SAVE_ERROR',
  PROCESS_TEMPLATE_DELETE_ERROR:
    'BackendUserMessages.PROCESS_TEMPLATE_DELETE_ERROR',
  NO_SUCH_BLOCK_RUN_EXISTS: 'BackendUserMessages.NO_SUCH_BLOCK_RUN_EXISTS',
  PROCESS_FETCH_ERROR: 'BackendUserMessages.PROCESS_FETCH_ERROR',
  PM_JOB_NOT_IN_PROGRESS: 'BackendUserMessages.PM_JOB_NOT_IN_PROGRESS',
  FAILED_TO_CHANGE_BLOCK_STATUS_ERROR:
    'BackendUserMessages.FAILED_TO_CHANGE_BLOCK_STATUS_ERROR',
  VALIDATING_DISABLE_BLOCK_ERROR:
    'BackendUserMessages.VALIDATING_DISABLE_BLOCK_ERROR',
  NO_DATA_FOUND_MESSAGE: 'BackendUserMessages.NO_DATA_FOUND_MESSAGE',
  TEMPLATE_CONTAINS_DUPLICATE_WORKFLOW_NAMES:
    'BackendUserMessages.TEMPLATE_CONTAINS_DUPLICATE_WORKFLOW_NAMES',
  UNABLE_TO_FETCH_LATEST_PROCESS_VERSION_ID_ERROR_MESSAGE:
    'BackendUserMessages.UNABLE_TO_FETCH_LATEST_PROCESS_VERSION_ID_ERROR_MESSAGE',
  FAILED_TO_CHECK_IF_ALL_BLOCKS_DISABLED_ERROR_MESSAGE:
    'BackendUserMessages.FAILED_TO_CHECK_IF_ALL_BLOCKS_DISABLED_ERROR_MESSAGE',
  FAILED_TO_CHECK_RUNNING_PROCESS_ERROR_MESSAGE:
    'BackendUserMessages.FAILED_TO_CHECK_RUNNING_PROCESS_ERROR_MESSAGE',
  ERROR_OCCURRED_WHILE_CREATING_NEW_CYCLE_MESSAGE:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_CREATING_NEW_CYCLE_MESSAGE',
  ERROR_OCCURRED_WHILE_CHECKING_CYCLE_UNIQUENESS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_CHECKING_CYCLE_UNIQUENESS',
  DUPLICATE_RULE_NAME_ERROR: 'BackendUserMessages.DUPLICATE_RULE_NAME_ERROR',
  SOME_ERROR_OCCURRED_WHILE_RUNNING_PROCESS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_RUNNING_PROCESS',
  SOME_ERROR_OCCURRED_WHILE_RUNNING_NEW_CYCLE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_RUNNING_NEW_CYCLE',
  SOME_ERROR_OCCURRED_WHILE_CREATING_NEW_CYCLE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CREATING_NEW_CYCLE',
  SOME_ERROR_OCCURRED_WHILE_RUNNING_EXISTING_CYCLE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_RUNNING_EXISTING_CYCLE',
  LAST_PROCESS_EXECUTION_WAS_SUCCESSFUL:
    'BackendUserMessages.LAST_PROCESS_EXECUTION_WAS_SUCCESSFUL',
  NO_LAST_PROCESS_RUN_FOUND: 'BackendUserMessages.NO_LAST_PROCESS_RUN_FOUND',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_BLOCK_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_BLOCK_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_FETCH_PROCESS_RUN_STATUS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCH_PROCESS_RUN_STATUS',
  CLUSTER_LAUNCH_FAILED: 'BackendUserMessages.CLUSTER_LAUNCH_FAILED',
  CLUSTER_TERMINATION_FAILED: 'BackendUserMessages.CLUSTER_TERMINATION_FAILED',
  NO_RUNNING_PROCESSES_FOUND: 'BackendUserMessages.NO_RUNNING_PROCESSES_FOUND',
  JOB_ALREADY_IN_PROGRESS_FOR_THIS_PROCESS:
    'BackendUserMessages.JOB_ALREADY_IN_PROGRESS_FOR_THIS_PROCESS',
  SOME_ERROR_OCCURRED_WHILE_CHECKING_NEW_TEMPLATE_AVAILABLE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CHECKING_NEW_TEMPLATE_AVAILABLE',
  CLUSTER_IP_INVALID_ERROR_MESSAGE:
    'BackendUserMessages.CLUSTER_IP_INVALID_ERROR_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_CHECKING_JOB_RUN:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CHECKING_JOB_RUN',
  SOME_ERROR_OCCURRED_WHILE_UPDATING_CLUSTER_IP:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_UPDATING_CLUSTER_IP',
  SOME_ERROR_OCCURRED_WHILE_CHECKING_CLUSTER_IP_VALIDITY:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CHECKING_CLUSTER_IP_VALIDITY',
  NO_PROCESS_FOUND: 'BackendUserMessages.NO_PROCESS_FOUND',
  INVALID_CLUSTER_TYPE: 'BackendUserMessages.INVALID_CLUSTER_TYPE',
  NO_ENVIRONMENT_FOUND_ERROR: 'BackendUserMessages.NO_ENVIRONMENT_FOUND_ERROR',
  INVALID_ADAPTOR_NAME: 'BackendUserMessages.INVALID_ADAPTOR_NAME',
  INVALID_PROCESS_TYPE: 'BackendUserMessages.INVALID_PROCESS_TYPE',
  LENGTH_GREATER_THAN_50_ERROR_MESSAGE:
    'BackendUserMessages.LENGTH_GREATER_THAN_50_ERROR_MESSAGE',
  CONTAINS_SPECIAL_CHARACTER_ERROR_MESSAGE:
    'BackendUserMessages.CONTAINS_SPECIAL_CHARACTER_ERROR_MESSAGE',
  ERROR_OCCURRED_WHILE_DELETING_JOBS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_DELETING_JOBS',
  ERROR_IN_HARD_DELETING_EXPORT_RECORD:
    'BackendUserMessages.ERROR_IN_HARD_DELETING_EXPORT_RECORD',
  ERROR_IN_HARD_DELETING_TABLE_RECORD:
    'BackendUserMessages.ERROR_IN_HARD_DELETING_TABLE_RECORD',
  ERROR_IN_HARD_DELETING_DQM_SEQ_RECORD:
    'BackendUserMessages.ERROR_IN_HARD_DELETING_DQM_SEQ_RECORD',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_DISPLAY_NAME:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_DISPLAY_NAME',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_DISPLAY_NAME_DICT:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_DISPLAY_NAME_DICT',
  DATATYPE_PROVIDED_NOT_SUPPORTED_ERROR_MESSAGE:
    'BackendUserMessages.DATATYPE_PROVIDED_NOT_SUPPORTED_ERROR_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_LOOKUP_TABLES:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_LOOKUP_TABLES',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_LOOKUP_COLUMNS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_LOOKUP_COLUMNS',
  DUPLICATE_TOP_LEVEL_FILTER_NAME:
    'BackendUserMessages.DUPLICATE_TOP_LEVEL_FILTER_NAME',
  CANNOT_ADD_TOP_LEVEL_FILTER:
    'BackendUserMessages.CANNOT_ADD_TOP_LEVEL_FILTER',
  CURRENTLY_RUNNING_JOBS_USING_ADAPTOR:
    'BackendUserMessages.CURRENTLY_RUNNING_JOBS_USING_ADAPTOR',
  DUPLICATE_CATEGORIES: 'BackendUserMessages.DUPLICATE_CATEGORIES',
  SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_IP:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_IP',
  CLUSTER_IP_ALREADY_PRESENT: 'BackendUserMessages.CLUSTER_IP_ALREADY_PRESENT',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_IP_LIST:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_IP_LIST',
  SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_IP_MESSAGE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_IP_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_HIVE_METASTORE_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_HIVE_METASTORE_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_IP:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_IP',
  SOME_ERROR_OCCURRED_WHILE_SAVING_HIVE_METASTORE_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_SAVING_HIVE_METASTORE_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_UPDATING_HIVE_METASTORE_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_UPDATING_HIVE_METASTORE_DETAILS',
  DUPLICATE_CLUSTER_IP: 'BackendUserMessages.DUPLICATE_CLUSTER_IP',
  SOME_ERROR_OCCURRED_WHILE_UPDATING_CLUSTER_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_UPDATING_CLUSTER_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_SAVING_CLUSTER_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_SAVING_CLUSTER_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_MESSAGE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DELETING_CLUSTER_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_HIVE_METASTORE_CONN_STRING:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_HIVE_METASTORE_CONN_STRING',
  SAVING_ADAPTOR_APPROVAL_REQUEST:
    'BackendUserMessages.SAVING_ADAPTOR_APPROVAL_REQUEST',
  UPDATING_ADAPTOR_APPROVAL_REQUEST:
    'BackendUserMessages.UPDATING_ADAPTOR_APPROVAL_REQUEST',
  GETTING_ADAPTOR_APPROVAL_REQUEST_DETAILS:
    'BackendUserMessages.GETTING_ADAPTOR_APPROVAL_REQUEST_DETAILS',
  GETTING_ADAPTOR_APPROVAL_REQUEST:
    'BackendUserMessages.GETTING_ADAPTOR_APPROVAL_REQUEST',
  PROCESS_ARE_RUNNING_FOR_ADAPTOR:
    'BackendUserMessages.PROCESS_ARE_RUNNING_FOR_ADAPTOR',
  INVALID_REQUEST_TYPE: 'BackendUserMessages.INVALID_REQUEST_TYPE',
  UNAUTHORIZED_TO_COMPLETE_APPROVAL_REQUEST:
    'BackendUserMessages.UNAUTHORIZED_TO_COMPLETE_APPROVAL_REQUEST',
  CONNECTION_SUCCESS_INVALID_BUCKET:
    'BackendUserMessages.CONNECTION_SUCCESS_INVALID_BUCKET',
  CONNECTION_SUCCESSFUL_VALID_BUCKET:
    'BackendUserMessages.CONNECTION_SUCCESSFUL_VALID_BUCKET',
  FETCH_COLUMNS_FAILED_S3: 'BackendUserMessages.FETCH_COLUMNS_FAILED_S3',
  NO_FILE_FORMAT: 'BackendUserMessages.NO_FILE_FORMAT',
  NO_FILE_FOUND: 'BackendUserMessages.NO_FILE_FOUND',
  NO_FILE_NAME_GIVEN: 'BackendUserMessages.NO_FILE_NAME_GIVEN',
  MISMATCHING_FILE_FORMATS_FOUND:
    'BackendUserMessages.MISMATCHING_FILE_FORMATS_FOUND',
  SHEET_NOT_FOUND: 'BackendUserMessages.SHEET_NOT_FOUND',
  FAILED_TO_READ: 'BackendUserMessages.FAILED_TO_READ',
  COULD_NOT_RETRIEVE_FILE: 'BackendUserMessages.COULD_NOT_RETRIEVE_FILE',
  NO_TABLE_OR_CUSTOM_QUERY_FOUND:
    'BackendUserMessages.NO_TABLE_OR_CUSTOM_QUERY_FOUND',
  UNABLE_TO_PREVIEW_DATA: 'BackendUserMessages.UNABLE_TO_PREVIEW_DATA',
  FTP_NOT_IMPLEMENTED: 'BackendUserMessages.FTP_NOT_IMPLEMENTED',
  INVALID_ADAPTOR_DETAILS: 'BackendUserMessages.INVALID_ADAPTOR_DETAILS',
  CLUSTER_IS_DOWN: 'BackendUserMessages.CLUSTER_IS_DOWN',
  SOME_ERROR_OCCURRED_UNHANDLED_EXCEPTION:
    'BackendUserMessages.SOME_ERROR_OCCURRED_UNHANDLED_EXCEPTION',
  NOT_AUTHORIZED_FOR: 'BackendUserMessages.NOT_AUTHORIZED_FOR',
  FETCH_DATA_SUCCESSFUL: 'BackendUserMessages.FETCH_DATA_SUCCESSFUL',
  FILE_SIZE_ERROR: 'BackendUserMessages.FILE_SIZE_ERROR',
  FETCHING_USERS_LIST_ERROR: 'BackendUserMessages.FETCHING_USERS_LIST_ERROR',
  FETCHING_USER_CLUSTER_MAPPING_ERROR:
    'BackendUserMessages.FETCHING_USER_CLUSTER_MAPPING_ERROR',
  UNAUTHORIZED_USER_ERROR: 'BackendUserMessages.UNAUTHORIZED_USER_ERROR',
  APPROVAL_REQUEST_APPROVED: 'BackendUserMessages.APPROVAL_REQUEST_APPROVED',
  APPROVAL_REQUEST_REJECTED: 'BackendUserMessages.APPROVAL_REQUEST_REJECTED',
  APPROVAL_REQUEST_DELETED: 'BackendUserMessages.APPROVAL_REQUEST_DELETED',
  CURRENTLY_RUNNING_PROCESSES:
    'BackendUserMessages.CURRENTLY_RUNNING_PROCESSES',
  DUPLICATE_CATEGORY_NAME: 'BackendUserMessages.DUPLICATE_CATEGORY_NAME',
  CAN_NOT_GET_PROCESS_TYPE: 'BackendUserMessages.CAN_NOT_GET_PROCESS_TYPE',
  APPROVAL_REQUEST_STATUS: 'BackendUserMessages.APPROVAL_REQUEST_STATUS',
  APPROVAL_REQUEST_OF_ALREADY_DELETED_ADAPTOR:
    'BackendUserMessages.APPROVAL_REQUEST_OF_ALREADY_DELETED_ADAPTOR',
  NO_COLUMN_NAME_FOUND_IN_DD_TABLE:
    'BackendUserMessages.NO_COLUMN_NAME_FOUND_IN_DD_TABLE',
  INCOMPLETE_DETAILS_FOR_ASYNC_JOB_ID:
    'BackendUserMessages.INCOMPLETE_DETAILS_FOR_ASYNC_JOB_ID',
  PROCESS_TYPE_WITH_NAME_ALREADY_EXISTS_INGESTION:
    'BackendUserMessages.PROCESS_TYPE_WITH_NAME_ALREADY_EXISTS_INGESTION',
  PROCESS_TYPE_WITH_NAME_ALREADY_EXISTS_EXTRACTION:
    'BackendUserMessages.PROCESS_TYPE_WITH_NAME_ALREADY_EXISTS_EXTRACTION',
  RECORD_CANNOT_BE_DELETED_AS_IT_IS_BEING_USED_IN_REFERENCE_TABLE:
    'BackendUserMessages.RECORD_CANNOT_BE_DELETED_AS_IT_IS_BEING_USED_IN_REFERENCE_TABLE',
  SHEET_CONTAINS_INCORRECT_COLUMN_NAMES:
    'BackendUserMessages.SHEET_CONTAINS_INCORRECT_COLUMN_NAMES',
  PM_PROCESS_DOES_NOT_EXISTS: 'BackendUserMessages.PM_PROCESS_DOES_NOT_EXISTS',
  SOME_ERROR_OCCURED_WHILE_INSERTING_PM_PARAMETERS:
    'BackendUserMessages.SOME_ERROR_OCCURED_WHILE_INSERTING_PM_PARAMETERS',
  EMPTY_PM_PROCESS_NAME: 'BackendUserMessages.EMPTY_PM_PROCESS_NAME',
  PM_PARAMETERS_CANNOT_BE_EMPTY:
    'BackendUserMessages.PM_PARAMETERS_CANNOT_BE_EMPTY',
  INCORRECT_ACTION_VALUE_PROVIDED_FOR_PM_PARAMETERS:
    'BackendUserMessages.INCORRECT_ACTION_VALUE_PROVIDED_FOR_PM_PARAMETERS',
  INCORRECT_IS_MANDATORY_VALUE_PROVIDED_FOR_PM_PARAMETERS:
    'BackendUserMessages.INCORRECT_IS_MANDATORY_VALUE_PROVIDED_FOR_PM_PARAMETERS',
  PM_PARAMETERS_ALREADY_EXISTS_FOR_ADD:
    'BackendUserMessages.PM_PARAMETERS_ALREADY_EXISTS_FOR_ADD',
  PM_PARAMETERS_ALREADY_EXISTS_FOR_DELETE:
    'BackendUserMessages.PM_PARAMETERS_ALREADY_EXISTS_FOR_DELETE',
  PM_PARAMETERS_DOES_NOT_EXISTS:
    'BackendUserMessages.PM_PARAMETERS_DOES_NOT_EXISTS',
  EMPTY_PM_PROCESS_TEMPLATE_PROVIDED:
    'BackendUserMessages.EMPTY_PM_PROCESS_TEMPLATE_PROVIDED',
  FAILED_TO_FETCH_LATEST_PROCESS_PARAMETERS_BY_CYCLE_ID:
    'BackendUserMessages.FAILED_TO_FETCH_LATEST_PROCESS_PARAMETERS_BY_CYCLE_ID',
  SHEET_CONTAINS_MISSING_COLUMN_NAMES:
    'BackendUserMessages.SHEET_CONTAINS_MISSING_COLUMN_NAMES',
  MISSING_ACTION_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.MISSING_ACTION_VALUE_FOR_PM_PARAMETERS',
  PM_PARAMETERS_ALREADY_EXISTS_FOR_EDIT:
    'BackendUserMessages.PM_PARAMETERS_ALREADY_EXISTS_FOR_EDIT',
  INVALID_PM_PARAMETER: 'BackendUserMessages.INVALID_PM_PARAMETER',
  INVALID_VARIABLE_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.INVALID_VARIABLE_VALUE_FOR_PM_PARAMETERS',
  INVALID_LOOKUP_VARIABLE_FORMAT_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.INVALID_LOOKUP_VARIABLE_FORMAT_VALUE_FOR_PM_PARAMETERS',
  INVALID_DATE_FORMAT_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.INVALID_DATE_FORMAT_VALUE_FOR_PM_PARAMETERS',
  INVALID_DATA_TYPE_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.INVALID_DATA_TYPE_VALUE_FOR_PM_PARAMETERS',
  INVALID_DEFAULT_VALUE_FOR_PM_PARAMETERS:
    'BackendUserMessages.INVALID_DEFAULT_VALUE_FOR_PM_PARAMETERS',
  SOME_ERROR_WHILE_FETCHING_DASHBOARD_MATCH:
    'BackendUserMessages.SOME_ERROR_WHILE_FETCHING_DASHBOARD_MATCH',
  INVALID_FILTER_PROVIDED_FOR_DASHBOARD_MATCH:
    'BackendUserMessages.INVALID_FILTER_PROVIDED_FOR_DASHBOARD_MATCH',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_DASHBOARD_STATS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_DASHBOARD_STATS',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_ACTIVITY_LOG_FOR_DASHBOARD:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_ACTIVITY_LOG_FOR_DASHBOARD',
  SOME_ERROR_OCCURRED_WHILE_ADDING_PM_PROCESS_MAPPING:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_ADDING_PM_PROCESS_MAPPING',
  SOME_ERROR_OCCURRED_WHILE_DELETING_PM_PROCESS_MAPPING:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DELETING_PM_PROCESS_MAPPING',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_PM_PROCESS_MAPPING:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_PM_PROCESS_MAPPING',
  ERROR_MERGING_FILE: 'BackendUserMessages.ERROR_MERGING_FILE',
  ERROR_UNMERGING_FILE: 'BackendUserMessages.ERROR_UNMERGING_FILE',
  ERROR_FETCHING_SUGGESTED_MATCHES_FOR_PROFILE:
    'BackendUserMessages.ERROR_FETCHING_SUGGESTED_MATCHES_FOR_PROFILE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_ACTIVITY_LOG_FOR_PROFILE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_ACTIVITY_LOG_FOR_PROFILE',
  SOME_ERROR_OCCURED_WHILE_GETTING_PROFILE_INFORMATION:
    'BackendUserMessages.SOME_ERROR_OCCURED_WHILE_GETTING_PROFILE_INFORMATION',
  SOME_ERROR_OCCURED_WHILE_SEARCHING_PROFILE:
    'BackendUserMessages.SOME_ERROR_OCCURED_WHILE_SEARCHING_PROFILE',
  SOME_ERROR_OCCURRED_WHILE_ADVANCE_SEARCH:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_ADVANCE_SEARCH',
  ERROR_WHILE_SCRAPPING_DATA: 'BackendUserMessages.ERROR_WHILE_SCRAPPING_DATA',
  DATA_REFRESH_IN_PROGRESS_APPLICATION_IN_VIEW_MODE_ONLY:
    'BackendUserMessages.DATA_REFRESH_IN_PROGRESS_APPLICATION_IN_VIEW_MODE_ONLY',
  SOME_ERROR_OCCURED_WHILE_FETCHING_DATA_REFRESH_STATUS:
    'BackendUserMessages.SOME_ERROR_OCCURED_WHILE_FETCHING_DATA_REFRESH_STATUS',
  MDM_NOT_CONFIGURED: 'BackendUserMessages.MDM_NOT_CONFIGURED',
  INVALID_CONNECTION_TYPE: 'BackendUserMessages.INVALID_CONNECTION_TYPE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_STATS_PROFILE_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_STATS_PROFILE_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_DESK_RESEARCH:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DESK_RESEARCH',
  PM_PARAMETERS_LIMIT_EXCEEDED:
    'BackendUserMessages.PM_PARAMETERS_LIMIT_EXCEEDED',
  PM_PARAMETER_NAME_LENGTH_LIMIT_EXCEEDED:
    'BackendUserMessages.PM_PARAMETER_NAME_LENGTH_LIMIT_EXCEEDED',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_PROFILE_NAME:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_PROFILE_NAME',
  MDM_IDS_DOES_NOT_EXISTS: 'BackendUserMessages.MDM_IDS_DOES_NOT_EXISTS',
  UNAUTHORIZED_TO_USE_ADVANCED_SEARCH_FEATURE:
    'BackendUserMessages.UNAUTHORIZED_TO_USE_ADVANCED_SEARCH_FEATURE',
  SOME_ERROR_OCCURRED_WHILE_EXECUTING_PROFILE_COMPARISON:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_EXECUTING_PROFILE_COMPARISON',
  FAILED_TO_DELETE_PROCESS: 'BackendUserMessages.FAILED_TO_DELETE_PROCESS',
  PM_PROCESS_MAPPING_EXISTS: 'BackendUserMessages.PM_PROCESS_MAPPING_EXISTS',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_INGESTIONS_MAPPED_WITH_PM_PROCESS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_INGESTIONS_MAPPED_WITH_PM_PROCESS',
  UNABLE_TO_FETCH_CYCLE: 'BackendUserMessages.UNABLE_TO_FETCH_CYCLE',
  UNABLE_TO_FETCH_CYCLES: 'BackendUserMessages.UNABLE_TO_FETCH_CYCLES',
  UNABLE_TO_FETCH_PROCESS_VERSION_IDS_ERROR_MESSAGE:
    'BackendUserMessages.UNABLE_TO_FETCH_PROCESS_VERSION_IDS_ERROR_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_DEFAULT_VALUE_FOR_PARAMETER:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_DEFAULT_VALUE_FOR_PARAMETER',
  INVALID_DD_DISPLAY_NAME: 'BackendUserMessages.INVALID_DD_DISPLAY_NAME',
  UNAUTHORIZED_TO_EXECUTE_PM_PROCESS:
    'BackendUserMessages.UNAUTHORIZED_TO_EXECUTE_PM_PROCESS',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_ADAPTORS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_ADAPTORS',
  ADAPTOR_ACCESS_DENIED_FOR_INGESTION_RUN:
    'BackendUserMessages.ADAPTOR_ACCESS_DENIED_FOR_INGESTION_RUN',
  ADAPTOR_ACCESS_DENIED_FOR_EXTRACTION_RUN:
    'BackendUserMessages.ADAPTOR_ACCESS_DENIED_FOR_EXTRACTION_RUN',
  TARGET_SYSTEM_ACCESS_DENIED_FOR_INGESTION_RUN:
    'BackendUserMessages.TARGET_SYSTEM_ACCESS_DENIED_FOR_INGESTION_RUN',
  TARGET_SYSTEM_ACCESS_DENIED_FOR_EXTRACTION_RUN:
    'BackendUserMessages.TARGET_SYSTEM_ACCESS_DENIED_FOR_EXTRACTION_RUN',
  SOME_ERROR_OCCURRED_FETCHING_DETAILS_WITH_PROCESS_ID:
    'BackendUserMessages.SOME_ERROR_OCCURRED_FETCHING_DETAILS_WITH_PROCESS_ID',
  TIME_PERIOD_NECESSARY_FOR_DRIVER_DATA_OBJECT:
    'BackendUserMessages.TIME_PERIOD_NECESSARY_FOR_DRIVER_DATA_OBJECT',
  FAILED_TO_FETCH_TIMEPERIOD_MAPPING:
    'BackendUserMessages.FAILED_TO_FETCH_TIMEPERIOD_MAPPING',
  PERMISSION_DENIED_ON_TABLE: 'BackendUserMessages.PERMISSION_DENIED_ON_TABLE',
  PERMISSION_DENIED_ON_SCHEMA:
    'BackendUserMessages.PERMISSION_DENIED_ON_SCHEMA',
  WRITE_PERMISSION_DENIED_ON_TABLE:
    'BackendUserMessages.WRITE_PERMISSION_DENIED_ON_TABLE',
  TIME_PERIOD_ALREADY_ARCHIVED:
    'BackendUserMessages.TIME_PERIOD_ALREADY_ARCHIVED',
  MISSING_FOREIGN_KEY: 'BackendUserMessages.MISSING_FOREIGN_KEY',
  REFERRED_COLUMN_DATA_TYPE_MISMATCH:
    'BackendUserMessages.REFERRED_COLUMN_DATA_TYPE_MISMATCH',
  FOREIGN_KEY_COLUMN_DATA_TYPE_MISMATCH:
    'BackendUserMessages.FOREIGN_KEY_COLUMN_DATA_TYPE_MISMATCH',
  NO_ACCESS_TO_PUBLISH_NEW_VERSION:
    'BackendUserMessages.NO_ACCESS_TO_PUBLISH_NEW_VERSION',
  ERROR_OCCURRED_IN_HCO_HIERARCHY:
    'BackendUserMessages.ERROR_OCCURRED_IN_HCO_HIERARCHY',
  ERROR_OCCURRED_IN_HCP_HIERARCHY:
    'BackendUserMessages.ERROR_OCCURRED_IN_HCP_HIERARCHY',
  ERROR_OCCURRED_IN_HCO_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_IN_HCO_DETAILS',
  ERROR_OCCURRED_IN_HCP_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_IN_HCP_DETAILS',
  UNAUTHORIZED_TO_FETCH_AFFILIATION_DETAILS:
    'BackendUserMessages.UNAUTHORIZED_TO_FETCH_AFFILIATION_DETAILS',
  ERROR_IN_GETTING_ETY_TYPE: 'BackendUserMessages.ERROR_IN_GETTING_ETY_TYPE',
  SOME_ERROR_OCCURRED_WHILE_PROCESSING_ADAPTOR_REQUEST:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_PROCESSING_ADAPTOR_REQUEST',
  FETCHING_USER_ENVIRONMENT_MAPPING_ERROR:
    'BackendUserMessages.FETCHING_USER_ENVIRONMENT_MAPPING_ERROR',
  FAILED_TO_CONNECT_TO_MYSQL: 'BackendUserMessages.FAILED_TO_CONNECT_TO_MYSQL',
  FAILED_TO_LIST_MYSQL_TABLES: 'BackendUserMessages.FAILED_TO_LIST_TABLES',
  FAILED_TO_LIST_MYSQL_DATABASE:
    'BackendUserMessages.FAILED_TO_LIST_MYSQL_DATABASE',
  FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT:
    'BackendUserMessages.FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT',
  FAILED_TO_ESTABLISH_CONNECTION_WITH_POSTGRES:
    'BackendUserMessages.FAILED_TO_ESTABLISH_CONNECTION_WITH_POSTGRES',
  FAILED_INVALID_REQUEST_DETAILS:
    'BackendUserMessages.FAILED_INVALID_REQUEST_DETAILS',
  INVALID_DETAILS: 'BackendUserMessages.INVALID_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_UPDATING_ENVIRONMENT_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_UPDATING_ENVIRONMENT_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_SAVING_ENVIRONMENT_DETAILS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_SAVING_ENVIRONMENT_DETAILS',
  SOME_ERROR_OCCURRED_WHILE_DELETING_ENVIRONMENT_MESSAGE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_DELETING_ENVIRONMENT_MESSAGE',
  UNABLE_TO_FETCH_EXCEL_FILE_PATH:
    'BackendUserMessages.UNABLE_TO_FETCH_EXCEL_FILE_PATH',
  FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_SCHEMAS:
    'BackendUserMessages.FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_SCHEMAS',
  FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_TABLES:
    'BackendUserMessages.FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_TABLES',
  FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_DATABASE:
    'BackendUserMessages.FAILED_TO_ESTABLISH_CONNECTION_WITH_REDSHIFT_WHILE_LISTING_DATABASE',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_FlOWS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_FlOWS',
  PM_FLOW_NAME_NOT_PROVIDED: 'BackendUserMessages.PM_FLOW_NAME_NOT_PROVIDED',
  FAILED_TO_UPDATE_FLOW_NAME: 'BackendUserMessages.FAILED_TO_UPDATE_FLOW_NAME',
  SOME_ERROR_OCCURRED_WHILE_GETTING_ADAPTOR:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_GETTING_ADAPTOR',
  INVALID_FLOW_ID_PROVIDED: 'BackendUserMessages.INVALID_FLOW_ID_PROVIDED',
  FAILED_TO_GET_FLOW_NAME: 'BackendUserMessages.FAILED_TO_GET_FLOW_NAME',
  ERROR_OCCURRED_WHILE_GETTING_MDM_PROFILE_DATA:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_MDM_PROFILE_DATA',
  ERROR_OCCURRED_WHILE_GETTING_MDM_PROFILE_NAMES:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_MDM_PROFILE_NAMES',
  FAILED_TO_GET_PM_PROCESS_DETAILS:
    'BackendUserMessages.FAILED_TO_GET_PM_PROCESS_DETAILS',
  ERROR_OCCURRED_WHILE_VALIDATING_PROCESS_REQUEST:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_VALIDATING_PROCESS_REQUEST',
  PM_PROCESS_NAME_ALREADY_EXISTS:
    'BackendUserMessages.PM_PROCESS_NAME_ALREADY_EXISTS',
  PM_FLOW_NAME_ALREADY_EXISTS:
    'BackendUserMessages.PM_FLOW_NAME_ALREADY_EXISTS',
  INVALID_FLOW_NAME_PROVIDED: 'BackendUserMessages.INVALID_FLOW_NAME_PROVIDED',
  PM_PROCESS_NAME_NOT_PROVIDED:
    'BackendUserMessages.PM_PROCESS_NAME_NOT_PROVIDED',
  ERROR_OCCURRED_WHILE_UPLOADING_FILE_TO_MWAA_S3:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_UPLOADING_FILE_TO_MWAA_S3',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_DATA_VISUALIZATION:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_DATA_VISUALIZATION',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_DATA_SUMMARY:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_DATA_SUMMARY',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_AUTO_CLASSIFY_TABLE:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_AUTO_CLASSIFY_TABLE',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_PREVIEW:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_PREVIEW',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_METRICS:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_METRICS',
  BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_PROCESS_ANALYSIS:
    'BackendUserMessages.BAD_DATA_PROVIDED_WHILE_EXTRACTING_IDATA_PROCESS_ANALYSIS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_JOB_STATUS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_JOB_STATUS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_LOGS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_LOGS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_CREATORS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_CREATORS',
  INVALID_DATA_PROVIDED_WHILE_STARTING_RECURRENCE:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_STARTING_RECURRENCE',
  INVALID_DATA_PROVIDED_WHILE_STOPPING_RECURRENCE:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_STOPPING_RECURRENCE',
  INVALID_DATA_PROVIDED_WHILE_VALIDATING_TABLE_NAME:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_VALIDATING_TABLE_NAME',
  INVALID_DATA_PROVIDED_WHILE_GETTING_ERROR_MESSAGES_FOR_PROCESSES:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_ERROR_MESSAGES_FOR_PROCESSES',
  INVALID_DATA_PROVIDED_WHILE_GETTING_RECENT_PROCESSES:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_RECENT_PROCESSES',
  INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_STATUS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_STATUS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_RUNS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_PROCESS_RUNS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_DATA_CENTER_DETAIL:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_DATA_CENTER_DETAIL',
  INVALID_DATA_PROVIDED_WHILE_GETTING_SMART_ANALYSIS_DETAIL:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_SMART_ANALYSIS_DETAIL',
  INVALID_DATA_PROVIDED_WHILE_VALIDATING_INGESTION_NAME:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_VALIDATING_INGESTION_NAME',
  INCORRECT_TRANSACTION_SCHEMA:
    'BackendUserMessages.INCORRECT_TRANSACTION_SCHEMA',
  INVALID_DATA_PROVIDED_WHILE_CHECKING_FILE_VALIDATION:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_CHECKING_FILE_VALIDATION',
  INVALID_DATA_PROVIDED_WHILE_GETTING_POST_PRESIGNED_URL:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_POST_PRESIGNED_URL',
  SOME_ERROR_OCCURRED_WHILE_TRIGERRING_MWAA_DAG:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_TRIGERRING_MWAA_DAG',
  SOME_ERROR_OCCURRED_WHILE_GETTING_MWAA_RESPONSE:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_GETTING_MWAA_RESPONSE',
  SOME_ERROR_OCCURRED_WHILE_STOPPING_MWAA_DAG:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_STOPPING_MWAA_DAG',
  INVALID_DATA_PROVIDED_WHILE_GETTING_FLOWS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_FLOWS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_FLOWS_STATUSES:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_FLOWS_STATUSES',
  UNABLE_TO_CREATE_PM_PROCESS: 'BackendUserMessages.UNABLE_TO_CREATE_PM_PROCESS',
  UNABLE_TO_CREATE_OR_UPDATE_PM_PROCESS_NAME: 'BackendUserMessages.UNABLE_TO_CREATE_OR_UPDATE_PM_PROCESS_NAME',
  ERROR_OCCURRED_WHILE_INSERTING_PM_PROCESS_BLOCK: 'BackendUserMessages.ERROR_OCCURRED_WHILE_INSERTING_PM_PROCESS_BLOCK',
  ERROR_OCCURRED_WHILE_CREATING_NEW_PARAMETER: 'BackendUserMessages.ERROR_OCCURRED_WHILE_CREATING_NEW_PARAMETER',
  ERROR_OCCURRED_WHILE_EXTRACTING_PM_PROCESS_BLOCK: 'BackendUserMessages.ERROR_OCCURRED_WHILE_EXTRACTING_PM_PROCESS_BLOCK',
  ERROR_OCCURRED_WHILE_EXTRACTING_PM_PARAMETERS: 'BackendUserMessages.ERROR_OCCURRED_WHILE_EXTRACTING_PM_PARAMETERS',
  DQM_CHECKS_FOR_TOP_LEVEL_FILTERS_NOT_SUPPORTED:
    'BackendUserMessages.DQM_CHECKS_FOR_TOP_LEVEL_FILTERS_NOT_SUPPORTED',
  SOME_ERROR_OCCURRED_WHILE_CLEARING_DAG_STATUS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CLEARING_DAG_STATUS',
  NO_WEEKDAY_SELECTED_ERROR: 'BackendUserMessages.NO_WEEKDAY_SELECTED_ERROR',
  ERROR_OCCURRED_WHILE_FETCHING_PROCESS_EXECUTIONS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_PROCESS_EXECUTIONS',
  DELETE_PROCESS_EXECUTION_ERROR:
    'BackendUserMessages.DELETE_PROCESS_EXECUTION_ERROR',
  NO_PM_PROCESS_FOUND: 'BackendUserMessages.NO_PM_PROCESS_FOUND',
  FLOW_CONTAINING_THIS_PROCESS_IS_CURRENTLY_IN_EXECUTION:
    'BackendUserMessages.FLOW_CONTAINING_THIS_PROCESS_IS_CURRENTLY_IN_EXECUTION',
  PROCESS_CANNOT_BE_DELETED_AS_IT_IS_USED_INSIDE_A_FLOW:
    'BackendUserMessages.PROCESS_CANNOT_BE_DELETED_AS_IT_IS_USED_INSIDE_A_FLOW',
  ERROR_OCCURRED_WHILE_GETTING_ERROR_LOG:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_ERROR_LOG',
  ERROR_OCCURRED_WHILE_GETTING_VIEW_ERROR:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_VIEW_ERROR',
  ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_DETAILS',
  ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_STATUS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_STATUS',
  ERROR_OCCURRED_WHILE_GETTING_LOG:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_LOG',
  ERROR_OCCURRED_WHILE_GETTING_PROCESS_RUN_INFO:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_PROCESS_RUN_INFO',
  ERROR_OCCURRED_WHILE_DELETING_PROCESS_DAG_FOLDER_ON_MWAA_S3:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_DELETING_PROCESS_DAG_FOLDER_ON_MWAA_S3',
  ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_TABLES_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_PROCESS_EXECUTION_BLOCK_TABLES_DETAILS',
  ERROR_OCCURRED_WHILE_GETTING_PARAMETERS_FOR_PROCESS_BLOCK:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_PARAMETERS_FOR_PROCESS_BLOCK',
  INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS_BY_USERS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS_BY_USERS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS_STATUSES:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_FLOW_EXECUTIONS_STATUSES',
  ERROR_OCCURRED_WHILE_FETCHING_FLOW_EXECUTIONS_BY_USERS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_FLOW_EXECUTIONS_BY_USERS',
  ERROR_OCCURRED_WHILE_FETCHING_FLOW_EXECUTIONS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_FLOW_EXECUTIONS',
  PROCESS_EXECUTION_ERROR: 'BackendUserMessages.PROCESS_EXECUTION_ERROR',
  ERROR_OCCURRED_WHILE_UPDATING_THE_CONNECTED_FLOWS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_UPDATING_THE_CONNECTED_FLOWS',
  ERROR_SAVING_CUSTOM_SCRIPT: 'BackendUserMessages.ERROR_SAVING_CUSTOM_SCRIPT',
  ERROR_GENERATING_PRE_SIGNED_URL_FOR_CUSTOM_SCRIPT_UPLOAD:
    'BackendUserMessages.ERROR_GENERATING_PRE_SIGNED_URL_FOR_CUSTOM_SCRIPT_UPLOAD',
  ERROR_RUNNING_CUSTOM_SCRIPT:
    'BackendUserMessages.ERROR_RUNNING_CUSTOM_SCRIPT',
  CLUSTER_IS_NOT_ACTIVE: 'BackendUserMessages.CLUSTER_IS_NOT_ACTIVE',
  CLUSTER_IS_NOT_SELECTED: 'BackendUserMessages.CLUSTER_IS_NOT_SELECTED',
  CAN_NOT_RESTART_THE_PROCESS_DUE_TO_PROCESS_RUN_NOT_ON_LATEST_VERSION:
    'BackendUserMessages.CAN_NOT_RESTART_THE_PROCESS_DUE_TO_PROCESS_RUN_NOT_ON_LATEST_VERSION',
  SOME_ERROR_OCCURRED_WHILE_RESTARTING_THE_PROCESS:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_RESTARTING_THE_PROCESS',
  CAN_NOT_RESTART_THE_PROCESS_AS_THE_PROCESS_IS_IN_RUNNING_STATE:
    'BackendUserMessages.CAN_NOT_RESTART_THE_PROCESS_AS_THE_PROCESS_IS_IN_RUNNING_STATE',
  ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_ID:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_CLUSTER_ID',
  ERROR_OCCURRED_WHILE_CANCELLING_EMR_STEPS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_CANCELLING_EMR_STEPS',
  NO_RECORD_FOUND_FOR_REQUESTED_FLOW_RUN:
    'BackendUserMessages.NO_RECORD_FOUND_FOR_REQUESTED_FLOW_RUN',
  SOME_ERROR_OCCURRED_WHILE_GETTING_RIBBON_DETAILS_OF_FLOW_RUN:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_GETTING_RIBBON_DETAILS_OF_FLOW_RUN',
  INVALID_FLOW_ID_PROVIDED_WHILE_DELETING_FLOWS:
    'BackendUserMessages.INVALID_FLOW_ID_PROVIDED_WHILE_DELETING_FLOWS',
  AN_INSTANCE_IS_ALREADY_IN_EXECUTION:
    'BackendUserMessages.AN_INSTANCE_IS_ALREADY_IN_EXECUTION',
  ERROR_OCCURRED_WHILE_DELETING_FLOW_DAG_FOLDER_ON_MWAA_S3:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_DELETING_FLOW_DAG_FOLDER_ON_MWAA_S3',
  SOME_ERROR_OCCURRED_WHILE_CLONING_FLOW:
    'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_CLONING_FLOW',
  ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_CARDS_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_CARDS_DETAILS',
  ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_CARDS_DETAILS_STATUS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_CARDS_DETAILS_STATUS',
  ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_TABLE_VIEW_DETAILS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_TABLE_VIEW_DETAILS',
  ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_TABLE_VIEW_DETAILS_STATUS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_GETTING_FLOW_RUN_TABLE_VIEW_DETAILS_STATUS',
  INVALID_DATA_PROVIDED_WHILE_FETCHING_FLOW_TABLE_VIEW_DETAILS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_FETCHING_FLOW_TABLE_VIEW_DETAILS',
  INVALID_DATA_PROVIDED_WHILE_FETCHING_FLOW_TABLE_VIEW_DETAILS_STATUSES:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_FETCHING_FLOW_TABLE_VIEW_DETAILS_STATUSES',
  ERROR_OCCURRED_WHILE_FETCHING_ERROR_LOGS:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_ERROR_LOGS',
  ERROR_OCCURRED_WHILE_SAVING_RCA_AND_ETA:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_SAVING_RCA_AND_ETA',
  ERROR_OCCURRED_WHILE_FETCHING_PROCESS_IDS_FROM_FLOW_ID:
    'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_PROCESS_IDS_FROM_FLOW_ID',
  INVALID_DATA_PROVIDED_WHILE_GETTING_ERROR_LOG_DETAILS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_ERROR_LOG_DETAILS',
  INVALID_DATA_PROVIDED_WHILE_GETTING_RCA_ETA_DETAILS:
    'BackendUserMessages.INVALID_DATA_PROVIDED_WHILE_GETTING_RCA_ETA_DETAILS',
  NO_FLOW_FOUND: 'BackendUserMessages.NO_FLOW_FOUND',
  FLOW_IS_CURRENTLY_IN_EXECUTION: 'BackendUserMessages.FLOW_IS_CURRENTLY_IN_EXECUTION',
  PM_FLOW_LINKED_TO_INGESTION: 'BackendUserMessages.PM_FLOW_LINKED_TO_INGESTION',
  FAILED_TO_FETCH_CUSTOM_SCRIPT_DETAILS: 'BackendUserMessages.FAILED_TO_FETCH_CUSTOM_SCRIPT_DETAILS',
  ERROR_GENERATING_PRE_SIGNED_URL_FOR_DOWNLOAD: 'BackendUserMessages.ERROR_GENERATING_PRE_SIGNED_URL_FOR_DOWNLOAD',
  ERROR_GENERATING_PRE_SIGNED_URL_FOR_UPLOAD: 'BackendUserMessages.ERROR_GENERATING_PRE_SIGNED_URL_FOR_UPLOAD',
  FAILED_TO_RUN_PM_FLOW: 'BackendUserMessages.FAILED_TO_RUN_PM_FLOW',
  CAN_NOT_RESTART_THE_FLOW_AS_THE_FLOW_IS_IN_RUNNING_STATE: 'BackendUserMessages.CAN_NOT_RESTART_THE_FLOW_AS_THE_FLOW_IS_IN_RUNNING_STATE',
  SOME_ERROR_OCCURRED_WHILE_RESTARTING_THE_FLOW: 'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_RESTARTING_THE_FLOW',
  ERROR_GENERATING_PRE_SIGNED_URL_FOR_VIRTUAL_ENVIRONMENT_UPLOAD: 'BackendUserMessages.ERROR_GENERATING_PRE_SIGNED_URL_FOR_VIRTUAL_ENVIRONMENT_UPLOAD',
  ERROR_CREATING_VIRTUAL_ENVIRONMENT: 'BackendUserMessages.ERROR_CREATING_VIRTUAL_ENVIRONMENT',
  ERROR_VALIDATING_VIRTUAL_ENVIRONMENT: 'BackendUserMessages.ERROR_VALIDATING_VIRTUAL_ENVIRONMENT',
  ERROR_FETCHING_VIRTUAL_ENVIRONMENTS: 'BackendUserMessages.ERROR_FETCHING_VIRTUAL_ENVIRONMENTS',
  ERROR_FETCHING_PACKAGES_OF_VIRTUAL_ENVIRONMENT: 'BackendUserMessages.ERROR_FETCHING_PACKAGES_OF_VIRTUAL_ENVIRONMENT',
  ERROR_DELETING_VIRTUAL_ENVIRONMENT: 'BackendUserMessages.ERROR_DELETING_VIRTUAL_ENVIRONMENT',
  ERROR_DOWNLOADING_SAMPLE_REQUIREMENTS_FILE: 'BackendUserMessages.ERROR_DOWNLOADING_SAMPLE_REQUIREMENTS_FILE',
  ERROR_UPDATING_VIRTUAL_ENVIRONMENT: 'BackendUserMessages.ERROR_UPDATING_VIRTUAL_ENVIRONMENT',
  ERROR_GENERATING_PRE_SIGNED_URL_FOR_VIRTUAL_ENVIRONMENT_DOWNLOAD: 'BackendUserMessages.ERROR_GENERATING_PRE_SIGNED_URL_FOR_VIRTUAL_ENVIRONMENT_DOWNLOAD',
  ERROR_RESTORING_THE_ORIGINAL_VIRTUAL_ENVIRONMENT_FILE: 'BackendUserMessages.ERROR_RESTORING_THE_ORIGINAL_VIRTUAL_ENVIRONMENT_FILE',
  ERROR_DUPLICATE_VIRTUAL_ENVIRONMENT_NAME: 'BackendUserMessages.ERROR_DUPLICATE_VIRTUAL_ENVIRONMENT_NAME',
  ERROR_VIRTUAL_ENVIRONMENT_IN_USE: 'BackendUserMessages.ERROR_VIRTUAL_ENVIRONMENT_IN_USE',
  PROCESS_IN_RUNNING_STATE_ERROR: 'BackendUserMessages.PROCESS_IN_RUNNING_STATE_ERROR',
  COMPONENT_CANNOT_BE_DELETED_AS_IT_IS_USED_INSIDE_A_PROCESS: 'BackendUserMessages.COMPONENT_CANNOT_BE_DELETED_AS_IT_IS_USED_INSIDE_A_PROCESS',
  FAILED_TO_FETCH_VIRTUAL_ENVIRONMENT_ID: 'BackendUserMessages.FAILED_TO_FETCH_VIRTUAL_ENVIRONMENT_ID',
  FAILED_TO_VALIDATE_DUPLICATE_COMPONENT_NAME: 'BackendUserMessages.FAILED_TO_VALIDATE_DUPLICATE_COMPONENT_NAME',
  DUPLICATE_COMPONENT_NAME: 'BackendUserMessages.DUPLICATE_COMPONENT_NAME',
  NO_PROCESS_PAGE_FOUND: 'BackendUserMessages.NO_PROCESS_PAGE_FOUND',
  ERROR_CLUSTER_IS_NOT_ACTIVE:
    'BackendUserMessages.ERROR_CLUSTER_IS_NOT_ACTIVE',
  FLOW_COMPILATION_IN_PROGRESS: 'BackendUserMessages.FLOW_COMPILATION_IN_PROGRESS',
  PROCESS_COMPILATION_IN_PROGRESS: 'BackendUserMessages.PROCESS_COMPILATION_IN_PROGRESS',
  PROCESS_HAS_NO_NODES: 'BackendUserMessages.PROCESS_HAS_NO_NODES',
  FLOW_HAS_NO_NODES: 'BackendUserMessages.FLOW_HAS_NO_NODES',
  ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_EXECUTIONS:
  'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_EXECUTIONS',
  ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_EXECUTIONS_USERS:
  'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_EXECUTIONS_USERS',
  TABLE_NAME_NOT_ALLOWED: 'BackendUserMessages.TABLE_NAME_NOT_ALLOWED',
  DELETE_PROCESS_IN_RUNNING_STATE: 'BackendUserMessages.DELETE_PROCESS_IN_RUNNING_STATE',
  ERROR_VALIDATING_VIRTUAL_ENVIRONMENT_FILE_CONTENT: 'BackendUserMessages.ERROR_VALIDATING_VIRTUAL_ENVIRONMENT_FILE_CONTENT',
  ALL_PROCESSES_IN_FLOW_DISABLED: 'BackendUserMessages.ALL_PROCESSES_IN_FLOW_DISABLED',
  LOG_GENERATION_IN_PROGESS: 'BackendUserMessages.LOG_GENERATION_IN_PROGESS',
  ERROR_STOPPING_CUSTOM_SCRIPT: 'BackendUserMessages.ERROR_STOPPING_CUSTOM_SCRIPT',
  SOME_ERROR_OCCURRED_WHILE_FETCHING_PROCESSES: 'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_FETCHING_PROCESSES',
  CUSTOM_SCRIPT_EXECUTION_IN_PROGRESS: 'BackendUserMessages.CUSTOM_SCRIPT_EXECUTION_IN_PROGRESS',
  NO_CUSTOM_SCRIPT_RUNNING: 'BackendUserMessages.NO_CUSTOM_SCRIPT_RUNNING',
  FLOW_PROCESS_VERSION_UPDATED: 'BackendUserMessages.FLOW_PROCESS_VERSION_UPDATED',
  ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_DETAILS: 'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_DETAILS',
  ERROR_OCCURRED_WHILE_CHECKING_IF_CUSTOM_SCRIPT_UPDATED: 'BackendUserMessages.ERROR_OCCURRED_WHILE_CHECKING_IF_CUSTOM_SCRIPT_UPDATED',
  ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_COMPONENT: 'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_CUSTOM_SCRIPT_COMPONENT',
  NO_FLOW_OCCURENCE_FOR_GIVEN_PERIOD: 'BackendUserMessages.NO_FLOW_OCCURENCE_FOR_GIVEN_PERIOD',
  NOT_ENOUGH_TIME_TO_UPDATE_FLOW_BEFORE_NEXT_RUN: 'BackendUserMessages.NOT_ENOUGH_TIME_TO_UPDATE_FLOW_BEFORE_NEXT_RUN',
  RETRYING_FLOW_RUN: 'BackendUserMessages.RETRYING_FLOW_RUN',
  CAN_NOT_CLONE_PROCESS_AS_THE_PROCESS_IS_EMPTY: 'BackendUserMessages.CAN_NOT_CLONE_PROCESS_AS_THE_PROCESS_IS_EMPTY',
  CAN_NOT_CLONE_FLOW_AS_THE_FLOW_IS_EMPTY: 'BackendUserMessages.CAN_NOT_CLONE_FLOW_AS_THE_FLOW_IS_EMPTY',
  FLOW_PROCESS_LINKED_TO_INGESTION_COMPILATION_IN_PROGRESS: 'BackendUserMessages.FLOW_PROCESS_LINKED_TO_INGESTION_COMPILATION_IN_PROGRESS',
  NO_LOGS_AS_FLOW_TRIGGERED_HAVING_NO_PROCESS_RUNS: 'BackendUserMessages.NO_LOGS_AS_FLOW_TRIGGERED_HAVING_NO_PROCESS_RUNS',
  BACKGROUND_FLOW_RUN_ERROR_MESSAGE: 'BackendUserMessages.BACKGROUND_FLOW_RUN_ERROR_MESSAGE',
  SOME_ERROR_OCCURRED_WHILE_GETTING_PROCESS_TEMPLATE: 'BackendUserMessages.SOME_ERROR_OCCURRED_WHILE_GETTING_PROCESS_TEMPLATE',
  ERROR_OCCURRED_WHILE_FETCHING_PM_PROCESS: 'BackendUserMessages.ERROR_OCCURRED_WHILE_FETCHING_PM_PROCESS'
};
