import { Injectable } from '@angular/core';
import { ApiResponseHeaderConstants } from '../constants/api-response-header-constants';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  setCSRFTokenInSessionStorage(xsrfToken) {
    window.sessionStorage.setItem(ApiResponseHeaderConstants.XSRF_TOKEN, xsrfToken);
  }

  getCSRFTokenInSessionStorage() {
    return window.sessionStorage.getItem(ApiResponseHeaderConstants.XSRF_TOKEN);
  }
}
