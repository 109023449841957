import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DomSanitizerConstants } from '../constants/security-attacks-constants';

@Pipe({
  name: 'domSanitizerPipe'
})
export class DomSanitizerPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  public transform(value: any, type: number, action: string): any {
    if (action === DomSanitizerConstants.SANITIZE) {
      return this.sanitizer.sanitize(type, value);
    } else if (action ===  DomSanitizerConstants.BYPASS_SECURITY_TRUST) {
        switch (type) {
          case 1: return this.sanitizer.bypassSecurityTrustHtml(value);
          case 2: return this.sanitizer.bypassSecurityTrustStyle(value);
          case 3: return this.sanitizer.bypassSecurityTrustScript(value);
          case 4: return this.sanitizer.bypassSecurityTrustUrl(value);
          case 5: return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      }
    }
  }

}
