import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CdkDragDrop, CdkDragRelease } from '@angular/cdk/drag-drop';
import {
  ExcludedKeys,
  MDMProfileCommonConstants,
  MDMProfileDOMActionConstants,
  ProfileAPIResponseConstants,
  QueryParams,
} from '../../constants/profile-constants';
import {
  MDMActivityLog,
  MDMCommonConstants,
} from '../../constants/mdm-common-constants';
import { startWith, switchMap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { interval } from 'rxjs';
import { mdm_labels } from '../../constants/ui-translation';
import { url } from '../../constants/api-urls';

@Component({
  selector: 'app-merge-unmerge',
  templateUrl: './merge-unmerge.component.html',
  styleUrls: ['./merge-unmerge.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MergeUnmergeComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  isHco = false;
  id = '';
  loading: boolean;
  golden: any;
  keys: string[];
  excludedKeys: string[] = [
    ExcludedKeys.IS_GOLDEN,
    ExcludedKeys.ADDRESSES,
    ExcludedKeys.COMMUNICATIONS,
    ExcludedKeys.IDENTIFIERS,
    ExcludedKeys.ETY_TYPE,
    ExcludedKeys.ETY_SUB_TYPE,
  ];

  mergeProfiles: any[] = [];
  unmergeProfiles: any[] = [];
  @ViewChild('detailsTable', { read: ElementRef }) detailsTable: ElementRef;
  @ViewChild('mergeUnmergeWrapper') wrapperElement: ElementRef;
  showScroll = false;

  mergeVariableConstant = MDMActivityLog.MERGE;
  unmergeVariableConstant = MDMActivityLog.UNMERGE;

  translated_labels: any;
  commmon_labels = commmon_labels;
  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.MERGE;
  ui_unmerge_labels = mdm_labels.ALL_RECORDS.PROFILE.UNMERGE;
  canNotFindProfilelabel: string;
  mergeAllLabel: string;
  youAreCurrentlyViewingLabel: string;
  suspectedMatchesLabel: string;
  error_label: string;
  success_lable: string;
  successfullyMergedMessage: string;
  notAMatchLabel: string;
  refreshIndicator$: any;
  refreshIndicator = false;
  emitProfileSelectedSubscr: any;
  mdmIdLabel = MDMCommonConstants.MDM_ID + ':';
  mergedLabel: string;
  unmergedSuspectedLabel: string;
  mergedRecords = 0;
  unMergedSuspects = 0;
  successfullyUnMergedMessage: string;
  isMergeSelected = true;
  mdm_urls = url;
  matches: any;

  constructor(
    private service: MDMService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private _translate: TranslateService,
    private _toasterDataService: ToasterDataService,
    private _location: Location
  ) {
    this.translated_labels = this._translate.instant([
      this.ui_labels.CAN_NOT_FIND_PROFILE,
      this.ui_labels.MERGE_ALL,
      this.ui_labels.YOU_ARE_CURRENTLY_VIEWING,
      this.ui_labels.SUSPECTED_MATCHES,
      this.commmon_labels.ERROR_WITH_COLON,
      this.commmon_labels.SUCCESS_WITH_COLON,
      this.ui_labels.SUCCESSFULLY_MERGED,
      this.ui_labels.SUCCESSFUL_NOT_A_MATCH,
      this.ui_labels.UNMERGE,
      this.ui_labels.UNMERGED_SUSPECTED,
      this.ui_labels.MERGED,
      this.ui_unmerge_labels.SUCCESSFULLY_UNMERGED,
    ]);
    this.canNotFindProfilelabel =
      this.translated_labels[this.ui_labels.CAN_NOT_FIND_PROFILE];
    this.mergeAllLabel = this.translated_labels[this.ui_labels.MERGE_ALL];
    this.youAreCurrentlyViewingLabel =
      this.translated_labels[this.ui_labels.YOU_ARE_CURRENTLY_VIEWING];
    this.suspectedMatchesLabel =
      this.translated_labels[this.ui_labels.SUSPECTED_MATCHES];
    this.error_label =
      this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
    this.success_lable =
      this.translated_labels[this.commmon_labels.SUCCESS_WITH_COLON];
    this.successfullyMergedMessage =
      this.translated_labels[this.ui_labels.SUCCESSFULLY_MERGED];
    this.notAMatchLabel =
      this.translated_labels[this.ui_labels.SUCCESSFUL_NOT_A_MATCH];
    this.mergedLabel = this.translated_labels[this.ui_labels.MERGED];
    this.unmergedSuspectedLabel =
      this.translated_labels[this.ui_labels.UNMERGED_SUSPECTED];
    this.successfullyUnMergedMessage =
      this.translated_labels[this.ui_unmerge_labels.SUCCESSFULLY_UNMERGED];
  }

  ngOnInit() {
    this.refreshIndicator$ = interval(10 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.service.getRefreshIndicator())
      )
      .subscribe(
        (res) => {
          this.refreshIndicator = res;
        },
        (exception) => {
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );

    this.isHco =
      window.location.href.indexOf(
        '/' + MDMCommonConstants.HCO.toLocaleLowerCase() + '/'
      ) >= 0;
    this.service.mergeScreenActivated(true);
    this.emitProfileSelectedSubscr =
      this.service.emitProfileSelected$.subscribe((profile) =>
        this.profileSelected(profile)
      );
    this.id = this.route.parent.snapshot.paramMap.get(QueryParams.ID);
    this.loadLineageData();
    this.loadProfileMatches();
  }

  loadProfileMatches() {
    this.mergeProfiles = [];
    this.service
      .getProfileMatchesData(
        this.id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (data) => {
          this.matches = data;
          data.forEach((e) => {
            e.checked = true;
            e.id = {
              [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
              [ProfileAPIResponseConstants.DISPLAY]: '',
              [ProfileAPIResponseConstants.DESC]: '',
              [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
            };
            this.mergeProfiles.push(e);
          });
        },
        (exception) => {
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  loadLineageData() {
    this.loading = true;

    this.service
      .getLineageData(
        this.id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (data: any) => {
          this.loading = false;
          if (data.length > 0) {
            this.golden = data.find(
              (d) => d.IsGolden.value === MDMCommonConstants.Y
            );
            if (this.golden) {
              this.golden.id = {
                [ProfileAPIResponseConstants.VALUE]: this.golden.MDM_ID.value,
                [ProfileAPIResponseConstants.DISPLAY]: '',
                [ProfileAPIResponseConstants.DESC]: '',
                [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
              };

              this.service.setEntitySubType(
                (this.golden.ETY_SUB_TYPE || {}).value || ''
              );
            }

            this.unmergeProfiles = data.filter(
              (d) => d.IsGolden.value === MDMCommonConstants.N
            );
            this.unmergeProfiles.forEach((profile) => {
              profile.id = {
                [ProfileAPIResponseConstants.VALUE]: profile.MDM_ID.value,
                [ProfileAPIResponseConstants.DISPLAY]: '',
                [ProfileAPIResponseConstants.DESC]: '',
                [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
              };
            });
            this.mergedRecords = this.unmergeProfiles.length;
            this.unMergedSuspects = this.mergeProfiles.length;

            this.keys = Object.keys(data[0]).filter(
              (k) => !this.excludedKeys.find((e) => e.indexOf(k) >= 0)
            );

            if (this.golden && this.golden.SRC_NM?.to_display) {
              this.golden.SRC_NM.to_display = MDMCommonConstants.N;
            }
            const srcIdIndex = this.keys.indexOf(
              MDMProfileCommonConstants.SRC_ID
            );
            if (srcIdIndex >= 0) {
              this.keys.splice(srcIdIndex, 1);
              this.keys.unshift(MDMProfileCommonConstants.SRC_ID);
              const temp = this.keys[0];
              this.keys[0] = this.keys[1];
              this.keys[1] = temp;
            }
          }
        },
        (exception) => {
          this.loading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  profileSelected(profile) {
    if (profile.checked) {
      this.mergeProfiles = [profile, ...this.mergeProfiles];
    } else if (this.mergeProfiles.length > 0) {
      const index = this.mergeProfiles.findIndex(
        (m) => m.id.value === profile.profile.id.value
      );
      if (index >= 0) {
        this.mergeProfiles.splice(index, 1);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.renderer.removeClass(
      this.detailsTable.nativeElement,
      MDMProfileDOMActionConstants.HOVERED
    );
    this.matches = this.service.getMatchesWithSearchData().length
      ? this.service.getMatchesWithSearchData()
      : this.matches;
    for (const match of this.matches) {
      if (match.MDM_ID.value === event.item.data.MDM_ID.value) {
        const item = match;
        item.checked = true;
        if (_.findIndex(this.mergeProfiles, item) < 0) {
          this.service.profileSelected(item);
        }
      }
    }
  }

  doMerge(profile: any, all: boolean = false) {
    this.loading = true;
    const allProfiles = all ? [...this.mergeProfiles] : [profile];
    const allIds = all
      ? this.mergeProfiles.map((e) => e.id.value)
      : [profile.id.value];

    this.service
      .postProfileMerge(
        this.id,
        allIds,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (result: any) => {
          this.loading = false;

          if (result && result.success) {
            allProfiles.forEach((el) => {
              this.profileSelected({ added: false, profile: el });
              this.service.profileRemoved(el);
            });

            if (result.masterId) {
              this.id = result.masterId;
            }
            this.service.setProfileBreadcrumID(this.id);
            this.loadLineageData();
            this.refreshTabs(MDMActivityLog.MERGE);
          }
        },
        (exception) => {
          this.loading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  doNotAMatch(profile: any) {
    this.loading = true;

    this.service
      .postProfileNotAMatch(
        this.id,
        profile.id.value,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (result: any) => {
          this.loading = false;

          if (result && result.success) {
            this.profileSelected({ added: false, profile });
            this.service.profileRemoved(profile);

            this.loadLineageData();
            this.refreshTabs();
            this._toasterDataService.addMessageToToaster(
              this.notAMatchLabel,
              ToasterTypeConstants.SUCCESS
            );
          }
        },
        (exception) => {
          this.loading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  doUnmerge(profile: any, all: boolean = false) {
    this.loading = true;
    const allIds = all
      ? this.unmergeProfiles.map((e) => e.id.value)
      : [profile.id.value];

    this.service
      .postProfileUnmerge(
        this.id,
        allIds,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (result: any) => {
          this.loading = false;

          if (result && result.success) {
            if (result.masterId) {
              this.id = result.masterId;
            }
            this.service.setProfileBreadcrumID(this.id);
            this.loadLineageData();
            this.refreshTabs(MDMActivityLog.UNMERGE);
          }
        },
        (exception) => {
          this.loading = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }
  onTabChange(value: string) {
    if (value === this.mergeVariableConstant) {
      this.isMergeSelected = true;
      this.service.mergeScreenActivated(true);
    } else {
      this.service.mergeScreenActivated(false);
      this.isMergeSelected = false;
    }
  }
  refreshTabs(operation = null) {
    if (this.isHco) {
      this.service.refreshAffiliationTab(this.id);
    }
    this.service.refreshActivityTab();
    const profileUrlParts = this._location.path().split('/');
    profileUrlParts[profileUrlParts.length - 2] = this.id;
    const finalProfileUrl = profileUrlParts.join('/');
    this.service.refreshMdmId(this.id);
    this.router.navigate([finalProfileUrl]).then(() => {
      if (operation === MDMActivityLog.UNMERGE) {
        this._toasterDataService.addMessageToToaster(
          this.successfullyUnMergedMessage,
          ToasterTypeConstants.SUCCESS
        );
      } else if (operation === MDMActivityLog.MERGE) {
        this._toasterDataService.addMessageToToaster(
          this.successfullyMergedMessage,
          ToasterTypeConstants.SUCCESS
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.emitProfileSelectedSubscr) {
      this.emitProfileSelectedSubscr.unsubscribe();
    }
    this.refreshIndicator$.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.detailsTable) {
      this.showScroll =
        this.detailsTable.nativeElement.scrollWidth >
        this.detailsTable.nativeElement.clientWidth;
    }
  }
}
