<div appZsLoader [isVisible]="statsLoading" class="stats-class">
  <div class="zs-row">
    <div class="zs-col-md-12">
      <form is="zs-form" class="form-style">
        <fieldset class="select-period">
          <app-multi-select-dropdown
            [dataSource]="chartPeriods"
            [(selected)]="selectedPeriod"
            (selectedOptionsChange)="filterChanged($event)"
            [isDataSourceTwoWayBindingRequired]="true"
            [isRequired]="false"
            class="multi-select-width"
          >
          </app-multi-select-dropdown>
        </fieldset>

        <fieldset>
          <app-multi-select-dropdown
            [emptyText]="'Select Source'"
            [dataSource]="sourceKeys"
            [isMultiple]="true"
            [isSelectAllVisible]="true"
            (selectedOptionsChange)="getSelectedSourceKeys()"
            [isDataSourceTwoWayBindingRequired]="true"
            [isCheckBoxVisible]="true"
            [isRequired]="false"
            class="multi-select-width"
          >
          </app-multi-select-dropdown>
        </fieldset>
      </form>
    </div>
  </div>
  <div *ngIf="!statsLoading" [hidden]="stats" class="start-loading">
    <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
    <span translate>{{ ui_labels.CANNOT_FIND_STATS }}</span>
  </div>
  <div>
    <p class="zs-message zs-error" *ngIf="showChartContentError">
      {{ showChartContentError }}
      <button
        class="zs-icon zs-icon-rejected-approval"
        (click)="closeErrorToaster()"
      ></button>
    </p>
  </div>
  <div [hidden]="!stats">
    <div class="zs-row">
      <div class="zs-col-md-12">
        <div id="container" class="chart-container" #chartContainer></div>
      </div>
    </div>

    <div class="metrics-styles">
      <div *ngFor="let metricKey of metricKeys" class="metrics-key-styles">
        <label class="zs-checkbox">
          <input
            [(ngModel)]="metricKey.selected"
            type="checkbox"
            (change)="keySelected(metricKey)"
            class="margin-span"
          />
          <span checkbox [style.color]="colors[metricKey.key.toUpperCase()]">{{
            metriKeysUpperCaseMapping[metricKey.key.toUpperCase()]
          }}</span>
        </label>
      </div>
    </div>
    <div>
      <p class="zs-message zs-error" *ngIf="showStatsProfileContentError">
        {{ showStatsProfileContentError }}
        <button
          class="zs-icon zs-icon-rejected-approval"
          (click)="closeStatsProfileErrorToaster()"
        ></button>
      </p>
    </div>
    <div [hidden]="showprofileStatisticsDetailsTable">
      <div [hidden]="!chartData || chartData.length === 0">
        <h4 class="chart-data">
          {{ metriKeysUpperCaseMapping[chartMetric.toUpperCase()] }} -
          {{ chartCategory.toUpperCase() }}
        </h4>

        <ag-grid-angular
          class="ag-grid-class ag-theme-alpine ag-grid-style"
          [rowData]="chartData"
          [columnDefs]="columnDefinitionList"
          [frameworkComponents]="frameworkComponents"
          [suppressCellSelection]="true"
          [enableCellTextSelection]="true"
          [headerHeight]="height"
          [rowHeight]="height"
          [defaultColDef]="defaultColDef"
        >
        </ag-grid-angular>

        <div class="pagination">
          <app-zs-pagination
            [itemCount]="paginationData.ItemCount"
            [currentPage]="paginationData.CurrentPage"
            [customStyle]="paginationData.CustomStyle"
            [pageSize]="paginationData.PageSize"
            (pageChange)="pageChange($event)"
          ></app-zs-pagination>
        </div>
      </div>
      <div [hidden]="chartData === undefined || chartData.length > 0">
        <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
        <span translate> {{ ui_labels.NO_STATISTICS_DETAILS_FOUND }}</span>
      </div>
    </div>
  </div>
</div>
