import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { MDMService } from 'src/app/mdm/mdm.service';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import * as ZsAnimate from 'src/assets/zsui/zsui-core/dist/animation/animate.min.js';
// import * as ZsDrawer from 'src/assets/zsui/zsui-layout/dist/drawer/drawer.min.js';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { mdm_labels } from '../constants/ui-translation';
import { MDMCommonConstants } from '../constants/mdm-common-constants';
import { commmon_labels } from 'src/app/common-label';
import { TranslateService } from '@ngx-translate/core';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('detailsDialog', { read: ElementRef }) detailsDialog: ElementRef;
  @Output() selectedFilterChange = new EventEmitter();
  refreshIndicator$: any;
  refreshIndicator: boolean = false;
  chartFilters = [{ value: MDMCommonConstants.HCP, label: MDMCommonConstants.HCP },
  { value: MDMCommonConstants.HCO, label: MDMCommonConstants.HCO }];
  selectedFilter = this.chartFilters[0];
  ui_labels = mdm_labels.SUSPECTED_MATCHES.DASHBOARD;
  error_label: string;
  commmon_labels = commmon_labels;
  translated_labels: any;
  toggleDashboardDrawerArrowIcon = false;
  showViewRecordsStatistics = false;

  constructor(private router: Router, private service: MDMService, private _translate: TranslateService,
    private _toasterDataService: ToasterDataService) {
    // let zs4 = ZsAnimate;
    // let zs3 = ZsDrawer;

    let last = sessionStorage.getItem(MDMCommonConstants.LAST_VISITED_PROFILE_TYPE);
    if (last) this.selectedFilter = this.chartFilters.find(f => f.value == last);
    else sessionStorage.setItem(MDMCommonConstants.LAST_VISITED_PROFILE_TYPE, this.selectedFilter.value);
    this.translated_labels = this._translate.instant(
      [
        this.commmon_labels.ERROR_WITH_COLON
      ]);
    this.error_label = this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
  }

  ngOnInit() {
    this.refreshIndicator$ = interval(10 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.service.getRefreshIndicator())
      )
      .subscribe(res =>  {
        this.refreshIndicator = res;
      }, exception => {
        this._toasterDataService.addMessageToToaster(exception.error.message);
      }
      );
  }

  filterChanged(filter) {
    this.selectedFilter = filter;
    this.selectedFilterChange.emit(this.selectedFilter);
  }

  changeDashboardToggle() {
    if (this.toggleDashboardDrawerArrowIcon === false) {
      this.toggleDashboardDrawerArrowIcon = true;
    } else {
      this.toggleDashboardDrawerArrowIcon = false;
    }
  }

  ngOnDestroy() {
    this.refreshIndicator$.unsubscribe();
  }
}
