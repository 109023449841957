export const commmon_labels = {
    SUCCESS: 'COMMON_LABELS.SUCCESS',
    SEARCH: 'COMMON_LABELS.SEARCH',
    DOWNLOAD: 'COMMON_LABELS.DOWNLOAD',
    ERROR_WITH_COLON: 'COMMON_LABELS.ERROR_WITH_COLON',
    CANCEL: 'COMMON_LABELS.CANCEL',
    OK: 'COMMON_LABELS.OK',
    BY_USERNAME: 'COMMON_LABELS.BY_USERNAME',
    USERNAME_ON: 'COMMON_LABELS.USERNAME_ON',
    SAVE: 'COMMON_LABELS.SAVE',
    CLOSE: 'COMMON_LABELS.CLOSE',
    ERROR: 'COMMON_LABELS.ERROR',
    BACK: 'COMMON_LABELS.BACK',
    UPDATE: 'COMMON_LABELS.UPDATE',
    SUCCESS_WITH_COLON: 'COMMON_LABELS.SUCCESS_WITH_COLON',
    DELETE: 'COMMON_LABELS.DELETE',
    COPY: 'COMMON_LABELS.COPY',
    EDIT: 'COMMON_LABELS.EDIT',
    BY: 'COMMON_LABELS.BY',
    USER: 'COMMON_LABELS.USER',
    TIMESTAMP: 'COMMON_LABELS.TIMESTAMP',
    NO_ROWS_TO_SHOW: 'COMMON_LABELS.NO_ROWS_TO_SHOW',
    RUN: 'COMMON_LABELS.RUN',
    TRIGGERED: 'COMMON_LABELS.TRIGGERED'
};
