import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AdaptorLabelTranslationPipe } from './custom-pipes/adaptor-label-translation.pipe';
import { AddKeyToObjectPipe } from './custom-pipes/add-key-to-object.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { DomSanitizerPipe } from './custom-pipes/dom-sanitizer-pipe';
import { FormsModule } from '@angular/forms';
import { GlobalParameterDataSourceTransformPipe } from './custom-pipes/global-parameter-datasource-transform-pipe';
import { RelativeTime } from './custom-pipes/relative-time.pipe';
import { RevoCoreModule } from '../revo-core/revo-core.module';
import { RouterModule } from '@angular/router';
import { TimeDurationPipe } from './custom-pipes/time-duration.pipe';
import { TimeSecondsFormatterPipe } from './custom-pipes/time-seconds-formatter.pipe';
import { TimeToUtcPipe } from './custom-pipes/time-to-utc.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RevoCoreModule,
    FormsModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    TimeDurationPipe,
    RelativeTime,
    DomSanitizerPipe,
    AdaptorLabelTranslationPipe,
    AddKeyToObjectPipe,
    GlobalParameterDataSourceTransformPipe,
    TimeSecondsFormatterPipe,
    TimeToUtcPipe
  ],
  exports: [
    TimeDurationPipe,
    RelativeTime,
    DomSanitizerPipe,
    AddKeyToObjectPipe,
    GlobalParameterDataSourceTransformPipe,
    TimeSecondsFormatterPipe,
    TimeToUtcPipe
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers:[
    TimeSecondsFormatterPipe,
    TimeToUtcPipe
  ]
})
export class SharedModule { }
