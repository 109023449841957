import { Pipe, PipeTransform } from '@angular/core';
import { ServiceUtils } from 'src/app/service-utils';

@Pipe({
  name: 'timeToUTC'
})

export class TimeToUtcPipe implements PipeTransform {
  constructor(private _serviceUtils: ServiceUtils){

  }
  transform(valueInDateTime: any): any {
    return this._serviceUtils.convert_time_to_utc(valueInDateTime);
  }

}