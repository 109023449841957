<div class="zs-master-style">
  <div class="zs-message zs-margin-0-0-1-0 zs-warning" *ngIf="refreshIndicator" translate>
    {{ profile_lables.DATA_REFRESH_IN_PROGRESS }}
  </div>

  <div [hidden]="!noProfileError">
    <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
    {{ canNotFindLastViewedProfileMessage }}
  </div>
  <div class="breadcrum" *ngIf="_returnId != undefined">
    <div class="zs-padding-2 zs-display-flex">
      <span class="zs-icon zs-size-xl zs-icon-arrow-left"></span>
      <span>
        <a class="search-home zs-font-style-italic zs-margin-0-1-0-1" (click)="goToProfile()" translate>{{
          returnToProfileLabel }} - <span>{{returnProfileNameWithId}}</span></a>
        <!-- [routerLink]="['/mdm/home']" -->
      </span>
    </div>
  </div>
  <div class="breadcrum" *ngIf="_returnId == undefined">
    <div *ngIf="!showReturnToProfile" class="zs-padding-2 zs-display-flex">
      <span *ngIf="activeBreadcrumbTab === MDMWrapperConstants.SUSPECTED_MATCHES">
        <span>
          <a class="search-home zs-font-style-italic zs-margin-0-1-0-1" [routerLink]="['/mdm/home']" translate>{{
            suspectedRecordsLabel }}</a>
        </span>
        <span class="zs-icon zs-size-xl zs-icon-arrow-right"></span>
      </span>
      <span *ngIf="activeBreadcrumbTab === MDMWrapperConstants.ALL_RECORDS">
        <span>
          <a class="search-home zs-font-style-italic zs-margin-0-1-0-1" [routerLink]="['/mdm/all-records/search']"
            translate>{{ allRecordsLabel }}</a>
        </span>
        <span class="zs-icon zs-size-xl zs-icon-arrow-right"></span>
      </span>
      <span class="profile-name zs-margin-0-1-0-1">{{
        profileNameWithMdmId
        }}</span>
    </div>
    <div *ngIf="showReturnToProfile" class="zs-padding-2 zs-display-flex return-to-profile-label">
      <span class="zs-icon zs-size-xl zs-icon-arrow-left"></span>
      <span *ngIf="!isHco">
        <a [routerLink]="['/mdm/all-records/hcp/profile', mdmId]">
          <i>{{ returnToProfileLabel }}</i>
        </a>
      </span>
      <span *ngIf="isHco">
        <a [routerLink]="['/mdm/all-records/hco/profile', mdmId]">
          <i>{{ returnToProfileLabel }}</i>
        </a>
      </span>
    </div>
  </div>
  <div [hidden]="noProfileError" class="scroll-class">
    <div class="zs-grid-3cols">
      <div class="
          zs-bg-surface-2
          zs-col-1
          zs-col-lw-1
          zs-col-span-3
          zs-col-lw-span-2
          zs-row-lw-1
          zs-row-1
        " #contentDiv>
        <div class="zs-padding-2">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="
          zs-bg-surface-2
          zs-col-lw-3
          zs-col-span-3
          zs-col-lw-span-1
          zs-col-1
          zs-row-lw-1
          zs-row-2
        " [ngClass]="{ sticky: !isHco }">
        <div class="sidebar">
          <div class="zs-tabs horizontal-scroll zs-tabs">
            <zs-tabs-container class="zs-tabs zs-tabs-solid zs-bg-standard">
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold"
                (click)="setSelectedTab(MDMProfileTabNameConstants.SUSPECTED_MATCHES)"
                [targetId]="MDMProfileTabNameConstants.SUSPECTED_MATCHES" active>{{ suspectedMatchesLabel |
                applyEllipses: ellipsesLengthForTabs }}
                <pre><span> ({{ suspectedCount }})</span></pre>
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{suspectedMatchesLabel}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>
              <!-- Opened pofile HCP -> Affiliation Tab HCP -->
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold" *ngIf="!isHco"
                (click)="setSelectedTab(MDMProfileTabNameConstants.HCP_HCP)"
                [targetId]="MDMProfileTabNameConstants.HCP_HCP">
                {{ hcpHierarchy | applyEllipses: ellipsesLengthForTabs }}
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{hcpHierarchy}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>

              <!-- Opened pofile HCP -> Affiliation Tab HCO -->
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold" *ngIf="!isHco"
                (click)="setSelectedTab(MDMProfileTabNameConstants.HCP_HCO)"
                [targetId]="MDMProfileTabNameConstants.HCP_HCO">{{ hcoDetails | applyEllipses: ellipsesLengthForTabs }}
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{hcoDetails}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>
              <!-- Opened pofile HCO -> Affiliation Tab HCP -->
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold" *ngIf="isHco"
                (click)="setSelectedTab(MDMProfileTabNameConstants.HCO_HCP)"
                [targetId]="MDMProfileTabNameConstants.HCO_HCP" translate>{{ hcpDetails | applyEllipses:
                ellipsesLengthForTabs }}
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{hcpDetails}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>
              <!-- Opened pofile HCO -> Affiliation Tab HCO -->
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold" *ngIf="isHco"
                (click)="setSelectedTab(MDMProfileTabNameConstants.HCO_HCO)"
                [targetId]="MDMProfileTabNameConstants.HCO_HCO" translate>{{ hcoHierarchy | applyEllipses:
                ellipsesLengthForTabs }}
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{hcoHierarchy}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>
              <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold"
                (click)="setSelectedTab(MDMProfileTabNameConstants.ACTIVITY_LOG)"
                [targetId]="MDMProfileTabNameConstants.ACTIVITY_LOG" translate>{{ logs | applyEllipses:
                ellipsesLengthForTabs }}
                <zs-tooltip position="bottom">
                  <zs-tooltip-content>
                    {{logs}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </zs-tab>
            </zs-tabs-container>
            <div class="affiliation-config" [attr.source-id]="MDMProfileTabNameConstants.SUSPECTED_MATCHES" active>
              <mdm-profile-matches *ngIf="
                  activeTab === MDMProfileTabNameConstants.SUSPECTED_MATCHES
                " [acivateDragDrop]="mergeScreenActivated" [profileName]="profileNameWithMdmId"
                (searchCount)="suspectedMatchesCount($event)"></mdm-profile-matches>
            </div>
            <div class="affiliation-config" [attr.source-id]="MDMProfileTabNameConstants.HCP_HCP">
              <app-affiliation-details *ngIf="
                  activeTab === MDMProfileTabNameConstants.HCP_HCP && !isHco
                " [profileType]="MDMCommonConstants.HCP" [profileDetail]="profileNameWithMdmId">
              </app-affiliation-details>
            </div>
            <div class="affiliation-config" [attr.source-id]="MDMProfileTabNameConstants.HCP_HCO">
              <app-affiliation-details *ngIf="
                  activeTab === MDMProfileTabNameConstants.HCP_HCO && !isHco
                " [profileType]="MDMCommonConstants.HCO" [profileDetail]="profileNameWithMdmId">
              </app-affiliation-details>
            </div>
            <div class="affiliation-config" [attr.source-id]="MDMProfileTabNameConstants.HCO_HCP">
              <app-affiliation-details *ngIf="
                  activeTab === MDMProfileTabNameConstants.HCO_HCP && isHco
                " [profileType]="MDMCommonConstants.HCP" [profileDetail]="profileNameWithMdmId">
              </app-affiliation-details>
            </div>
            <div class="affiliation-config" [attr.source-id]="MDMProfileTabNameConstants.HCO_HCO">
              <app-affiliation-details *ngIf="
                  activeTab === MDMProfileTabNameConstants.HCO_HCO && isHco
                " [profileType]="MDMCommonConstants.HCO" [profileDetail]="profileNameWithMdmId">
              </app-affiliation-details>
            </div>

            <div [attr.source-id]="MDMProfileTabNameConstants.ACTIVITY_LOG">
              <mdm-profile-activities *ngIf="activeTab === MDMProfileTabNameConstants.ACTIVITY_LOG">
              </mdm-profile-activities>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>