export const SearchConstants = {
    ALL: 'ALL',
    NAME: 'Name',
    A_TO_Z: 'A -> Z',
    Z_TO_A: 'Z -> A',
    TEXT: 'text',
    MDM_ID: 'MDM ID',
    ADDRESS: 'Address',
    CHECKBOX: 'checkbox',
    PDRP_FLAG: 'PDRP Flag',
    KAISER_FLAG: 'Kaiser Flag',
    DMR_FLAG: 'DMR Flag',
    TARGET_FLAG: 'Target Flag',
    ACCOUNT_NAME: 'Account Name',
    CLASS_OF_TRADE: 'Class Of Trade',
    PAGE: 'page',
    SIZE: 'size',
    TOTAL: 'total',
    UNDEFINED: 'undefined',
    Q: 'q',
    SELECT: 'select',
    LAST_VISTED_PROFILE_TYPE: 'lastVisitedProfileType',
    SEARCH_FILTERS: 'search filters',
    SELECT_DROPDOWN: 'select_dropdown',
    FLAGS: 'Flags',
    DATE_PICKER: 'date_picker',
    EDIT: 'Edit_vod',
    NEW: 'New_vod',
    HCP: 'HCP',
    HCO: 'HCO',
    ENTITY_TYPE: 'Entity Type',
    REQUEST_TYPE: 'Request Type',
    SUBTYPE: 'Sub Type',
    PROFILE: 'Profile',
    AFFILIATION: 'Affiliation',
};


export const searchSliceDataPipeConstants = {
    ADDRESS_LINE: 'addressLine',
    ADDRESS_ZIP_CODE: 'addressZipCode'
}