<div appZsLoader [isVisible]="activitiesLoading">
	<div class="log-margin">
	<div *ngIf="!activities"><span class="zs-icon zs-icon-warning  zs-icon-color-warning"></span> {{noProfileActivityLabel}}</div>
	<div *ngIf="activities && activities.length > 0" [ngClass]="(activities.length < 10) ? 'activity-log-with-auto-height' : 'activity-log'">
		<div *ngFor="let act of activities">
			<div *ngIf="act">
				<div class="zs-layout-flex zs-flex-justify-flex-start zs-flex-align-start">
					<div>
						<span class="zs-icon {{ act.ATVY_DESC | getActivityImage}} zs-size-large zs-margin-0-0-0-1"></span>
					</div>
					<div class="log-text zs-margin-0-0-0-1">
						<div class="activity-user-name zs-padding-0-0-1-0">{{ act.USR_NM }}</div>
						<div class="activity-date zs-padding-0-0-1-0">{{ act.MODF_DT + dateTimeConstants.Z | date : environment.dateTimeFormat.timeFormats }}</div>
						<div>
							<span>{{ getActivityDesc(act.ATVY_DESC) }}</span>
							<span>
								<a class="link-text" *ngIf="act.ATVY_TYPE === MDMCommonConstants.HCO" [routerLink]="[mdm_urls.hcoProfile, act.MDM_ID]"> {{ act.NAME }}</a>
								<a class="link-text" *ngIf="act.ATVY_TYPE === MDMCommonConstants.HCP" [routerLink]="[mdm_urls.hcpProfile, act.MDM_ID]"> {{ act.NAME }}</a>
							</span>
						</div>
					</div>
				</div>
			</div>
			<br />
		</div>
	</div>
	<div class="show-more-button-div">
		<button
		[disabled]="page === totalPages || activities.length === 0"
		type="button"
		class="show-more-button zs-button zs-border-rounded-0 zs-button-action"
		(click)="loadMoreActivities()"
	translate>
	{{ui_labels.VIEW_MORE}}
	</button>
	</div>
	</div>
</div>
