<ul class="tree" *ngIf="treeData" [ngClass]="{'multiple-parent-top-left-border': (level === 0 && treeData.length > 1)}">
  <li *ngFor="let node of treeData" [ngClass]="{'single-parent-top-border': (level === 0 && topMostParentLength === 1),'multiple-parent-hide-border': (level === 0 && topMostParentLength > 1)}">
    <span class="collapse-button" [ngClass]="(node | addKeyToObject : 'showChildren' : true).showChildren ? 'zs-icon zs-icon-carat-down' : 'zs-icon zs-icon-carat-right'" 
    *ngIf="node.CHILDREN != 0" (click)="toggleChild(node)">
    </span>
    <span [ngClass]="{'childnode': node.CHILDREN.length === 0}"></span>
    <span [ngClass]="affiliationTab === MDMCommonConstants.HCO ? 'zs-icon zs-icon-field-unit zs-size-l' : 'zs-icon zs-icon-user zs-size-l'"></span>
    <div *ngIf="(node.PRIM_FLG === MDMCommonConstants.Y)" 
        [ngClass]="node.CHILDREN.length === 0 ? 'badge-class-for-leaf' : 'badge-class'" 
        class="zs-badge-counter zs-size-tiny">{{primaryLabel.toUpperCase()}}</div>
    <span>
      <a (click)="gotoProfile(node.ID)" class='cursor-pointer'
        [ngClass]="{'highlight-primary-parent-for-leaf': (node.PRIM_FLG === MDMCommonConstants.Y && node.CHILDREN.length === 0),
                    'highlight-primary-parent': (node.PRIM_FLG === MDMCommonConstants.Y),
                    'text-first-class': (level === 0), 'text-class': (level!==0)}"> 
        {{ node.NAME.toLowerCase() }}
      </a>
    <div *ngIf="node.DIRECT_PARENT === MDMCommonConstants.Y || node.PRIM_FLG === MDMCommonConstants.Y && (MDMProfileTabNameConstants.HCP_HCP !== affiliationType) && (MDMProfileTabNameConstants.HCO_HCO !== affiliationType)" 
          class="zs-layout-flex zs-flex-direction-column" 
          [ngClass]="{'highlight-current-profile-child': (node.CHILDREN.length === 0), 
                      'highlight-current-profile': (node.CHILDREN.length !== 0), 
                      'hide-border': (!(node | addKeyToObject : 'showChildren' : true).showChildren)}">
      <div [ngClass]="node.CHILDREN.length === 0 ? 'mdm-name-margin-class-for-leaf' : 'mdm-name-margin-class'">{{name.toLowerCase()}}</div> 
      <div [ngClass]="node.CHILDREN.length === 0 ? 'id-margin-for-leaf' : 'id-margin'">MDM ID: {{mdm_id}}</div>
    </div>
    <zs-tooltip class="data-table" position="left" offset-x="40">
      <table class="zs-table zs-size-s">
        <thead>
          <tr>
            <th translate>{{ui_labels.ATTRIBUTES}}</th>
            <th translate>{{ui_labels.VALUE}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MDM ID</td>
            <td>{{ node.ID }}</td>
          </tr>
          <tr>
            <td translate>{{ui_labels.NAME}}</td>
            <td>{{ node.NAME }}</td>
          </tr>
          <ng-container *ngIf="node && node.ADDRESSES">
            <tr>
              <td translate>{{ui_labels.ADDRESS}}</td>
              <td>
                <span *ngFor="let address of node.ADDRESSES.slice(0, environment.mdmMaxAddressesToDisplayCount)">
                  <app-mdm-address [address]="address"></app-mdm-address>
                </span>
                <span *ngIf="environment.mdmMaxAddressesToDisplayCount !== 0" [ngClass]="{'addresses-ellipsis zs-icon zs-size-xl zs-icon-more': node.ADDRESSES.length > environment.mdmMaxAddressesToDisplayCount}"
                ></span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </zs-tooltip>
  </span>
    <app-tree *ngIf="(node | addKeyToObject : 'showChildren' : true).showChildren" [treeData]="node.CHILDREN"
    [affiliationTab]="affiliationTab" [isHco]="isHco" [openedProfileID]='openedProfileID' [level]="level + 1"
    [topMostParentLength]="topMostParentLength" [profileDetail]="profileDetail" [affiliationType]="affiliationType"></app-tree>
  </li>
</ul>