import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ActivitiesComponent } from '../activities/activities.component';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMDashboardPeriodList } from '../constants/mdm-common-constants';
import { Pagination } from 'src/app/shared/models/pagination.models';
import { Router } from '@angular/router';
import { SelectDropDown } from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { common_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { mdm_labels } from '../constants/ui-translation';

@Component({
  selector: 'mdm-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.less'],
})
export class ActivityLogComponent implements OnInit {
  @Input() selectedShowValue: string;
  @Output() selectedFilterChanged = new EventEmitter<any>();
  @ViewChild('activatedGridComponent') activatedGridComponent: ActivitiesComponent;
  searchTextboxPlaceholder: string;
  searchTextboxLabel: string;
  viewFilterValues = [];
  pastRunsValues = [];
  pastYearLabel: string;
  past6MonthsLabel: string;
  past24HoursLabel: string;
  past7DaysLabel: string;
  past30DaysLabel: string;
  allLabel: string;
  translated_labels: any;
  mdmDashboardPeriodList = MDMDashboardPeriodList;
  activity_ui_labels = mdm_labels.ALL_RECORDS.PROFILE.ACTIVITIES;
  commonLabels = common_labels;
  searchPlaceholderLabel: string;
  showLable: string;
  pastVal: string;
  searchRecords: string;
  showPagination = false;
  paginationData: Pagination = {
    ItemCount: 0,
    CurrentPage: 1,
    CustomStyle: ''
  };
  environment: EnvironmentConfig;
  constructor(private _translate: TranslateService, private router: Router, private config: AppConfig) {
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant([
      this.activity_ui_labels.ALL,
      this.activity_ui_labels.PAST_24_HOURS,
      this.activity_ui_labels.PAST_7_DAYS,
      this.activity_ui_labels.PAST_30_DAYS,
      this.activity_ui_labels.PAST_6_MONTHS,
      this.activity_ui_labels.PAST_YEAR,
      this.activity_ui_labels.VIEW,
      this.commonLabels.SHOW,
      this.commonLabels.APPLY,
      this.commonLabels.SEARCH,
    ]);
    this.allLabel = this.translated_labels[this.activity_ui_labels.ALL];
    this.past24HoursLabel = this.translated_labels[
      this.activity_ui_labels.PAST_24_HOURS
    ];
    this.past7DaysLabel = this.translated_labels[
      this.activity_ui_labels.PAST_7_DAYS
    ];
    this.past30DaysLabel = this.translated_labels[
      this.activity_ui_labels.PAST_30_DAYS
    ];
    this.past6MonthsLabel = this.translated_labels[
      this.activity_ui_labels.PAST_6_MONTHS
    ];
    this.pastYearLabel = this.translated_labels[
      this.activity_ui_labels.PAST_YEAR
    ];
    this.searchPlaceholderLabel = this.translated_labels[
      this.commonLabels.SEARCH
    ];
    this.showLable = this.translated_labels[this.activity_ui_labels.VIEW];

    this.pastRunsValues.push({
      displayLabel: this.allLabel,
      value: this.mdmDashboardPeriodList.ALL,
    });
    this.pastRunsValues.push({
      displayLabel: this.past24HoursLabel,
      value: this.mdmDashboardPeriodList.PAST_24_HOURS,
    });
    this.pastRunsValues.push({
      displayLabel: this.past7DaysLabel,
      value: this.mdmDashboardPeriodList.PAST_7_DAYS,
    });
    this.pastRunsValues.push({
      displayLabel: this.past30DaysLabel,
      value: this.mdmDashboardPeriodList.PAST_30_DAYS,
    });
    this.pastRunsValues.push({
      displayLabel: this.past6MonthsLabel,
      value: this.mdmDashboardPeriodList.PAST_6_MONTHS,
    });
    this.pastRunsValues.push({
      displayLabel: this.pastYearLabel,
      value: this.mdmDashboardPeriodList.PAST_YEAR,
    });
  }

  ngOnInit(): void {
    this.paginationData.PageSize = this.environment.pageSize;
    this.setShowPastRunsDropDown();
  }

  setShowPastRunsDropDown() {
    this.viewFilterValues = [];
    this.pastRunsValues.forEach((element) => {
      const selectDropdown = new SelectDropDown();
      selectDropdown.value = element.value;
      selectDropdown.displayLabel = element.displayLabel;
      this.viewFilterValues.push(selectDropdown);
    });
    this.selectedShowValue = this.viewFilterValues[0].value;
  }

  refreshTable($event) {
    if (this.selectedShowValue !== $event[0].value) {
      this.pastVal = $event[0].value;
      this.selectedShowValue = this.pastVal;
      this.paginationData.CurrentPage = 1;
      this.activatedGridComponent.resetFilter();
    }
  }

  setPaginationItemCount(event) {
    this.paginationData.ItemCount = event.itemCount;
    this.paginationData.CurrentPage = event.page;
    this.showPagination = event.itemCount ? true : false;
  }

  pageChange(newPage: number) {
    this.paginationData.CurrentPage = newPage;
  }
}
