<div class="breadcrum">
    <div *ngIf="!showReturnToProfile" class="zs-padding-2 zs-display-flex">
        <span *ngIf="activeBreadcrumbTab === MDMWrapperConstants.SUSPECTED_MATCHES">
            <span>
                <a class="search-home zs-font-style-italic zs-margin-0-1-0-1" [routerLink]="['/mdm/home']" translate>{{
                    suspectedRecordsLabel }}</a>
            </span>
            <span class="zs-icon zs-size-xl zs-icon-arrow-right"></span>
        </span>
        <span *ngIf="activeBreadcrumbTab === MDMWrapperConstants.ALL_RECORDS">
            <span>
                <a class="search-home zs-font-style-italic zs-margin-0-1-0-1" [routerLink]="['/mdm/all-records/search']"
                    translate>{{ allRecordsLabel }}</a>
            </span>
            <span class="zs-icon zs-size-xl zs-icon-arrow-right"></span>
        </span>
        <span class="profile-name zs-margin-0-1-0-1" *ngIf="profileNameWithMdmId">{{
            profileNameWithMdmId
            }}</span>

    </div>
    <div *ngIf="showReturnToProfile" class="zs-padding-2 zs-display-flex return-to-profile-label">
        <span class="zs-icon zs-size-xl zs-icon-arrow-left"></span>
        <span>
            <a [routerLink]="['/mdm/all-records/payer/profile/', _returnId]">
                {{ returnToProfileLabel }}
            </a>
        </span>

    </div>
</div>
<div class="scroll-class" cdkDropListGroup>
    <div class="zs-grid-3cols">
        <div class="
          zs-bg-surface-2
          zs-col-1
          zs-col-lw-1
          zs-col-span-3
          zs-col-lw-span-2
          zs-row-lw-1
          zs-row-1
        " #contentDiv>
            <div class="zs-padding-2">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="
          zs-bg-surface-2
          zs-col-lw-3
          zs-col-span-3
          zs-col-lw-span-1
          zs-col-1
          zs-row-lw-1
          zs-row-2
          zs-padding-2
        ">
            <div class="sidebar">
                <div class="zs-tabs horizontal-scroll zs-tabs">
                    <zs-tabs-container class="zs-tabs zs-tabs-solid zs-bg-standard">
                        <zs-tab class="zs-tab zs-size-s zs-font-weight-semi-bold" [targetId]="'suspected_matches'"
                            active>Suspected Matches
                            <pre><span> ({{matchesLength}})</span></pre>
                            <zs-tooltip position="bottom">
                                <zs-tooltip-content>
                                    Suspected Matches
                                </zs-tooltip-content>
                            </zs-tooltip>
                        </zs-tab>

                    </zs-tabs-container>
                    <div class="affiliation-config" [attr.source-id]="'suspected_matches'" active>
                        <app-payer-matches></app-payer-matches>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>