<div class="merge-unmerge-component" #mergeUnmergeWrapper>
  <div
    [ngClass]="{ 'zs-loading': loading }"
    class="
      zs-padding-2
      zs-bg-standard
      zs-box-shadow
      zs-border-color-default
    "
  >
    <div *ngIf="!golden && !loading" class="zs-row">
      <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
      {{ canNotFindProfilelabel }}
      <p>
        <button
          *ngIf="!isHco"
          class="zs-button zs-button-solid zs-border-rounded-0"
          [routerLink]="[mdm_urls.hcpProfile, id]"
          type="button"
          translate
        >
          {{ ui_labels.BACK_TO_PROFILE }}
        </button>
        <button
          *ngIf="isHco"
          class="zs-button zs-button-solid zs-border-rounded-0"
          [routerLink]="[mdm_urls.hcoProfile, id]"
          type="button"
          translate
        >
          {{ ui_labels.BACK_TO_PROFILE }}
        </button>
      </p>
    </div>
    <div *ngIf="golden" class="zs-padding-2-0-2-0 zs-row zs-margin-0-1-0-0">
      <div>
        <div class="zs-grid-5cols">
          <div class="zs-col-1 zs-col-span-3 profile-name zs-grid-5cols">
            <div class="zs-row-1 zs-col-1 zs-size-xxl">
              <span
                *ngIf="!isHco"
                class="zs-margin-0-0-0-1 zs-icon zs-size-xxl zs-icon-user"
              ></span>
              <span
                *ngIf="isHco"
                class="zs-margin-0-0-0-1 zs-icon zs-size-xxl zs-icon-field-unit"
              ></span>
            </div>
            <div class="zs-margin-1-0-0-0 zs-row-1 zs-col-2 zs-col-span-4">
              <h4 class="zs-margin-0" *ngIf="!isHco">
                {{ golden.FRST_NM.value }} {{ golden.LAST_NM.value }}
              </h4>
              <h4 class="zs-margin-0" *ngIf="isHco">
                {{ golden.ACCT_NM.value }}
              </h4>
              <div class="zs-display-flex zs-margin-1-0-0-0">
                <p
                  class="
                    zs-size-extra-small zs-font-weight-bold zs-text-uppercase
                  "
                >
                  {{ mdmIdLabel }}
                </p>
                <p class="zs-margin-0-0-1-1 zs-body zs-size-extra-small">
                  {{ golden.MDM_ID.value }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="
              zs-col-4
              zs-col-span-2
              zs-margin-1-0-0-0
              zs-layout-flex
              zs-flex-justify-flex-end
              zs-flex-wrap
            "
          >
            <button
              *ngIf="isMergeSelected"
              class="
                zs-margin-1-0-0-0 zs-button
                solid-buttons
                zs-button-solid zs-border-rounded-0
              "
              [hidden]="!(mergeProfiles?.length > 1)"
              (click)="doMerge(undefined, true)"
              [ngClass]="{ disabled: refreshIndicator }"
            >
              {{ mergeAllLabel }}
            </button>
            <zs-tabs-container class="zs-margin-0-0-0-1 zs-toggle-tabs">
              <div
                class="
                  tabs-container
                  zs-margin-1-0-0-0 zs-layout-flex
                  toggle-tabs
                "
              >
                <zs-tab class="zs-tab zs-active" target-id="merge" active>
                  <button
                    (click)="onTabChange(mergeVariableConstant)"
                    class="zs-button solid-buttons zs-border-rounded-0"
                  >
                    <span translate>{{ ui_labels.MERGE }}</span>
                  </button>
                </zs-tab>
                <zs-tab class="zs-tab" target-id="unmerge">
                  <button
                    (click)="onTabChange(unmergeVariableConstant)"
                    class="zs-button solid-buttons zs-border-rounded-0"
                  >
                    <span translate>{{ ui_labels.UNMERGE }}</span>
                  </button>
                </zs-tab>
              </div>
            </zs-tabs-container>
          </div>
        </div>
        <div class="zs-margin-0-0-0-0 zs-float-right zs-row">
          <div class="matches-count zs-text-caption">
            {{ mergedRecords }} {{ mergedLabel }}, {{ unMergedSuspects }}
            {{ unmergedSuspectedLabel }}
          </div>
        </div>
        <br />
        <div source-id="merge" active cdkDropListGroup>
          <div class="zs-row zs-margin-2-1-0-0">
            <div
              #detailsTable
              class="details-table-container"
              cdkDropList
              cdkDropListOrientation="horizontal"
              id="dropList"
              cdkDropListConnectedTo="dragList"
              (cdkDropListDropped)="drop($event)"
            >
              <mdm-profile-details-table
                *ngIf="isMergeSelected"
                tableId="mergePageTable1"
                tableCssClass="zs-table"
                [cdkDragDisabled]="true"
                cdkDrag
                onMergePage="true"
                [keys]="keys"
                [golden]="golden"
                [profiles]="mergeProfiles"
                (mergeClicked)="doMerge($event)"
                (notamatchClicked)="doNotAMatch($event)"
                [wrapperElement]="wrapperElement"
              >
              </mdm-profile-details-table>
            </div>
          </div>
        </div>
        <div source-id="unmerge" class="unmerge-details-table">
          <div class="zs-row zs-margin-2-1-0-0">
            <div #detailsTable class="details-table-container">
              <mdm-profile-details-table
                *ngIf="!isMergeSelected"
                tableId="mergePageTable1"
                tableCssClass="zs-table"
                onUnmergePage="true"
                [keys]="keys"
                [golden]="golden"
                [profiles]="unmergeProfiles"
                (unmergeClicked)="doUnmerge($event)"
                [wrapperElement]="wrapperElement"
              >
              </mdm-profile-details-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
