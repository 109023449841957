import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MDMProfileTabNameConstants, QueryParams } from '../../constants/profile-constants';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMCommonConstants } from '../../constants/mdm-common-constants';
import { MDMService } from '../../mdm.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../../constants/ui-translation';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.less']
})
export class TreeComponent implements OnInit, OnChanges {

  @Input() treeData: any[];
  @Input() isHco: boolean;
  @Input() affiliationTab = '';
  @Input() openedProfileID = '';
  @Input() level = 0;
  @Input() topMostParentLength = 0;
  @Input() profileDetail: string;
  @Input() affiliationType = '';

  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS;
  translated_labels: any;
  primaryLabel: string;
  commmon_labels = commmon_labels;
  MDMCommonConstants = MDMCommonConstants;
  environment: EnvironmentConfig;
  name: string;
  mdm_id: string;
  MDMProfileTabNameConstants = MDMProfileTabNameConstants;
  mdmName: string;

  constructor( private service: MDMService, private router: Router, private _translate: TranslateService,
    private appConfig: AppConfig) {
    this.environment = this.appConfig.getConfigObject();
    this.translated_labels = this._translate.instant(
    [
      this.ui_labels.PRIMARY
    ]);
    this.primaryLabel = this.translated_labels[this.ui_labels.PRIMARY];
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.name = this.profileDetail.substring(0, this.profileDetail.lastIndexOf('(') - 1);
    this.mdm_id = this.profileDetail.substring(this.profileDetail.lastIndexOf('(') + 1, this.profileDetail.lastIndexOf(')'));
    this.mdmName = this.profileDetail.substring(0, this.profileDetail.lastIndexOf('('));
  }

  toggleChild(node) {
    node.showChildren = !node.showChildren;
  }

  refreshTabs(mdmID) {
    this.service.refreshAffiliationTab(mdmID);
  }

  gotoProfile(mdmID) {
    if (mdmID !== this.openedProfileID) {
      this.refreshTabs(mdmID);
      this.router.navigate([this.affiliationTab === MDMCommonConstants.HCO ?
        '/mdm/all-records/hco/profile' : '/mdm/all-records/hcp/profile', mdmID],
    { queryParams: { [QueryParams.RETURN_ID]: this.openedProfileID, [QueryParams.RETURN_NAME]: this.mdmName}});
    }
    return;
}
}
