<div class="mdm-details-wrapper">
  <!-- <nav class="zs-margin-2-0-2-0 zs-padding-0-0-2-2 breadcrumb-bottom">
    <a
      class="zs-margin-6-2-2-1 zs-font-style-italic breadcrum"
      (click)="goBackConfirmationPopup()"
    >
      {{ mdmConstants.MDM }}
    </a>
    <span
      class="zs-icon zs-size-large zs-icon-arrow-right breadcrum-arrow-icon zs-text-caption"
    ></span>
    <span class="zs-h4 zs-margin-0-2-0-1 zs-text-headline">
      {{ mdmConstants.EDIT_MDM_CONFIG }}
    </span>
  </nav> -->
  <main class="main-wrapper">
    <section class="scroll-class mdm-section">
      <h2 class="mdm-configuration-label">
        {{ mdmConstants.MDM_CONFIGURATION }}
      </h2>
      <div *ngIf="showMdmConfigDetails">
        <form id="data-source" class="zs-form">
          <fieldset>
            <!-- <app-zs-textbox
              class="text-box-global-field-class"
              [required]="true"
              [hint]="mdmConstants.HOSTNAME"
              [(modelValue)]="mdmConfiguration.hostname"
              [inputType]="'text'"
              [validateFunction]="nameValidationFunction"
              [customStyle]="{ width: '20.3em' }"
              [formValidationCallbackFunction]="formValidationFunction"
            >
            </app-zs-textbox> -->
            <!-- <div class="grid-container">
              <div class="grid-item">
                <app-zs-textbox
                  class="text-box-global-field-class"
                  [required]="true"
                  [hint]="mdmConstants.USERNAME"
                  [(modelValue)]="mdmConfiguration.username"
                  [validateFunction]="nameValidationFunction"
                  [formValidationCallbackFunction]="formValidationFunction"
                  [customStyle]="{ width: '20.3em' }"
                >
                </app-zs-textbox>
              </div>
              <div class="grid-item">
                <app-zs-textbox
                  class="text-box-global-field-class"
                  [required]="true"
                  [hint]="mdmConstants.PASSWORD"
                  [(modelValue)]="mdmConfiguration.password"
                  (modelValueChange)="changePassword($event)"
                  [inputType]="'password'"
                  [validateFunction]="nameValidationFunction"
                  [formValidationCallbackFunction]="formValidationFunction"
                  [customStyle]="{ width: '20.3em' }"
                  [clearPasswordOnClick]="false"
                >
                </app-zs-textbox>
              </div>
              <div class="grid-item">
                <app-zs-textbox
                  class="text-box-global-field-class"
                  [required]="true"
                  [hint]="mdmConstants.DATABASE_NAME"
                  [(modelValue)]="mdmConfiguration.database_name"
                  [inputType]="'text'"
                  [validateFunction]="nameValidationFunction"
                  [formValidationCallbackFunction]="formValidationFunction"
                  [customStyle]="{ width: '20.3em' }"
                >
                </app-zs-textbox>
              </div>
              <div class="grid-item">
                <app-zs-textbox
                  class="text-box-global-field-class"
                  [required]="true"
                  [hint]="mdmConstants.SCHEMA_NAME"
                  [(modelValue)]="mdmConfiguration.schema_name"
                  [inputType]="'text'"
                  [validateFunction]="nameValidationFunction"
                  [customStyle]="{ width: '20.3em' }"
                  [formValidationCallbackFunction]="formValidationFunction"
                >
                </app-zs-textbox>
              </div>
              <div class="grid-item">
                <app-zs-textbox
                  class="text-box-global-field-class"
                  [required]="false"
                  [hint]="mdmConstants.SEARCH_ENGINE_KEY"
                  [(modelValue)]="mdmConfiguration.search_engine_key"
                  [inputType]="'text'"
                  [customStyle]="{ width: '20.3em' }"
                >
                </app-zs-textbox>
              </div>
            </div> -->
            <br />
            <span>
              <button

                class="zs-button zs-border-rounded-0 zs-button-action zs-margin-0-0-0-1 zs-size-s"
                (click)="fetchMDMAttributes()"
              >
                <span>{{ mdmConstants.FETCH_ATTRIBUTES }}</span>
              </button>
            </span>
            <div
              *ngIf="showAttributeConfiguration"
              class="attribute-configuration-style"
            >
              <h5 class="zs-margin-0-0-0-1">
                {{ mdmConstants.ATTRIBUTE_CONFIGURATION }}
              </h5>
              <div>
                <div
                  class="zs-grid-2cols-auto search-bar-div"
                >
                  <div class="zs-margin-0-0-0-0">
                    <app-zs-textbox
                      [(modelValue)]="searchValue"
                      [inputType]="'text'"
                      enableSearch="true"
                      [placeholder]="mdmConstants.SEARCH_ATTRIBUTES"
                      [customStyle]="{ width: '20.3em' }"
                      (modelValueChange)="searchValueChange()"
                      (searchClear)="clearSearchValue()"
                    >
                    </app-zs-textbox>
                  </div>
                  <div
                    class="zs-row-1 zs-col-2 zs-margin-1-0-0-0 add-new-attribute"
                  >
                    <button
                      *ngIf="showAttributeConfiguration"
                      class="zs-button zs-border-rounded-0 add-new-button zs-margin-0-2-0-0 float-right"
                      (click)="addNewMDMAttributes()"
                    >
                      <span class="zs-icon zs-icon-add zs-size-large"></span>
                      <span>{{ mdmConstants.ADD_NEW_ATTRIBUTES }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div appZsLoader [isVisible]="mdmAttributes === undefined"
              *ngIf="(mdmAttributes | filterTable : [mdmConstants.SRC_FLD, mdmConstants.TGT_FLD, mdmConstants.GROUP_NAME, mdmConstants.ETY_TYPE]
              : searchValue : false) as filteredAttributes">
              <div *ngIf="(filteredAttributes | slice: ((this.paginationData.CurrentPage-1)*(this.paginationData.PageSize)): ((this.paginationData.PageSize)*(this.paginationData.CurrentPage))) as pageData">
                <div class="pagination">
                  <app-zs-pagination class="zs-margin-0-2-2-0"
                   *ngIf="filteredAttributes?.length" [isTranslate]="false" [itemCount]="filteredAttributes?.length"
                   [currentPage]="paginationData.CurrentPage" [pageSize]="paginationData.PageSize"
                   [customStyle]="paginationData.CustomStyle"
                    (pageChange)="pageChange($event)"></app-zs-pagination>
                  </div>
                <table class="zs-table" *ngIf="mdmAttributes !== undefined"
                >
                  <thead class="mdm-table">
                    <tr valign="middle">
                      <th class="heading-label">
                        {{ mdmConstants.ATTRIBUTE_NAME }}
                      </th>
                      <th>{{ mdmConstants.DISPLAY_ON_PROFILE }}</th>
                      <th>{{ mdmConstants.DISPLAY_AS_PRIMARY }}</th>
                      <th class="heading-label">
                        {{ mdmConstants.ALIAS_NAME }}
                      </th>
                      <th>{{ mdmConstants.ATTRIBUTE_GROUP_NAME }}</th>
                      <th>{{ mdmConstants.ATTRIBUTE_ETY_TYPE }}</th>
                      <th>{{ mdmConstants.ACTION }}</th>
                    </tr>
                  </thead>
                  <tbody class="mdm-table">
                    <tr *ngFor="let attr of pageData; let i = index" valign="middle">
                        <td>
                          <app-zs-textbox
                          [(modelValue)]="attr.SRC_FLD"
                          [inputType]="'text'"
                          [required]="true"
                          [customStyle]="{ width: '10.625em' }"
                          [showOptionalText]="false"
                          [validateFunction]="
                            validateMdmAttributeDetailsFunction
                          "
                          [validateFunctionArgs]="[
                            attr,
                            mdmConstants.SRC_FLD,
                            i
                          ]"
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                          [disabled]="
                            !attr.NEW_ATTRIBUTE || !attr.IS_USER_CREATED
                          "
                        >
                        </app-zs-textbox>
                      </td>
                      <td>
                        <app-zs-select
                          [dataSource]="showToDisplayValues"
                          [selected]="attr.TO_DISP"
                          [emptyValue]="mdmConstants.YES"
                          [isRequired]="true"
                          [customStyle]="{ width: '10.625em' }"
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                          (selectedChange)="
                            onToDisplayValueChanged($event, attr, i)
                          "
                        >
                        </app-zs-select>
                      </td>
                      <td>
                        <app-zs-select
                          #isPrimarySelect
                          [dataSource]="showToPrimaryDisplayValues"
                          [disabled]="attr.TO_DISP === mdmCommonConstants.N"
                          [validateFunction]="validateIsPrimaryFunction"
                          [customStyle]="{ width: '10.625em' }"
                          [validateFunctionArgs]="[attr, i]"
                          [selected]="
                            attr.IS_PRIMARY
                              ? mdmCommonConstants.Y
                              : mdmCommonConstants.N
                          "
                          [isRequired]="true"
                          (selectedChange)="
                            onToPrimaryDisplayValueChanged($event, attr)
                          "
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                        >
                        </app-zs-select>
                      </td>
                      <td>
                        <app-zs-textbox
                          [(modelValue)]="attr.TGT_FLD"
                          [inputType]="'text'"
                          [required]="true"
                          [showOptionalText]="false"
                          [customStyle]="{ width: '10.625em' }"
                          [validateFunction]="
                            validateMdmAttributeDetailsFunction
                          "
                          [validateFunctionArgs]="[
                            attr,
                            mdmConstants.TGT_FLD,
                            i
                          ]"
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                          [disabled]="!attr.IS_USER_CREATED"
                        >
                        </app-zs-textbox>
                      </td>
                      <td>
                        <app-zs-select
                          [dataSource]="showGroupNameValues"
                          [(selected)]="attr.GROUP_NAME"
                          [emptyValue]="mdmConstants.PROFILE_LABEL"
                          [isRequired]="true"
                          [customStyle]="{ width: '10.625em' }"
                          [validateFunction]="
                            validateMdmAttributeDetailsFunction
                          "
                          [validateFunctionArgs]="[
                            attr,
                            mdmConstants.GROUP_NAME,
                            i
                          ]"
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                          [disabled]="!attr.IS_USER_CREATED"
                        >
                        </app-zs-select>
                      </td>
                      <td>
                        <app-zs-select
                          [dataSource]="showEntityTypeValues"
                          [(selected)]="attr.ETY_TYPE"
                          [isRequired]="true"
                          [customStyle]="{ width: '10.625em' }"
                          [formValidationCallbackFunction]="
                            formValidationFunction
                          "
                          [disabled]="!attr.IS_USER_CREATED"
                          (selectedChange)="onEntityTypeValueChange($event, i)"
                        >
                        </app-zs-select>
                      </td>
                      <td>
                        <a
                          [ngClass]="
                            attr.IS_USER_CREATED || attr.NEW_ATTRIBUTE
                              ? 'zs-text-caption'
                              : 'zs-disabled zs-text-disabled'
                          "
                          class="zs-icon zs-icon-delete zs-size-l zs-margin-0-0-0-1"
                          (click)="deleteAttributes(attr)"
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </section>

    <div class="footer-wrapper">
      <footer class="footer zs-layout-footer">
        <button
        appZsLoader
        [isVisible]="!cancelButtonEnabled"
        [ngClass]="{
          'zs-disabled': !cancelButtonEnabled || !saveButtonEnabled
        }"
        class="zs-button zs-border-rounded-0"
        (click)="cancel()"
      >
        {{ mdmConstants.CANCEL }}
      </button>
      <button
        appZsLoader
        [isVisible]="!saveButtonEnabled"
        [ngClass]="{
          'zs-disabled': !saveButtonEnabled || !cancelButtonEnabled
        }"
        class="zs-button zs-button-solid zs-border-rounded-0"
        (click)="saveMDMConfigurationDetails()"
      >
        {{ mdmConstants.SAVE }}
      </button>
      </footer>
    </div>
  </main>

  <app-modaldialog
    [(showmodal)]="showGoBackPopup"
    [titletext]="mdmConstants.BACK_TO_MDM_CONFIGURATION_LISTING"
    class="edit-group-modal"
  >
    <section modal-body class="edit-group-modal-section">
      <span>{{ mdmConstants.WARNING_MESSAGE }}</span>
    </section>
    <div modal-footer>
      <button (click)="hideGoBackPopup()" class="zs-button zs-border-rounded-0">
        {{ mdmConstants.CANCEL }}
      </button>
      <button
        class="zs-button zs-button-action zs-border-rounded-0"
        (click)="back()"
      >
        {{ mdmConstants.OK }}
      </button>
    </div>
  </app-modaldialog>
</div>
