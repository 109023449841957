import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { EnvironmentConfig } from '../shared/models/environment';
import TabModule from '../../../node_modules/zsui/src/tab/tab.m.js';
const TabsContainer = TabModule.TabsContainer;
const Tab = TabModule.Tab;
TabsContainer.register();
Tab.register();

@Injectable({
  providedIn: 'root'
})
export class RevoCoreService {
  environment: EnvironmentConfig;
  baseUrl: String;
  constructor(public http: HttpClient, private config: AppConfig) {
    this.environment = this.config.getConfigObject();
    this.baseUrl = this.environment.baseUrl;
  }

  get_user_session() {
    const httpOptions = { withCredentials: true };
    const data = { Return_URL: location.href };

    return this.http.post(this.baseUrl + '/user_session/', data, httpOptions);
  }

}
