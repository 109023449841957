<form class="zs-form">
  <fieldset class="zs-display-flex">
    <p class="filter-p">
      <span class="period-filter-dropdown">
        <app-multi-select-dropdown
          [dataSource]="viewFilterValues"
          [label]="showLable"
          [(selected)]="selectedShowValue"
          (selectedOptionsChange)="refreshTable($event)"
          [defaultText]=""
          [minOptionsForSearch]="10"
        >
        </app-multi-select-dropdown>
      </span>
    </p>
    <div class="pagination" *ngIf="showPagination">
      <app-zs-pagination
        [itemCount]="paginationData.ItemCount"
        [currentPage]="paginationData.CurrentPage"
        [customStyle]="paginationData.CustomStyle"
        [pageSize]="paginationData.PageSize"
        (pageChange)="pageChange($event)"
      ></app-zs-pagination>
    </div>
  </fieldset>
  <mdm-activities
    #activatedGridComponent
    [selectedPast]="pastVal"
    [page]="paginationData.CurrentPage"
    [pageSize]="paginationData.PageSize"
    (setPaginationItemCount)="setPaginationItemCount($event)"
  ></mdm-activities>
</form>
