<div class="zs-master-style zs-display-flex select" [ngClass]="labelInverse ? 'zs-text-inverse': 'zs-text-headline'">
  <label *ngIf="label" class="select-label">
    {{label}}
  </label>
  <p-is-zs-multiselect
  [ngClass]="{'background-color-class': selectedOptions.length}"
  [multiple]="isMultiple" mode="action" class="zs-margin-0 zs-action-field zs-select" #zsMultiSelect
  [labelKey]="'displayLabel'" [valueKey]="'value'" [options]="source" [selectedValues]="selectedValues"
  [ngClass]="touched ? inputClass : ''"
  [ngStyle]="customStyle"
  [disabled]="disabled"
  [flippable]="flippable"
  [hideOnScroll]="flippable || hideOnScroll"
  (change)="onChange()"
  [addCheckboxes]="isCheckBoxVisible"
  [addSelectAll]="isSelectAllVisible"
  [minOptionsForSearch]="minOptionsForSearch"
  [noRecordsText]="noRecordsText"
  (beforeclose)="beforeClose()"
  clear> </p-is-zs-multiselect>
  <p *ngIf="touched && inErrorState" [ngStyle]="customStyle" [ngClass]="inputClass" class="message-margin zs-message">{{errorMessage}}</p>
</div>