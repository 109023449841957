export const api_url_constants = {
    get_cluster_databases: '/data-query/cluster-databases',
    get_rdh_process_pm_processes: '/data-query/rdh-process-pm-processes',
    list_redshift_adaptor_schema: '/adaptor/list_schemas',
    get_target_system_list: '/data-query/target-system-list',
    getAllGlobalParameter: '/common/global-parameter-names-with-type',
    get_adaptor_Columns : '/common/fetch-columns',
    get_adapter_templates: '/common/templates',
    fetch_columns : '/common/fetch-columns',
    get_adaptors : '/common/adaptors',
    test_connection : '/common/test-connection',
    save_adaptor : '/common/save-adaptor',
    rerun_new_job : '/common/rerun-new-job?',
    get_processes_status : '/common/processes-status',
    get_job_statuses : '/common/job-statuses',
    delete_process : '/common/process',
    get_processes : '/common/processes',
    get_recent_processes : '/common/recent-processes',
    get_process_logs : '/common/process-logs',
    get_process_creators : '/common/process-creators',
    get_process_runs : '/common/process-runs',
    stop_recurrence : '/common/stop-recurrence',
    start_recurrence : '/common/start-recurrence',
    get_process_errors_by_job_id : '/common/process-errors-by-job-id',
    check_duplicate_table_name: '/common/validate-table-name',
    redshift_adaptors : '/common/redshift-adaptors',
    upload_adapter: '/common/upload-adapter',
    get_environment_details : '/common/environment-details?project_name=',
    adaptor_by_id : '/common/adaptor-by-id',
    getAllClientClusters: '/common/client-clusters',
};
