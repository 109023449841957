import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mdm-communication',
  template: `{{communication.COMM_VAL}} {{communication.COMM_TYPE}}`
})
export class CommunicationComponent implements OnInit {

    @Input() communication: any;
  constructor() { }

  ngOnInit() {
  }

}
