<div class="zs-row search-component">
  <div class="zs-col-md-12">
    <div class="zs-search-button search-radio">
      <span class="zs-display-flex">
        <label class="zs-radio" *ngFor="let filter of visibleFilters">
          <input [value]="filter" [(ngModel)]="selectedFilter" name="filter.label" type="radio"
            (ngModelChange)="selectedFilterChanged(filter)" [disabled]="loading" />
          <span class="radio-label" radio>{{ filter.label }}</span>
        </label>
      </span>
    </div>
  </div>
  <div class="search-info"
    *ngIf="selectedFilter.value == MDMCommonConstants.HCO || selectedFilter.value == MDMCommonConstants.HCP">
    <span class="zs-icon zs-icon-info-fill info-icon"></span>
    <span translate>{{ ui_labels.ADVANCE_SEARCH_INFO }}</span>
  </div>
  <div class="zs-col-md-12"
    *ngIf="selectedFilter.value == MDMCommonConstants.HCO || selectedFilter.value == MDMCommonConstants.HCP">
    <div class="zs-row">
      <div class="filter-view zs-col-md-3">
        <section class="zs-margin-1-1-0-0">
          <div *ngIf="!(visibleFilters && appliedFilter.length > 0) && !loading" class="zs-row">
            <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
            <span translate>{{ ui_labels.NO_FILTERS_SELECTED }}</span>
          </div>
          <ng-container *ngFor="let filter of visibleFilters">
            <div class="zs-row" *ngIf="filter.fields && filter.fields.length > 0">
              <div class="zs-accordion" [hidden]="!filter.applied">
                <div class="filter-title" (click)="flipAccordion(!filter.show)">
                  <a class="expand-collapse-icon"><span [ngClass]="
                        filter.show
                          ? 'zs-icon zs-size-xl zs-icon-arrow-up'
                          : 'zs-icon zs-size-xl zs-icon-arrow-down'
                      "></span></a>
                  <span class="filters-label">
                    {{ filter.label }}:
                    <span translate>{{ ui_labels.FILTERS }}</span>
                  </span>
                </div>
                <div *ngIf="filter.show" class="filter-option-div">
                  <div *ngFor="let field of filter.fields; let idx = index" class="on-hover-show filter-field-div">
                    <div *ngIf="field.type == searchConstants.SELECT_DROPDOWN">
                      <div class="multiselect_dropdown">
                        <app-multi-select-dropdown [isSelectAllVisible]="true" [dataSource]="field.datasource"
                          [isMultiple]="true" #filterSearch (selectedOptionsChange)="flagsChanged($event)"
                          [isDataSourceTwoWayBindingRequired]="true" [isCheckBoxVisible]="true"
                          [customstyle]="{ height: '1em' }" [label]="field.displayLabel">
                        </app-multi-select-dropdown>
                      </div>
                    </div>
                    <div *ngIf="field.type == 'text'">
                      <label>
                        <app-zs-textbox [hint]="field.displayLabel" class="filter-textbox" [ngClass]="
                            findclass(filter.label, field.displayLabel)
                          " [(modelValue)]="field.selectedValue" [showOptionalText]="false" [inputType]="'text'"
                          (modelValueChange)="inputFieldChanged(field)" [placeholder]="' '"
                          (keyup.enter)="applyfilters()">
                        </app-zs-textbox>
                      </label>
                    </div>
                  </div>
                  <button class="apply-button zs-button zs-button-solid zs-border-rounded-0" (click)="applyfilters()">
                    <span class="button-label" translate>{{
                      ui_labels.SEARCH
                      }}</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </section>
      <div *ngIf="checkSpecialCharacter " class="zs-text-error">Special characters are not allowed</div>
      </div>
      <!-- <ng-container *ngIf="showTags">
        <ng-container *ngFor="let category of filters; let i = index">
          <ng-container *ngFor="let sub of category.fields">
            <app-zs-tag *ngIf="isFieldApplied(sub) && sub.type != 'checkbox'" dismissable="true"
              [onCloseCallBackFunction]="tagRemovedFunc" [validatefunctionArgs]="[sub]">
              <span tag-value translate>
                {{ sub.displayLabel
                }}<span>:
                  <span class="font-strong">{{
                    getFieldValue(sub)
                    }}</span></span>
              </span>
            </app-zs-tag>
            <app-zs-tag *ngIf="isFieldApplied(sub) && sub.type == 'checkbox'" dismissable="true"
              [onCloseCallBackFunction]="tagRemovedFunc" [validatefunctionArgs]="[sub]">
              <span tag-value translate>
                <span class="font-strong">{{ sub.displayLabel }}</span>
              </span>
            </app-zs-tag>
          </ng-container>
        </ng-container>
      </ng-container> -->
      <div class="pagination zs-display-flex zs-flex-direction-row-reverse"
        *ngIf="selectedFilter.value == MDMCommonConstants.HCO || selectedFilter.value == MDMCommonConstants.HCP">
        <app-zs-pagination *ngIf="this.paginationData.ItemCount > 0 && !loading" [itemCount]="paginationData.ItemCount"
          [currentPage]="paginationData.CurrentPage" [pageSize]="paginationData.PageSize"
          (pageChange)="pageChange($event)" [paginationLabelRequired]="false"
          [pageSizeForDropdown]="pageSizeForDropdown">
        </app-zs-pagination>
      </div>
      <div class="search-view zs-col-md-9">
        <div class="grid-table-loader">
          <div *ngIf="loading" class="loader">
            <span class="loader-position" appZsLoader [isVisible]="loading" [isWrapper]="false">
              <p class="zs-text-inverse zs-body-small zs-font-weight-semi-bold">
                {{ loadingLabel }}{{threeDotConstant}}
              </p>
            </span>
          </div>

          <section class="zs-padding-1-0-0-0"
            *ngIf="selectedFilter.value == MDMCommonConstants.HCO || selectedFilter.value == MDMCommonConstants.HCP">
            <div *ngIf="!(results && results.length > 0) && !loading" class="zs-row">
              <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
              <strong class="no-records-message" translate>{{common_labels.NO_RECORDS_TO_DISPLAY }}</strong>
            </div>
            <div #cards *ngIf="(results && results.length > 0)">
              <div class="grid-container">
                <div *ngFor="let result of results; let i = index" class="grid-item">
                  <div class="zs-master-style card-component card-block">
                    <app-zs-card (click)="goToProfile(result)">
                      <div card-section class="zs-padding-2 card-width">
                        <div class="zs-row">
                          <div class="zs-col-md-1 card-icon-text">
                            <span *ngIf="result.ETY_TYPE === MDMCommonConstants.HCP"
                              class="card-icon zs-icon zs-size-l zs-icon-user">
                            </span>
                            <span *ngIf="result.ETY_TYPE === MDMCommonConstants.HCO"
                              class="card-icon zs-icon zs-size-l zs-icon-field-unit">
                            </span>
                          </div>
                          <div class="zs-padding-0-0-0-1 zs-col-md-11 card-icon-text">
                            <h4 [ngClass]="
                                result.ETY_TYPE === MDMCommonConstants.HCO
                                  ? 'highlight-hco-account-name'
                                  : 'highlight-hcp-name'
                              " class="zs-margin-0 search-profile-label-name" [attr.title]="result.NAME">
                              {{
                              result.NAME &&
                              result.NAME.length > profileNameLabellength
                              ? (result.NAME
                              | slice: 0:profileNameLabellength) + "..."
                              : result.NAME
                              }}
                            </h4>
                            <div>
                              <p class="zs-body zs-size-extra-small">
                                <span translate>{{ ui_labels.MATCH_DATE }} </span>
                                <span>{{
                                  result.MSTR_LOAD_DT + dateTimeConstants.Z
                                  | date: environment.dateTimeFormat.dateFormats
                                  }}</span>
                              </p>
                            </div>
                            <div class="zs-margin-0-0-1-0" [attr.title]="result.SOURCES">
                              <span *ngFor="
                                  let source of result.SOURCES;
                                  let i = index
                                ">
                                <span class="sources-label zs-badge-counter zs-border-rounded-0" [ngStyle]="{
                                    'background-color':
                                      environment.mdmSourceColorCodes[
                                        source.trim().toLowerCase()
                                      ]
                                  }" *ngIf="i < 3">{{ source.trim().toUpperCase() }}</span>
                                <span *ngIf="i === 3">...</span>
                              </span>
                            </div>
                            <div>
                              <p class="zs-margin-0 zs-body-small" [ngClass]="
                                  result.ETY_TYPE === MDMCommonConstants.HCO
                                    ? 'highlight-hco-address'
                                    : 'highlight-hcp-address'
                                " [attr.title]="
                                  result.ADDRESS.ADDR_LN1 +
                                  ', ' +
                                  result.ADDRESS.ADDR_LN2
                                ">
                                {{
                                {
                                addressLine1: result.ADDRESS.ADDR_LN1,
                                addressLine2: result.ADDRESS.ADDR_LN2
                                }
                                | sliceData
                                : searchSliceDataPipeConstants.ADDRESS_LINE
                                : 21
                                }}
                              </p>
                              <p class="zs-margin-0 zs-body-small" [attr.title]="
                                  result.ADDRESS.CITY +
                                  ', ' +
                                  result.ADDRESS.ST +
                                  ' ' +
                                  result.ADDRESS.ZIP_CD
                                ">
                                {{
                                {
                                city: result.ADDRESS.CITY,
                                st: result.ADDRESS.ST,
                                zip: result.ADDRESS.ZIP_CD
                                }
                                | sliceData
                                : searchSliceDataPipeConstants.ADDRESS_ZIP_CODE
                                : 21
                                }}
                              </p>
                            </div>
                            <div class="zs-padding-1-0-1-0 zs-row">
                              <app-zs-tag nonclickable="true">
                                <span tag-value translate>
                                  <span class="font-strong">
                                    {{ result.ETY_TYPE }}
                                  </span>
                                </span>
                              </app-zs-tag>
                            </div>
                          </div>
                        </div>
                        <div class="zs-row">
                          <p class="zs-margin-0 zs-float-left zs-body zs-size-extra-small mdm-id-align">
                            <span class="truncate">
                              {{ MDMCommonConstants.MDM_ID }}:
                              <span class="font-strong" [ngClass]="
                                  result.ETY_TYPE === MDMCommonConstants.HCO
                                    ? 'highlight-hco-mdm-id'
                                    : 'highlight-hcp-mdm-id'
                                ">{{ result.MDM_ID }}</span>
                            </span>
                          </p>
                          <P class="zs-margin-0 zs-float-right zs-body zs-size-extra-small npi-id-align"
                            *ngIf="result.NPI && result.NPI.length > 0">
                            <span class="truncate">
                              {{ MDMCommonConstants.NPI }}:
                              <span class="font-strong" [ngClass]="
                                  result.ETY_TYPE === MDMCommonConstants.HCO
                                    ? 'highlight-hco-npi'
                                    : 'highlight-hcp-npi'
                                ">{{ result.NPI }}</span>
                            </span>
                          </P>
                        </div>
                      </div>
                    </app-zs-card>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  </div>
  <!-- <app-payer-records *ngIf="selectedFilter.value == MDMCommonConstants.PAYER"></app-payer-records> -->
  <app-payer-record-wrapper *ngIf="selectedFilter.value == MDMCommonConstants.PAYER"></app-payer-record-wrapper>
</div>
