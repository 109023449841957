import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import CustomPagination from 'src/app/revo-core/zsui-component-extensions/customPagination.m.js'
import { TranslateService } from '@ngx-translate/core';
import { revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';

CustomPagination.register('zs-pagination');

@Component({
  selector: 'app-zs-pagination',
  templateUrl: './zs-pagination.component.html',
  styleUrls: ['./zs-pagination.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ZsPaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() itemCount: number;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Input() pageSizeForDropdown: number;
  @Input() customStyle: object;
  @Input() showOffset = false;
  @Input() isTranslate = true;
  @Output() pageChange = new EventEmitter();
  @Input() paginationLabelRequired = true;
  uiLabels = revo_core_labels.PAGINATION;
  currentLanguage: string;
  paginationStatusLabel: string;
  environment: EnvironmentConfig;
  constructor(private _translate: TranslateService, private config: AppConfig,) {
    this.environment = this.config.getConfigObject();
    this.pageSizeForDropdown = this.environment.defaultPaginationDropdownLimit;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    const pageRange = ((this.currentPage - 1) * (this.pageSize) + 1) + '-' + Math.min(this.currentPage * this.pageSize, this.itemCount);
    this.paginationStatusLabel = this._translate.instant(this.uiLabels.SHOW, {
      pageRange: pageRange,
      itemCount: this.itemCount
    } );
  }

  onPageChange($event) {
    const oldPage = $event.detail.oldPage;
    const newPage = $event.detail.newPage;
    if (oldPage === newPage) {
      return;
    }
    this.currentPage = parseInt(newPage, 10);
    this.pageChange.emit(newPage);
    const pageRange = ((this.currentPage - 1) * (this.pageSize) + 1) + '-' + this.currentPage * this.pageSize;
    this.paginationStatusLabel = this._translate.instant(this.uiLabels.SHOW, {
      pageRange: pageRange,
      itemCount: this.itemCount
    } );
  }

  ngOnDestroy() {
  }

}
