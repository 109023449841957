import { Component, OnInit } from '@angular/core';
import { PBMTableConstant, MCOTableConstant, PayerWrapperConstants, PAYERTableConstant, PLANTableConstant } from 'src/app/mdm/constants/mdm-common-constants';
import { TabConfig } from 'src/assets/listtabs/Tab.js'

@Component({
  selector: 'app-payer-wrapper',
  templateUrl: './payer-wrapper.component.html',
  styleUrls: ['./payer-wrapper.component.less']
})
export class PayerWrapperComponent implements OnInit {
  isVisible = TabConfig.Payer_Tabs;
    PayerWrapperConstants = PayerWrapperConstants;
    chartFilters = [
    { value: PayerWrapperConstants.PAYER, Visiblity: this.isVisible.PAYER },
    { value: PayerWrapperConstants.PLAN, Visiblity: this.isVisible.PLAN },
    { value: PayerWrapperConstants.PBM, Visiblity: this.isVisible.PBM },
    { value: PayerWrapperConstants.MCO, Visiblity: this.isVisible.MCO },
  ];
  activeFilter = this.chartFilters.filter(obj => obj.Visiblity);
  activeTab = this.activeFilter[0].value;
  tableColumns: any;
  
  ngOnInit(): void {
    this.onTabClick(this.activeTab);
  }
  onTabClick(event) {
    this.activeTab = event;
    if (this.activeTab == PayerWrapperConstants.PAYER) {
      this.tableColumns = PAYERTableConstant
    } else if (this.activeTab == PayerWrapperConstants.PBM) {
      this.tableColumns = PBMTableConstant;
    } else if (this.activeTab == PayerWrapperConstants.MCO) {
      this.tableColumns = MCOTableConstant
    } else if (this.activeTab == PayerWrapperConstants.PLAN) {
      this.tableColumns = PLANTableConstant
    }
  }
}
