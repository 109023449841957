import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MDMCommonConstants,
  MDMWrapperConstants,
} from '../constants/mdm-common-constants';
import {
  SearchConstants,
  searchSliceDataPipeConstants,
} from '../constants/search-constants';

import { AppConfig } from 'src/app/app.config';
import { DateTimeConstants } from 'src/app/shared/constants';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { HighlightTextPipe } from 'src/app/revo-core/editable-table/highlight-text.pipe';
import { MDMService } from '../mdm.service';
import { MultiSelectDropdownComponent } from 'src/app/revo-core/multi-select-dropdown/multi-select-dropdown.component';
import { Pagination } from 'src/app/shared/models/pagination.models';
import { QueryParams } from '../constants/profile-constants';
import { Subject } from 'rxjs';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { common_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { debounceTime } from 'rxjs/operators';
import { mdm_labels } from '../constants/ui-translation';
import { TabConfig }  from 'src/assets/listtabs/Tab.js'
import { containsSpecialCharacters } from 'src/app/shared/utils/string-check-utils';

declare var jQuery: any;

@Component({
  selector: 'mdm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  @ViewChild('filterSearch', { static: false })
  filterSearchDropDownContent: MultiSelectDropdownComponent;
  loading: boolean;
  results: any[];
  commmon_labels = commmon_labels;
  common_labels = common_labels;
  ui_labels = mdm_labels.ALL_RECORDS.SEARCH;
  translated_labels: any;
  searchPlaceholder: string;
  error_label: string;
  MDMWrapperConstants = MDMWrapperConstants;
  searchConstants = SearchConstants;
  now = new Date();
  txtChangedSubscr: any;
  public inputFieldChangedFunc: Function;
  public tagRemovedFunc: Function;
  loadingFromNgOnInit = false;
  pageSizeForDropdown: number;
  selectedFilter: any;

  get appliedFilter(): any[] {
    return this.visibleFilters.filter((f) => f.applied);
  }

  hcpCount = 0;
  hcoCount = 0;
  sortColumns = [SearchConstants.NAME];
  sortOrders = [SearchConstants.A_TO_Z, SearchConstants.Z_TO_A];
  allCounter = 0;
  showTags = false;
  keepFlagsVisible = false;

  profileNameLabellength = 17;

  addressLabel: string;
  checkboxLabel: string;
  pdrpFlagLabel: string;
  kaiserFlagLabel: string;
  dmrFlagLabel: string;
  targetFlagLabel: string;
  accountNameLabel: string;
  classOfTradeLabel: string;
  nameLabel: string;
  loadingLabel: string;
  flagsLabel: string;
  threeDotConstant = "...";

  filtersExceptAll = [];
  filters: any[] = [
    {
      displayLabel: MDMCommonConstants.HCP,
      label: MDMCommonConstants.HCP,
      value: MDMCommonConstants.HCP,
      applied: true,
      show: true,
      display: TabConfig.Entity_Type.HCP,
      selected: true,
      fields: [],
      selectedValue: undefined,
      checked: true,
    },
    {
      displayLabel: MDMCommonConstants.HCO,
      label: MDMCommonConstants.HCO,
      value: MDMCommonConstants.HCO,
      applied: false,
      show: true,
      display: TabConfig.Entity_Type.HCO,
      selected: false,
      selectedValue: undefined,
    },
    {
      displayLabel: MDMCommonConstants.PAYER,
      label: MDMCommonConstants.PAYER,
      value: MDMCommonConstants.PAYER,
      applied: false,
      show: true,
      display: TabConfig.Entity_Type.PAYER,
      selected: false,
      selectedValue: undefined,
    },
  ];

  visibleFilters=this.filters.filter( obj => obj.display);

  flags = [];

  MDMCommonConstants = MDMCommonConstants;
  @ViewChild('cards', { read: ElementRef }) cards: ElementRef;

  paginationData: Pagination = {
    ItemCount: 0,
    CurrentPage: 1,
    CustomStyle: ''
  };
  currentPage: 1;

  txtChanged: Subject<string> = new Subject<string>();

  dateTimeConstants = DateTimeConstants;
  environment: EnvironmentConfig;
  isFilterCalledFromNgOnInit = false;
  searchSliceDataPipeConstants = searchSliceDataPipeConstants;
  profileNotExistsError: string;

  checkSpecialCharacter: boolean = false;

  constructor(
    private service: MDMService,
    private _highlightText: HighlightTextPipe,

    private route: ActivatedRoute,
    private router: Router,
    private _translate: TranslateService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService
  ) {
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant([
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels.SEARCH_PLACEHOLDER,
      this.ui_labels.ALL,
      this.ui_labels.PROFILE_DOES_NOT_EXISTS,
      this.ui_labels.ADDRESS,
      this.ui_labels.PDRP_FLAG,
      this.ui_labels.KAISER_FLAG,
      this.ui_labels.DMR_FLAG,
      this.ui_labels.TARGET_FLAG,
      this.ui_labels.ACCOUNT_NAME,
      this.ui_labels.CLASS_OF_TRADE,
      this.ui_labels.NAME,
      this.ui_labels.FLAGS,
      this.common_labels.YOUR_RESULTS_ARE_LOADING,
      this.common_labels.NO_RECORDS_TO_DISPLAY
    ]);
    this.searchPlaceholder =
      this.translated_labels[this.ui_labels.SEARCH_PLACEHOLDER];
    this.error_label =
      this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
    this.addressLabel = this.translated_labels[this.ui_labels.ADDRESS];
    this.pdrpFlagLabel = this.translated_labels[this.ui_labels.PDRP_FLAG];
    this.kaiserFlagLabel = this.translated_labels[this.ui_labels.KAISER_FLAG];
    this.dmrFlagLabel = this.translated_labels[this.ui_labels.DMR_FLAG];
    this.targetFlagLabel = this.translated_labels[this.ui_labels.TARGET_FLAG];
    this.accountNameLabel = this.translated_labels[this.ui_labels.ACCOUNT_NAME];
    this.classOfTradeLabel =
      this.translated_labels[this.ui_labels.CLASS_OF_TRADE];
    this.nameLabel = this.translated_labels[this.ui_labels.NAME];
    this.profileNotExistsError =
      this.translated_labels[this.ui_labels.PROFILE_DOES_NOT_EXISTS];
    this.loadingLabel = this.translated_labels[this.common_labels.YOUR_RESULTS_ARE_LOADING];
    this.flagsLabel = this.translated_labels[this.ui_labels.FLAGS];

    this.setUpFilterData();

    this.txtChangedSubscr = this.txtChanged
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.paginationData.CurrentPage = 1;
        this.loadSearches();
      });
  }

  ngOnInit() {
    this.selectedFilter = this.visibleFilters[0];
    this.service.setActiveTab(MDMWrapperConstants.ALL_RECORDS);
    this.paginationData.PageSize = this.environment.pageSize;
    this.pageSizeForDropdown = this.environment.defaultPaginationDropdownLimit;
    this.inputFieldChangedFunc = this.inputFieldChanged.bind(this);
    this.tagRemovedFunc = this.tagRemoved.bind(this);
    this.showErrorIfProfileDoesNotExists();
    this.route.params.subscribe((params) => {
      this.loadSearches();
    });
    this.isFilterCalledFromNgOnInit = true;
    this.selectedFilterChanged(this.selectedFilter);
  }

  showErrorIfProfileDoesNotExists() {
    if (
      this.route.snapshot.queryParamMap.get(QueryParams.IS_PROFILE_EXISTS) ===
      'false'
    ) {
      this._toasterDataService.addMessageToToaster(this.profileNotExistsError);
    }
  }
  findclass(filter_name, field_name) {
    if (filter_name === this.MDMCommonConstants.HCP) {
      return field_name === this.searchConstants.ADDRESS
        ? 'hcp-address-width filter-input-fields'
        : 'hcp-text-fields-width filter-input-fields';
    }
    if (field_name === this.searchConstants.ACCOUNT_NAME) {
      return 'hco-account-width filter-input-fields';
    }
    if (field_name === this.searchConstants.ADDRESS) {
      return 'hco-address-width filter-input-fields';
    }
    if (field_name === this.searchConstants.CLASS_OF_TRADE) {
      return 'hco-cot-width filter-input-fields';
    }
    return 'hco-text-fields-width filter-input-fields';
  }

  pageChange(newPage) {
    this.currentPage = newPage;
    this.paginationData.CurrentPage = this.currentPage;
    this.loadSearches();
  }

  flipAccordion(showAccordion) {
    this.visibleFilters.forEach((filter) => (filter.show = showAccordion));
    if (showAccordion) {
      if (this.showTags) {
        this.visibleFilters.forEach((filter) =>
          filter.fields.forEach((field) => (field.keepTagVisible = true))
        );
        this.keepFlagsVisible = true;
      }
    }
  }

  setUpFilterData() {
    this.flags = [
      {
        isSelected: false,
        displayLabel: this.kaiserFlagLabel,
        label: SearchConstants.KAISER_FLAG,
        value: SearchConstants.KAISER_FLAG,
        applied: true,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: this.pdrpFlagLabel,
        label: SearchConstants.PDRP_FLAG,
        value: SearchConstants.PDRP_FLAG,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: this.targetFlagLabel,
        label: SearchConstants.TARGET_FLAG,
        value: SearchConstants.TARGET_FLAG,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
      {
        isSelected: false,
        displayLabel: this.dmrFlagLabel,
        label: SearchConstants.DMR_FLAG,
        value: SearchConstants.DMR_FLAG,
        applied: false,
        disable: false,
        show: true,
        display: true,
        fields: [],
        selected: undefined,
        selectedValue: undefined,
      },
    ];
    this.visibleFilters[0].fields = [
      {
        type: SearchConstants.TEXT,
        displayLabel: SearchConstants.MDM_ID,
        label: SearchConstants.MDM_ID,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: MDMCommonConstants.NPI,
        label: MDMCommonConstants.NPI,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: this.nameLabel,
        label: SearchConstants.NAME,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: this.addressLabel,
        label: SearchConstants.ADDRESS,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.SELECT_DROPDOWN,
        displayLabel: this.flagsLabel,
        label: SearchConstants.FLAGS,
        applied: true,
        datasource: this.flags,
        selectedValue: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.CHECKBOX,
        displayLabel: this.pdrpFlagLabel,
        label: SearchConstants.PDRP_FLAG,
        applied: true,
        selected: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.CHECKBOX,
        displayLabel: this.kaiserFlagLabel,
        label: SearchConstants.KAISER_FLAG,
        applied: true,
        selected: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.CHECKBOX,
        displayLabel: this.dmrFlagLabel,
        label: SearchConstants.DMR_FLAG,
        applied: true,
        selected: undefined,
        keepTagVisible: false,
      },
      {
        type: SearchConstants.CHECKBOX,
        displayLabel: this.targetFlagLabel,
        label: SearchConstants.TARGET_FLAG,
        applied: true,
        selected: undefined,
        keepTagVisible: false,
      },
    ];
    if (this.visibleFilters.length>1)
    {
    this.visibleFilters[1].fields = [
      {
        type: SearchConstants.TEXT,
        displayLabel: SearchConstants.MDM_ID,
        label: SearchConstants.MDM_ID,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: MDMCommonConstants.NPI,
        label: MDMCommonConstants.NPI,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: MDMCommonConstants.HIN,
        label: MDMCommonConstants.HIN,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: MDMCommonConstants.DEA,
        label: MDMCommonConstants.DEA,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: this.accountNameLabel,
        label: SearchConstants.ACCOUNT_NAME,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: this.addressLabel,
        label: SearchConstants.ADDRESS,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
      {
        type: SearchConstants.TEXT,
        displayLabel: this.classOfTradeLabel,
        label: SearchConstants.CLASS_OF_TRADE,
        applied: true,
        selectedValue: '',
        keepTagVisible: false,
      },
    ];
  }
}

  applyfilters() {
    let inputArr = [];
    if (this.selectedFilter.value === 'HCP') {
      inputArr = this.visibleFilters[0].fields;
    } else if (this.selectedFilter.value === 'HCO') {
      inputArr = this.visibleFilters[1].fields;
    }
    const inputArray = inputArr
      .filter((item) => item.selectedValue !== undefined)
      .map((item) => item.selectedValue);
    this.checkSpecialCharacter = containsSpecialCharacters(inputArray, true);
    if (!this.checkSpecialCharacter) {
      this.showTags = true;
      this.txtChanged.next();
    }
  }

  selectedFilterChanged(e) {
    this.checkSpecialCharacter = false;
    this.keepFlagsVisible = false;
    this.showTags = false;
    if ((e as any).target) {
      return;
    }

    this.visibleFilters.forEach((f) => (f.applied = false));

    const item = this.visibleFilters.find((f) => f.label === e.label || f.label === e);
    if (item) {
      item.applied = true;
    }

    if (this.isFilterCalledFromNgOnInit === true) {
      this.isFilterCalledFromNgOnInit = false;
    } else if (this.isFilterCalledFromNgOnInit === false) {
      // this.deselectAllFields();
      this.paginationData.CurrentPage = 1;
      if (e.value !== 'PAYER') {
        this.setUpFilterData();
        this.loadSearches();
      }

    }
  }

  queryChanged() {
    this.loadSearches();
  }

  loadSearches() {
    setTimeout(() => {
      jQuery(jQuery('.all-text')[0]).hide();
    }, 50);

    this.loading = true;
    this.loadingFromNgOnInit = this.isFilterCalledFromNgOnInit ? true : false;
    const categories = {};
    this.visibleFilters
      .filter((f) => f.applied)
      .forEach((f) => {
        const subCategory = {};
        f.fields
          .filter((fld) => this.isFieldApplied(fld))
          .forEach((fld) => {
            subCategory[fld.label] = this.getFieldValue(fld);
          });

        categories[f.value] = subCategory;
      });

    if (_.isEmpty(categories)) {
      this.loading = false;
      this.hcpCount = 0;
      this.hcoCount = 0;
      this.paginationData.ItemCount = 0;
      this.results = [];
    } else {
      this.service
        .getSearchData(
          categories,
          this.paginationData.CurrentPage,
          this.paginationData.PageSize
        )
        .subscribe(
          (result) => {
            this.loading = false;

            if (result) {
              this.paginationData.ItemCount = result.totalRecords;
              this.pageSizeForDropdown = this.paginationData.ItemCount > this.environment.showLinearPaginationDropdownThreshold ?
                this.environment.disablePaginationDropdownRecordLimit : this.environment.defaultPaginationDropdownLimit;
              this.results = result.data;

              this.highlight();
            } else {
              this.hcpCount = 0;
              this.hcoCount = 0;
              this.paginationData.ItemCount = 0;
              this.results = [];
            }
          },
          (exception) => {
            this.loading = false;
            this.hcpCount = 0;
            this.hcoCount = 0;
            this.paginationData.ItemCount = 0;
            this.results = [];
            this._toasterDataService.addMessageToToaster(
              exception.error.message
            );
          }
        );
    }
  }

  flagsChanged(flags) {
    if (!this.keepFlagsVisible) {
      this.showTags = false;
    }
    this.visibleFilters[0].fields
      .filter((element) => element['type'] === 'checkbox')
      .forEach((element) => {
        element['selected'] = undefined;
      });
    flags.forEach((f) => {
      this.visibleFilters[0].fields.find((element) => element['label'] === f['label'])[
        'selected'
      ] = true;
    });
    this.keepFlagsVisible = false;
  }

  tagRemoved(field) {
    this.deselectField(field);
    this.txtChanged.next();
    this.keepFlagsVisible = true;
  }

  inputFieldChanged(field) {
    if (!field.keepTagVisible) {
      this.showTags = false;
    } else {
      field.keepTagVisible = false;
    }
  }

  deselectAllFields() {
    this.visibleFilters.forEach((f) =>
      f.fields.forEach((field) => this.deselectField(field))
    );
  }

  getFieldValue(field: any) {
    if (this.isFieldApplied(field)) {
      if (field.type === SearchConstants.TEXT) {
        return field.selectedValue;
      }
      if (field.type === SearchConstants.CHECKBOX) {
        return MDMCommonConstants.Y;
      }
    }
    return undefined;
  }

  isFieldApplied(field: any) {
    if (field.type === SearchConstants.CHECKBOX && !field.selected) {
      return false;
    }
    return field.applied && this.fieldHasValue(field);
  }

  fieldHasValue(field: any) {
    if (field.type === SearchConstants.TEXT) {
      return !!field.selectedValue;
    }

    if (field.type === SearchConstants.CHECKBOX) {
      return true;
    }
    return false;
  }

  deselectField(field: any) {
    if (field.type === SearchConstants.TEXT) {
      field.selectedValue = '';
    }

    if (field.type === SearchConstants.CHECKBOX) {
      field.selected = undefined;
      this.visibleFilters[0].fields
        .find((f) => f.label === SearchConstants.FLAGS)
        .datasource.find((f) => f.label === field.label).isSelected = false;
      if (this.filterSearchDropDownContent) {
        this.filterSearchDropDownContent.configure();
      }
    }
  }

  highlight() {
    setTimeout(() => {
      jQuery('.truncate').each(function (i) {
        if (jQuery(this).text().length > 21) {
          jQuery(this).text(jQuery(this).text().substr(0, 21) + '...');
        }
      });

      this.visibleFilters.forEach((f) => {
        f.fields
          .filter(
            (fld) =>
              fld.type === SearchConstants.TEXT && this.isFieldApplied(fld)
          )
          .forEach((fld) => {
            const selector = `highlight-${f.label.toLowerCase() +
              '-' +
              fld.label.replace(/ /g, '-').toLowerCase()
              }`;
            const nodes =
              this.cards.nativeElement.getElementsByClassName(selector);
            let n;
            for (n = 0; n < nodes.length; ++n) {
              const value = this._highlightText.transform(
                nodes[n].innerHTML,
                fld.selectedValue
              );
              nodes[n].innerHTML = value;
            }
          });
      });
    }, 500);
  }

  goToProfile(profile: any) {
    /* Edge 17039 changes starts
    in case of routing from all records cards, Setting activeTab to "All records" */

    this.service.setActiveTab(MDMWrapperConstants.ALL_RECORDS);
    // Edge 17039 changes ends
    sessionStorage.setItem(
      SearchConstants.LAST_VISTED_PROFILE_TYPE,
      profile.ETY_TYPE
    );
    this.router.navigate(
      [
        profile.ETY_TYPE === MDMCommonConstants.HCP
          ? '/mdm/all-records/hcp/profile'
          : '/mdm/all-records/hco/profile',
        profile.MDM_ID,
      ],
      { relativeTo: this.route }
    );
  }

  ngOnDestroy() {
    if (this.txtChangedSubscr) {
      this.txtChangedSubscr.unsubscribe();
    }
  }
}
