<fieldset>
  <div class="zs-master-style text-area-div">
    <label class="text-area-label">{{hint}}
      <span *ngIf="required !==true">
        <i translate>{{common_labels.OPTIONAL_IN_PARANTHESIS}}</i>
      </span>
    </label>
    <p-is-zs-textarea #myTextArea
      [ngClass]="errorClass"
      [ngStyle]="customStyle"
      class="zs-field zs-text-area"
      (input)="onInput($event)"
      [value]="modelValue"
      [placeholder]="placeholder"
      [required]="required"
      (click)="getCaretPos(myTextArea)"
      (keyup)="getCaretPos(myTextArea)"
      [disabled]="disabled"
      [maxlength]="charLimit"
      [attr.readonly]="isReadonly"
      >
    </p-is-zs-textarea>
    <span *ngIf="touched && inErrorState" [ngClass]=" !customStyle ? 'zs-message zs-message-min-width': 'zs-message'" class="zs-error error-style">{{errorMessage}}</span>
  </div>
</fieldset>

