import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() { }

  public saveOrRetrieveDataFromSession(key, data) {
    const retrievedData = sessionStorage.getItem(key);
    if (retrievedData) return JSON.parse(retrievedData);
    if (data) sessionStorage.setItem(key, JSON.stringify(data));
    return data;
  }
  public setToSessionStorage(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
  public getFromSessionStorage(key) {
    const retrievedData = JSON.parse(sessionStorage.getItem(key));
    return retrievedData
  }
}
