import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MDMService } from '../../../mdm.service';
import { Subscription } from 'rxjs';
import { MDMWrapperConstants } from '../../../constants/mdm-common-constants';
import { CommonService } from 'src/app/mdm/services/common.service';
import { PayerWrapperConstants, MDMActivityLog } from '../../../../../../src/app/mdm/constants/mdm-common-constants';
import { PayerService } from '../payer-services/payer.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'


@Component({
  selector: 'app-payer-profile',
  templateUrl: './payer-profile.component.html',
  styleUrls: ['./payer-profile.component.less']
})
export class PayerProfileComponent implements OnInit {
  _returnId = '';
  profileNameWithMdmId: string = '';
  showReturnToProfile = false;
  returnToProfileLabel: string = 'Return to profile';
  tempVal = true;
  urlParamsSubscriber: Subscription;
  mdmId: string;
  isMergeActive = false;
  suspectedRecordsLabel = 'Suspected Matches';
  allRecordsLabel = 'All Records';
  page_details: any;
  MDMWrapperConstants = MDMWrapperConstants;
  activeBreadcrumbTab: any;
  matches: any;
  matchesLength: number;
  matchesSubscribe: any;
  profileDetailsSubscribe: any;
  constructor(private service: MDMService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private payerService: PayerService,
    private toaster: ToasterNotificationService,) {

    const route_state = this.router.getCurrentNavigation()!.extras.state;
    if (route_state) {
      this.page_details = route_state;
      this.commonService.setToSessionStorage('pageDetails', route_state);
    } else {
      this.page_details = this.commonService.getFromSessionStorage('pageDetails')
    }
    this.urlParamsSubscriber = route.params.subscribe((params) => {
      this._returnId = params['id'];
      this.getProfileDetails();
    });
    // detects router change for breadcrum
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showReturnToProfile = this.router.url.includes(MDMActivityLog.MERGE);
      }
    });
  }

  ngOnInit(): void {

    //initialize behavioral subject on load
    this.payerService.LineageDetails$.next({ golden: '', profiles: '' });
    this.payerService.profileDetails$.next([]);

    this.activeBreadcrumbTab = this.service.getActiveTab();
    this.matchesSubscribe = this.payerService.matches$.subscribe((matches) => {
      this.matchesLength = matches.length;
    })
    this.profileDetailsSubscribe = this.payerService.profileDetails$.subscribe((data: any) => {
      this.profileNameWithMdmId = `${data.name}(${data.mdm_id})`
    })

  }
  goToProfile() {
    this.service.refreshAffiliationTab(this._returnId);
    this.tempVal = false;
  }

  getProfileDetails() {
    if (this.page_details.entity == PayerWrapperConstants.PBM) {
      this.payerService.getPBMProfileByid(this._returnId);
    } else if (this.page_details.entity == PayerWrapperConstants.MCO) {
      this.payerService.getMCOProfileByid(this._returnId);
    } else if (this.page_details.entity == PayerWrapperConstants.PAYER) {
      this.payerService.getPAYERProfileByid(this._returnId);
    } else if (this.page_details.entity == PayerWrapperConstants.PLAN) {
      this.payerService.getPLANProfileByid(this._returnId);
    }
  }

  ngOnDestroy(): void {
    this.matchesSubscribe.unsubscribe();
    this.urlParamsSubscriber.unsubscribe();

  }
}
