import { TranslateService } from '@ngx-translate/core';
import { BackendUserMessages } from '../constants/ui_labels_translation_mapping';
import { SecurityContext } from '@angular/core';

export class RDHMultipleMessages {
    ui_labels = BackendUserMessages;
    defaultErrorMessage: string;
    translated_labels: any;
    constructor(private _translate: TranslateService,
        public message) {
        this.translated_labels = this._translate.instant(
            [
                this.ui_labels.SOME_ERROR_OCCURED
            ]
        );
        this.defaultErrorMessage = this.translated_labels[this.ui_labels.SOME_ERROR_OCCURED];
        this.message = message;
    }

    translate() {
        try {
            let errorMessage = '';
            for (const message of this.message) {
                errorMessage += '<li>' + this._translate.instant(
                    this.ui_labels[message.name],
                    message.parameters
                ) + '</li>';
            }
            return errorMessage;
        } catch {
            return this.defaultErrorMessage;
        }

    }

}
