import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mdm-identifier',
  templateUrl: './identifier.component.html'
})
export class IdentifierComponent {

    @Input() identifier: any;

    constructor() { }
}
