<div class="detail-content zs-margin-2-0-0-0 " appZsLoader [isVisible]="isLoading">
    <div *ngIf="!isLoading">
        <div class="detail-header">
            <div>
                <h3 class="zs-margin-0">Change Request Details : <span
                        class="zs-counter">{{dcr_details?.DCR_NAME}}</span>
                </h3>
                <div class="zs-margin-1">
                    <span class="zs-subtitle-2">Status:</span>
                    <span class="zs-badge-text zs-size-xs zs-bg-info-inverse zs-text-info"
                        *ngIf="dcr_details?.DCR_STATUS == DCRStatus.PROCESSED">Processed</span>
                    <span class="zs-badge-text zs-size-xs zs-bg-success-inverse zs-text-success"
                        *ngIf="dcr_details?.DCR_STATUS == DCRStatus.APPROVED || dcr_details?.DCR_STATUS == DCRStatus.SUBMITTED">approved</span>
                    <span class="zs-badge-text zs-size-xs zs-bg-error-inverse zs-text-error"
                        *ngIf="dcr_details?.DCR_STATUS == DCRStatus.CANCELLED ">Cancelled</span>
                    <span class="zs-badge-text zs-size-xs zs-bg-warning-inverse zs-text-warning"
                        *ngIf="dcr_details?.DCR_STATUS == DCRStatus.PENDING && (dcr_details.IS_HOME_OFFICE =='N' || dcr_details.IS_HOME_OFFICE == null)">Pending</span>
                    <span class="zs-badge-text zs-size-xs zs-bg-warning-inverse zs-text-warning"
                        *ngIf="dcr_details?.DCR_STATUS == DCRStatus.PENDING && dcr_details.IS_HOME_OFFICE == 'Y'">
                        Pending
                        HQ
                        Approval</span>
                    <span class="zs-margin-1">|</span>
                    <span class="zs-subtitle-2">Type:</span>

                    <span *ngIf="dcr_details?.CHANGE_REQUEST_TYPE == 'Edit_vod'"
                        class="zs-body-small zs-text-uppercase zs-font-weight-bold">
                        EDIT
                    </span>
                    <span *ngIf="dcr_details?.CHANGE_REQUEST_TYPE == 'New_vod'"
                        class="zs-body-small zs-text-uppercase zs-font-weight-bold">
                        NEW
                    </span>
                    <span class="zs-margin-1">|</span>
                    <span class="zs-subtitle-2"> Sub Type:</span>

                    <span class="zs-body-small zs-text-uppercase zs-font-weight-bold">
                        {{dcr_details?.CHANGE_REQUEST_SUB_TYPE}}
                    </span>
                </div>

            </div>
            <div>
                <button class="zs-button  zs-border-rounded-0" [routerLink]="['/mdm/dcr-management']">
                    <span class="zs-icon zs-size-large zs-icon-arrow-prev-circle language-icon-globe"></span>
                    back
                </button>
            </div>
        </div>
        <table class="zs-table">
            <tbody>
                <tr>

                    <td><span class="zs-font-weight-bold">Entity Type</span> <br>
                        <span *ngIf="dcr_details?.ENTITYTYPE">{{dcr_details?.ENTITYTYPE}}</span>
                        <span *ngIf="!dcr_details?.ENTITYTYPE">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Account Name</span> <br>
                        <span *ngIf="dcr_details?.ACCOUNT_NAME">{{dcr_details?.ACCOUNT_NAME}}</span>
                        <span *ngIf="!dcr_details?.ACCOUNT_NAME">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">MDM ID</span> <br>
                        <span *ngIf="dcr_details?.MDM_ID">
                            {{dcr_details?.MDM_ID}}
                        </span>
                        <span *ngIf="!dcr_details?.MDM_ID && dcr_details?.CHANGE_REQUEST_TYPE == 'New_vod' ">
                            NA
                        </span>
                        <span *ngIf="!dcr_details?.MDM_ID && dcr_details?.CHANGE_REQUEST_TYPE != 'New_vod' ">
                            -
                        </span>
                    </td>
                    <td><span class="zs-font-weight-bold">NPI</span> <br>
                        <span *ngIf="dcr_details?.NPI">
                            {{dcr_details?.NPI}}
                        </span>
                        <span *ngIf="!dcr_details?.NPI && dcr_details?.CHANGE_REQUEST_TYPE == 'New_vod' ">
                            NA
                        </span>
                        <span *ngIf="!dcr_details?.NPI && dcr_details?.CHANGE_REQUEST_TYPE != 'New_vod' ">
                            -
                        </span>

                    </td>

                </tr>
                <tr>
                    <td><span class="zs-font-weight-bold">Created By</span> <br>
                        <span *ngIf="dcr_details?.REQUEST_CREATED_BY">{{dcr_details?.REQUEST_CREATED_BY}}</span>
                        <span *ngIf="!dcr_details?.REQUEST_CREATED_BY">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Created Date</span> <br>
                        <span *ngIf="dcr_details?.REQUEST_CREATED_DATE">{{dcr_details?.REQUEST_CREATED_DATE}}</span>
                        <span *ngIf="!dcr_details?.REQUEST_CREATED_DATE">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Last Modified By</span> <br>
                        <span *ngIf="dcr_details?.LAST_MODIFIED_BY">{{dcr_details?.LAST_MODIFIED_BY}}</span>
                        <span *ngIf="!dcr_details?.LAST_MODIFIED_BY">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Last Modified Date</span> <br>
                        <span *ngIf="dcr_details?.LAST_MODIFIED_DATE">{{dcr_details?.LAST_MODIFIED_DATE}}</span>
                        <span *ngIf="!dcr_details?.LAST_MODIFIED_DATE">-</span>
                    </td>

                </tr>
                <tr>
                    <td><span class="zs-font-weight-bold">Manager</span><br>
                        <span *ngIf="dcr_details?.MANAGER_NAME">{{dcr_details?.MANAGER_NAME}}</span>
                        <span *ngIf="!dcr_details?.MANAGER_NAME">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">User geo </span><br>
                        <span *ngIf="dcr_details?.USER_GEO">{{dcr_details?.USER_GEO}}</span>
                        <span *ngIf="!dcr_details?.USER_GEO">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Notes</span> <br>
                        <span *ngIf="dcr_details?.REQUESTERS_COMMENTS">{{dcr_details?.REQUESTERS_COMMENTS}}</span>
                        <span *ngIf="!dcr_details?.REQUESTERS_COMMENTS">-</span>
                    </td>
                    <td><span class="zs-font-weight-bold">Territory</span><br>
                        <span *ngIf="dcr_details?.TERRITORY">{{dcr_details?.TERRITORY}}</span>
                        <span *ngIf="!dcr_details?.TERRITORY">-</span>
                    </td>
                </tr>
                <tr>
                    <td *ngIf="dcr_details?.COMMENTS">
                        <span class="zs-font-weight-bold">Comments</span> <br>

                        <span *ngIf="dcr_details?.COMMENTS">{{dcr_details?.COMMENTS}}</span>
                        <span *ngIf="!dcr_details?.COMMENTS">-</span>
                    </td>
                </tr>

            </tbody>
        </table>
        <div class=" zs-margin-2-0-3-0">
            <div class="table-content zs-margin-2-0-0-0">
                <div class="table-header zs-margin-2-0-3-0">
                    <div class="zs-margin-2">
                        <h3>Data Change Request lines</h3>
                    </div>
                </div>
                <div class=" ag-grid-component">
                    <div class="grid-table-loader">
                        <div class="zs-ag-grid zs-custom-scrollbar zs-margin-1-1-1-1">
                            <ag-grid-angular class="ag-theme-alpine" [columnDefs]="colDefs" [gridOptions]="gridOptions"
                                [rowData]="rowData" [enableSorting]="true" [suppressCellSelection]="true"
                                [enableCellTextSelection]="true" [domLayout]="domLayout" [suppressLoadingOverlay]="true"
                                [overlayNoRowsTemplate]="noRowsTemplate" (gridReady)="onGridReady($event)">
                            </ag-grid-angular>
                        </div>

                    </div>
                </div>
            </div>
            <div class="manage-details"
                *ngIf="dcr_details?.DCR_STATUS != DCRStatus.APPROVED && dcr_details?.DCR_STATUS != DCRStatus.CANCELLED && dcr_details?.DCR_STATUS != DCRStatus.SUBMITTED && dcr_details?.DCR_STATUS != DCRStatus.PROCESSED">
                <app-zs-textarea [placeholder]="'Add Comments'" [hint]="'Comments'" [required]="false"
                    [(modelValue)]="comments">
                </app-zs-textarea>
            </div>
            <div class="manage-details"
                *ngIf="dcr_details?.DCR_STATUS != DCRStatus.APPROVED && dcr_details?.DCR_STATUS != DCRStatus.CANCELLED && dcr_details?.DCR_STATUS != DCRStatus.SUBMITTED && dcr_details?.DCR_STATUS != DCRStatus.PROCESSED">

                <zs-loader class="zs-loader zs-inverse" *ngIf="showSubmitLoader"></zs-loader>
                <button class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-1-2-0-0"
                    (click)="updateDcr(DCRStatus.APPROVED,dcr_details?.DCR_ID)">
                    Approve
                </button>
                <button class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-1-2-0-0"
                    (click)="updateDcr(DCRStatus.CANCELLED,dcr_details?.DCR_ID)">
                    Reject
                </button>
                <button
                    *ngIf="dcr_details?.DCR_STATUS == DCRStatus.PENDING && (dcr_details.IS_HOME_OFFICE =='N' ||dcr_details.IS_HOME_OFFICE == null) "
                    class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-1-2-0-0"
                    (click)="updateDcr(DCRStatus.HOMEOFFICE,dcr_details?.DCR_ID)">
                    Delegate to HQ
                </button>
            </div>

        </div>
    </div>
</div>
<zs-drawer *ngIf="this.dcr_details?.ENTITYTYPE && this.dcr_details?.MDM_ID" class="zs-drawer zs-box-shadow"
    align="right">
    <mdm-profile-details [mdmId]="this.dcr_details?.MDM_ID" [entitytype]="this.dcr_details?.ENTITYTYPE">
    </mdm-profile-details>
</zs-drawer>