import { CyberSecurity } from "./CyberSecurity";
import { Icp } from "./Icp";

export class Footer {
    logoName: string;
    cyberSecurity: CyberSecurity;
    icp: Icp;
    termsOfUseUrl: string;
    privacyPolicyUrl: string;
    constructor(termsOfUseUrl: string, privacyPolicyUrl: string, logoName: string = null,
        cyberSecurity: CyberSecurity = null,
        icp: Icp = null) {
        this.termsOfUseUrl = termsOfUseUrl;
        this.privacyPolicyUrl = privacyPolicyUrl;
        this.logoName = logoName;
        this.cyberSecurity = cyberSecurity;
        this.icp = icp;
    }
}