import { ApiRequestConstants } from './shared/constants';
import { HttpParams } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { utils_constants } from './shared/constants/ui_labels_translation_mapping';

@Injectable({
  providedIn: 'root'
})
export class ServiceUtils {
  translated_labels: any;
  seconds: string;
  one_minute_ago: string;
  one_minute_from_now: string;
  minutes: string;
  one_hour_ago: string;
  one_hour_from_now: string;
  hours: string;
  yesterday: string;
  tomorrow: string;
  days: string;
  last_week: string;
  next_week: string;
  weeks: string;
  last_month: string;
  next_month: string;
  months: string;
  last_year: string;
  next_year: string;
  years: string;
  last_century: string;
  next_century: string;
  centuries: string;
  ago: string;
  just_now: string;
  from_now: string;
  na_get_time_from_minute: string;
  sec_get_time_from_minute: string;
  min_get_time_from_minute: string;
  hour_get_time_from_minute: string;
  hours_get_time_from_minute: string;
  day_get_time_from_minute: string;
  days_get_time_from_minute: string;
  get_relative_time_constants = utils_constants.GET_RELATIVE_TIME;
  get_time_from_minute_constants = utils_constants.GET_TIME_FROM_MINUTE;
  constructor(private _localStorageService: LocalStorageService,
    private _translate: TranslateService) {
    this.translated_labels = this._translate.instant(
      [
        this.get_relative_time_constants.SECONDS,
        this.get_relative_time_constants.ONE_MINUTE_AGO,
        this.get_relative_time_constants.ONE_MINUTE_FROM_NOW,
        this.get_relative_time_constants.MINUTES,
        this.get_relative_time_constants.ONE_HOUR_AGO,
        this.get_relative_time_constants.ONE_HOUR_FROM_NOW,
        this.get_relative_time_constants.HOURS,
        this.get_relative_time_constants.YESTERDAY,
        this.get_relative_time_constants.TOMORROW,
        this.get_relative_time_constants.DAYS,
        this.get_relative_time_constants.LAST_WEEK,
        this.get_relative_time_constants.NEXT_WEEK,
        this.get_relative_time_constants.WEEKS,
        this.get_relative_time_constants.LAST_MONTH,
        this.get_relative_time_constants.NEXT_MONTH,
        this.get_relative_time_constants.MONTHS,
        this.get_relative_time_constants.LAST_YEAR,
        this.get_relative_time_constants.NEXT_YEAR,
        this.get_relative_time_constants.YEARS,
        this.get_relative_time_constants.LAST_CENTURY,
        this.get_relative_time_constants.NEXT_CENTURY,
        this.get_relative_time_constants.CENTURIES,
        this.get_relative_time_constants.AGO,
        this.get_relative_time_constants.JUST_NOW,
        this.get_relative_time_constants.FROM_NOW,
        this.get_time_from_minute_constants.NA,
        this.get_time_from_minute_constants.DAY,
        this.get_time_from_minute_constants.DAYS,
        this.get_time_from_minute_constants.HOURS,
        this.get_time_from_minute_constants.HOUR,
        this.get_time_from_minute_constants.MIN,
        this.get_time_from_minute_constants.SECONDS
      ]);
    this.seconds = this.translated_labels[this.get_relative_time_constants.SECONDS];
    this.one_minute_ago = this.translated_labels[this.get_relative_time_constants.ONE_MINUTE_AGO];
    this.one_minute_from_now = this.translated_labels[this.get_relative_time_constants.ONE_MINUTE_FROM_NOW];
    this.minutes = this.translated_labels[this.get_relative_time_constants.MINUTES];
    this.one_hour_ago = this.translated_labels[this.get_relative_time_constants.ONE_HOUR_AGO];
    this.one_hour_from_now = this.translated_labels[this.get_relative_time_constants.ONE_HOUR_FROM_NOW];
    this.hours = this.translated_labels[this.get_relative_time_constants.HOURS];
    this.yesterday = this.translated_labels[this.get_relative_time_constants.YESTERDAY];
    this.tomorrow = this.translated_labels[this.get_relative_time_constants.TOMORROW];
    this.days = this.translated_labels[this.get_relative_time_constants.DAYS];
    this.last_week = this.translated_labels[this.get_relative_time_constants.LAST_WEEK];
    this.next_week = this.translated_labels[this.get_relative_time_constants.NEXT_WEEK];
    this.weeks = this.translated_labels[this.get_relative_time_constants.WEEKS];
    this.last_month = this.translated_labels[this.get_relative_time_constants.LAST_MONTH];
    this.next_month = this.translated_labels[this.get_relative_time_constants.NEXT_MONTH];
    this.months = this.translated_labels[this.get_relative_time_constants.MONTHS];
    this.last_year = this.translated_labels[this.get_relative_time_constants.LAST_YEAR];
    this.next_year = this.translated_labels[this.get_relative_time_constants.NEXT_YEAR];
    this.years = this.translated_labels[this.get_relative_time_constants.YEARS];
    this.last_century = this.translated_labels[this.get_relative_time_constants.LAST_CENTURY];
    this.next_century = this.translated_labels[this.get_relative_time_constants.NEXT_CENTURY];
    this.centuries = this.translated_labels[this.get_relative_time_constants.CENTURIES];
    this.ago = this.translated_labels[this.get_relative_time_constants.AGO];
    this.just_now = this.translated_labels[this.get_relative_time_constants.JUST_NOW];
    this.na_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.NA];
    this.hour_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.HOUR];
    this.hours_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.HOURS];
    this.min_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.MIN];
    this.sec_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.SECONDS];
    this.day_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.DAY];
    this.days_get_time_from_minute = this.translated_labels[this.get_time_from_minute_constants.DAYS];
  }

  getHttpParam(get_params = null) {
    const selectedEnvironmentDetails = this._localStorageService.getSelectedEnvironmentDetails();
    let params = new HttpParams();

    params = params.append(ApiRequestConstants.environment_id, selectedEnvironmentDetails[0]);
    if (get_params) {
      Object.keys(get_params).map(function (key) {
        params = params.append(key, get_params[key]);
      });
    }
    return params;
  }


  getRelativeTime(time) {
    if (time == null) {
      return null;
    }
    time = new Date(time + 'Z');
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) {
          time = time.getTime();
        }
        break;
      default:
        time = +new Date();
    }
    const time_formats = [
      [60, this.seconds, 1], // 60
      [120, this.one_minute_ago, this.one_minute_from_now], // 60*2
      [3600, this.minutes, 60], // 60*60, 60
      [7200, this.one_hour_ago, this.one_hour_from_now], // 60*60*2
      [86400, this.hours, 3600], // 60*60*24, 60*60
      [172800, this.yesterday, this.tomorrow], // 60*60*24*2
      [604800, this.days, 86400], // 60*60*24*7, 60*60*24
      [1209600, this.last_week, this.next_week], // 60*60*24*7*4*2
      [2419200, this.weeks, 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, this.last_month, this.next_month], // 60*60*24*7*4*2
      [29030400, this.months, 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, this.last_year, this.next_year], // 60*60*24*7*4*12*2
      [2903040000, this.years, 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, this.last_century, this.next_century], // 60*60*24*7*4*12*100*2
      [58060800000, this.ago, 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
      token = this.ago,
      list_choice = 1;

    if (seconds === 0) {
      return this.just_now;
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = this.from_now;
      list_choice = 2;
    }
    let i = 0,
      format;
    while (format = time_formats[i++]) {
      if (seconds < format[0]) {
        if (typeof format[2] === 'string') {
          return format[list_choice];
        } else {
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
      }
    }
    return time;

  }

  getTimeFromMinute(num) {
    if (num === undefined) {
      return this.na_get_time_from_minute;
    }
    if (num === null) {
      return '';
    }
    // if value is in negative, show as N/A
    if (num < 0) {
      return this.na_get_time_from_minute;
    }
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    if (hours === 0) {
      return `${minutes} ${this.min_get_time_from_minute}`;
    }
    if (hours === 1) {
      return `${hours} ${this.hour_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute}`;
    } else {
      return `${hours} ${this.hours_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute}`;
    }
  }

  getTimeFromSeconds(num) {

    if (num === undefined) {
      return this.na_get_time_from_minute;
    }
    if (num === null) {
      return '';
    }
    // if value is in negative, show as N/A
    if (num < 0) {
      return this.na_get_time_from_minute;
    }

    num = Math.floor(Number(num))
    const days = Math.floor(num / (24 * 60 * 60));
    num -= days * (24 * 60 * 60);
    const hours = Math.floor(num / (60 * 60));
    num -= hours * (60 * 60)
    const minutes = Math.floor(num / (60))
    num -= minutes * (60)
    const seconds = num;

    if (days === 0) {
      if (hours === 0) {
        return `${minutes} ${this.min_get_time_from_minute} ${seconds} ${this.sec_get_time_from_minute}`;
      }
      if (hours === 1) {
        return `${hours} ${this.hour_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute} ${seconds} ${this.sec_get_time_from_minute}`;
      }
      else {
        return `${hours} ${this.hours_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute} ${seconds} ${this.sec_get_time_from_minute}`;
      }
    }
    if (days == 1) {
      return `${days} ${this.day_get_time_from_minute} ${hours} ${this.hours_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute} ${seconds} ${this.sec_get_time_from_minute}`;
    }
    else {
      return `${days} ${this.days_get_time_from_minute} ${hours} ${this.hours_get_time_from_minute} ${minutes} ${this.min_get_time_from_minute} ${seconds} ${this.sec_get_time_from_minute}`;
    }

  }

  convert_time_to_utc(date: Date) {
    // convert the time to utc inorder to have time in common timezone
    let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return now_utc;
  }
}
