<div class="activies-wrapper">
  <div class="ag-grid-component">
    <div class="grid-table-loader">
      <div *ngIf="activitiesLoading" class="loader">
        <span
          class="loader-position"
          appZsLoader
          [isVisible]="activitiesLoading"
          [isWrapper]="false"
        >
          <p class="zs-text-inverse zs-body-small zs-font-weight-semi-bold">
            {{ loadingLabel }}{{threeDotConstant}}
          </p>
        </span>
      </div>
    </div>
    <div  class="zs-ag-grid zs-custom-scrollbar">
      <ag-grid-angular
        class="ag-grid-class ag-theme-alpine"
        [columnDefs]="columnDefs"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
        (sortChanged)="onSort($event)"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        [overlayNoRowsTemplate]="noRowsTemplate"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
