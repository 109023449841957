import { AppRoles, IDMDetailsConstants, app_name } from './constants';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdmDetailsService {
  roles = {};
  role_details = new Observable((observer) => {
    observer.next(this.roles);
    observer.complete();
  });

  constructor() {
    this.roles = {};
    for (const role in AppRoles) {
      if (AppRoles[role] !== undefined && AppRoles[role].trim() !== '') {
        this.roles[AppRoles[role]] = false;
      }
    }
  }

  set_roles(role_list, username) {
    if (this.roles !== undefined) {
      for (const role_name in this.roles) {
        if (role_list.indexOf(app_name + '.' + role_name) > -1) {
          this.roles[role_name] = true;
        }
      }
      this.roles[IDMDetailsConstants.USERNAME] = username;
    }
  }

  get_roles() {
    return this.roles;
  }

  getNotRequiredRolesBasedAuthorization(notRequiredRoles) {
    if (!!notRequiredRoles && !this.roles[AppRoles.ADMIN]) {
      for (const role of notRequiredRoles) {
        if (this.roles[role]) {
            return false;
        }
        }
      }
      return true;
    }
}
