<zs-tabs-container class="zs-tabs zs-tabs-solid">
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PAYER)" class="zs-tab" [targetId]="PayerWrapperConstants.PAYER"
        [attr.active]="activeTab === PayerWrapperConstants.PAYER ? true : null" *ngIf="isVisible.PAYER">PAYER</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PLAN)" class="zs-tab" target-id='div2' 
    [attr.active]="activeTab === PayerWrapperConstants.PLAN ? true : null" *ngIf="isVisible.PLAN">PLAN</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PBM)" class="zs-tab" target-id='div3'
    [attr.active]="activeTab === PayerWrapperConstants.PBM ? true : null" *ngIf="isVisible.PBM">PBM</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.MCO)" class="zs-tab" target-id='div4'
    [attr.active]="activeTab === PayerWrapperConstants.MCO ? true : null" *ngIf="isVisible.MCO">MCO</zs-tab>
</zs-tabs-container>
<div [attr.source-id]="PayerWrapperConstants.PAYER"
    [attr.active]="activeTab === PayerWrapperConstants.PAYER ? true : null">
    <div>
        <app-payer-records *ngIf="activeTab === PayerWrapperConstants.PAYER"></app-payer-records>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.PLAN"
    [attr.active]="activeTab === PayerWrapperConstants.PLAN ? true : null">
    <div>
        <app-plan-records *ngIf="activeTab === PayerWrapperConstants.PLAN"></app-plan-records>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.PBM" [attr.active]="activeTab === PayerWrapperConstants.PBM ? true : null">
    <div>
        <app-pbm-records *ngIf="activeTab === PayerWrapperConstants.PBM"></app-pbm-records>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.MCO" [attr.active]="activeTab === PayerWrapperConstants.MCO ? true : null">
    <div>
        <app-mco-records *ngIf="activeTab === PayerWrapperConstants.MCO"></app-mco-records>
    </div>
</div>