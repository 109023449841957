import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import {
  EntityTypeCount,
  GroupNames,
  MDMAttributes,
  MDMConfiguration,
  MDMConstants,
  MDMEntityTypes,
  MDMRouteConstants,
  showEntityTypeValues,
  showGroupNameValues,
  showToDisplayValues,
  showToPrimaryDisplayValues
} from 'src/app/site-admin/constants/mdm-configuration';
import { ErrorMessages, SiteAdminConstants } from 'src/app/site-admin/constants/site-admin-constants';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { FormValidationService } from 'src/app/revo-core/form-validation-service/form-validation-service';
import { MDMCommonConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { MDMService } from 'src/app/mdm/mdm.service';
import { Pagination } from 'src/app/shared/models/pagination.models';
import { SiteAdminService } from 'src/app/site-admin/site-admin.service';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { ZsSelectComponent } from 'src/app/revo-core/zs-select/zs-select.component';

@Component({
  selector: 'app-mdm-config-details',
  templateUrl: './mdm-config-details.component.html',
  styleUrls: ['./mdm-config-details.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MdmConfigDetailsComponent implements OnInit {

  @ViewChildren('isPrimarySelect') isPrimarySelect: QueryList<ZsSelectComponent>;
  errorLabel: string;
  successLabel: string;
  mdmConstants = MDMConstants;
  errorMessages = ErrorMessages;

  isEditMDMConfig = false;
  formErrors: boolean[];
  mdmConfiguration: MDMConfiguration;
  showGoBackPopup = false;
  showMdmConfigDetails = true;
  mdmAttributes: any[] = [];
  connection_string = '';
  newMDMAttributes: any[] = [];
  showToDisplayValues = showToDisplayValues;
  showToPrimaryDisplayValues = showToPrimaryDisplayValues;
  mdmGroupNameList = [];
  showGroupNameValues = showGroupNameValues;
  showEntityTypeValues = showEntityTypeValues;
  showFetchAttributes = false;
  searchValue = '';
  mdmConfigID = null;
  environmentId: number;
  showAttributeConfiguration = false;
  maxLimitExceededErrorMessage = this.errorMessages.MAX_LIMIT_EXCEEDED;
  groupAttributesMapper = new Map<string, EntityTypeCount>();
  isFormSubmitted = false;
  mdmCommonConstants = MDMCommonConstants;
  environment: EnvironmentConfig;
  showPagination = true;
  paginationData: Pagination = {
    ItemCount: 0,
    CurrentPage: 1,
    CustomStyle: '',
    PageSize: 1
  };
  cancelButtonEnabled = true;
  saveButtonEnabled = true;
  attributesFetched = false;
  tempNewAttributes = [];
  pagesInError = [];

  public formValidationFunction: Function;
  public nameValidationFunction: Function;
  public validateMdmAttributeDetailsFunction: Function;
  public validateIsPrimaryFunction: Function;

  constructor(private _siteAdminService: SiteAdminService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formValidationService: FormValidationService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService,
    private mdmService: MDMService) {
    this.formValidationFunction = this.formValidation.bind(this);
    this.formErrors = [];
    this.errorLabel = this.mdmConstants.ERROR_WITH_COLON;
    this.successLabel = this.mdmConstants.SUCCESS;
    this.environment = this.config.getConfigObject();
  }

  ngOnInit() {
    this.paginationData.PageSize = this.environment.pageSize;
    this.mdmConfiguration = new MDMConfiguration();
    this.nameValidationFunction = this.namevalidation.bind(this);
    this.validateMdmAttributeDetailsFunction = this.validateMdmAttributeDetails.bind(this);
    this.validateIsPrimaryFunction = this.validateIsPrimary.bind(this);
    this.environmentId = +this._route.snapshot.paramMap.get(MDMRouteConstants.ENVIRONMENT_ID);
    if (this.environmentId !== null && this.environmentId > 0) {
      this.isEditMDMConfig = true;
      // this.getMDMConfigurationDetails();
    }
  }

  namevalidation(value: string) {
    if (value && value.toString().trim().length === 0) {
      return MDMConstants.VALUE_REQUIRED;
    }
  }

  searchValueChange() {
    this.paginationData.CurrentPage = 1;
  }

  clearSearchValue() {
    this.searchValue = '';
    this.paginationData.CurrentPage = 1;
  }

  updateGroupAttributesMapping(value, attribute, index) {
    if (attribute.ETY_TYPE === MDMEntityTypes.HCP || attribute.ETY_TYPE === MDMEntityTypes.ALL) {
      if (value === MDMCommonConstants.Y) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hcp.add(index);
      } else if (value === MDMCommonConstants.N) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hcp.delete(index);
      }
    }
    if (attribute.ETY_TYPE === MDMEntityTypes.HCO || attribute.ETY_TYPE === MDMEntityTypes.ALL) {
      if (value === MDMCommonConstants.Y) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hco.add(index);
      } else if (value === MDMCommonConstants.N) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hco.delete(index);
      }
    }
  }

  validateIsPrimary(value, attribute_details) {
    const attribute = attribute_details[0];
    const index = attribute_details[1];
    if (attribute.TO_DISP === MDMCommonConstants.N) {
      return '';
    }
    this.updateGroupAttributesMapping(value, attribute, index);
    if (((attribute.ETY_TYPE === MDMEntityTypes.HCP || attribute.ETY_TYPE === MDMEntityTypes.ALL) &&
      this.groupAttributesMapper[attribute.GROUP_NAME].hcp.size > this.environment.maxPrimaryAttributeToDisplayPerGroup)
      || ((attribute.ETY_TYPE === MDMEntityTypes.HCO || attribute.ETY_TYPE === MDMEntityTypes.ALL) &&
        this.groupAttributesMapper[attribute.GROUP_NAME].hco.size > this.environment.maxPrimaryAttributeToDisplayPerGroup)) {
      return value === MDMCommonConstants.Y ?
        this.maxLimitExceededErrorMessage + this.environment.maxPrimaryAttributeToDisplayPerGroup : '';
    }
  }

  formValidation(errrorState: boolean) {
    this.formErrors.push(errrorState);
  }

  goBackConfirmationPopup() {
    this.showGoBackPopup = true;
  }

  hideGoBackPopup() {
    this.showGoBackPopup = false;
  }

  back() {
    this.showGoBackPopup = false;
    this._router.navigate([SiteAdminConstants.MDM_URL]);
  }

  cancel() {
    this.cancelButtonEnabled = false;
    this._router.navigate([SiteAdminConstants.MDM_URL]);
  }

  // getMDMConfigurationDetails() {
  //   this._siteAdminService.getMDMConfigurationDetails(this.environmentId).subscribe((result: MDMConfiguration) => {
  //     this.mdmConfiguration = result.id ? result : this.mdmConfiguration;
  //     this.showMdmConfigDetails = true;
  //   }, exception => {
  //     if (exception.error.message === 'No MDM Config found.') {
  //       this.showMdmConfigDetails = true;
  //     } else {
  //       this._toasterDataService.addMessageToToaster(exception.error.message);
  //     }
  //   });
  // }

  addNewMDMAttributes() {
    this.paginationData.CurrentPage = 1;
    const attribute = new MDMAttributes('', '', MDMCommonConstants.Y, MDMConstants.PROFILE, MDMEntityTypes.HCP,
     true, true, true, false);
    this.tempNewAttributes.push(attribute);
    this.mdmAttributes.unshift(attribute);
    this.searchValue = '';
    this.resetAttributeMapping();
    this.allIsPrimarySelectValidation();
  }

  deleteAttributes(attribute: any) {
    if (attribute.IS_USER_CREATED) {
      let index = null;
      for (let i = 0; i < this.mdmAttributes.length; i++) {
        if (this.mdmAttributes[i][MDMConstants.SRC_FLD] === attribute[MDMConstants.SRC_FLD] &&
          this.mdmAttributes[i][MDMConstants.TGT_FLD] === attribute[MDMConstants.TGT_FLD] &&
          this.mdmAttributes[i][MDMConstants.GROUP_NAME] === attribute[MDMConstants.GROUP_NAME]) {
          index = i;
          break;
        }
      }
      if (index != null) { this.mdmAttributes.splice(index, 1); }

      for (let i = 0; i < this.tempNewAttributes.length; i++) {
        if (this.tempNewAttributes[i][MDMConstants.SRC_FLD] === attribute[MDMConstants.SRC_FLD] &&
          this.tempNewAttributes[i][MDMConstants.TGT_FLD] === attribute[MDMConstants.TGT_FLD] &&
          this.tempNewAttributes[i][MDMConstants.GROUP_NAME] === attribute[MDMConstants.GROUP_NAME]) {
          index = i;
          break;
        }
      }
      if (index != null) { this.tempNewAttributes.splice(index, 1); }
      this.resetAttributeMapping();
      this.allIsPrimarySelectValidation();
    }
  }

  // getConnectionDetails() {
  //   const connectionStringDetail = new MDMConfiguration();
  //   connectionStringDetail.hostname = this.mdmConfiguration.hostname;
  //   connectionStringDetail.username = this.mdmConfiguration.username;
  //   connectionStringDetail.password = this.mdmConfiguration.password;
  //   connectionStringDetail.database_name = this.mdmConfiguration.database_name;
  //   connectionStringDetail.schema_name = this.mdmConfiguration.schema_name;
  //   connectionStringDetail.id = this.mdmConfigID;
  //   return connectionStringDetail;
  // }

  checkIfConnectionFiledsAreNotEmpty() {
    if (this.mdmConfiguration.hostname.length === 0 || this.mdmConfiguration.username.length === 0
      || this.mdmConfiguration.password.length === 0 || this.mdmConfiguration.database_name.length === 0
      || this.mdmConfiguration.schema_name.length === 0) {
      return true;
    }
    return false;
  }

  // New attributes can be added in this method
  addIsNewAttributeToMDMAttributes() {
    for (const attr of this.mdmAttributes) {
      attr[MDMConstants.IS_VALUE_CHANGED] = false;
    }
  }

  pageChange(newPage) {
    this.formErrors = [];
    const index = this.pagesInError.indexOf(this.paginationData.CurrentPage);
    this._formValidationService.sendMessage(this.mdmConstants.FORM_SUBMITTED);
    if (this.formErrors.includes(true)) {
      if (index < 0) {
        this.pagesInError.push(this.paginationData.CurrentPage);
      }
    } else {
      if (index > -1) {
        this.pagesInError.splice(index, 1);
      }
    }
    this.paginationData.CurrentPage = newPage;
  }

  fetchMDMAttributes() {
    this.attributesFetched = true;
      this._siteAdminService.fetchMDMAttributes().subscribe((result) => {
          this.mdmAttributes = result.Data;
          this.resetAttributeMapping();
          this.addIsNewAttributeToMDMAttributes();
          this.showAttributeConfiguration = true;
          this.tempNewAttributes.forEach(attribute => {
            this.mdmAttributes.unshift(attribute);
          });
          if (result.TotalRecords > 0) {
            this.showPagination = true;
            this.paginationData.ItemCount = result.TotalRecords;
          } else {
            this.showPagination = false;
          }
        }, exception => {
          this.mdmAttributes = [];
          this.showAttributeConfiguration = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        });
  }
  resetAttributeMapping() {
    this.groupAttributesMapper[GroupNames.ADDRESS] = new EntityTypeCount();
    this.groupAttributesMapper[GroupNames.IDENTIFIER] = new EntityTypeCount();
    this.groupAttributesMapper[GroupNames.PROFILE] = new EntityTypeCount();
  }

  onToDisplayValueChanged(event, attribute, index) {
    if (event !== attribute[MDMConstants.TO_DISP]) {
      attribute[MDMConstants.IS_VALUE_CHANGED] = true;
      attribute[MDMConstants.TO_DISP] = event;
      if (attribute.ETY_TYPE === MDMEntityTypes.HCP || attribute.ETY_TYPE === MDMEntityTypes.ALL) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hcp.delete(index);
      }
      if (attribute.ETY_TYPE === MDMEntityTypes.HCO || attribute.ETY_TYPE === MDMEntityTypes.ALL) {
        this.groupAttributesMapper[attribute.GROUP_NAME].hco.delete(index);
      }
      if (!this.isFormSubmitted) {
        this.allIsPrimarySelectValidation();
      }
    }
  }

  onToPrimaryDisplayValueChanged(event, attr) {
    const isPrimary = event === MDMCommonConstants.Y ? true : false;
    if (isPrimary !== attr[MDMConstants.IS_PRIMARY]) {
      attr[MDMConstants.IS_VALUE_CHANGED] = true;
      attr[MDMConstants.IS_PRIMARY] = isPrimary;
    }
  }

  onEntityTypeValueChange(event, index) {
    if (!this.isFormSubmitted) {
      this.RemoveValueFromGroupAttributesMapper(index);
      this.allIsPrimarySelectValidation();
    }
  }

  changePassword($event) {
    if ($event !== undefined && $event !== SiteAdminConstants.DUMMY_PASSWORD) {
    }
  }

  saveMDMConfigurationDetails() {
    this.saveButtonEnabled = false;
    this.isFormSubmitted = true;
    this.formErrors = [];
    this.resetAttributeMapping();
    this._formValidationService.sendMessage(this.mdmConstants.FORM_SUBMITTED);
    if (!this.formErrors.includes(true)) {
      const index = this.pagesInError.indexOf(this.paginationData.CurrentPage);
      if (index > -1) {
        this.pagesInError.splice(index, 1);
      }
      if (this.pagesInError.length > 0) {
        this.paginationData.CurrentPage = this.pagesInError[0];
        this.saveButtonEnabled = true;
      } else {
        this._siteAdminService.saveMDMConfigurationDetails(this.mdmAttributes,
          this.attributesFetched).subscribe(() => {
            if (this.isEditMDMConfig) {
              this._toasterDataService.addMessageToToaster(this.mdmConstants.MDM_CONFIGURATION_EDITED_SUCCESSFULLY,
                ToasterTypeConstants.SUCCESS);
                this.saveButtonEnabled = true;
              } else {
                this._toasterDataService.addMessageToToaster(this.mdmConstants.MDM_CONFIGURATION_ADDED_SUCCESSFULLY,
                  ToasterTypeConstants.SUCCESS);
                  this.saveButtonEnabled = true;
              }
              this.mdmService.clearCache();
              this._router.navigate([SiteAdminConstants.MDM_URL]);
            }, exception => {
              this.saveButtonEnabled = true;
              this._toasterDataService.addMessageToToaster(exception.error.message);
            });
      }
    } else {
      this.saveButtonEnabled = true;
    }
  }

  RemoveValueFromGroupAttributesMapper(value) {
    Object.keys(this.groupAttributesMapper).forEach(key => {
      this.groupAttributesMapper[key].hcp.delete(value);
      this.groupAttributesMapper[key].hco.delete(value);
    });
  }

  validateMdmAttributeDetails(value: any, attribute_details: any) {
    const attribute_dict = attribute_details[0];
    const attribute_key = attribute_details[1];
    const index = attribute_details[2];
    if (MDMConstants.GROUP_NAME === attribute_key && attribute_dict.TO_DISP === MDMCommonConstants.Y && attribute_dict.IS_PRIMARY === true
      && !this.isFormSubmitted) {
      this.RemoveValueFromGroupAttributesMapper(index);
      attribute_dict[attribute_key] = value;
      this.allIsPrimarySelectValidation();
    }
    let value_count = 0;
    for (let i = 0; i < this.mdmAttributes.length; i++) {
      if (attribute_dict.IS_USER_CREATED &&
        this.mdmAttributes[i][attribute_key].toLowerCase() === value.toLowerCase()) {
        if ((attribute_key === MDMConstants.GROUP_NAME &&
          this.mdmAttributes[i][MDMConstants.SRC_FLD] !== attribute_dict[MDMConstants.SRC_FLD]) ||
          (attribute_key === MDMConstants.SRC_FLD &&
            this.mdmAttributes[i][MDMConstants.GROUP_NAME] !== attribute_dict[MDMConstants.GROUP_NAME])) {
          continue;
        } else {
          value_count = value_count + 1;
        }
      }
      if (value_count > 1) {
        return this.errorMessages.DUPLICATE_VALUE;
      }
    }
    return '';
  }

  allIsPrimarySelectValidation() {
    setTimeout(() => this.isPrimarySelect.forEach(child => {
      child.validate(true);
    }));
  }
}
