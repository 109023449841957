<div class="details-table-wrapper">
	<table class="zs-width-100" [ngClass]="tableCssClass" #mergeUnmergeTable [id]="tableId">
		<thead>
			<tr>
				<th *ngIf="golden?.SRC_NM?.value?.length > 0" class="golden-profile">
					<h5 translate> {{ui_labels.SOURCES}} </h5>
					<span *ngFor="let source of golden.SRC_NM.value"
						[ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
						class="badge zs-badge-counter zs-border-rounded-0">{{ source.trim().toUpperCase() }}
					</span>
				</th>
				<th *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
					class="profile-details-data profiles-header">
					<span class="close-drag-icon">
						<span *ngIf="onMergePage" class="zs-padding-1-0-1-0 zs-icon zs-icon-close zs-size-xl"
							(click)="profileDeselected(item, $event)"></span>
						<span *ngIf="profiles?.length > 1" class="zs-icon zs-icon-item-drag"></span>
					</span>
					<div>
						<h5 translate> {{ui_labels.SOURCES}}</h5>
					</div>

					<ng-container *ngIf="item?.SRC_NM?.value?.length > 0">
						<span>
							<span *ngFor="let src of item.SRC_NM.value"
								[ngStyle]="{'background-color': environment.mdmSourceColorCodes[src.trim().toLowerCase()]}"
								class="badge zs-badge-counter zs-border-rounded-0">
								{{ src.trim().toUpperCase() }}
							</span>
						</span>
					</ng-container>
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf="onMergePage && profiles?.length > 0">
				<!-- Merge/Not A Match buttons row when on Merge page -->
				<tr>
					<td class="golden-profile"></td>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<div>
							<button class="zs-button zs-button-solid solid-buttons zs-border-rounded-0" type="button"
								(click)="doMerge(item)" [disabled]="refreshIndicator" translate>
								{{ui_labels.MERGE}}
							</button>
							<button [disabled]="refreshIndicator" *ngIf="!item.searched"
								class="zs-button solid-buttons zs-button-solid zs-border-rounded-0" type="button"
								(click)="doNotAMatch(item)" translate>
								{{ui_labels.NOT_A_MATCH}}
							</button>
						</div>
					</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="onUnmergePage && profiles?.length > 1">
				<!-- Unmerge button row when on Unmerge page -->
				<tr>
					<td class="golden-profile"></td>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<button [disabled]="refreshIndicator"
							class="zs-button zs-button-solid solid-buttons zs-border-rounded-0" type="button"
							(click)="doUnmerge(item)" translate>
							{{ui_labels.UNMERGE}}
						</button>
					</td>
				</tr>
			</ng-container>
			<!-- Key value row -->
			<tr *ngFor="let key of keys; let i = index"
				[hidden]="golden[key] && golden[key].to_display && golden[key].to_display === mdmConstants.N">
				<td *ngIf="golden[key]" class="golden-profile">
					<div id="td-{{ key }}" *ngIf="golden[key]" class="zs-display-flex">
						<h5> {{ golden[key].display !== "" ?
							(detailTableMapping.hasOwnProperty(golden[key].display.trim()) ?
							detailTableMapping[golden[key].display.trim()] : golden[key].display): key }} </h5>
						<span [hidden]="!(golden[key] && golden[key].desc && golden[key].desc !== '')"
							class="zs-margin-0-0-0-1">
							<span class="zs-icon zs-icon-info zs-size-m info-icon"></span>
							<zs-tooltip position="right" offset-y="10">
								{{ golden[key].desc }}
							</zs-tooltip>
						</span>
					</div>
					<ng-container *ngIf="[].constructor.isArray(golden[key].value)">
						<span *ngFor="let item of golden[key].value; let i = index">
							<span *ngIf="i > 0 && i < golden[key].value.length">, </span>
							<span *ngIf="item">{{ item }}</span>
						</span>
					</ng-container>
					<ng-container *ngIf="![].constructor.isArray(golden[key].value)">
						<div *ngIf="golden[key] && golden[key].value" class="profile-applied-value">
							{{ golden[key].value }}
						</div>
					</ng-container>
				</td>
				<ng-container>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<div id="td-{{ key }}" *ngIf="golden[key]" class="zs-display-flex">
							<h5> {{ golden[key].display !== "" ?
								(detailTableMapping.hasOwnProperty(golden[key].display.trim()) ?
								detailTableMapping[golden[key].display.trim()] : golden[key].display): key }} </h5>
						</div>
						<span *ngIf="item[key] && item[key].value && item[key].value !== ''">
							{{ item[key].value }}
						</span>
					</td>
				</ng-container>
			</tr>
			<!-- Identifiers row -->
			<tr *ngIf="golden?.IDENTIFIERS">
				<td class="golden-profile">
					<div class="zs-display-flex">
						<h5 translate>
							{{ui_labels.IDENTIFIER}}
						</h5>
						<span [hidden]="!(golden?.IDENTIFIERS?.desc !== '')" class="zs-margin-0-0-0-1">
							<span class="zs-icon zs-icon-info zs-size-m info-icon"></span>
							<zs-tooltip position="top" offset-y="10">
								<span [innerHTML]="splitToNewlines(golden.IDENTIFIERS.desc)"></span>
							</zs-tooltip>
						</span>
					</div>
					<ng-container *ngFor="let identifier of golden.IDENTIFIERS.value | keyvalue">
						<div>
							<mdm-identifier [identifier]="identifier"></mdm-identifier>
						</div>
					</ng-container>
				</td>
				<ng-container>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<div class="zs-display-flex">
							<h5 translate> {{ui_labels.IDENTIFIER}} </h5>
						</div>
						<div *ngFor="let identifier of item.IDENTIFIERS.value | keyvalue">
							<mdm-identifier [identifier]="identifier"></mdm-identifier>
						</div>
					</td>
				</ng-container>
			</tr>
			<!-- Communications row -->
			<tr *ngIf="golden?.COMMUNICATIONS">
				<td class="golden-profile">
					<h5 translate>
						{{ui_labels.COMMUNICATION}}
					</h5>
					<div *ngFor="let item of golden.COMMUNICATIONS">
						<mdm-communication [communication]="item"></mdm-communication>
					</div>
				</td>
				<ng-container>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<h5 translate> {{ui_labels.COMMUNICATION}} </h5>
						<div *ngFor="let comm of item.COMMUNICATIONS">
							<mdm-communication [communication]="comm"></mdm-communication>
						</div>
					</td>
				</ng-container>
			</tr>
			<!-- Address row -->
			<tr *ngIf="golden?.ADDRESSES">
				<td class="golden-profile">
					<h5 translate> {{ui_labels.ADDRESS}} </h5>
					<ng-container *ngIf="golden.ADDRESSES | primaryAddress : mdmConstants.Y as primaryAddresses">
						<ng-container *ngIf="primaryAddresses.length > 0">
							<h6> {{primaryLabel}}: {{mdmConstants.Y}} </h6>
							<div *ngFor="let item of primaryAddresses">
								<app-mdm-address [address]="item" [showPrimaryLabel]="false"></app-mdm-address>
							</div>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="golden.ADDRESSES | primaryAddress : mdmConstants.N as nonPrimaryAddresses">
						<ng-container *ngIf="nonPrimaryAddresses.length > 0">
							<h6> {{primaryLabel}}: {{mdmConstants.N}} </h6>
							<div *ngFor="let item of nonPrimaryAddresses">
								<app-mdm-address [address]="item" [showPrimaryLabel]="false"></app-mdm-address>
							</div>
						</ng-container>
					</ng-container>
				</td>
				<ng-container>
					<td *ngFor="let item of profiles; let i = index" [hidden]="!profiles || profiles.length === 0"
						class="profile-details-data">
						<h5 translate> {{ui_labels.ADDRESS}} </h5>
						<ng-container *ngIf="item.ADDRESSES | primaryAddress : mdmConstants.Y as primaryAddresses">
							<ng-container *ngIf="primaryAddresses.length > 0">
								<h6> {{primaryLabel}}: {{mdmConstants.Y}} </h6>
								<div *ngFor="let address of primaryAddresses">
									<app-mdm-address [address]="address" [showPrimaryLabel]="false"></app-mdm-address>
								</div>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="item.ADDRESSES | primaryAddress : mdmConstants.N as nonPrimaryAddresses">
							<ng-container *ngIf="nonPrimaryAddresses.length > 0">
								<h6> {{primaryLabel}}: {{mdmConstants.N}} </h6>
								<div *ngFor="let address of nonPrimaryAddresses">
									<app-mdm-address [address]="address" [showPrimaryLabel]="false"></app-mdm-address>
								</div>
							</ng-container>
						</ng-container>
					</td>
				</ng-container>
			</tr>
		</tbody>
	</table>
</div>