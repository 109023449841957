import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  GetMdmApiRequest,
  MDMCommonConstants,
  MDMDashboardPeriodList,
  MDMServiceConstants,
  MDMWrapperConstants,
  PayerRequestConstants

} from './constants/mdm-common-constants';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiRequestConstants } from 'src/app/shared/constants';
import { AppConfig } from 'src/app/app.config';
import { AppliedFiltersData } from 'src/app/revo-core/table-column-filters/table-column-filters-models';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ToasterDataService } from '../revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { common_labels } from '../shared/constants/ui_labels_translation_mapping';
import { map } from 'rxjs/operators';
import { url, PAYER_ENDPOINTS } from 'src/app/mdm/constants/api-urls';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class MDMService {
  environment: EnvironmentConfig;
  commonLabels = common_labels;
  someErrorOccurredLabel: string;
  public responseCache = new Map();
  private entitySubType = '';
  affiliationURL: String;
  activeTab = MDMWrapperConstants.ALL_RECORDS;
  matchesWithSearchData = [];
  constructor(
    private _http: HttpClient,
    private _localStorageService: LocalStorageService,
    private config: AppConfig,
    public _toasterDataService: ToasterDataService,
    private _translate: TranslateService
  ) {
    this.environment = this.config.getConfigObject();
    this.someErrorOccurredLabel = this._translate.instant(
      this.commonLabels.SOME_ERROR_OCCURRED
    );
  }

  private emitSearchScreenActivated = new Subject<boolean>();
  searchScreenActivated$ = this.emitSearchScreenActivated.asObservable();

  searchScreenActivated(activated: boolean) {
    this.emitSearchScreenActivated.next(activated);
  }

  getActivitiesData(
    page = 1,
    pageSize: number,
    selectedPast = MDMDashboardPeriodList.ALL,
    appliedFilters: Array<AppliedFiltersData>,
    sortByColumn: string,
    sortByOrder: string
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(
      MDMServiceConstants.PAST_FILTER,
      selectedPast.toString()
    );
    params = params.append(
      MDMServiceConstants.FILTERS,
      JSON.stringify(appliedFilters)
    );
    params = params.append(
      MDMServiceConstants.SORT_COLUMN,
      sortByColumn.toString()
    );
    params = params.append(
      MDMServiceConstants.SORT_ORDER,
      sortByOrder.toString()
    );
    return this._http
      .get<any>(this.environment.baseUrl + url.ActivityLog, { params: params })
      .pipe(map((res: any) => res));
  }

  getMdmActivityLogFilterValues(columnID, selectedPast = MDMDashboardPeriodList.ALL) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.COLUMN_ID, columnID.toString());
    params = params.append(
      MDMServiceConstants.PAST_FILTER,
      selectedPast.toString()
    );
    return this._http
      .get<any>(this.environment.baseUrl + url.mdmActivityLogFilterValues, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  postCompareData(
    id: string,
    mdmids: string,
    filter: string = MDMCommonConstants.HCP
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + url.ProfComp,
        { masterIds: [id, mdmids], filter },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }

  getSearchData(filters: any, page: any, pageSize: any) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + url.AdvSearch,
        {
          filters,
          page,
          pageSize,
        },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }

  //////////////////////// dashboard

  getRefreshIndicator() {
    let params = new HttpParams();
    return this._http
      .get<any>(this.environment.baseUrl + url.refreshIndicator, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  getStatsData(timeline: string, filter: string, format: string) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.TIMELINE, timeline);
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.FORMAT, format);

    return this._http
      .get<any>(this.environment.baseUrl + url.ProfileStats, { params: params })
      .pipe((response) => response);
  }


  getMatchesDataForDashboard(filter: string, selectedPeriodValue: string = MDMDashboardPeriodList.ALL,
    sortByColumn: string = '', sortByOrder: string = '',
    searchText: string = '', currentPage: any = '', pageSize: any = ''
  ) {
    let params = new HttpParams();
    params = params.append(GetMdmApiRequest.page, currentPage);
    params = params.append(GetMdmApiRequest.pageSize, pageSize);
    params = params.append(GetMdmApiRequest.sortByColumn, sortByColumn);
    params = params.append(GetMdmApiRequest.sortByOrder, sortByOrder);
    params = params.append(GetMdmApiRequest.searchText, searchText);
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(
      MDMServiceConstants.PAST_FILTER,
      selectedPeriodValue
    );
    return this._http
      .get<any>(this.environment.baseUrl + url.DashboardMatches, {
        params: params,
      })
      .pipe((response) => response);
  }

  getChartData(
    category: string,
    metric: string,
    sources: string,
    timline: string,
    filter: string,
    page: number,
    pageSize: number
  ) {
    let params = new HttpParams();

    return this._http
      .post(
        this.environment.baseUrl + url.statsProfileDetails,
        {
          loadDate: category,
          type: metric,
          sources: sources,
          filter: filter,
          page: page,
          pageSize: pageSize,
        },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }

  setEntitySubType(val) {
    this.entitySubType = val;
  }

  private setEntityTypeChangedSubject = new Subject<boolean>();
  setEntityTypeFlagChangedStatus(isEntityTypeChangedFlag: boolean) {
    this.setEntityTypeChangedSubject.next(isEntityTypeChangedFlag);
  }

  getEntityTypeFlagChangedStatus(): Observable<any> {
    return this.setEntityTypeChangedSubject.asObservable();
  }

  private setFilterChanged = new Subject<boolean>();
  setFilterChangedStatus(selectedFilter: any) {
    this.setFilterChanged.next(selectedFilter);
  }

  getFilterChangedStatus(): Observable<any> {
    return this.setFilterChanged.asObservable();
  }

  private emitrefreshMatchesTab = new Subject<boolean>();
  refreshMatchesTab$ = this.emitrefreshMatchesTab.asObservable();
  refreshMatchesTab() {
    this.emitrefreshMatchesTab.next();
  }

  private emitrefreshAffiliationTab = new Subject<boolean>();
  refreshAffiliationTab$ = this.emitrefreshAffiliationTab.asObservable();
  refreshAffiliationTab(id) {
    this.emitrefreshAffiliationTab.next(id);
  }

  private emitrefreshActivityTab = new Subject<boolean>();
  refreshActivityTab$ = this.emitrefreshActivityTab.asObservable();
  refreshActivityTab() {
    this.emitrefreshActivityTab.next();
  }

  private emitMdmId = new Subject<boolean>();
  refreshMdmId$ = this.emitMdmId.asObservable();
  refreshMdmId(mdmId) {
    this.emitMdmId.next(mdmId);
  }

  getRefreshMdmId(): Observable<any> {
    return this.emitMdmId.asObservable();
  }

  private emitMergeScreenActivated = new BehaviorSubject<boolean>(null);
  mergeScreenActivated$ = this.emitMergeScreenActivated.asObservable();
  mergeScreenActivated(activated: boolean) {
    this.emitMergeScreenActivated.next(activated);
  }

  private emitProfileSelected = new Subject<any>();
  emitProfileSelected$ = this.emitProfileSelected.asObservable();
  profileSelected(profile: any) {
    this.emitProfileSelected.next(profile);
  }

  private emitProfileRemoved = new Subject<any>();
  profileRemoved$ = this.emitProfileRemoved.asObservable();
  profileRemoved(profile: any) {
    this.emitProfileRemoved.next(profile);
  }

  private setProfileBreadcrumSubject = new Subject<String>();
  setProfileBreadcrumID(id: String) {
    this.setProfileBreadcrumSubject.next(id);
  }

  getProfileBreadcrumID(): Observable<any> {
    return this.setProfileBreadcrumSubject.asObservable();
  }

  getLineageData(
    id: string,
    filter: string = MDMCommonConstants.HCP,
    view = MDMCommonConstants.N
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.VIEW, view);
    return this._http
      .get(this.environment.baseUrl + url.ProfileLineage, { params: params })
      .pipe(map((res: any) => res));
  }

  getProfileMatchesData(
    id: string,
    filter: string = MDMCommonConstants.HCP,
    page: number = 1,
    pageSize: number = 10
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    return this._http
      .get(this.environment.baseUrl + url.SugMatches, { params: params })
      .pipe(
        map((res: any) => {
          res.forEach((e) =>
            Object.keys(e).forEach((e1) => {
              if (e1.toUpperCase().startsWith(MDMServiceConstants.SLAVE))
                return (e[
                  e1.toUpperCase().replace(MDMServiceConstants.SLAVE, '')
                ] = e[e1]);
            })
          );
          return res;
        })
      );
  }

  getMatchesData(
    search: string,
    page: number = 1,
    pageSize: number = 10,
    filter: string = MDMCommonConstants.HCP
  ) {
    if (search && search.trim() == '') search = null;
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    params = params.append(
      MDMServiceConstants.ETY_SUB_TYPE,
      this.entitySubType
    );

    return this._http
      .get(this.environment.baseUrl + url.SearchProf, { params: params })
      .pipe(map((res: any) => res));
  }


  getProfileActivitiesData(
    id: string,
    filter: string,
    page: number = 1,
    pageSize: number = 10
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    return this._http
      .get(this.environment.baseUrl + url.ActivityLogProfile, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  postProfileMerge(
    id: string,
    mdmids: string[],
    filter: string = MDMCommonConstants.HCP
  ) {
    let params = new HttpParams();

    this.removeCachedAffiliationDetails(mdmids.concat([id]), filter);
    this.clearCache();
    return this._http
      .post(
        this.environment.baseUrl + url.Merge,
        { masterId: id, slaveId: mdmids, filter },
        { params: params }
      )
      .pipe(map((res: any) => {
        this.refreshAffiliationTab(id);
        return res;
      }));
  }

  removeCachedAffiliationDetails(mdm_id_list: string[], filter: string) {
    const selectedEnvironmentDetails = this._localStorageService.getSelectedEnvironmentDetails();
    mdm_id_list.forEach((mdm_id) => {
      let affiliationCachedKey =
        this.environment.baseUrl +
        url.Affiliation +
        `?${ApiRequestConstants.environment_id}=${selectedEnvironmentDetails[0]}&${MDMServiceConstants.MASTER_ID}=${mdm_id}&${MDMServiceConstants.FILTER}=${filter}`;
      if (this.responseCache.get(affiliationCachedKey)) {
        this.responseCache.delete(affiliationCachedKey);
      }
    });
  }

  postProfileNotAMatch(
    id: string,
    mdmid: string,
    filter: string = MDMCommonConstants.HCP
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + url.NotaMatch,
        { filter, masterId: id, slaveId: mdmid },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }

  postProfileUnmerge(
    id: string,
    mdmids: string[],
    filter: string = MDMCommonConstants.HCP
  ) {
    let params = new HttpParams();

    this.removeCachedAffiliationDetails(mdmids.concat([id]), filter);
    this.clearCache();
    return this._http
      .post(
        this.environment.baseUrl + url.Unmerge,
        { masterId: id, slaveId: mdmids, filter },
        { params: params }
      )
      .pipe(map((res: any) => {
        this.refreshAffiliationTab(id);
        return res;
      }));
  }

  getAffilationsDetails(id: string, affiliationType: string) {
    this.deleteExpiredCache();
    const selectedEnvironmentDetails = this._localStorageService.getSelectedEnvironmentDetails();
    this.affiliationURL = this.environment.baseUrl + url.Affiliation +
      `?${ApiRequestConstants.cluster_id}=${selectedEnvironmentDetails[0]}&${MDMServiceConstants.MASTER_ID}=${id}&${MDMServiceConstants.FILTER}=${affiliationType}`;
    const affilationsDetails = this.responseCache.get(this.affiliationURL);
    if (affilationsDetails) {
      return of(affilationsDetails.response);
    }
    let params = new HttpParams();
    params = params.append(
      ApiRequestConstants.environment_id,
      selectedEnvironmentDetails[0]
    );
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(
      MDMServiceConstants.AFFILIATION_TYPE,
      affiliationType
    );
    return this._http
      .get(this.environment.baseUrl + url.Affiliation, { params: params })
      .pipe(map((res: any) => {
        const CacheMap = {
          url: this.affiliationURL,
          response: res,
          entryTime: Date.now()
        };
        this.setAffiliationsCache(CacheMap);
        return res;
      }));
  }

  getSearchEngineKey() {
    let params = new HttpParams();
    return this._http
      .get(this.environment.baseUrl + url.getSearchEngineKey, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  getProfileNameById(id, filter: string = MDMCommonConstants.HCP) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.FILTER, filter);
    return this._http
      .get(this.environment.baseUrl + url.getProfileNameById, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  getEntityTypeById(id) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    return this._http
      .get(this.environment.baseUrl + url.getEntityTypeById, { params: params })
      .pipe(map((res: any) => res));
  }
  /*  Edge 17039 changes starts
    Added new generic setter and getter for setting proper breadcrumbs in mdm profile page
    By Default the value of activeTab is "All Records": considering if user directly paste the URL of profile page in search bar
*/
  setActiveTab(tab) {
    this.activeTab = tab;
  }
  getActiveTab() {
    return this.activeTab;
  }
  resetActiveTab() {
    this.activeTab = MDMWrapperConstants.ALL_RECORDS;
  }
  //  Edge 17039 changes ends

  setAffiliationsCache(result: any) {
    if (!this.responseCache.get(this.affiliationURL)) {
      this.responseCache.set(this.affiliationURL, result);
    }
  }

  deleteExpiredCache() {
    this.responseCache.forEach(entry => {
      if (Date.now() - entry.entryTime > this.environment.mdmMaxCacheAge) {
        this.responseCache.delete(entry.url);
      }
    });
  }

  clearCache() {
    this.responseCache.clear();
  }

  getSelectedSuspectedProfileData(mdm_id: string, filter: string = MDMCommonConstants.HCP) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MDM_ID, mdm_id);
    params = params.append(MDMServiceConstants.FILTER, filter);
    return this._http.get(this.environment.baseUrl + url.getSuspectedProfileMetadata, { params: params }).pipe(
      map((res: any) => res)
    );
  }

  getSearchedSuspectedProfileNames(search: string, page: number = 1, pageSize: number = 10, filter: string = MDMCommonConstants.HCP) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.FILTER, filter);
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    params = params.append(MDMServiceConstants.ETY_SUB_TYPE, this.entitySubType);
    return this._http
      .get(this.environment.baseUrl + url.getSuspectedProfileNames, { params: params }
      )
      .pipe(
        map((res: any) => res)
      );
  }

  setMatchesWithSearchData(data = []) {
    this.matchesWithSearchData = data;
  }

  getMatchesWithSearchData() {
    return this.matchesWithSearchData;
  }
  getDcrmanagment(request_type, entity_type, selected_sub_type, page, pageSize, fromDate, toDate, status) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.REQUEST_TYPE, request_type);
    params = params.append(MDMServiceConstants.ENTITY_TPE, entity_type);
    params = params.append(MDMServiceConstants.SUB_TYPE, selected_sub_type);
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    if (fromDate && toDate) {
      params = params.append(MDMServiceConstants.FROM, fromDate);
      params = params.append(MDMServiceConstants.TO, toDate);
    }
    if (status) {
      params = params.append(MDMServiceConstants.STATUS, status);
    }

    return this._http
      .get(this.environment.baseUrl + url.dcrManagement, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getDcrDetailsById(id) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.DCR_ID, id);
    // params = params.append(MDMServiceConstants.FILTER, filter);
    return this._http
      .get(this.environment.baseUrl + url.dcrDetails, {
        params: params,
      })
      .pipe(map((res: any) => res));

  }
  updateDcrById(payload) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + url.updateDcr,
        payload,
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  getDCRSummary(request_type, entity_type, selected_sub_type, fromDate, toDate, status) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.REQUEST_TYPE, request_type);
    params = params.append(MDMServiceConstants.ENTITY_TPE, entity_type);
    params = params.append(MDMServiceConstants.SUB_TYPE, selected_sub_type);
    if (fromDate && toDate) {
      params = params.append(MDMServiceConstants.FROM, fromDate);
      params = params.append(MDMServiceConstants.TO, toDate);
    }
    if (status) {
      params = params.append(MDMServiceConstants.STATUS, status);
    }
    return this._http
      .get(this.environment.baseUrl + url.dcrSummary, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getDCRFilterData(request_type, entity_type, sub_type) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.REQUEST_TYPE, request_type);
    params = params.append(MDMServiceConstants.ENTITY_TPE, entity_type);
    params = params.append(MDMServiceConstants.SUB_TYPE, sub_type);
    return this._http
      .get(this.environment.baseUrl + url.dcrFilterData, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  syncDCR() {
    let params = new HttpParams();
    return this._http
      .get(this.environment.baseUrl + url.dcrSync, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  //returns All dcr for export to excel without limit
  getAllDCR(request_type, selected_sub_type, entity_type, fromDate, toDate, status) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.REQUEST_TYPE, request_type);
    params = params.append(MDMServiceConstants.SUB_TYPE, selected_sub_type);
    params = params.append(MDMServiceConstants.ENTITY_TPE, entity_type);
    if (fromDate && toDate) {
      params = params.append(MDMServiceConstants.FROM, fromDate);
      params = params.append(MDMServiceConstants.TO, toDate);
    }
    if (status) {
      params = params.append(MDMServiceConstants.STATUS, status);
    }

    return this._http
      .get(this.environment.baseUrl + url.dcrToExcel, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getDcrTableCount(request_type, entity_type, selected_sub_type, fromDate, toDate, status) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.REQUEST_TYPE, request_type);
    params = params.append(MDMServiceConstants.SUB_TYPE, selected_sub_type);
    params = params.append(MDMServiceConstants.ENTITY_TPE, entity_type);
    if (fromDate && toDate) {
      params = params.append(MDMServiceConstants.FROM, fromDate);
      params = params.append(MDMServiceConstants.TO, toDate);
    }
    if (status) {
      params = params.append(MDMServiceConstants.STATUS, status);
    }

    return this._http
      .get(this.environment.baseUrl + url.dcrCount, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  public exportAsExcelFile(json: any[], excelFileName: string, headers): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });

    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }
  getChangeRequestLine(dcr_name) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.DCR_NAME, dcr_name);
    return this._http
      .get(this.environment.baseUrl + url.dcrChangeRequestLine, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPBMMatches(
    sortByColumn: string = '', sortByOrder: string = '',
    searchText: string = '', currentPage: any = '', pageSize: any = '') {
    let params = new HttpParams();
    params = params.append(GetMdmApiRequest.page, currentPage);
    params = params.append(GetMdmApiRequest.pageSize, pageSize);
    params = params.append(GetMdmApiRequest.sortByColumn, sortByColumn);
    params = params.append(GetMdmApiRequest.sortByOrder, sortByOrder);
    params = params.append(GetMdmApiRequest.searchText, searchText);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_SUSPECTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPBMDetailsById(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);

    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_DETAILS_BY_ID, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPBMSuspectedMatches(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_SUGGESTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  getPBMLineageData(
    id: string,
    view = MDMCommonConstants.N
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.VIEW, view);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_ProfileLineage, { params: params })
      .pipe(map((res: any) => res));
  }
  postPBMProfileMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PBM_ProfileMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPBMProfileUnMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PBM_ProfileUnMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPBMProfileNotaMatch(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PBM_ProfileNotaMatch,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  getPBMRecords(payload) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PBM_Records,
        payload
      )
      .pipe(map((res: any) => res));
  }
  getPBMSuspectedProfileNames(
    page,
    pageSize,
    search = ''
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_SUSPECTED_PROFILE_NAMES, { params: params })
      .pipe(map((res: any) => res));
  }
  getPBMSuspectedProfileMetadata(
    mdm_id
  ) {
    let params = new HttpParams();

    params = params.append(MDMServiceConstants.MDM_ID, mdm_id);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PBM_SUSPECTED_PROFILE_METADATA, { params: params })
      .pipe(map((res: any) => res));
  }
  getMCOMatches(
    sortByColumn: string = '', sortByOrder: string = '',
    searchText: string = '', currentPage: any = '', pageSize: any = '') {
    let params = new HttpParams();
    params = params.append(GetMdmApiRequest.page, currentPage);
    params = params.append(GetMdmApiRequest.pageSize, pageSize);
    params = params.append(GetMdmApiRequest.sortByColumn, sortByColumn);
    params = params.append(GetMdmApiRequest.sortByOrder, sortByOrder);
    params = params.append(GetMdmApiRequest.searchText, searchText);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_SUSPECTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getMCORecords(payload) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.MCO_Records,
        payload
      )
      .pipe(map((res: any) => res));
  }
  getMCODetailsById(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);

    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_DETAILS_BY_ID, {
        params: params,
      })
  }
  getMCOSuspectedMatches(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_SUGGESTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getMCOLineageData(
    id: string,
    view = MDMCommonConstants.N
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.VIEW, view);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_ProfileLineage, { params: params })
      .pipe(map((res: any) => res));
  }
  getMCOSuspectedProfileNames(
    page,
    pageSize,
    search = ''
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_SUSPECTED_PROFILE_NAMES, { params: params })
      .pipe(map((res: any) => res));
  }
  getMCOSuspectedProfileMetadata(
    mdm_id
  ) {
    let params = new HttpParams();

    params = params.append(MDMServiceConstants.MDM_ID, mdm_id);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.MCO_SUSPECTED_PROFILE_METADATA, { params: params })
      .pipe(map((res: any) => res));
  }
  postMCOProfileMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.MCO_ProfileMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postMCOProfileUnMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.MCO_ProfileUnMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postMCOProfileNotaMatch(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.MCO_ProfileNotaMatch,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  getPAYERMatches(
    sortByColumn: string = '', sortByOrder: string = '',
    searchText: string = '', currentPage: any = '', pageSize: any = '') {
    let params = new HttpParams();
    params = params.append(GetMdmApiRequest.page, currentPage);
    params = params.append(GetMdmApiRequest.pageSize, pageSize);
    params = params.append(GetMdmApiRequest.sortByColumn, sortByColumn);
    params = params.append(GetMdmApiRequest.sortByOrder, sortByOrder);
    params = params.append(GetMdmApiRequest.searchText, searchText);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_SUSPECTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPayerRecords(payload) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_Records,
        payload
      )
      .pipe(map((res: any) => res));
  }
  getPAYERDetailsById(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);

    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_DETAILS_BY_ID, {
        params: params,
      })
  }
  getPAYERLineageData(
    id: string,
    view = MDMCommonConstants.N
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.VIEW, view);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_ProfileLineage, { params: params })
      .pipe(map((res: any) => res));
  }
  getPAYERSuspectedMatches(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_SUGGESTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPAYERSuspectedProfileNames(
    page,
    pageSize,
    search = ''
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_SUSPECTED_PROFILE_NAMES, { params: params })
      .pipe(map((res: any) => res));
  }
  getPAYERSuspectedProfileMetadata(
    mdm_id
  ) {
    let params = new HttpParams();

    params = params.append(MDMServiceConstants.MDM_ID, mdm_id);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_SUSPECTED_PROFILE_METADATA, { params: params })
      .pipe(map((res: any) => res));
  }
  postPAYERProfileMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_ProfileMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPAYERProfileUnMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_ProfileUnMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPAYERProfileNotaMatch(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PAYER_ProfileNotaMatch,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  getPLANMatches(
    sortByColumn: string = '', sortByOrder: string = '',
    searchText: string = '', currentPage: any = '', pageSize: any = '') {
    let params = new HttpParams();
    params = params.append(GetMdmApiRequest.page, currentPage);
    params = params.append(GetMdmApiRequest.pageSize, pageSize);
    params = params.append(GetMdmApiRequest.sortByColumn, sortByColumn);
    params = params.append(GetMdmApiRequest.sortByOrder, sortByOrder);
    params = params.append(GetMdmApiRequest.searchText, searchText);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_SUSPECTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
  getPLANRecords(payload) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_Records,
        payload
      )
      .pipe(map((res: any) => res));
  }
  getPLANDetailsById(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);

    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_DETAILS_BY_ID, {
        params: params,
      })
  }
  getPLANLineageData(
    id: string,
    view = MDMCommonConstants.N
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.MASTER_ID, id);
    params = params.append(MDMServiceConstants.VIEW, view);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_ProfileLineage, { params: params })
      .pipe(map((res: any) => res));
  }
  getPLANSuspectedProfileNames(
    page,
    pageSize,
    search = ''
  ) {
    let params = new HttpParams();
    params = params.append(MDMServiceConstants.PAGE, page.toString());
    params = params.append(MDMServiceConstants.PAGE_SiZE, pageSize.toString());
    params = params.append(MDMServiceConstants.SEARCH, search);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_SUSPECTED_PROFILE_NAMES, { params: params })
      .pipe(map((res: any) => res));
  }
  getPLANSuspectedProfileMetadata(
    mdm_id
  ) {
    let params = new HttpParams();

    params = params.append(MDMServiceConstants.MDM_ID, mdm_id);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_SUSPECTED_PROFILE_METADATA, { params: params })
      .pipe(map((res: any) => res));
  }
  postPLANProfileMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_ProfileMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPLANProfileUnMerge(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_ProfileUnMerge,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  postPLANProfileNotaMatch(
    id: string,
    mdmids: string[]
  ) {
    let params = new HttpParams();
    return this._http
      .post(
        this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_ProfileNotaMatch,
        { masterId: id, slaveId: mdmids },
        { params: params }
      )
      .pipe(map((res: any) => res));
  }
  getPLANSuspectedMatches(masterId) {
    let params = new HttpParams();
    params = params.append(PayerRequestConstants.MASTER_ID, masterId);
    return this._http
      .get(this.environment.baseUrl + PAYER_ENDPOINTS.PLAN_SUGGESTED_MATCHES, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }
}
