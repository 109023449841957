import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { PayerService } from '../payer-services/payer.service';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParams, ProfileAPIResponseConstants } from '../../../constants/profile-constants';
import { DetailsConstants, MDMCommonConstants, PayerWrapperConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { CommonService } from 'src/app/mdm/services/common.service';

@Component({
  selector: 'app-payer-matches',
  templateUrl: './payer-matches.component.html',
  styleUrls: ['./payer-matches.component.less']
})
export class PayerMatchesComponent implements OnInit {

  MDMCommonConstants = MDMCommonConstants;
  searchPlaceholder = 'Search'
  environment: EnvironmentConfig;
  searchValue: string = '';
  _id = '';
  matches: any;
  dragging = false;
  onMergeScreen = false;
  maxLengthForEllipses = DetailsConstants.MAX_LENGTH_FOR_ELLIPSES_IN_MATCHES;
  matchesSubscribe: any;
  matchesLoadingSubscribe: any;
  page = 1
  pageSize = 10;
  totalPages = 0;

  page_details: any;
  loader: boolean;
  get isBlank(): boolean {
    return !this.searchValue;
  }
  showSearchItems: boolean = false;
  searchResults: any[] = [];
  showNoResultsFoundLabel: boolean = false;
  matchesLoading: boolean = true;
  constructor(private config: AppConfig,
    private payerService: PayerService,
    private service: MDMService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();
    // refresh matches subscriber
    this.payerService.matches$.next([]);
    this.payerService.matchesLoading$.next(true);

  }

  ngOnInit(): void {
    this.page_details = this.commonService.getFromSessionStorage('pageDetails');
    this.route.params.subscribe((params) => {
      this._id = params[QueryParams.ID];
      this.initLoad();
    });
  }
  initLoad() {
    //based on the payer entities call its suggested matches API
    if (this.page_details.entity == PayerWrapperConstants.PBM) {
      this.getPBMSuspectedMatches();
    } else if (this.page_details.entity == PayerWrapperConstants.MCO) {
      this.getMCOSuspectedMatches();
    } else if (this.page_details.entity == PayerWrapperConstants.PAYER) {
      this.getPAYERSuspectedMatches();
    } else if (this.page_details.entity == PayerWrapperConstants.PLAN) {
      this.getPLANSuspectedMatches();
    }
    this.matchesLoadingSubscribe = this.payerService.matchesLoading$.subscribe((loading) => {
      this.matchesLoading = loading;

    })
    this.matchesSubscribe = this.payerService.matches$.subscribe((matches) => {
      this.matches = matches;

    })
  }

  drag() {
    this.dragging = !this.dragging;
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  getPBMSuspectedMatches() {
    this.payerService.getPBMSuspectedMatches(this._id);

  }
  getMCOSuspectedMatches() {
    this.payerService.getMCOSuspectedMatches(this._id);
  }
  getPAYERSuspectedMatches() {
    this.payerService.getPAYERSuspectedMatches(this._id);

  }
  getPLANSuspectedMatches() {
    this.payerService.getPLANSuspectedMatches(this._id);
    // this.loader = false;
  }
  searchMatches(isLoadMoreData) {
    if (this.searchValue) {
      if (isLoadMoreData) {
        if (this.page >= this.totalPages) {
          return;
        } else {
          this.page += 1;
        }
      } else {
        this.page = 1;
      }
      if (this.page_details.entity == PayerWrapperConstants.PBM) {
        this.getPBMSuspectedProfileNames();
      } else if (this.page_details.entity == PayerWrapperConstants.MCO) {
        this.getMCOSuspectedProfileNames();
      } else if (this.page_details.entity == PayerWrapperConstants.PAYER) {
        this.getPAYERSuspectedProfileNames();
      } else if (this.page_details.entity == PayerWrapperConstants.PLAN) {
        this.getPLANSuspectedProfileNames();
      }
    }
  }

  getPBMSuspectedProfileNames() {
    this.showSearchItems = true;
    this.service.getPBMSuspectedProfileNames(this.page, this.pageSize, this.searchValue).subscribe((resp) => {
      this.totalPages = Math.ceil(resp.total_records / this.pageSize);
      resp.data = this.checkIfSearchedProfileAlreadyInSuspectedMathces(
        resp.data
      );
      this.searchResults = [...this.searchResults, ...resp.data];
      this.showNoResultsFoundLabel = true ? resp.data.length <= 0 : false;
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  getMCOSuspectedProfileNames() {
    this.showSearchItems = true;
    this.service.getMCOSuspectedProfileNames(this.page, this.pageSize, this.searchValue).subscribe((resp) => {
      this.totalPages = Math.ceil(resp.total_records / this.pageSize);
      resp.data = this.checkIfSearchedProfileAlreadyInSuspectedMathces(
        resp.data
      );
      this.searchResults = [...this.searchResults, ...resp.data];
      this.showNoResultsFoundLabel = true ? resp.data.length <= 0 : false;
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  getPAYERSuspectedProfileNames() {
    this.showSearchItems = true;
    this.service.getPAYERSuspectedProfileNames(this.page, this.pageSize, this.searchValue).subscribe((resp) => {
      this.totalPages = Math.ceil(resp.total_records / this.pageSize);
      resp.data = this.checkIfSearchedProfileAlreadyInSuspectedMathces(
        resp.data
      );
      this.searchResults = [...this.searchResults, ...resp.data];
      this.showNoResultsFoundLabel = true ? resp.data.length <= 0 : false;
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  getPLANSuspectedProfileNames() {
    this.showSearchItems = true;
    this.service.getPLANSuspectedProfileNames(this.page, this.pageSize, this.searchValue).subscribe((resp) => {
      this.totalPages = Math.ceil(resp.total_records / this.pageSize);
      resp.data = this.checkIfSearchedProfileAlreadyInSuspectedMathces(
        resp.data
      );
      this.searchResults = [...this.searchResults, ...resp.data];
      this.showNoResultsFoundLabel = true ? resp.data.length <= 0 : false;
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  searchItemSelected(item) {
    if (!this.matches.find((match) => match.MDM_ID.value === item.mdm_id)) {
      if (this.page_details.entity == PayerWrapperConstants.PBM) {
        this.getPBMSelectedSuspectedProfileData(item.mdm_id);

      } else if (this.page_details.entity == PayerWrapperConstants.MCO) {
        this.getMCOSelectedSuspectedProfileData(item.mdm_id);
      } else if (this.page_details.entity == PayerWrapperConstants.PAYER) {
        this.getPAYERSelectedSuspectedProfileData(item.mdm_id);
      } else if (this.page_details.entity == PayerWrapperConstants.PLAN) {
        this.getPLANSelectedSuspectedProfileData(item.mdm_id);
      }
    } else {
      this.searchValue = '';
      this.searchResults = [];
      this.page = 1;
      return;
    }


  }
  getPBMSelectedSuspectedProfileData(mdm_id) {
    this.showSearchItems = false;
    this.service.getPBMSuspectedProfileMetadata(mdm_id).subscribe((resp) => {
      resp.data.forEach((e) => {
        e['searched'] = true;
        e['id'] = {
          [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
          [ProfileAPIResponseConstants.DISPLAY]: '',
          [ProfileAPIResponseConstants.DESC]: '',
          [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
        };
      });
      this.matches = [...this.matches, ...resp.data];
      this.searchValue = '';
      this.searchResults = [];
    })

  }
  getMCOSelectedSuspectedProfileData(mdm_id) {
    this.showSearchItems = false;
    this.service.getMCOSuspectedProfileMetadata(mdm_id).subscribe((resp) => {
      resp.data.forEach((e) => {
        e['searched'] = true;
        e['id'] = {
          [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
          [ProfileAPIResponseConstants.DISPLAY]: '',
          [ProfileAPIResponseConstants.DESC]: '',
          [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
        };
      });
      this.matches = [...this.matches, ...resp.data];
      this.searchValue = '';
      this.searchResults = [];
    })

  }
  getPAYERSelectedSuspectedProfileData(mdm_id) {
    this.showSearchItems = false;
    this.service.getPAYERSuspectedProfileMetadata(mdm_id).subscribe((resp) => {
      resp.data.forEach((e) => {
        e['searched'] = true;
        e['id'] = {
          [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
          [ProfileAPIResponseConstants.DISPLAY]: '',
          [ProfileAPIResponseConstants.DESC]: '',
          [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
        };
      });
      this.matches = [...this.matches, ...resp.data];
      this.searchValue = '';
      this.searchResults = [];
    })

  }
  getPLANSelectedSuspectedProfileData(mdm_id) {
    this.showSearchItems = false;
    this.service.getPLANSuspectedProfileMetadata(mdm_id).subscribe((resp) => {
      resp.data.forEach((e) => {
        e['searched'] = true;
        e['id'] = {
          [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
          [ProfileAPIResponseConstants.DISPLAY]: '',
          [ProfileAPIResponseConstants.DESC]: '',
          [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
        };
      });
      this.matches = [...this.matches, ...resp.data];
      this.searchValue = '';
      this.searchResults = [];
    })

  }
  hideSearchItems(e) {
    this.searchResults = [];
    this.showSearchItems = false;
  }
  searchItemRemoved(item) {
    if (this.matches.includes(item)) {
      this.matches.splice(this.matches.indexOf(item), 1);
    }
  }
  checkIfSearchedProfileAlreadyInSuspectedMathces(data) {
    const mdmIdsTobBeDeleted = [];
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].mdm_id === this._id &&
        !mdmIdsTobBeDeleted.includes(index)
      ) {
        mdmIdsTobBeDeleted.push(index);
      } else if (
        this.matches.some((el) => el.MDM_ID.value === data[index].mdm_id) &&
        !mdmIdsTobBeDeleted.includes(index)
      ) {
        mdmIdsTobBeDeleted.push(index);
      }
    }

    while (mdmIdsTobBeDeleted.length) {
      data.splice(mdmIdsTobBeDeleted.pop(), 1);
    }
    return data;
  }
  showProfile(profileID) {
    let state;
    switch (this.page_details.entity) {
      case PayerWrapperConstants.PBM:
        state = { entity: PayerWrapperConstants.PBM, return_page: 'suspected' }
        break;
      case PayerWrapperConstants.MCO:
        state = { entity: PayerWrapperConstants.MCO, return_page: 'suspected' }
        break;
      case PayerWrapperConstants.PAYER:
        state = { entity: PayerWrapperConstants.PAYER, return_page: 'suspected' }
        break;
      case PayerWrapperConstants.PLAN:
        state = { entity: PayerWrapperConstants.PLAN, return_page: 'suspected' }
        break;

    }
    this.router.navigate(['/mdm/all-records/payer/profile/', profileID], {
      state: state,
    });

  }
  ngOnDestroy(): void {
    this.matchesSubscribe.unsubscribe();
    this.matchesLoadingSubscribe.unsubscribe();
  }
  searchBoxValueChange() {
    this.searchResults = [];
    this.page = 1;
    this.showNoResultsFoundLabel = false;
  }

}

