import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community/dist/types/core/rendering/cellRenderers/iCellRenderer';
import { DCRStatus } from '../../constants/mdm-common-constants';

@Component({
  selector: 'app-dcr-cell-renderer',
  templateUrl: './dcr-cell-renderer.component.html',
  styleUrls: ['./dcr-cell-renderer.component.less']
})
export class DcrCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  data: any;
  request_id: any;
  REQUEST_CREATED_BY: any;
  DCR_NAME: any;
  status: any;
  CHANGE_REQUEST_TYPE: any;
  routeLink: string;
  DCRStatus = DCRStatus;
  MDM_SYNC_FLAG: any;
  CRM_SYNC_FLAG: any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params: ICellRendererParams) {
    this.params = params;
    this.data = this.params.data;
    this.DCR_NAME = this.params.DCR_NAME;
    this.request_id = this.params.CHANGE_REQUEST_ID;
    this.REQUEST_CREATED_BY = this.params.REQUEST_CREATED_BY;
    this.CHANGE_REQUEST_TYPE = this.params.CHANGE_REQUEST_TYPE;
    this.MDM_SYNC_FLAG = this.params.MDM_SYNC_FLAG;
    this.CRM_SYNC_FLAG = this.params.CRM_SYNC_FLAG;
    this.status = this.params.DCR_STATUS;
  }
  refresh(params) {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
      };
      this.params.onClick(params);
    }
  }
}
