export const mdm_labels = {
  ALL_RECORDS: {
    PROFILE: {
      DATA_REFRESH_IN_PROGRESS:
        'MDM.ALL_RECORDS.PROFILE.DATA_REFRESH_IN_PROGRESS',
      CAN_NOT_FIND_LAST_VISITE_PROFILE:
        'MDM.ALL_RECORDS.PROFILE.CAN_NOT_FIND_LAST_VISITE_PROFILE',
      HOME: 'MDM.ALL_RECORDS.PROFILE.HOME',
      DETAILS: {
        MERGE_UNMERGE: 'MDM.ALL_RECORDS.PROFILE.DETAILS.MERGE_UNMERGE',
        BACK_TO_PROFILE: 'MDM.ALL_RECORDS.PROFILE.DETAILS.BACK_TO_PROFILE',
        CAN_NOT_FIND_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS.CAN_NOT_FIND_PROFILE',
        HCP: 'MDM.ALL_RECORDS.PROFILE.DETAILS.HCP',
        HCO: 'MDM.ALL_RECORDS.PROFILE.DETAILS.HCO',
        SHOW_LINEAGE: 'MDM.ALL_RECORDS.PROFILE.DETAILS.SHOW_LINEAGE',
        SOURCES: 'MDM.ALL_RECORDS.PROFILE.DETAILS.SOURCES',
        PROFILE_DETAILS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.PROFILE_DETAILS',
        ACCOUNT_DETAILS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.ACCOUNT_DETAILS',
        ADDRESS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.ADDRESS',
        IDENTIFIER: 'MDM.ALL_RECORDS.PROFILE.DETAILS.IDENTIFIER',
        IDENTIFIERS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.IDENTIFIERS',
        COMMUNICATION: 'MDM.ALL_RECORDS.PROFILE.DETAILS.COMMUNICATION',
        VIEW_MORE_DETAILS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.VIEW_MORE_DETAILS',
        VIEW_LESS_DETAILS: 'MDM.ALL_RECORDS.PROFILE.DETAILS.VIEW_LESS_DETAILS',
        NEED_TO_UPDATE: 'MDM.ALL_RECORDS.PROFILE.DETAILS.NEED_TO_UPDATE',
        ADDRESS_OF: 'MDM.ALL_RECORDS.PROFILE.DETAILS.ADDRESS_OF',
      },
      DETAILS_TABLE: {
        ATTRIBUTES: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ATTRIBUTES',
        GOLDEN_VALUE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.GOLDEN_VALUE',
        SOURCE_VALUES: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SOURCE_VALUES',
        SURVIVORSHIP_RULES:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SURVIVORSHIP_RULES',
        SOURCES: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SOURCES',
        MERGE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.MERGE',
        NOT_A_MATCH: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.NOT_A_MATCH',
        UNMERGE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.UNMERGE',
        IDENTIFIER: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.IDENTIFIER',
        COMMUNICATION: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COMMUNICATION',
        ADDRESS: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ADDRESS',
        PRIMARY: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PRIMARY',
        MDM_ID: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.MDM_ID',
        ACCOUNT_NAME: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ACCOUNT_NAME',
        ALTERNATE_ACCOUNT_NAME:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ALTERNATE_ACCOUNT_NAME',
        ACCOUNT_TYPE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ACCOUNT_TYPE',
        ACCOUNT_SUB_TYPE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ACCOUNT_SUB_TYPE',
        ACCOUNT_STATUS: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ACCOUNT_STATUS',
        CLASS_OF_TRADE_CODE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.CLASS_OF_TRADE_CODE',
        COT_CLASSIFICATION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_CLASSIFICATION',
        COT_CLASSIFICATION_ID:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_CLASSIFICATION_ID',
        COT_FACILITY_TYPE_ID:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_FACILITY_TYPE_ID',
        COT_FACILITY_TYPE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_FACILITY_TYPE',
        COT_SPECIALTY_ID:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_SPECIALTY_ID',
        COT_SPECIALTY_DESCRIPTION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.COT_SPECIALTY_DESCRIPTION',
        SOURCE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SOURCE',
        SOURCE_ID: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SOURCE_ID',
        FIRST_NAME: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.FIRST_NAME',
        LAST_NAME: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.LAST_NAME',
        MIDDLE_NAME: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.MIDDLE_NAME',
        PREFIX: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PREFIX',
        SUFFIX: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SUFFIX',
        GENDER: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.GENDER',
        TITLE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.TITLE',
        DATE_OF_BIRTH: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.DATE_OF_BIRTH',
        DATE_OF_DEATH: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.DATE_OF_DEATH',
        PROFESSIONAL_DESIGNATION_CODE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PROFESSIONAL_DESIGNATION_CODE',
        PROFESSIONAL_DESIGNATION_DESCRIPTION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PROFESSIONAL_DESIGNATION_DESCRIPTION',
        CUSTOMER_STATUS:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.CUSTOMER_STATUS',
        CUSTOMER_TYPE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.CUSTOMER_TYPE',
        PRIMARY_SPECIALTY:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PRIMARY_SPECIALTY',
        PRIMARY_SPECIALTY_DESCRIPTION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PRIMARY_SPECIALTY_DESCRIPTION',
        SECONDARY_SPECIALTY:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SECONDARY_SPECIALTY',
        SECONDARY_SPECIALTY_DESCRIPTION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SECONDARY_SPECIALTY_DESCRIPTION',
        TERTIARY_SPECIALTY:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.TERTIARY_SPECIALTY',
        TERTIARY_SPECIALTY_DESCRIPTION:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.TERTIARY_SPECIALTY_DESCRIPTION',
        NO_CONTACT_FLAG:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.NO_CONTACT_FLAG',
        PDRP_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PDRP_FLAG',
        PDRP_EFFECTIVE_DATE:
          'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PDRP_EFFECTIVE_DATE',
        TARGET_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.TARGET_FLAG',
        DMR_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.DMR_FLAG',
        KOL_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.KOL_FLAG',
        KAISER_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.KAISER_FLAG',
        AMA_DEAD_FLAG: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.AMA_DEAD_FLAG',
        STRT_DT: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.STRT_DT',
        END_DT: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.END_DT',
        ADDR_MDM_ID: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ADDR_MDM_ID',
        ADDR_LN1: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ADDR_LN1',
        ADDR_LN2: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ADDR_LN2',
        CITY: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.CITY',
        ST: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ST',
        CNTRY: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.CNTRY',
        LAT: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.LAT',
        LNGTD: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.LNGTD',
        ZIP_CD: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ZIP_CD',
        ZIP_CD_EXTN: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ZIP_CD_EXTN',
        PHONE: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.PHONE',
        FAX: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.FAX',
        SLN_NM: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.SLN_NM',
        DEA_NM: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.DEA_NM',
        NPI_NM: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.NPI_NM',
        ME_NM: 'MDM.ALL_RECORDS.PROFILE.DETAILS_TABLE.ME_NM',
      },
      ACTIVITIES: {
        PROFILE_VIEW: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.PROFILE_VIEW',
        PROFILE_MERGED: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.PROFILE_MERGED',
        PROFILE_UNMERGED: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.PROFILE_UNMERGED',
        PROFILE_NOT_A_MATCH:
          'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.PROFILE_NOT_A_MATCH',
        UNKNOWN: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.UNKNOWN',
        NO_PROFILE_ACTIVITIES_YET:
          'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.NO_PROFILE_ACTIVITIES_YET',
        SHOW_MORE: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.SHOW_MORE',
        VIEW_MORE: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.VIEW_MORE',
        ACTIVITY_LOG: 'MDM.ALL_RECORDS.PROFILE.ACTIVITIES.ACTIVITY_LOG',
        PROFILE: 'MDM.ACTIVITY_LOG.PROFILE',
        TYPE: 'MDM.ACTIVITY_LOG.TYPE',
        VIEW: 'MDM.ACTIVITY_LOG.VIEW',
        ACTIVITY: 'MDM.ACTIVITY_LOG.ACTIVITY',
        ALL: 'MDM.ACTIVITY_LOG.ALL',
        PAST_24_HOURS: 'MDM.ACTIVITY_LOG.PAST_24_HOURS',
        PAST_7_DAYS: 'MDM.ACTIVITY_LOG.PAST_7_DAYS',
        PAST_30_DAYS: 'MDM.ACTIVITY_LOG.PAST_30_DAYS',
        PAST_6_MONTHS: 'MDM.ACTIVITY_LOG.PAST_6_MONTHS',
        PAST_YEAR: 'MDM.ACTIVITY_LOG.PAST_YEAR',
      },
      AFFILIATION_DETAILS: {
        HCO_HIERARCHY_NOT_FOUND:
          'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.HCO_HIERARCHY_NOT_FOUND',
        HCP_HIERARCHY_NOT_FOUND:
          'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.HCP_HIERARCHY_NOT_FOUND',
        HCP_DETAILS_NOT_FOUND:
          'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.HCP_DETAILS_NOT_FOUND',
        HCO_DETAILS_NOT_FOUND:
          'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.HCO_DETAILS_NOT_FOUND',
        ATTRIBUTES: 'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.ATTRIBUTES',
        VALUE: 'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.VALUE',
        ADDRESS: 'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.ADDRESS',
        PRIMARY: 'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.PRIMARY',
        NAME: 'MDM.ALL_RECORDS.PROFILE.AFFILIATION_DETAILS.NAME',
      },
      MATCHES: {
        SEARCH_FOR_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.MATCHES.SEARCH_FOR_PROFILE',
        SHOW_MORE: 'MDM.ALL_RECORDS.PROFILE.MATCHES.SHOW_MORE',
        CLEAR: 'MDM.ALL_RECORDS.PROFILE.MATCHES.CLEAR',
        NO_RESULTS_FOUND: 'MDM.ALL_RECORDS.PROFILE.MATCHES.NO_RESULTS_FOUND',
        ATTRIBUTES: 'MDM.ALL_RECORDS.PROFILE.MATCHES.ATTRIBUTES',
        VALUE: 'MDM.ALL_RECORDS.PROFILE.MATCHES.VALUE',
        NAME: 'MDM.ALL_RECORDS.PROFILE.MATCHES.NAME',
        SOURCES: 'MDM.ALL_RECORDS.PROFILE.MATCHES.SOURCES',
        ADDRESS: 'MDM.ALL_RECORDS.PROFILE.MATCHES.ADDRESS',
        SUSPECTED_MATCHES: 'MDM.ALL_RECORDS.PROFILE.MATCHES.SUSPECTED_MATCHES',
        NO_SUSPECTED_MATCHES_FOUND:
          'MDM.ALL_RECORDS.PROFILE.MATCHES.NO_SUSPECTED_MATCHES_FOUND',
        MATCH: 'MDM.ALL_RECORDS.PROFILE.MATCHES.MATCH',
        COMPARE_PROFILES: 'MDM.ALL_RECORDS.PROFILE.MATCHES.COMPARE_PROFILES',
        TITLE: 'MDM.ALL_RECORDS.PROFILE.MATCHES.TITLE',
        GO: 'MDM.ALL_RECORDS.PROFILE.MATCHES.GO',
        INCLUDE_CURRENT_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.MATCHES.INCLUDE_CURRENT_PROFILE',
        ADVANCED_SEARCH_INFO:
          'MDM.ALL_RECORDS.PROFILE.MATCHES.ADVANCED_SEARCH_INFO',
        VIEW_MORE: 'MDM.ALL_RECORDS.PROFILE.MATCHES.VIEW_MORE',
      },
      MERGE: {
        RETURN_TO_PROFILE: 'MDM.ALL_RECORDS.PROFILE.MERGE.RETURN_TO_PROFILE',
        CAN_NOT_FIND_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.MERGE.CAN_NOT_FIND_PROFILE',
        BACK_TO_PROFILE: 'MDM.ALL_RECORDS.PROFILE.MERGE.BACK_TO_PROFILE',
        MERGE: 'MDM.ALL_RECORDS.PROFILE.MERGE.MERGE',
        UNMERGE: 'MDM.ALL_RECORDS.PROFILE.MERGE.UNMERGE',
        HCP: 'MDM.ALL_RECORDS.PROFILE.MERGE.HCP',
        HCO: 'MDM.ALL_RECORDS.PROFILE.MERGE.HCO',
        DRAG_SUSPECTED_MATCHES_TO_MERGE:
          'MDM.ALL_RECORDS.PROFILE.MERGE.DRAG_SUSPECTED_MATCHES_TO_MERGE',
        MERGE_ALL: 'MDM.ALL_RECORDS.PROFILE.MERGE.MERGE_ALL',
        YOU_ARE_CURRENTLY_VIEWING:
          'MDM.ALL_RECORDS.PROFILE.MERGE.YOU_ARE_CURRENTLY_VIEWING',
        SUSPECTED_MATCHES: 'MDM.ALL_RECORDS.PROFILE.MERGE.SUSPECTED_MATCHES',
        SUCCESSFULLY_MERGED:
          'MDM.ALL_RECORDS.PROFILE.MERGE.SUCCESSFULLY_MERGED',
        SUCCESSFUL_NOT_A_MATCH:
          'MDM.ALL_RECORDS.PROFILE.MERGE.SUCCESSFUL_NOT_A_MATCH',
        UNMERGED_SUSPECTED: 'MDM.ALL_RECORDS.PROFILE.MERGE.UNMERGED_SUSPECTED',
        MERGED: 'MDM.ALL_RECORDS.PROFILE.MERGE.MERGED',
      },
      UNMERGE: {
        RETURN_TO_PROFILE: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.RETURN_TO_PROFILE',
        CAN_NOT_FIND_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.UNMERGE.CAN_NOT_FIND_PROFILE',
        BACK_TO_PROFILE: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.BACK_TO_PROFILE',
        MERGE: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.MERGE',
        UNMERGE: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.UNMERGE',
        HCP: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.HCP',
        HCO: 'MDM.ALL_RECORDS.PROFILE.UNMERGE.HCO',
        SUCCESSFULLY_UNMERGED:
          'MDM.ALL_RECORDS.PROFILE.UNMERGE.SUCCESSFULLY_UNMERGED',
        UNMERGED_SUSPECTED: 'MDM.ALL_RECORDS.PROFILE.MERGE.UNMERGED_SUSPECTED',
        MERGED: 'MDM.ALL_RECORDS.PROFILE.MERGE.MERGED',
      },
      PROFILE_TABS: {
        PROFILES: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.PROFILES',
        MATCHES: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.MATCHES',
        HCP_DETAILS: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.HCP_DETAILS',
        HIERARCHY: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.HIERARCHY',
        ACTIVITY_LOG: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.ACTIVITY_LOG',
        LOGS: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.LOGS',
        HCP_HIERARCHY: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.HCP_HIERARCHY',
        HCO_DETAILS: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.HCO_DETAILS',
        HCO_HIERARCHY: 'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.HCO_HIERARCHY',
        RETURN_TO_PROFILE:
          'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.RETURN_TO_PROFILE',
        SUSPECTED_MATCHES:
          'MDM.ALL_RECORDS.PROFILE.PROFILE_TABS.SUSPECTED_MATCHES',
      },
    },
    SEARCH: {
      SEARCH: 'MDM.ALL_RECORDS.SEARCH.SEARCH',
      SEARCH_PLACEHOLDER: 'MDM.ALL_RECORDS.SEARCH.SEARCH_PLACEHOLDER',
      NO_FILTERS_SELECTED: 'MDM.ALL_RECORDS.SEARCH.NO_FILTERS_SELECTED',
      ALL: 'MDM.ALL_RECORDS.SEARCH.ALL',
      NO_RESULTS_FOUND: 'MDM.ALL_RECORDS.SEARCH.NO_RESULTS_FOUND',
      MDM_ID: 'MDM.ALL_RECORDS.SEARCH.MDM_ID',
      NPI: 'MDM.ALL_RECORDS.SEARCH.NPI',
      MATCH_DATE: 'MDM.ALL_RECORDS.SEARCH.MATCH_DATE',
      PROFILE_DOES_NOT_EXISTS: 'MDM.ALL_RECORDS.SEARCH.PROFILE_DOES_NOT_EXISTS',
      ADDRESS: 'MDM.ALL_RECORDS.SEARCH.ADDRESS',
      CHECKBOX: 'MDM.ALL_RECORDS.SEARCH.CHECKBOX',
      PDRP_FLAG: 'MDM.ALL_RECORDS.SEARCH.PDRP_FLAG',
      KAISER_FLAG: 'MDM.ALL_RECORDS.SEARCH.KAISER_FLAG',
      DMR_FLAG: 'MDM.ALL_RECORDS.SEARCH.DMR_FLAG',
      TARGET_FLAG: 'MDM.ALL_RECORDS.SEARCH.TARGET_FLAG',
      ACCOUNT_NAME: 'MDM.ALL_RECORDS.SEARCH.ACCOUNT_NAME',
      CLASS_OF_TRADE: 'MDM.ALL_RECORDS.SEARCH.CLASS_OF_TRADE',
      NAME: 'MDM.ALL_RECORDS.SEARCH.NAME',
      FILTERS: 'MDM.ALL_RECORDS.SEARCH.FILTERS',
      ADVANCE_SEARCH_INFO: 'MDM.ALL_RECORDS.SEARCH.ADVANCE_SEARCH_INFO',
      FLAGS: 'MDM.ALL_RECORDS.SEARCH.FLAGS',
    },
  },
  SUSPECTED_MATCHES: {
    ACTIVITIES: {
      NO_PROFILE_ACTIVITIES_YET:
        'MDM.SUSPECTED_MATCHES.ACTIVITIES.NO_PROFILE_ACTIVITIES_YET',
      SHOW_MORE: 'MDM.SUSPECTED_MATCHES.ACTIVITIES.SHOW_MORE',
      ACTIVITY_LOG: 'MDM.SUSPECTED_MATCHES.ACTIVITIES.ACTIVITY_LOG',
    },
    DASHBOARD: {
      DATA_REFRESH_IS_IN_PROGRESS_LABEL:
        'MDM.SUSPECTED_MATCHES.DASHBOARD.DATA_REFRESH_IS_IN_PROGRESS_LABEL',
      DASHBOARD: 'MDM.SUSPECTED_MATCHES.DASHBOARD.DASHBOARD',
      ACTIVITY_LOG: 'MDM.SUSPECTED_MATCHES.DASHBOARD.ACTIVITY_LOG',
      STATS: {
        CANNOT_FIND_STATS:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.CANNOT_FIND_STATS',
        MDM_ID: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.MDM_ID',
        NAME: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.NAME',
        SOURCES: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.SOURCES',
        NO_STATISTICS_DETAILS_FOUND:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.NO_STATISTICS_DETAILS_FOUND',
        LAST_1_RUN: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.LAST_1_RUN',
        LAST_2_RUN: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.LAST_2_RUN',
        LAST_3_RUN: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.LAST_3_RUN',
        LAST_4_RUN: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.LAST_4_RUN',
        AUTO_MERGED: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.AUTO_MERGED',
        SINGLE: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.SINGLE',
        SUSPECTED: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.SUSPECTED',
        NEW: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.NEW',
        UPDATE: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.UPDATE',
        AUTO_MERGED_UPPER_CASE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.AUTO_MERGED_UPPER_CASE',
        SINGLE_UPPER_CASE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.SINGLE_UPPER_CASE',
        SUSPECTED_UPPER_CASE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.SUSPECTED_UPPER_CASE',
        NEW_UPPER_CASE: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.NEW_UPPER_CASE',
        UPDATE_UPPER_CASE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.UPDATE_UPPER_CASE',
        VALUES: 'MDM.SUSPECTED_MATCHES.DASHBOARD.STATS.VALUES',
      },
      MATCHES: {
        NO_SUSPECTED_MATCHES_FOUND:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.NO_SUSPECTED_MATCHES_FOUND',
        MDM_ID: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.MDM_ID',
        NAME: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.NAME',
        MATCH_DATE: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.MATCH_DATE',
        RECORD_COUNT: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.RECORD_COUNT',
        HIGHEST_MATCH_PERCENTAGE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.HIGHEST_MATCH_PERCENTAGE',
        SUSPECT_SOURCES:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.SUSPECT_SOURCES',
        SEARCH_SUSPECTED_MATCHES:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.SEARCH_SUSPECTED_MATCHES',
        SUSPECTED: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.SUSPECTED',
        MATCHES: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.MATCHES',
        VIEW: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.VIEW',
        FILTER_BY: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.FILTER_BY',
        FIRST_MATCH_DATE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.FIRST_MATCH_DATE',
        LAST_MATCH_DATE:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.LAST_MATCH_DATE',
        ALL: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.ALL',
        PAST_24_HOURS: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.PAST_24_HOURS',
        PAST_7_DAYS: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.PAST_7_DAYS',
        PAST_30_DAYS: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.PAST_30_DAYS',
        PAST_6_MONTHS: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.PAST_6_MONTHS',
        PAST_YEAR: 'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.PAST_YEAR',
        SUSPECTED_MATCHES_LABEL:
          'MDM.SUSPECTED_MATCHES.DASHBOARD.MATCHES.SUSPECTED_MATCHES_LABEL',
      },
    },
  },
  MDM_WRAPPER: {
    MASTER_DATA_MANAGEMENT: 'MDM.MDM_WRAPPER.MASTER_DATA_MANAGEMENT',
    SUSPECTED_MATCHES: 'MDM.MDM_WRAPPER.SUSPECTED_MATCHES',
    ALL_RECORDS: 'MDM.MDM_WRAPPER.ALL_RECORDS',
    ADVANCED_SEARCH: 'MDM.MDM_WRAPPER.ADVANCED_SEARCH',
    VIEW_RECORD_STATISTICS: 'MDM.MDM_WRAPPER.VIEW_RECORD_STATISTICS',
    RECORD_STATISTICS: 'MDM.MDM_WRAPPER.RECORD_STATISTICS',
    ACTIVITY_LOG: 'MDM.MDM_WRAPPER.ACTIVITY_LOG',
    DCR_MANAGEMENT: 'MDM.MDM_WRAPPER.DCR_MANAGEMENT',
  },
  ADVANCED_SEARCH: {
    BACK_TO_PROFILE: 'MDM.ADVANCED_SEARCH.BACK_TO_PROFILE',
    MDM_ID1: 'MDM.ADVANCED_SEARCH.MDM_ID1',
    MDM_ID2: 'MDM.ADVANCED_SEARCH.MDM_ID2',
    SUBMIT: 'MDM.ADVANCED_SEARCH.SUBMIT',
    NO_COMPARE_DATA_FOUND: 'MDM.ADVANCED_SEARCH.NO_COMPARE_DATA_FOUND',
    ADVANCED_SEARCH_RESULTS: 'MDM.ADVANCED_SEARCH.ADVANCED_SEARCH_RESULTS',
    NAME: 'MDM.ADVANCED_SEARCH.NAME',
    AGE: 'MDM.ADVANCED_SEARCH.AGE',
    SPECIALTY: 'MDM.ADVANCED_SEARCH.SPECIALTY',
    EDUCATION: 'MDM.ADVANCED_SEARCH.EDUCATION',
    COLLEGE: 'MDM.ADVANCED_SEARCH.COLLEGE',
    YEAR_OF_PASSING: 'MDM.ADVANCED_SEARCH.YEAR_OF_PASSING',
    NPI: 'MDM.ADVANCED_SEARCH.NPI',
    WORKING_LOCATION: 'MDM.ADVANCED_SEARCH.WORKING_LOCATION',
    RESIDENTIAL_LOCATION: 'MDM.ADVANCED_SEARCH.RESIDENTIAL_LOCATION',
    ATTRIBUTES: 'MDM.ADVANCED_SEARCH.ATTRIBUTES',
  },
};
