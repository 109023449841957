import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiRequestConstants } from '../shared/constants';
import { AppConfig } from '../app.config';
import { EnvironmentConfig } from '../shared/models/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})


export class SiteAdminService {
  url = {
    get_mdm_config_list: '/site_admin/get_mdm_config_list',
    get_mdm_config_by_environment_id:
      '/site_admin/get_mdm_config_by_environment_id',
    save_mdm_config_details: '/site_admin/mdm_config_details',
    fetch_mdm_attributes: '/site_admin/mdm_attributes',
  }
  environment: EnvironmentConfig;

  constructor(public http: HttpClient, private _config: AppConfig) {
    this.environment = this._config.getConfigObject();
  }


  getMDMConfigList() {
    return this.http
      .get(this.environment.baseUrl + this.url.get_mdm_config_list)
      .pipe((response) => response);
  }

  getMDMConfigurationDetails(environmentId): Observable<any> {
    let params = new HttpParams();
    params = params.append(ApiRequestConstants.environment_id, environmentId);
    return this.http
      .get(this.environment.baseUrl + this.url.get_mdm_config_by_environment_id, {
        params: params,
      })
      .pipe((response) => response);
  }

  fetchMDMAttributes(
  ): Observable<any> {
    const request_body = {};
    return this.http
      .post(this.environment.baseUrl + this.url.fetch_mdm_attributes, request_body)
      .pipe((response) => response);
  }

  saveMDMConfigurationDetails(
    mdmAttributesDetails: any,
    attributesFetched
  ) {
    const request_body = {};
    request_body[ApiRequestConstants.mdmAttributesDetails] =
      mdmAttributesDetails;
    request_body[ApiRequestConstants.attributesFetched] = attributesFetched;
    return this.http
      .post(
        this.environment.baseUrl + this.url.save_mdm_config_details,
        request_body
      )
      .pipe((response) => response);
  }
}
