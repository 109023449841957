import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AccordionDirective } from './accordion.directive';
import { ActionComponent } from './zs-flow/flow-nodes/action/action.component';
import { AgGridModule } from 'ag-grid-angular';
import { ApplyEllipses } from '../revo-core/apply-ellipses.pipe';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CommonModule } from '@angular/common';
import { CursorPointerDirective } from './cursor-pointer.directive';
import { DragAndDropFileDirective } from './drag-and-drop-file.directive';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { EditableTableService } from './table-column-filters/table-column-filters.service';
import { FilterTablePipe } from '../revo-core/filterTablePipe';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighlightTextPipe } from './editable-table/highlight-text.pipe';
import { IdmBarDirective } from './idm-bar.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IsCheckedPipe } from './editable-table/isChecked.pipe';
import { ModaldialogComponent } from './modaldialog/modaldialog.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { OrderrByPipe } from './OrderByPipe';
import { RevoCoreService } from './revo-core.service';
import { StepperComponent } from './stepper/stepper.component';
import { StickyHeaderDirective } from './sticky-header.directive';
import { ToasterServiceComponent } from './toaster-service/toaster-service.component';
import { TranslateModule } from '@ngx-translate/core';
import { ValuesPipe } from './valuesPipe';
import { VisualizationChartsComponent } from './visualization-charts/visualization-charts.component';
import { ZsAutoSuggestComponent } from './zs-auto-suggest/zs-auto-suggest.component';
import { ZsCardComponent } from '../revo-core/zs-card/zs-card.component';
import { ZsDatePickerComponent } from './zs-date-picker/zs-date-picker.component';
import { ZsDateRangeComponent } from './zs-date-range/zs-date-range.component';
import { ZsEditableTableSelectComponent } from './zs-editable-table-select/zs-editable-table-select.component';
import { ZsFlowComponent } from './zs-flow/zs-flow.component';
import { ZsGridComponent } from './zs-grid/zs-grid.component';
import { ZsLoaderDirective } from './zs-loader/zs-loader.directive';
import { ZsMenuComponent } from './zs-menu/zs-menu.component';
import { ZsMultiSelectDropdownComponent } from './zs-multi-select-dropdown/zs-multi-select-dropdown.component';
import { ZsPaginationComponent } from './zs-pagination/zs-pagination.component';
import { ZsProgressBarComponent } from './zs-progress-bar/zs-progress-bar.component';
import { ZsSelectComponent } from './zs-select/zs-select.component';
import { ZsTagComponent } from './zs-tag/zs-tag.component';
import { ZsTextareaComponent } from './zs-textarea/zs-textarea.component';
import { ZsTextboxComponent } from './zs-textbox/zs-textbox.component';
import { ZsTimePickerComponent } from './zs-time-picker/zs-time-picker.component';
import { ZsToggleComponent } from './zs-toggle/zs-toggle.component';
// import { jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    TranslateModule,
    HighchartsChartModule,
    AgGridModule,
  ],
  declarations: [
    ZsTextboxComponent,
    ModaldialogComponent,
    ToasterServiceComponent,
    OrderrByPipe,
    IdmBarDirective,
    ValuesPipe,
    ZsDatePickerComponent,
    ZsTimePickerComponent,
    MultiSelectDropdownComponent,
    ZsSelectComponent,
    ZsToggleComponent,
    FilterTablePipe,
    ZsFlowComponent,
    ActionComponent,
    ZsProgressBarComponent,
    ZsPaginationComponent,
    EditableTableComponent,
    IsCheckedPipe,
    ZsTextareaComponent,
    ZsMultiSelectDropdownComponent,
    ZsMenuComponent,
    ZsAutoSuggestComponent,
    HighlightTextPipe,
    ZsGridComponent,
    StickyHeaderDirective,
    AccordionDirective,
    VisualizationChartsComponent,
    ApplyEllipses,
    ZsLoaderDirective,
    ZsTagComponent,
    ZsCardComponent,
    StepperComponent,
    ZsDateRangeComponent,
    CursorPointerDirective,
    ZsEditableTableSelectComponent,
    DragAndDropFileDirective,
  ],
  exports: [
    ZsTextboxComponent,
    ModaldialogComponent,
    ToasterServiceComponent,
    OrderrByPipe,
    IdmBarDirective,
    ValuesPipe,
    ZsDatePickerComponent,
    ZsTimePickerComponent,
    MultiSelectDropdownComponent,
    ZsSelectComponent,
    ZsToggleComponent,
    FilterTablePipe,
    ZsFlowComponent,
    ActionComponent,
    ZsProgressBarComponent,
    ZsPaginationComponent,
    EditableTableComponent,
    ZsTextareaComponent,
    ZsMenuComponent,
    ZsAutoSuggestComponent,
    ZsGridComponent,
    StickyHeaderDirective,
    AccordionDirective,
    VisualizationChartsComponent,
    ApplyEllipses,
    ZsLoaderDirective,
    ZsTagComponent,
    ApplyEllipses,
    ZsCardComponent,
    StepperComponent,
    CursorPointerDirective,
    DragAndDropFileDirective,
  ],
  providers: [RevoCoreService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RevoCoreModule {}
