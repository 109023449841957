export const ChartColors = {
    "NEW": "#86c8bc",
    "UPDATE": "#FFA500",
    "SINGLE": "#6e2b62",
    "SUSPECTED": "#b8cc7b",
    "AUTO MERGED": "#01a6dc",
    "MANUAL MERGED": "#a3b2aa",
    "UNMERGED": "#a0afc6",
    "NOT A MATCH": "#53565a"
};

export const StatsConstants = {
    formatValue: 'd',
    height: 38
};

export const DashboardTableColumnsConstants = {
    MDM_ID: 'MDM_ID',
    NAME: 'NAME',
    SOURCES: 'SOURCES'
};

export const ExcludeRecordStatisticsColumnsList = [
    DashboardTableColumnsConstants.MDM_ID,
    DashboardTableColumnsConstants.NAME,
    DashboardTableColumnsConstants.SOURCES,
];

export const RecordStatisticsPaginationConstant = {
    PAGE_SIZE: 20
};

