import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  DetailsConstants,
  MDMCommonConstants,
  ProfileMatchesIncludedKeysConstants
} from '../../constants/mdm-common-constants';
import {
  ExcludedKeys,
  IncludedKeys,
  ProfileAPIResponseConstants,
  QueryParams,
} from '../../constants/profile-constants';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../../constants/ui-translation';

declare var jQuery: any;

@Component({
  selector: 'mdm-profile-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MatchesProfileComponent implements OnInit {
  @ViewChild('detailsDialog', { read: ElementRef })
  detailsDialog: ElementRef;
  @ViewChildren('profileChecked') profileChecked: QueryList<ElementRef>;

  isHco = false;
  onMergeScreen = false;
  currentProfileCheckbox = false;
  matchesLoading: boolean;
  addProfileLoading: boolean;
  matches: any[] = [];
  page = 1;
  pageSize = 10;
  totalPages = 0;
  matchesToShow = 10;
  profilesToCompare = [];
  disableCheckboxes = false;
  searchPlaceholder: string;

  get isBlank(): boolean {
    return !this.searchValue;
  }
  showSearchItems = false;
  searchResultsLoading: boolean;
  searchResults: any[] = [];
  searches: any[] = [];
  maxLengthForEllipses = DetailsConstants.MAX_LENGTH_FOR_ELLIPSES_IN_MATCHES;
  @Output() searchCount: EventEmitter<any> = new EventEmitter();
  @Input() profileName: string;

  detailsId = '';
  details: any;
  keys: string[];
  excludedKeys: string[] = [
    ExcludedKeys.CHECKED,
    ExcludedKeys.FUZZY_SCORE,
    ExcludedKeys.SRC_NM,
    ExcludedKeys.IS_GOLDEN,
    ExcludedKeys.ADDRESSES,
    ExcludedKeys.COMMUNICATIONS,
    ExcludedKeys.IS_GOLDEN,
  ];

  includeKeys: string[] = [
    IncludedKeys.HCP_MDM_ID,
    IncludedKeys.MDM_ID,
    IncludedKeys.TITL,
  ];

  _id = '';
  _returnId = '';

  uiLabels = mdm_labels.ALL_RECORDS.PROFILE.MATCHES;
  translatedLabels: any;
  searchForProfileLabel: string;
  noSuspectedMatchesFoundLabel: string;
  compareProfileLabelTitle: string;
  errorLabel: string;
  commonLabels = commmon_labels;
  showNoSuggestedMatchesMessage = true;
  MDMCommonConstants = MDMCommonConstants;
  suspectedMatchesMDMIds: any[] = [];
  includedKeysMapping = new Map<string, string>();
  environment: EnvironmentConfig;
  refreshMatchesTabSubscr: any;
  mergeScreenActivatedSubscr: any;
  profileRemovedSubscr: any;
  keyDisplayNo = MDMCommonConstants.N;
  susMatch = 'susmatch';
  curProf = 'curprof';
  searchValue = '';
  showNoResultsFoundLabel = false;
  dragging = false;

  constructor(
    private service: MDMService,
    private route: ActivatedRoute,
    private router: Router,
    private _translate: TranslateService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService
  ) {
    this.environment = this.config.getConfigObject();
    this.translatedLabels = this._translate.instant([
      this.uiLabels.SEARCH_FOR_PROFILE,
      this.uiLabels.NO_SUSPECTED_MATCHES_FOUND,
      this.uiLabels.COMPARE_PROFILES,
      this.commonLabels.ERROR_WITH_COLON,
      this.uiLabels.TITLE,
      this.commonLabels.SEARCH,
    ]);
    this.searchPlaceholder = this.translatedLabels[this.commonLabels.SEARCH];
    this.noSuspectedMatchesFoundLabel =
      this.translatedLabels[this.uiLabels.NO_SUSPECTED_MATCHES_FOUND];
    this.compareProfileLabelTitle =
      this.translatedLabels[this.uiLabels.COMPARE_PROFILES];
    this.errorLabel = this.translatedLabels[this.commonLabels.ERROR_WITH_COLON];
    this.includedKeysMapping[ProfileMatchesIncludedKeysConstants.TITLE] =
      this.translatedLabels[this.uiLabels.TITLE];
    this.includedKeysMapping[ProfileMatchesIncludedKeysConstants.MDM_ID] =
      ProfileMatchesIncludedKeysConstants.MDM_ID;
    this.includedKeysMapping[ProfileMatchesIncludedKeysConstants.HCP_MDM_ID] =
      ProfileMatchesIncludedKeysConstants.HCP_MDM_ID;

    this.mergeScreenActivatedSubscr =
      this.service.mergeScreenActivated$.subscribe((activated) => {
        this.onMergeScreen = activated;

        if (this.profileChecked) {
          this.profileChecked.forEach((element) => {
            element.nativeElement.checked = false;
          });
        }
      });

    this.refreshMatchesTabSubscr = this.service.refreshMatchesTab$.subscribe(
      () => {
        this.refreshProfileMatches();
      }
    );

    this.profileRemovedSubscr = this.service.profileRemoved$.subscribe(
      (profile) => {
        let index = this.matches.findIndex(
          (m) => m.id.value === profile.id.value
        );
        if (index >= 0) {
          this.matches.splice(index, 1);
          this.searchCount.emit(
            this.matches.filter((match) => !match.searched)
          );
        }

        index = this.searches.findIndex((m) => m.id.value === profile.id.value);
        if (index >= 0) {
          this.searches.splice(index, 1);
        }
      }
    );
  }

  ngOnInit() {
    this.isHco = window.location.href.indexOf('/hco/') >= 0;
    this.route.params.subscribe((params) => {
      this._id = params[QueryParams.ID];
      this.matches = [];
      this.searches = [];
      this.refreshCompareProfiles();
      this.loadProfileMatches();
    });
    this.route.queryParams.subscribe((params) => {
      this._returnId = params[QueryParams.RETURN_ID];
    });
  }

  drag() {
    this.dragging = !this.dragging;
  }

  getSuspectedProfileNames(isLoadMoreData) {
    if (isLoadMoreData) {
      if (this.page >= this.totalPages) {
        return;
      } else {
        this.page += 1;
      }
    } else {
      this.page = 1;
    }

    this.searchResultsLoading = true;
    this.showSearchItems = true;
    this.showNoResultsFoundLabel = false;
    this.service
      .getSearchedSuspectedProfileNames(
        this.searchValue,
        this.page,
        this.pageSize,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (result) => {
          if (result && result.data && result.data.length > 0) {
            this.totalPages = Math.ceil(result.total_records / this.pageSize);
            result.data = this.checkIfSearchedProfileAlreadyInSuspectedMathces(
              result.data
            );
            this.searchResults = [...this.searchResults, ...result.data];
            this.showNoResultsFoundLabel =
              result.data.length === 0 ? true : false;
          } else {
            this.totalPages = 0;
            this.searchResults = [];
            this.showNoResultsFoundLabel = true;
          }
          this.searchResultsLoading = false;
        },
        (exception) => {
          this.searchResultsLoading = false;
          this.showSearchItems = true;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  getSelectedSuspectedProfileData(mdmId) {
    this.searchResultsLoading = true;
    this.service
      .getSelectedSuspectedProfileData(
        mdmId,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (result) => {
          this.searchResultsLoading = false;
          if (result && result.data && result.data.length > 0) {
            result.data.forEach((e) => {
              e.searched = true;
              e.id = {
                [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
                [ProfileAPIResponseConstants.DISPLAY]: '',
                [ProfileAPIResponseConstants.DESC]: '',
                [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
              };
            });
            this.searches = [...this.searches, ...result.data];
            for (const search of result.data) {
              this.matches = [search, ...this.matches];
            }
            this.service.setMatchesWithSearchData(this.matches);
            this.showSearchItems = false;
            this.searchValue = '';
            this.searchResults = [];
            this.truncateString();
            this.filterKeys(result.data);
            this.addProfileLoading = false;
          }
        },
        (exception) => {
          this.addProfileLoading = false;
          this.searchResultsLoading = false;
          this.showSearchItems = true;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  gotoAdvancedSearch() {
    this.router.navigate(['/mdm/advanced-search'], {
      queryParams: {
        current_profile_id: this._id,
        id1: this.profilesToCompare[0],
        id2: this.profilesToCompare[1],
        type: this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP,
      },
    });
  }

  showAllProfileMatches() {
    this.matchesToShow = this.matches.length;
  }

  profileCheckedToCompare(event, profile_type, profile) {
    let key: string;
    key = profile_type === this.susMatch ? profile.id.value : this._id;
    if (event.currentTarget.checked) {
      this.profilesToCompare.push(key);
    } else {
      const index = this.profilesToCompare.indexOf(key, 0);
      if (index > -1) {
        this.profilesToCompare.splice(index, 1);
      }
    }
    this.disableCheckboxes = this.profilesToCompare.length > 1 ? true : false;
  }

  refreshProfileMatches() {
    this.matches = [];
    this.searches = [];
    this.loadProfileMatches();
  }

  loadProfileMatches() {
    this.matchesLoading = true;

    this.service
      .getProfileMatchesData(
        this._id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (data) => {
          this.matchesLoading = false;
          this.showNoSuggestedMatchesMessage = true;
          data.forEach((e) => {
            e.checked = false;
            e.id = {
              [ProfileAPIResponseConstants.VALUE]: e.MDM_ID.value,
              [ProfileAPIResponseConstants.DISPLAY]: '',
              [ProfileAPIResponseConstants.DESC]: '',
              [ProfileAPIResponseConstants.TO_DISPLAY]: MDMCommonConstants.N,
            };
          });
          this.matches = [...data, ...this.matches];

          if (this.matches && this.matches.length > 0) {
            this.truncateString();
            this.filterKeys(this.matches);
          }
          this.searchCount.emit(
            this.matches.filter((match) => !match.searched)
          );
        },
        (exception) => {
          this.matchesLoading = false;
          this.showNoSuggestedMatchesMessage = false;
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
  }

  closeSearchResult() {
    this.searchValue = '';
    this.showSearchItems = false;
    this.searchResults = [];
    this.page = 1;
  }

  searchItemSelected(item: any) {
    this.addProfileLoading = true;
    if (!this.searches.find((match) => match.MDM_ID.value === item.mdm_id)) {
      this.getSelectedSuspectedProfileData(item.mdm_id);
    } else {
      this.searchValue = '';
      this.searchResults = [];
      this.page = 1;
      this.addProfileLoading = false;
      return;
    }
  }

  checkIfSearchedProfileAlreadyInSuspectedMathces(data) {
    const mdmIdsTobBeDeleted = [];
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].mdm_id === this._id &&
        !mdmIdsTobBeDeleted.includes(index)
      ) {
        mdmIdsTobBeDeleted.push(index);
      } else if (
        this.matches.some((el) => el.MDM_ID.value === data[index].mdm_id) &&
        !mdmIdsTobBeDeleted.includes(index)
      ) {
        mdmIdsTobBeDeleted.push(index);
      }
    }

    while (mdmIdsTobBeDeleted.length) {
      data.splice(mdmIdsTobBeDeleted.pop(), 1);
    }
    return data;
  }

  profileSelected(profile: any) {
    this.service.profileSelected(profile);
  }

  hideSearchItems(e) {
    this.searchResults = [];
    this.showSearchItems = false;
  }

  searchItemRemoved(item) {
    if (this.matches.includes(item)) {
      this.matches.splice(this.matches.indexOf(item), 1);
    }
    if (this.searches.includes(item)) {
      this.searches.splice(this.searches.indexOf(item), 1);
    }
  }

  refreshCompareProfiles() {
    this.currentProfileCheckbox = false;
    this.profilesToCompare = [];
    this.disableCheckboxes = false;
  }

  showProfile(id) {
    const returnId = this.route.snapshot.paramMap.get(QueryParams.ID);
    this.router
      .navigate(
        [
          this.isHco
            ? '/mdm/all-records/hco/profile'
            : '/mdm/all-records/hcp/profile',
          id,
        ],
        { queryParams: { returnId: returnId, returnName: this.profileName.substring(0, this.profileName.lastIndexOf('(')) } }
      )
      .then(() => {
        this.refreshCompareProfiles();
      });
  }

  truncateString() {
    setTimeout(() => {
      jQuery('.truncate').each(function (i) {
        if (jQuery(this).text().length > 18) {
          jQuery(this).text(jQuery(this).text().substr(0, 18) + '...');
        }
      });
    }, 50);
  }

  gotoCompare(profile) {
    this.router.navigate(['/mdm/advanced-search'], {
      queryParams: {
        slaveId: profile.id.value,
        id: this._id,
        type: this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP,
      },
    });
  }

  filterKeys(data) {
    this.keys = Object.keys(data[0])
      .filter((k) => this.includeKeys.find((e) => e.indexOf(k) >= 0))
      .filter((k) => !k.toUpperCase().startsWith('SLAVE_'));
  }

  ngOnDestroy() {
    if (this.refreshMatchesTabSubscr) {
      this.refreshMatchesTabSubscr.unsubscribe();
    }

    if (this.mergeScreenActivatedSubscr) {
      this.mergeScreenActivatedSubscr.unsubscribe();
    }

    if (this.profileRemovedSubscr) {
      this.profileRemovedSubscr.unsubscribe();
    }
  }

  searchBoxValueChange() {
    this.searchResults = [];
    this.page = 1;
    this.showNoResultsFoundLabel = false;
  }
}
