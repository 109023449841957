<div class="modal-wrapper">
  <zs-dialog #dialog class="zs-dialog" id="dialog1" [ngStyle]="customstyle" (close)="onClose($event)"
    (cancel)="onClose($event)" [zIndex]="dialogZIndex">
    <header>
      <h4 class="modal-heading">
        <ng-content select="[modal-header]"></ng-content>
        <span id="heading" [ngStyle]="headerCustomStyle">
          {{titletext}}
        </span>
      </h4>
      <span *ngIf="isCrossIconEnabled" close (click)="hideModal()">
        <a class="zs-icon zs-interactive-secondary zs-icon-close zs-size-xl"></a>
      </span>
    </header>
    <ng-content select="[modal-body]"></ng-content>
    <footer class="modal-footer">
      <ng-content select="[modal-footer]"></ng-content>
    </footer>
  </zs-dialog>

  <zs-tooltip *ngIf="showTooltipForTitle" for="heading" class="zs-tooltip tooltip" position="bottom">
    <zs-tooltip-content class="zs-size-xs">
      {{titletext}}
    </zs-tooltip-content>
  </zs-tooltip>
</div>