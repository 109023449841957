import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';

import { AppConfig } from '../../../../../app/app.config';
import { EnvironmentConfig } from '../../../../shared/models/environment';
import { MDMCommonConstants, PayerWrapperConstants, MDMActivityLog } from '../../../../../app/mdm/constants/mdm-common-constants';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { PayerService } from '../payer-services/payer.service';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/mdm/services/common.service';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { Location } from '@angular/common';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'

@Component({
  selector: 'app-payer-merge-unmerge-details',
  templateUrl: './payer-merge-unmerge-details.component.html',
  styleUrls: ['./payer-merge-unmerge-details.component.less'],
})
export class PayerMergeUnmergeDetailsComponent implements OnInit {
  constructor(
    private config: AppConfig,
    private cdRef: ChangeDetectorRef,
    private payerService: PayerService,
    private service: MDMService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private _location: Location,
    private router: Router,
    private toaster: ToasterNotificationService,
  ) {
    this.environment = this.config.getConfigObject();
  }
  @Input() tableId: string;
  @Input() tableCssClass: string;
  @Input() profiles;
  @Input() onMergePage = false;
  @Input() onUnmergePage = false;
  @Input() wrapperElement: ElementRef;
  environment: EnvironmentConfig;
  keys = [];
  id: any;
  mdmConstants = MDMCommonConstants;
  lineageSubscribe: any;
  pageDetails: any;
  golden: any;

  ngOnInit(): void {
    this.id = this.route.parent.snapshot.paramMap.get('id');
    this.pageDetails = this.commonService.getFromSessionStorage('pageDetails');
    this.lineageSubscribe = this.payerService.LineageDetails$.subscribe((data) => {
      this.golden = data.golden;
    })
    if (!this.golden) {
      if (this.pageDetails.entity == PayerWrapperConstants.PBM) {
        this.payerService.getPBMLineageData(this.id);
      } else if (this.pageDetails.entity == PayerWrapperConstants.MCO) {
        this.payerService.getMCOLineageData(this.id);
      } else if (this.pageDetails.entity == PayerWrapperConstants.PAYER) {
        this.payerService.getPAYERLineageData(this.id);
      } else if (this.pageDetails.entity == PayerWrapperConstants.PLAN) {
        this.payerService.getPLANLineageData(this.id);
      }
    }
  }
  ngAfterViewInit(): void {
    this.cdRef.markForCheck();
  }

  splitToNewlines(val: string) {
    return val
      ? val
        .replace(
          `${MDMCommonConstants.ME}:`,
          `<strong>${MDMCommonConstants.ME}</strong>`
        )
        .replace(
          `${MDMCommonConstants.NPI}:`,
          `<strong>${MDMCommonConstants.NPI}:</strong>`
        )
        .replace(
          `${MDMCommonConstants.SLN}:`,
          `<strong>${MDMCommonConstants.SLN}:</strong>`
        )
        .replace(
          `${MDMCommonConstants.DEA}:`,
          `<strong>${MDMCommonConstants.DEA}:</strong>`
        )
        .replace(',', '<br>')
      : val;
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  profileDeselected(item, $event, index) {
    this.payerService.removeFromMergeArray(index);

  }
  doMerge(item, index) {
    const master_id = this.golden.MDM_ID.value;
    const slave_id = [item.MDM_ID.value];
    switch (this.pageDetails.entity) {
      case PayerWrapperConstants.PBM:
        if (item.PBM_CHANNEL.value === this.golden.PBM_CHANNEL.value) {
          this.PBMMerge(master_id, slave_id, index);
        } else {
          this.toaster.showWarning('PBM Channels must be same to merge', '');
        }
        break;
      case PayerWrapperConstants.MCO:
        this.MCOMerge(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PLAN:
        this.PLANMerge(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PAYER:
        if (item.PAYER_CHANNEL.value === this.golden.PAYER_CHANNEL.value) {
          this.PAYERMerge(master_id, slave_id, index);
        } else {
          this.toaster.showWarning('PAYER Channels must be same to merge', '');
        }
        break;
    }
  }
  doNotAMatch(record, index) {
    const master_id = this.golden.MDM_ID.value;
    const slave_id = record.MDM_ID.value;
    switch (this.pageDetails.entity) {
      case PayerWrapperConstants.PBM:
        this.PBMDoNotmatch(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.MCO:
        this.MCODoNotmatch(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PLAN:
        this.PLANDoNotmatch(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PAYER:
        this.PAYERDoNotmatch(master_id, slave_id, index);
        break;
    }

  }
  doUnmerge(record, index) {
    const master_id = this.golden.MDM_ID.value;
    const slave_id = [record.MDM_ID.value];
    switch (this.pageDetails.entity) {
      case PayerWrapperConstants.PBM:
        this.PBMUnMerge(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.MCO:
        this.MCOUnMerge(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PLAN:
        this.PLANUnMerge(master_id, slave_id, index);
        break;
      case PayerWrapperConstants.PAYER:
        this.PAYERUnMerge(master_id, slave_id, index);
        break;
    }

  }
  PBMMerge(master_id, slave_id, index) {
    this.service.postPBMProfileMerge(master_id, slave_id).subscribe((data: any) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.MERGE);
      this.payerService.getPBMSuspectedMatches(this.id);
      this.payerService.getPBMLineageData(this.id);
      this.payerService.getPBMProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PBMDoNotmatch(master_id, slave_id, index) {
    this.service.postPBMProfileNotaMatch(master_id, slave_id).subscribe((data) => {
      this.payerService.removeFromMergeArray(index);
      this.toaster.showSuccess('Not a match is successfully applied', '');
      this.payerService.getPBMSuspectedMatches(this.id);
      this.payerService.getPBMLineageData(this.id);
      this.payerService.getPBMProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PBMUnMerge(master_id, slave_id, index) {
    this.service.postPBMProfileUnMerge(master_id, slave_id).subscribe((data) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.UNMERGE);
      this.payerService.getPBMSuspectedMatches(this.id);
      this.payerService.getPBMProfileByid(this.id);
      this.payerService.getPBMLineageData(this.id);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  MCOMerge(master_id, slave_id, index) {
    this.service.postMCOProfileMerge(master_id, slave_id).subscribe((data: any) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.MERGE);
      this.payerService.getMCOSuspectedMatches(this.id);
      this.payerService.getMCOLineageData(this.id);
      this.payerService.getMCOProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  MCODoNotmatch(master_id, slave_id, index) {
    this.service.postMCOProfileNotaMatch(master_id, slave_id).subscribe((data) => {
      this.payerService.removeFromMergeArray(index);
      this.toaster.showSuccess('Not a match is successfully applied', '');
      this.payerService.getMCOSuspectedMatches(this.id);
      this.payerService.getMCOLineageData(this.id);
      this.payerService.getMCOProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  MCOUnMerge(master_id, slave_id, index) {
    this.service.postMCOProfileUnMerge(master_id, slave_id).subscribe((data) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.UNMERGE);
      this.payerService.getMCOSuspectedMatches(this.id);
      this.payerService.getMCOLineageData(this.id);
      this.payerService.getMCOProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PLANMerge(master_id, slave_id, index) {
    this.service.postPLANProfileMerge(master_id, slave_id).subscribe((data: any) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.MERGE);
      this.payerService.getPLANSuspectedMatches(this.id);
      this.payerService.getPLANLineageData(this.id);
      this.payerService.getPLANProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PLANDoNotmatch(master_id, slave_id, index) {
    this.service.postPLANProfileNotaMatch(master_id, slave_id).subscribe((data) => {
      this.payerService.removeFromMergeArray(index);
      this.toaster.showSuccess('Not a match is successfully applied', '');
      this.payerService.getPLANSuspectedMatches(this.id);
      this.payerService.getPLANLineageData(this.id);
      this.payerService.getPLANProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PLANUnMerge(master_id, slave_id, index) {
    this.service.postPLANProfileUnMerge(master_id, slave_id).subscribe((data) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.UNMERGE);
      this.payerService.getPLANSuspectedMatches(this.id);
      this.payerService.getPLANLineageData(this.id);
      this.payerService.getPLANProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PAYERMerge(master_id, slave_id, index) {
    this.service.postPAYERProfileMerge(master_id, slave_id).subscribe((data: any) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);

      this.refreshTabs(MDMActivityLog.MERGE);
      this.payerService.getPAYERSuspectedMatches(this.id);
      this.payerService.getPAYERLineageData(this.id);
      this.payerService.getPAYERProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PAYERDoNotmatch(master_id, slave_id, index) {
    this.service.postPAYERProfileNotaMatch(master_id, slave_id).subscribe((data) => {
      this.payerService.removeFromMergeArray(index);
      this.toaster.showSuccess('Not a match is successfully applied', '');
      this.payerService.getPAYERSuspectedMatches(this.id);
      this.payerService.getPAYERLineageData(this.id);
      this.payerService.getPAYERProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  PAYERUnMerge(master_id, slave_id, index) {
    this.service.postPAYERProfileUnMerge(master_id, slave_id).subscribe((data) => {
      this.id = data.masterId
      this.payerService.removeFromMergeArray(index);
      this.refreshTabs(MDMActivityLog.UNMERGE);
      this.payerService.getPAYERSuspectedMatches(this.id);
      this.payerService.getPAYERLineageData(this.id);
      this.payerService.getPAYERProfileByid(this.id);

    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  refreshTabs(operation = null) {
    const profileUrlParts = this._location.path().split('/');
    profileUrlParts[profileUrlParts.length - 2] = this.id;
    const finalProfileUrl = profileUrlParts.join('/');
    this.router.navigate([finalProfileUrl]).then(() => {
      if (operation === MDMActivityLog.UNMERGE) {
        this.toaster.showSuccess('Unmerged Successfully', '');

      } else if (operation === MDMActivityLog.MERGE) {
        this.toaster.showSuccess('Merged Successfully', '');
      }
    });
  }
  ngOnDestroy(): void {
    this.lineageSubscribe.unsubscribe();
  }
}
