import { Guid } from "guid-typescript";
import { uuid_prefix } from "./shared/constants";

export default class Utils {
    static getProgressbarValue(duration, max) {
        return (max === 0) ? 100 : (duration / max) * 100;
    }

    static getFormattedStringFromList(list) {
        return list.join(', ');
    }

    static isFileNameForUploadValid(fileName, fileTypes) {
        let fileType = fileTypes.join('|');
        var re = new RegExp("^[a-zA-Z0-9-_]{1,120}\(" + fileType + ")$");
        if (re.test(fileName)) {
            return true
        } else {
            return false
        }
    }

    static isFileSizeForUploadValid(fileSize, maxFileSize) {
        if ((fileSize / (1024 * 1024)) > maxFileSize) {
            return false;
        } else {
            return true;
        }
    }

    static is_json_equal_to_base(comparision_json, base_json): boolean {
        let is_equal = true;
        for (const item in base_json) {
            if (comparision_json[item] !== base_json[item]) {
                is_equal = false;
                return is_equal;
            }
        }
        return is_equal;
    }

    static formatStringListToDisplay(list=[]): any {
        if (list == null || list === undefined) {
            return '';
        }
        return list.join(', ');
    }

    static detectChrome() {
        var isChromium = (window as any).chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isOpera = typeof (window as any).opr !== "undefined";
        var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
        var isIOSChrome = winNav.userAgent.match("CriOS");

        if (isIOSChrome) {
            return true;
        } else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            return true;
        } else {
            return false;
        }
    }

    static getGuid(remove_hyphen_characters = false, should_start_from_alphabet = true) {
        let guid = Guid.raw();
        if (remove_hyphen_characters) {
            guid = Guid.raw().replace(new RegExp('-', 'g'), '');
        }
        if (should_start_from_alphabet) {
            guid = uuid_prefix + guid;
        }
        return guid;
    }

    static checkAlphaNumeric(value) {
        var re = new RegExp("^[a-zA-Z0-9_]+$");
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    }

    static replaceSpaceWithUnderscore(value) {
        return value.split(' ').join('_');
    }

    static applyPaginationOnData(currentPage, pageSize, data) {
        const startIndexOfiProfilerList = (currentPage - 1) * pageSize;
        const endIndexOfiProfilerList = (currentPage * pageSize);
        return data.slice(startIndexOfiProfilerList, endIndexOfiProfilerList);
    }
    static generateNoRowsHtml(msg: string) {
        return `<strong class="no-row-template-style" style="font-size:16px;">${msg}</strong>`;
    }
}
