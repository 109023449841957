import { RouterModule, Routes } from '@angular/router';

import { AppRoles } from '../constants';
import { AuthGuard } from '../auth.guard';
import { CompareComponent } from './compare/compare.component';
import { DetailsComponent } from './profile/details/details.component';
import { MdmConfigDetailsComponent } from './mdm-config-details/mdm-config-details.component';
import { MdmWrapperComponent } from './mdm-wrapper/mdm-wrapper.component';
import { MergeUnmergeComponent } from './profile/merge-unmerge/merge-unmerge.component';
import { NavigationTabs } from '../shared/constants';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { SearchComponent } from './search/search.component';
import { DcrDetailsComponent } from './dcr-management/dcr-details/dcr-details.component';
import { PayerProfileComponent } from './payer/profile/payer-profile/payer-profile.component';
import { PayerMergeUnmergeComponent } from './payer/profile/payer-merge-unmerge/payer-merge-unmerge.component';
import { PayerProfileDetailsComponent } from './payer/profile/payer-profile-details/payer-profile-details.component';
import { TabConfig }  from 'src/assets/listtabs/Tab.js'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      /*visibility: TabConfig.MDM_Tabs.Suspected_Matches,*/
      visibility: true,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'all-records',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [{ path: 'search', component: SearchComponent }],
  },
  {
    path: 'all-records/hcp/profile/:id',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [{ path: '', component: DetailsComponent }],
  },
  {
    path: 'all-records/hcp/profile/:id/merge-unmerge',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [{ path: '', component: MergeUnmergeComponent }],
  },
  {
    path: 'all-records/profile/:id/merge-unmerge',
    component: PayerMergeUnmergeComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'all-records/payer/profile/:id',
    component: PayerProfileComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [
      { path: '', component: PayerProfileDetailsComponent },
      { path: 'merge-unmerge', component: PayerMergeUnmergeComponent },
    ],
  },
  {
    path: 'all-records/hco/profile/:id',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [{ path: '', component: DetailsComponent }],
  },
  {
    path: 'all-records/hco/profile/:id/merge-unmerge',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.All_Records,
      navigationTabName: [NavigationTabs.MASTER],
    },
    children: [{ path: '', component: MergeUnmergeComponent }],
  },

  {
    path: 'advanced-search',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'activity-log',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.ADMIN, AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.Activity_Log,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'admin',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.SITE_ADMIN],
      isUserAuthorized: true,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'dcr-management',
    component: MdmWrapperComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.DCR_Management,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
  {
    path: 'dcr-management/:id',
    component: DcrDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.MasterDataSteward],
      isUserAuthorized: true,
      visibility: TabConfig.MDM_Tabs.DCR_Management,
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MDMRoutingModule { }
