export const AgGridColumnDefsConstants = {
    HEADER_NAME: 'headerName',
    FIELD: 'field',
    SORTABLE: 'sortable',
    MAX_WIDTH: 'maxWidth',
    TOOLTIP_VALUE_GETTER: 'tooltipValueGetter',
    CELL_RENDERER: 'cellRenderer',
    RESIZABLE: 'resizable',
    CELL_RENDERER_PARAMS: 'cellRendererParams',
    GET_QUICK_FILTER_TEXT: 'getQuickFilterText',
    SUPPRESS_MENU: 'suppressMenu',
    WIDTH: 'width',
    FLEX: 'flex',
    MIN_WIDTH: 'minWidth',
    SUPPRESS_MOVABLE: 'suppressMovable',
    MENU_TABS: 'menuTabs',
    ROW_HEIGHT: 'rowHeight',
    HEADER_HEIGHT: 'headerHeight',
    STOP_EDITING_WHEN_CELLS_LOSE_FOCUS: 'stopEditingWhenCellsLoseFocus',
    SINGLE_CLICK_EDIT: 'singleClickEdit',
    FRAMEWORK_COMPONENTS: 'frameworkComponents',
    ROW_SELECTION: 'rowSelection',
    ROW_MULTIPLE_SELECT_WITH_CLICK: 'rowMultiSelectWithClick',
    SUPPRESS_ROW_CLICK_SELECTION: 'suppressRowClickSelection',
    DEFAULT_COL_DEF: 'defaultColDef',
    IS_ROW_SELECTABLE: 'isRowSelectable',
    CHECKBOX_SELECTION: 'checkboxSelection',
    HEADER_CHECKBOX_SELECTION: 'headerCheckboxSelection',
    FILTER: 'filter',
    FILTER_PARAMS: 'filterParams',
    UNSORT_ICON: 'unSortIcon',
    SORTING_ORDER: 'sortingOrder',
    COMPARATOR: 'comparator',
    HEADER_CLASS: 'headerClass',
    ROW_DRAG: 'rowDrag',
    WRAP_TEXT: 'wrapText',
    WRAP_HEADER_TEXT: 'wrapHeaderText',
    AUTO_HEIGHT: 'autoHeight',
    AUTO_HEADER_HEIGHT: 'autoHeaderHeight',
    CELL_STYLE: 'cellStyle',
    HEADER_TOOLTIP: 'headerTooltip',
    HEADER_COMPONENT_PARAMS: 'headerComponentParams',
    EDITABLE: 'editable',
    CELL_EDITOR: 'cellEditor',
    CELL_EDITOR_PARAMS: 'cellEditorParams',
    VALUE_FORMATTER: 'valueFormatter',
    FILTER_MENU_TAB: 'filterMenuTab',
    GENERAL_MENU_TAB: 'generalMenuTab',
    COLUMNS_MENU_TAB: 'columnsMenuTab',
    MULTIPLE: 'multiple',
    TOOLTIP_FIELD: 'tooltipField',
    ROW_SPAN: 'rowSpan',
    CHILDREN: 'children',
    PINNED: 'pinned',
    CELL_CLASS: 'cellClass',
    APPLY_COLUMN_DEF_ORDER: 'applyColumnDefOrder',
    HIDE: 'hide',
    SUPPRESS_COLUMN_TOOL_PANEL: 'suppressColumnsToolPanel',
    VALUE_SETTER: 'valueSetter',
    TOOLTIP_SHOW_DELAY: 'tooltipShowDelay',
    SUPPRESS_CELL_SELECTION: 'suppressCellSelection',
    LOADING_OVERLAY_COMPONENT: 'loadingOverlayComponent',
    ENABLE_CELL_TEXT_SELECTION: 'enableCellTextSelection',
    CELL_RENDERER_SELECTOR: 'cellRendererSelector'
};

