<div class="matches-component">
  <div cdkDropListGroup>
    <div class="profile-matches-group-one zs-width-100">
      <div class="zs-display-flex search-bar">
        <span class="zs-display-flex">
          <app-zs-textbox class="search-box" [(modelValue)]="searchValue" [enableSearch]="'true'"
            [placeholder]="searchPlaceholder" (modelValueChange)="searchBoxValueChange()">
          </app-zs-textbox>
        </span>
        <span class="zs-display-flex search-button-custom">
          <button [hidden]="searchResults && searchResults.length > 0" type="button"
            [disabled]="searchValue.trim().length === 0" class="
              zs-button zs-button-action
              only-search-button-class
              zs-border-rounded-0
            " (click)="getSuspectedProfileNames(false)" translate>
            {{ commonLabels.SEARCH }}
          </button>
          <button [hidden]="searchResults && searchResults.length === 0" type="button" [disabled]="page === totalPages"
            class="
              zs-button zs-button-action
              only-show-more-button-class
              zs-border-rounded-0
            " (click)="getSuspectedProfileNames(true)" translate>
            {{ uiLabels.SHOW_MORE }}
          </button>
        </span>
      </div>
      <div (clickedOutside)="hideSearchItems($event)" class="autocomplete-items" [hidden]="!showSearchItems || isBlank">
        <div *ngFor="let result of searchResults" (click)="searchItemSelected(result)"
          [ngClass]="{ 'disable-div': addProfileLoading }">
          <span>{{ result.name }}</span>
        </div>
        <div *ngIf="showNoResultsFoundLabel" class="filter-select-list" translate>
          {{ uiLabels.NO_RESULTS_FOUND }}
        </div>
      </div>
    </div>

    <div *ngIf="
        (!matches || matches.length === 0) &&
        !matchesLoading &&
        showNoSuggestedMatchesMessage
      " class="no-suspected-matches">
      <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
      {{ noSuspectedMatchesFoundLabel }}
    </div>

    <div *ngIf="!isHco && this.environment.isMDMAdvancedSearchEnabled && matches.length">
      <div class="advanced-search">
        <div class="left-cell check-pos">
          <span class="zs-icon-info-fill zs-icon zs-text-info"></span>
          <span class="checkbox-info" translate>{{
            uiLabels.ADVANCED_SEARCH_INFO
            }}</span>
        </div>
        <div class="right-cell go-btn-pos">
          <button class="go-button" [disabled]="!disableCheckboxes" (click)="gotoAdvancedSearch()">
            <span class="zs-button zs-button-action zs-border-rounded-0" translate>{{ uiLabels.GO }}</span>
          </button>
        </div>
      </div>
      <div class="checkbox-margin">
        <label class="zs-checkbox zs-display-inline">
          <input type="checkbox" [disabled]="disableCheckboxes && !currentProfileCheckbox"
            [(ngModel)]="currentProfileCheckbox" (click)="profileCheckedToCompare($event, curProf, 'val')" />
          <span checkbox></span>
        </label>
        <span class="checkbox-text" translate>{{
          uiLabels.INCLUDE_CURRENT_PROFILE
          }}</span>
      </div>
    </div>
    <div appZsLoader [isVisible]="searchResultsLoading || matchesLoading"
      class="profile-matches-group-two zs-col-sm-11 advanced-search">
      <div *ngIf="matches && matches.length > 0" [ngClass]="
          matches.length < 10
            ? 'suspected-matches-with-auto-height'
            : 'suspected-matches'
        " cdkDropList cdkDropListOrientation="horizontal" id="dragList" cdkDropListConnectedTo="dropList">
        <div [cdkDragDisabled]="!onMergeScreen" cdkDrag (cdkDragStarted)="drag()" (cdkDragEnded)="drag()"
          [cdkDragData]="item" *ngFor="let item of matches | slice: 0:matchesToShow; let i = index">
          <div *ngIf="!searchResultsLoading" class="profile-checkboxes">
            <div [ngClass]="{
                'cursor-move': onMergeScreen,
                'search-background': item.searched,
                'checked-background': item.isChecked
              }" class="zs-card zs-padding-2 zs-display-flex zs-flex-align-center" tabindex="0">
              <label *ngIf="!isHco && this.environment.isMDMAdvancedSearchEnabled" class="all-records zs-checkbox"
                style="top: -0.7em">
                <input type="checkbox" [disabled]="!item.isChecked && disableCheckboxes"
                  (click)="profileCheckedToCompare($event, susMatch, item)" [checked]="item.selectionStatus"
                  [(ngModel)]="item.isChecked" />
                <span checkbox></span>
              </label>
              <span class="left-cell">
                <div class="profile-name-tag" [ngClass]="{ 'zs-flex-direction-column': item.searched }">
                  <div class="zs-col-sm-6">
                    <span class="zs-icon zs-icon-dragger" [hidden]="!onMergeScreen"></span>
                    <span [ngClass]="
                        isHco
                          ? 'zs-icon zs-icon-field-unit'
                          : 'zs-icon zs-icon-user'
                      "></span>
                    <span class="profile-matches-show-profile-fn" *ngIf="!isHco"
                      (click)="showProfile(item.MDM_ID.value)">{{ item.FRST_NM.value + " " + item.LAST_NM.value |
                      applyEllipses : maxLengthForEllipses }}</span>
                    <span class="profile-matches-show-profile-fn" *ngIf="isHco"
                      (click)="showProfile(item.MDM_ID.value)">{{ item.ACCT_NM.value | applyEllipses :
                      maxLengthForEllipses }}</span>
                    <zs-tooltip class="tooltip-index tooltip-padding" position="left" offset-x="10" *ngIf="!dragging">
                      <table class="zs-table zs-size-s">
                        <thead>
                          <tr>
                            <th translate>{{ uiLabels.ATTRIBUTES }}</th>
                            <th translate>{{ uiLabels.VALUE }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let key of keys">
                            <td *ngIf="item[key].to_display !== keyDisplayNo">
                              {{
                              item[key].display != ''
                              ? includedKeysMapping.hasOwnProperty(
                              item[key].display.trim()
                              )
                              ? includedKeysMapping[
                              item[key].display.trim()
                              ]
                              : item[key].display
                              : key
                              }}
                            </td>
                            <td *ngIf="item[key].to_display !== keyDisplayNo">
                              {{ item[key].value }}
                            </td>
                          </tr>
                          <tr>
                            <td translate>{{ uiLabels.NAME }}</td>
                            <td>
                              <span *ngIf="!isHco">{{ item.FRST_NM.value }}
                                {{ item.LAST_NM.value }}</span>
                              <span *ngIf="isHco">{{
                                item.ACCT_NM.value
                                }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td translate>{{ uiLabels.SOURCES }}</td>
                            <td>
                              <span *ngFor="let src of item.SRC_NM.value" [ngStyle]="{
                                  'background-color':
                                    environment.mdmSourceColorCodes[
                                      src.trim().toLowerCase()
                                    ]
                                }" class="zs-badge-counter">
                                {{ src.trim().toUpperCase() }}
                              </span>
                            </td>
                          </tr>
                          <ng-container *ngIf="item && item.ADDRESSES">
                            <tr>
                              <td translate>{{ uiLabels.ADDRESS }}</td>
                              <td>
                                <span *ngFor="
                                    let address of item.ADDRESSES.slice(
                                      0,
                                      environment.mdmMaxAddressesToDisplayCount
                                    )
                                  ">
                                  <app-mdm-address [address]="address"></app-mdm-address>
                                </span>
                                <span *ngIf="
                                    environment.mdmMaxAddressesToDisplayCount
                                  " [ngClass]="{
                                    'addresses-ellipsis zs-icon zs-size-xl zs-icon-more':
                                      item.ADDRESSES.length >
                                      environment.mdmMaxAddressesToDisplayCount
                                  }"></span>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </zs-tooltip>
                  </div>
                  <div class="zs-col-sm-6 word-break">
                    <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 0
                      " [ngStyle]="{
                        'background-color':
                          environment.mdmSourceColorCodes[
                            item.SRC_NM.value[0].trim().toLowerCase()
                          ]
                      }" [ngClass]="{ 'search-margin': item.searched }" class="zs-badge-counter zs-border-rounded-0">
                      {{ item.SRC_NM.value[0].trim().toUpperCase() }}
                    </span>
                    <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 1
                      " [ngStyle]="{
                        'background-color':
                          environment.mdmSourceColorCodes[
                            item.SRC_NM.value[1].trim().toLowerCase()
                          ]
                      }" class="zs-badge-counter zs-border-rounded-0">
                      {{ item.SRC_NM.value[1].trim().toUpperCase() }}
                    </span>
                    <span *ngIf="
                        item.SRC_NM &&
                        item.SRC_NM.value &&
                        item.SRC_NM.value.length > 2
                      ">...</span>
                  </div>
                </div>
                <div *ngIf="item.FUZZY_SCORE && item.FUZZY_SCORE.value" class="fuzzy-score">
                  <span class="match-text">{{ item.FUZZY_SCORE.value }}% </span><span class="match-text" translate>
                    {{ uiLabels.MATCH }}</span>
                </div>
              </span>
              <span class="right-cell zs-icon zs-size-large zs-icon-close" [hidden]="onMergeScreen"
                (click)="searchItemRemoved(item)" *ngIf="item.searched">
              </span>
              <span class="right-cell zs-icon zs-icon-item-drag" [hidden]="!onMergeScreen"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="matches.length" class="show-more-button-div advanced-search zs-col-sm-11">
      <button type="button" [disabled]="matches.length < 10 || matchesToShow === matches.length"
        class="show-more-button zs-button zs-button-action zs-border-rounded-0" (click)="showAllProfileMatches()"
        translate>
        {{ uiLabels.VIEW_MORE }}
      </button>
    </div>
  </div>
</div>