<span class="center-align wrap-tags">
  <div class="source-col" *ngIf=isSource>
    <div *ngIf='data.ATTRIBUTES == data.ADDRESS_LABEL || data.ATTRIBUTES == data.IDENTIFIERS_LABEL ||
    data.ATTRIBUTES == data.COMMUNICATION_LABEL'>
      <div *ngFor="let source of data.SOURCE_DICT">
        <div>
          <span [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source['key'].trim().toLowerCase()]}"
            class="zs-badge-text tags zs-badge-counter">{{source['value'].trim().toLowerCase()}}</span>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf='data.ATTRIBUTES != data.ADDRESS_LABEL && data.ATTRIBUTES != data.IDENTIFIERS_LABEL &&
    data.ATTRIBUTES != data.COMMUNICATION_LABEL'>
        <span *ngFor="let source of data.SOURCE_DICT"
          [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source['key'].trim().toLowerCase()]}"
          class="zs-badge-text tags zs-badge-counter">{{source['value'].trim().toLowerCase()}}</span>
      </div>
    </div>
  </div>
  <div
    [ngClass]="data.SURVIVORSHIP_RULES.split('>').length > 2 ? 'zs-display-grid source-col' : 'zs-display-flex source-col'"
    *ngIf='data.SURVIVORSHIP_RULES && isRules && data.ATTRIBUTES != data.IDENTIFIERS_LABEL'>
    <span *ngFor="let rule of data.SURVIVORSHIP_RULES.split('>'); let i=index"
      [ngStyle]="{'color': environment.mdmSourceColorCodes[rule.split(' ').join('').trim().toLowerCase()]}">
      {{ rule.trim().toUpperCase()}}
      <span *ngIf="i+1 < data.SURVIVORSHIP_RULES.split('>').length">></span></span>
  </div>
  <div *ngIf='data.SURVIVORSHIP_RULES && isRules && data.ATTRIBUTES == data.IDENTIFIERS_LABEL'>
    <span *ngFor="let rule of data.SURVIVORSHIP_RULES">
      {{ rule['key'].trim().toUpperCase()}}
      <div
        [ngClass]="rule['value'].split('>').length > 2 ? 'zs-display-grid source-col' : 'zs-display-flex source-col'">
        <span *ngFor="let element of rule['value'].split('>'); let i=index"
          [ngStyle]="{'color': environment.mdmSourceColorCodes[element.trim().toLowerCase()]}">
          {{ element.trim().toUpperCase()}}
          <span *ngIf="i+1 < rule['value'].split('>').length">></span></span>
      </div>
    </span>
  </div>
</span>