
<div *ngIf="data.ATTRIBUTES !== data.ADDRESS_LABEL && data.ATTRIBUTES !== data.IDENTIFIERS_LABEL">
    <span>
        {{ data.GOLDEN_VALUE }}
    </span>
</div>
<div *ngIf="data.ATTRIBUTES === data.ADDRESS_LABEL">
    <div *ngFor="let val of data.GOLDEN_VALUE">
        <strong>{{ val.key.toUpperCase() }}</strong>
        <div *ngFor="let address of val.value">
            {{ address }}
        </div>
    </div>
</div>
<div *ngIf="data.ATTRIBUTES === data.IDENTIFIERS_LABEL">
    <div *ngFor="let val of data.GOLDEN_VALUE">
        <strong> {{ val.split(lineageConstants.SPLIT_CONSTANT)[0] }}</strong>
        <span > {{ val.split(lineageConstants.SPLIT_CONSTANT)[1] }} </span>
    </div>
</div>
