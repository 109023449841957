import { Pipe, PipeTransform } from '@angular/core';
import { ServiceUtils } from 'src/app/service-utils';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  constructor(private _serviceUtils: ServiceUtils){

  }
  transform(valueInMinutes: any): any {
    return this._serviceUtils.getTimeFromMinute(valueInMinutes);
  }

}
