import Field from 'zsui/src/field/field.m';

function isThing(elem) {
    if (elem && (elem.length || document.body.contains(elem))) {
        return true;
    }

    return false;
}

function isVisible(elem) {
    return !(elem.offsetWidth <= 0 && elem.offsetHeight <= 0)
}

class Select extends Field {

    constructor(...$) {
        const _ = super(...$);

        return _;
    }

    setup() {
        // Initialize Field
        super.setup();

        // Set prop defaults
        this._setDefaults();

        //
        this.on(['open', 'ready', 'close', 'beforeopen', 'beforeclose'], this, this, false, false);

        // Sync Props
        this.syncProp('alwaysOpen');
        this.syncProp('noRender');
        this.syncProp('hideOnScroll');
        this.syncProp('flippable');
        this.syncProp('searchDelay');
        this.syncProp('deselectAllText');
        this.syncProp('selectAllText');
        this.syncProp('addSelectAll');
        this.syncProp('minOptionsForSearch');
        this.syncProp('maxOptionsVisible');
        this.syncProp('addCheckboxes');
        this.syncProp('emptyText');
        this.syncProp('noRecordsText');
        this.syncProp('mode');

        // Polyfill to support NodeList.prototype.forEach in all browsers
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }
    }

    static get observedAttributes() {
        return super.observedAttributes.concat(this.getObservedAttributes);
    }

    static get getObservedAttributes() {
        return ['always-open', 'no-render', 'hide-on-scroll', 'flippable', 'add-select-all', 'add-checkboxes', 'search-delay', 'min-options-for-search', 'empty-text', 'max-options-visible', 'deselect-all-text', 'select-all-text', 'no-records-text', 'mode'];
    }

    /**
     * Method to get index/indices of selected options in the list.
     * @returns {string|Array} String containing selected index for single select dropdown and Array[] containing selected indices for multi-select dropdown.
     */
    getValue() {
        if (!this.selectEl) {
            return "";
        }

        if (this.selectEl.selectedIndex != -1) {
            if (this.multiple) {
                var selectedIndices = [];
                var len = this.selectEl.children.length;
                var item;

                for (var i = 0; i < len; i++) {
                    var child = this.selectEl.children[i];

                    if (child.tagName.toLowerCase() === "optgroup") { // in case select contains optgroup
                        for (var k = 0; k < child.children.length; k++) {
                            item = child.children[k];
                            if (item.selected) {
                                selectedIndices.push(item.value);
                            }
                        }
                    } else {
                        item = this.selectEl.children[i];

                        if (item.selected) {
                            selectedIndices.push(item.value);
                        }
                    }
                }

                return selectedIndices;
            } else {
                return this.selectEl.value;
            }
        } else {
            return "";
        }
    }

    propertyChangedCallback(name, oldValue, newValue) {
        this.mode === 'classic' ? console.warn("Classic mode has been deprecated since v5.0 and shall be removed in future release. Please use 'action' mode instead.") : null;

        super.propertyChangedCallback(name, oldValue, newValue);

        if (name === 'label') {
            if (!this.labelElement && this.label) {
                var lbl = document.createElement('label');
                lbl.innerHTML = `<span label>${this.label ? this.label : ""}</span>`;
                this.insertAdjacentElement("afterbegin", lbl);
            }

            if (this.labelElement && !this.label) {
                this.removeChild(this.labelElement.parentElement);
            }
        } else if (name === 'clear') {
            this.clear ? this.containerEl && this.containerEl.setAttribute("hint-icon", "") : this.containerEl && this.containerEl.removeAttribute("hint-icon");
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this._init();

        if (oldValue === newValue) {
            return
        }

        if (this.constructor.getObservedAttributes.indexOf(name) == -1) {
            super.attributeChangedCallback(name, oldValue, newValue);
            return;
        }

        if (name == 'always-open') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'no-render') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'hide-on-scroll') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'flippable') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'add-select-all') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'add-checkboxes') {
            this.syncAttr(name, newValue, 'boolean');
        } else if (name == 'search-delay') {
            this.syncAttr(name, newValue, 'number');
        } else if (name == 'min-options-for-search') {
            this.syncAttr(name, newValue, 'number');
        } else if (name == 'max-options-visible') {
            this.syncAttr(name, newValue, 'number');
        } else if (name == 'empty-text') {
            this.syncAttr(name, newValue, 'string');
        } else if (name == 'deselect-all-text') {
            this.syncAttr(name, newValue, 'string');
        } else if (name == 'select-all-text') {
            this.syncAttr(name, newValue, 'string');
        } else if (name == 'no-records-text') {
            this.syncAttr(name, newValue, 'string');
        } else if (name == 'mode') {
            this.syncAttr(name, newValue, 'string');
            return;
        }

        if (!this._isRendered) {
            return;
        }

        this.render();
    }

    connectedCallback() {
        this._init();
        window.requestAnimationFrame(() => {
            this.render();
        })
    }

    disconnectedCallback() {
        //Check whether the select component is being re-arranged or being removed from DOM
        var parent = this.parentElement;
        while (parent && parent != document.body) {
            parent = parent.parentElement;
        }
        if (!parent) {
            window.requestAnimationFrame(() => {
                this.cleanUp();
            })
        }
    }

    _setDefaults() {
        // this is to set defaults for synchronized properties see syncProps

        /**
         * Specifies if dropdown should be always open. Default value is `false`.
         * @type {boolean}
         * @name alwaysOpen
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" always-open>
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'}); 
                    dropdown.alwaysOpen = true;
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
         */
        this._alwaysOpen = false;

        /**
         * When set to `true`, prevents dropdown from being automatically rendered when connected to DOM. Useful in scenarios where `<select>` within dropdown is expected to be populated after a delay (eg: after an AJAX call). We can manually call `dropdown.render()` method later to render the dropdown. Default value is `false`.
         * @type {boolean}
         * @name noRender
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" no-render>
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.noRender = true;
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    document.body.appendChild(dropdown);
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.render();
         */
        this._noRender = false;

        /**
         * When set to `true`, open the dropdown above the field incase there isn't enough space to open it below the field. Also, this appends dropdown's container element to `<body>`.Default value is `false`.
         * @type {boolean}
         * @name flippable
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" flippable>
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.flippable = true;
         */
        this._flippable = false;

        /**
         * When set to `true`, closes the dropdown when document is scrolled. Typically used along with `flippable` open set to `true`. This prevents dropdown from being mispositioned while user scrolls page in `flippable` mode. Default value is `false`.
         * @type {boolean}
         * @name hideOnScroll
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" hide-on-scroll>
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>'; 
                    dropdown.hideOnScroll = true;
         */
        this._hideOnScroll = false;

        /**
         * When set to `true`, adds an option to select all elements in the dropdown. Default value is `false`.
         * @type {boolean}
         * @name addSelectAll
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" add-select-all>
                        <select multiple><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select multiple><option value="1">one</option></select>';
                    dropdown.addSelectAll = true;
         */
        this._addSelectAll = false;

        /**
         * When set to `true`, adds checkboxes alongside options in a `multiple` dropdown. Default value is `false`.
         * @type {boolean}
         * @name addCheckboxes
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" add-checkboxes>
                        <select multiple><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select multiple><option value="1">one</option></select>';
                    dropdown.addCheckboxes = true;
         */
        this._addCheckboxes = false;

        this._multiple = false;

        /**
         * Delay in milliseconds after which search begins while typing. Default value is `300`. A value of `0` indicates absence of delay.
         * @type {number}
         * @name searchDelay
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" search-delay="1000">
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.searchDelay = 1000;
         */
        this._searchDelay = 300;

        /**
         * Minimum number of options that would enable search feature. Default value is `6`.
         * @type {number}
         * @name minOptionsForSearch
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" min-options-for-search="10">
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.minOptionsForSearch = 10;
         */
        this._minOptionsForSearch = 6;

        /**
         * Specifies how many options will be shown initially in the list. If we have more items in `<select>` they will be shown 
         incrementally when user scrolls to the bottom of the list (aka 'Infinitive scroll'). Default value is `100`.
         * @type {number}
         * @name maxOptionsVisible
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" max-options-visible="200">
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.maxOptionsVisible = 200;
        */
        this._maxOptionsVisible = 100;

        /**
         * Specifies text that will be shown if nothing selected. Default text is `Select value`.
         * @type {text}
         * @name emptyText
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" empty-text="Select an option">
                        <select multiple><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select multiple><option value="1">one</option></select>';
                    dropdown.emptyText = 'Select an option';
        */
        this._emptyText = "Select value";

        /**
         * Specifies text to be shown to deselect all options. Default text is `Deselect all`.
         * @type {text}
         * @name deselectAllText
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" deselect-all-text="Select an option" add-select-all>
                        <select multiple><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select multiple><option value="1">one</option></select>';
                    dropdown.addSelectAll = true;
                    dropdown.deselectAllText = 'Deselect options';
        */
        this._deselectAllText = "Deselect all";

        /**
         * Specifies text to be shown to select all options. Default text is `Select all`.
         * @type {text}
         * @name selectAllText
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" select-all-text="Select an option" add-select-all>
                        <select multiple><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select multiple><option value="1">one</option></select>';
                    dropdown.addSelectAll = true;
                    dropdown.selectAllText = 'Deselect options';
        */
        this._selectAllText = "Select all";

        /**
         * Specifies text to be shown when no search results are present. Default text is `No items match your search.`.
         * @type {text}
         * @name noRecordsText
         * @memberof Select
         * @example <p is="zs-select" class="zs-field zs-select" no-records-text="No Records Found">
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.noRecordsText = 'No Records Found.';
        */
        this._noRecordsText = "No items match your search.";

        /**
         * Specifies the mode of dropdown component. Default value is `action`. Setting mode value to `action` during initialization and assigning `zs-action-field` class (instead of `zs-field`), renders Action Field within dropdown and applies relevant styles. Setting value to `classic` renders component in legacy look n feel.
         * @type {text}
         * @name mode
         * @memberof Select
         * @example <p is="zs-select" class="zs-action-field zs-select" mode="action">
                        <select><option value="1">one</option></select>
                    </p>
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.classList.add('zs-action-field');
                    dropdown.classList.add('zs-select');
                    dropdown.innerHTML = '<select><option value="1">one</option></select>';
                    dropdown.mode = 'action';
        */
        this._mode = "action";
    }

    /**
     * Method to cleanup the dropdown and unbind listeners upon detach.
     */
    cleanUp() {
        while (this.firstChild) {
            this.removeChild(this.firstChild);
        }

        // Unbind listeners
        this.off(['keyup', 'blur', 'paste', 'change', 'focus', 'keydown'], this, this.inputElement);
        this.off(['keyup', 'keydown'], this, this.navEl);

        this.off(['click', 'touchstart', 'mousewheel', 'DOMMouseScroll', 'touchmove', 'scroll', 'mousedown'], this, document);

        this.off(['click', 'keydown', 'keyup'], this, this.overlayEl);
        this.off(['click', 'keydown'], this, this.clearIconElement);

        this.off(['change'], this, this.selectEl);

        // Unset props
        this._isRendered = undefined;
        this.selectAllText = undefined;
        //this.inputEl = undefined;
        this.data = undefined;
        //this.selectEl = undefined;
        this.alwaysOpen = undefined;
        this.multiple = undefined;
        this.noRender = undefined;
        this.hideOnScroll = undefined;
        this.flippable = undefined;
        this.addSelectAll = undefined;
        this.addCheckboxes = undefined;

        this.searchDelay = undefined;
        this.minOptionsForSearch = undefined;
        this.maxOptionsVisible = undefined;

        this.emptyText = undefined;
        this.deselectAllText = undefined;
        this.selectedMap = undefined;
        this.selectFirst = undefined;
        this.enteredText = undefined;
        this.noRecordsText = undefined;

        // this.navEl = undefined;
        (this.containerEl && this.containerEl.parentElement) ? this.containerEl.parentElement.removeChild(this.containerEl) : undefined;
        this.containerEl = undefined;
    }

    get isMobile() {
        return window.navigator && window.navigator.userAgent && (window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/Android/i));
    }

    get isTablet() {
        return window.navigator && window.navigator.userAgent && window.navigator.userAgent.match(/iPad/i);
    }

    get selectEl() {
        return this.querySelector('select');
    }

    get navEl() {
        return this.containerEl ? this.containerEl.querySelector('nav[options]') : this.querySelector('nav[options]');
    }

    get inputElement() {
        return this.containerEl ? this.containerEl.querySelector('input') : this.querySelector('input');
    }

    get clearIconElement() {
        return this.containerEl ? this.containerEl.querySelector('[clear]') : this.root.querySelector('input');
    }

    get overlayEl() {
        return this.querySelector('div[overlay]');
    }

    get actionElement() {
        return this.containerEl ? this.containerEl.querySelector('[action]') : this.root.querySelector('[action]');
    }

    template() {
        return `
        ${this.label ? `<label><span label>${this.label}</span></label>` : ``}
        ${this.selectEl.outerHTML}
        <div overlay tabindex=${this.disabled ? '-1' : '0'} style="top:0;bottom:0;left:0;right:0;">Select value</div>
        <div class="zs-search-dropdown zs-menu ${this.mode === 'action' ? "zs-action-field zs-action-field-solid" : "zs-field"}" style="top:0;left:0;" container ${this.mode === 'action' ? (!this.alwaysOpen ? 'action-icon icon' : 'icon') : ''}>
            ${this.mode === 'action' ?
                `<label>
                <input ${this.alwaysOpen && this.disabled ? 'tabindex="-1"' : ''} input type="text" value="">
                <span signifier class="zs-icon zs-icon-search"></span>
                <a class="zs-icon zs-icon-close-circle-fill zs-interactive-primary" tabindex="0" clear href="#" onclick="return false;" hidden></a>
                ${!this.alwaysOpen ? '<a tabindex="-1" action href="#" onclick="return false;" class="zs-icon zs-interactive-secondary zs-icon-carat-down"></a>' : ''}
            </label>
            `
                :
                `<p class="zs-input-icon zs-icon-search" field>
                <input input type="text" class="zs-input"/>
                <a class="zs-icon zs-icon-remove" tabindex="-1" clear href="#" onclick="return false;" hidden></a>
            </p>`
            }
            <nav tabindex="-1" options class="zs-custom-scrollbar"></nav>
        </div>
        ` ;
        //Nav becomes focussable for Firefox. So, adding tabindex="-1" to prevent focus. This is to prevent issue with FF in keyboard Accessibility when Tabbed from Input/ClearIcon.
    }

    /**
     * Method to render the dropdown
     */
    render() {

        // To resolve constructor caveat in Safari, we may require to call init() again if component is not initialized.
        // https://github.com/ungap/custom-elements-builtin#constructor-caveat
        // this.init();

        //this.selectEl = this.querySelector('select');

        if (this.noRender) {
            return;
        }

        if (!this.selectEl) {
            console.warn("<select> element not found. Dropdown could not be initialized.")
            return;
        }

        if (!this._isRendered) {
            super.render(); // Call Field's render first
        }

        this.multiple = this.selectEl.multiple;

        this._allSelected = false;
        this._isRendered = false;
        this.selectedMap = {};

        if (this.addSelectAll && !this.multiple) { // selectAll makes sense only for multiple 
            throw new Error('addSelectAll is available in multiple mode only');
        }

        if (this.addSelectAll && this.selectEl > 999) {
            console.warn('We do not recommend to use addSelectAll with a long list of options due to possible lack of performance');
        }

        var self = this;

        this.enteredText = '';

        // Detect a parent container
        if (this.selectEl.parentElement.classList.contains('zs-select')) {
            this.parentEl = this.selectEl.parentElement;
        } else {
            this.parentEl = this;
        }

        if (!this.containerEl) {
            var containerEl = this.querySelector('div[container]');
            if (!containerEl) {
                containerEl = document.createElement('div');
                containerEl.setAttribute('container', '');

                if (this.mode === 'action') {
                    containerEl.setAttribute('class', 'zs-search-dropdown zs-menu zs-action-field zs-action-field-solid');
                    containerEl.setAttribute('action-icon', '');
                    containerEl.setAttribute('icon');
                    containerEl.innerHTML = '<label><input input type="text" value=""><span signifier class="zs-icon zs-icon-search"></span><a class="zs-icon zs-icon-close-circle-fill zs-interactive-primary" tabindex="-1" clear href="#" onclick="return false;" hidden></a><a action href="#" onclick="return false;" class="zs-interactive-secondary zs-icon  zs-icon-collapse"></a></label>';
                }
                else {
                    containerEl.setAttribute('class', 'zs-search-dropdown zs-menu zs-field');
                    containerEl.innerHTML = '<p class="zs-input-icon zs-icon-search"><input input type="text" class="zs-input"/><a class="zs-icon zs-icon-remove" tabindex="-1" clear href="#" onclick="return false;" hidden></a></p><nav options></nav>';
                }
                this.parentEl.appendChild(containerEl);
            }
            this.containerEl = containerEl;
        }

        this.clear ? this.containerEl.setAttribute("hint-icon", "") : this.containerEl.removeAttribute("hint-icon"); // Adjust input padding if clear icon present in action mode

        this.on(['click', 'keydown'], this, this.clearIconElement);

        this.on(['keydown'], this, this.overlayEl, false, false);

        this.selectEl.style.display = 'block';

        this.parentEl.style.position = 'relative';

        // Create an overlay to handle clicks
        if (!this.overlayEl) {
            var overlayEl = document.createElement('div');
            overlayEl.setAttribute('overlay', '');
            this.parentEl.appendChild(overlayEl);
        }

        this.containerEl.style.display = 'none'; // Container should be hidden to get accurate selectEl width

        //handling cases where select is hidden when rendered. Eg. Accordion
        if (!this.alwaysOpen) {
            if (this.selectEl.offsetWidth) {
                this.overlayEl.style.width = this.selectEl.offsetWidth + 'px'; // We need to set width so that overlay does not expand while selecting options in multiple mode
            }
            else {
                this.getSelectVirtualWidth().then((result) => {
                    this.overlayEl.style.width = result;
                });

            }
        }


        //this.style.width = this.selectEl.offsetWidth + 'px';

        if (this.alwaysOpen) {

            // Hide overlay
            this.containerEl.style.display = '';
            this.overlayEl.style.display = 'none';

            if (this.selectEl === this.parentEl) {
                this.containerEl.style.width = '100%';

                this.selectEl.insertAdjacentElement('afterend', this.overlayEl);
                this.selectEl.insertAdjacentElement('afterend', this.containerEl);

                this.selectEl.parentElement.classList.add('zs-open-dropdown');
            } else {
                if (this.labelElement) {
                    this.containerEl.style.top = (this.selectEl.getBoundingClientRect().top - this.getBoundingClientRect().top) + 'px';
                    this.containerEl.style.position = 'absolute';
                }

                //handling cases where select is hidden when rendered. Eg. Accordion
                if (this.selectEl.offsetWidth) {
                    this.containerEl.style.width = this.selectEl.offsetWidth + 4 + 'px';
                } else {
                    this.getSelectVirtualWidth().then((result) => {
                        this.containerEl.style.width = parseInt(result) + 4 + 'px';
                    });
                }

                /*if (!this.overlayEl) {
                    this.parentEl.appendChild(this.overlayEl);
                }

                if (!this.containerEl) {
                    this.parentEl.appendChild(this.containerEl);
                }*/

                this.parentEl.classList.add('zs-open-dropdown');
            }

            // this.inputEl = this.containerEl.querySelector('[input]');

            this.on(['keyup', 'blur', 'paste', 'change'], this, this.inputElement, false, false);


            // this.navEl = this.containerEl.querySelector('[options]');

            this.on(['keyup'], this, this.navEl, false, false);

            this.readOptions();

            // this.selectEl.style.display = 'none';
        } else {

            if (this.selectEl === this.parentEl) {
                this.selectEl.parentElement.classList.remove('zs-open-dropdown');
            } else {
                this.parentEl.classList.remove('zs-open-dropdown');
            }

            //this.containerEl.style.visibility = 'hidden';
            this.containerEl.style.position = 'absolute';

            // Click anywhere on document
            this.on(['click', 'touchstart'], this, document, false, false);

            // Hide on scroll
            if (this.hideOnScroll) {
                if (this.isTablet) {
                    this.on(['mousewheel', 'DOMMouseScroll', 'touchmove', 'mousedown'], this, document, false, false);
                } else {
                    this.on(['mousewheel', 'DOMMouseScroll', 'mousedown'], this, document, false, false);

                    // We need to bind scroll event only for Desktop browsers
                    if (!this.isMobile) {
                        this.on(['scroll'], this, document, false, false);
                    }
                }
            }

            this.on(['click'], this, this.overlayEl, false, false);

            /*if (!this.overlayEl) {
                this.parentEl.appendChild(this.overlayEl);
            }*/

            this.overlayEl.textContent = this.emptyText;

            //this.containerEl.style.width = this.overlayEl.offsetWidth + 4 + 'px';
            this.containerEl.style.width = this.overlayEl.offsetWidth + 'px';

            if (!this.containerEl) {
                this.parentEl.appendChild(this.containerEl);
            }

            // this.inputEl = this.containerEl.querySelector('[input]');

            this.on(['keyup', 'blur', 'paste', 'change'], this, this.inputElement, false, false);

            // this.navEl = this.containerEl.querySelector('[options]');

            this.on(['keyup'], this, this.navEl, false, false);

            this.readOptions();

            this.containerEl.style.display = 'none';
            this.overlayEl.style.display = '';
            /*this.containerEl.style.visibility = '';*/

            this.on(['focus'], this, this.inputElement, false, false);

            // Clicking to collapse icon closes the dropdown in action mode
            this.actionElement ? this.on(['click'], this, this.actionElement, false, false) : null;
        }

        this.selectEl.style.display = 'none';

        /**
         * `change` event will be dispatched whenever a selection is changed in dropdown.
         * @event change
         * @type {Event}
         * @memberof Select
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.innerHTML = '<select><option value="1">one</option></select><option value="2">two</option></select>';
                    dropdown.addEventListener('change',function(){
                        console.log("Dropdown selection changed");
                    });
         */
        this.on(['change'], this, this.selectEl, false, false);

        this.on(['keydown'], this, this.inputElement, false, false);

        // Commented since it seems we do not need to listen 'keydown' on navEl.
        // this.on(['keydown'], this, this.navEl, false, false);

        this._markSelectedOptions();

        this.select(); // updates text in overlay 

        this._isRendered = true;
        this.fire('ready', { bubbles: true });
    }

    _markSelectedOptions(keepSelection) {
        var anchorEl,
            self = this;

        if (this.selectEl.selectedIndex != -1) {
            if (this.multiple) {
                var len = this.selectEl.children.length;
                var item;
                for (var i = 0; i < len; i++) {
                    var child = this.selectEl.children[i];

                    if (child.tagName.toLowerCase() === "optgroup") { // in case select contains optgroup

                        for (var k = 0; k < child.children.length; k++) {
                            item = child.children[k];
                            if (item && (item.selected || item.hasAttribute('selected'))) {
                                anchorEl = self.navEl.querySelector('[index = "' + item.index + '"]');
                                if (anchorEl) {
                                    if (anchorEl.hasAttribute('disabled')) { // This is to handle click on disabled anchor in IE
                                        this.choseOption(anchorEl);
                                    } else {
                                        this.fire('click', {}, anchorEl);
                                    }
                                } else {
                                    this.mapSelection(i, keepSelection);
                                }
                            }
                        }
                    } else {
                        item = this.selectEl.children[i];

                        if (item && (item.selected || item.hasAttribute('selected'))) {
                            anchorEl = self.navEl.querySelector('[index = "' + item.index + '"]');
                            if (anchorEl && !anchorEl.hasAttribute('active')) {
                                if (anchorEl.hasAttribute('disabled')) { // This is to handle click on disabled anchor in IE
                                    this.choseOption(anchorEl);
                                } else {
                                    this.fire('click', {}, anchorEl);
                                }
                            } else {
                                this.mapSelection(i, keepSelection);
                            }
                        }
                    }
                }
            } else {
                anchorEl = self.navEl.querySelector('[index = "' + this.selectEl.selectedIndex + '"]');
                if (anchorEl && !anchorEl.hasAttribute('active')) {
                    this.fire('click', {}, anchorEl);
                } else {
                    this.mapSelection(i, keepSelection);
                }
            }
        }
    }

    /**
     * Method to update the dropdown when options within `<select>` are changed.
     * @example var dropdown = document.createElement('p',{is:'zs-select'});
                dropdown.classList.add('zs-field');
                dropdown.classList.add('zs-select');
                dropdown.innerHTML = '<select><option value="1">one</option></select>';
                document.body.appendChild(dropdown);
                dropdown.selectEl.innerHTML = '<option value="1">one</option><option value="2" selected>two</option>';
                dropdown.update();
    */
    update() {

        this._isRendered = false;

        // Clear selected map
        this.selectedMap = {};

        this.containerEl.style.minHeight = "";

        // Read options
        this.readOptions();

        // Select and activate options
        if (this.selectEl.selectedIndex != -1) {
            if (this.multiple) {

                var optionsData = this.data.filter((item) => {
                    return !item.group;
                });

                for (var index = 0; index < optionsData.length; index++) {
                    var anchorEl = this.locateOption(optionsData[index]);

                    if (this.selectEl.options[index].selected) {
                        if (isThing(anchorEl)) {
                            anchorEl.removeAttribute("active");
                            // choseOption() also triggers 'change' event whose listener calls select() method to update overlay text
                            this.choseOption(anchorEl);
                        } else {
                            this.mapSelection(index);
                        }
                    }
                }
            } else {
                this.activateOption(this.data[this.selectEl.selectedIndex]);
            }


        }
        // Update text in overlay
        this.select();
        this._isRendered = true;
    }
    onkeyup(event) {
        if ((event.target === this.inputElement) || event.target === this.overlayEl || (event.target === this.navEl)) {
            this.handleKeyUp(event);
        }
    }

    onkeydown(event) {
        if ((event.target === this.inputElement) || (event.target === this.navEl)) {
            this.handleKeyDown(event);
        }
        else if (event.target === this.clearIconElement) {
            if (event.key === "Enter") {
                this.clearIconElement.click();
                this.inputElement.focus();
                event.preventDefault();
            }
            else if (!event.shiftKey && event.key === "Tab" && !this.alwaysOpen) {   // This is to regulate the TAB from ClearIcon. ShiftKey is to allow regular behavior for SHIFT+TAB. In case of Always open, the dropdown must not close and search remain intact.
                this.clearIconElement.click();
                this.close();
            }
        }
        else if (event.target === this.overlayEl) {
            // If-part is for normal overlay being opened on Space and Enter . Else part caters to the condition when Overlay would listen for key events, namely when options< minOptions.
            if (!isVisible(this.containerEl) && (event.key === " " || event.key === "Spacebar" || event.key === "Enter")) { //event.key === "Spaceber" for IE
                this.open();
                event.preventDefault();
            }
            else {
                this.handleKeyDown(event);
            }
        }
    }

    onblur(event) {
        if (event.target === this.inputElement) {
            this.handleKeyUp(event);
        }
    }

    onchange(event) {
        // Call Field onchange first
        super.onchange(event);

        if (event.target === this.inputElement) {
            this.handleKeyUp(event);

            // Only <select> element's change should be propagated
            event.stopPropagation();
        } else if (event.target === this.selectEl) {
            this.select(event);
        }
    }
    onbeforeopen(event) {

    }
    onbeforeclose(event) {

    }
    onopen(event) {

    }
    onclose(event) {

    }
    onready(event) {

    }
    onpaste(event) {
        if (event.target === this.inputElement) {
            this.handleKeyUp(event);
        }
    }

    onclick(event) {
        // Call Field onclick first
        super.onclick(event);
        if (event.currentTarget === this) {
            return;
        }

        if (this.clearIconElement && event.currentTarget == this.clearIconElement) {
            this.search(); // Reset search upon clear icon click
        }

        if (this.actionElement && event.currentTarget == this.actionElement) {
            this.close(); // Close when collapse icon is click in action mode
        }
        if (event.currentTarget == this.overlayEl) {
            this.open();
        } else if (this.navEl.contains(event.currentTarget)) {

            if (event.currentTarget.tagName && event.currentTarget.tagName.toLowerCase() === 'a' && event.currentTarget.classList.contains("select-all")) {
                this.toggleAll(event);
            }

            this.optionClick(event);

        } else {
            this.documentClick(event);
        }
    }
    onoptgroupclick(target) {
        var checkboxEl = target.querySelector('input[type="checkbox"]');
        if (target.hasAttribute('optgroup-link')) {
            if (checkboxEl) {
                checkboxEl.indeterminate = false;
            }
            checkboxEl.checked = !checkboxEl.checked;
        }
        var targetId = target.getAttribute('index');
        if (!targetId) {
            return;
        }
        var targetChildren = checkboxEl.checked ? this.navEl.querySelectorAll('[optgroup-link-parent="' + targetId + '"]:not([active])') : this.navEl.querySelectorAll('[optgroup-link-parent="' + targetId + '"][active]');
        if (!targetChildren) {
            return;
        }
        targetChildren.forEach((item) => {
            if (!item.hasAttribute('disabled')) {
                item.click();
            }
        })
    }

    onmouseover(event) {
        if (event.target.tagName.toLowerCase() === "a" && this.navEl.contains(event.target)) {
            if (event.target.hasAttribute('index')) {
                this.optionMouseOver(event);
            }
        }
    }

    ontouchstart() {
        this.documentClick(event);
    }

    onmousewheel(event) {
        this.documentClick(event);
    }

    onmousedown(event) {
        this.documentClick(event);
    }

    onDOMMouseScroll(event) {
        this.documentClick(event);
    }

    ontouchmove(event) {
        this.documentClick(event);
    }

    onscroll(event) {
        if (event.currentTarget === this.navEl) {
            this._handleNavScroll();
        } else {
            this.documentClick(event);
        }
    }

    onfocus(event) {
        if (event.target === this.inputElement) {
            this.scrollDocumentIfNeeded(event);
        } else if (event.currentTarget.tagName && event.currentTarget.tagName.toLowerCase === 'a' && this.navEl.contains(event.currentTarget)) {
            this._handleFocusToLastOption();
        }
    }

    /**
     * Method to scroll the document and uncover dropdown on iPad when input field is focused
     * @param {Object} event event object
     */
    scrollDocumentIfNeeded(event) {
        if (this._timer) {
            return;
        }

        if (this.isTablet) { // viewport is smaller because of keyboard
            var needSpace = this.containerEl.offsetHeight + this.containerEl.getBoundingClientRect().top + window.pageYOffset;
            var hasSpace = this.containerEl.offsetHeight + window.pageYOffset;

            var orientation = Math.abs(window.orientation) == 90 ? 'landscape' : 'portrait';

            if (orientation === 'portrait') {
                hasSpace = 2 * window.innerHeight / 3 + window.pageYOffset;
            } else {
                hasSpace = window.innerHeight / 2 + window.pageYOffset;
            }
            if (needSpace < hasSpace) {
                return;
            }

            var offset = this.inputElement.getBoundingClientRect().top - 20;
            var interval = 1000;

            var steps = parseInt(Math.round(interval / 20));

            var scrollOffset = offset / steps;

            this._timer = window.setInterval(() => {
                if (steps > 0) {
                    window.requestAnimationFrame(() => window.scrollBy(0, scrollOffset));
                } else {
                    window.clearInterval(this._timer);
                    this._timer = undefined;
                }
                steps--;
            }, 20);
        }
    }

    /**
     * Callback to handle `keyup` event on input field.
     * @param {Object} event event object
     */
    handleKeyUp(event) {
        switch (event.key) {
            case "Backspace":
                // ?	
                break;
            case "Escape":
            case "Esc":
                if (!this.alwaysOpen) {
                    this.close();
                    this.overlayEl.focus();
                }
                break;
            case "Tab":
                //
                event.preventDefault();
                event.stopPropagation();
                break;
            case "Left":
            case "ArrowLeft":
            case "Right":
            case "ArrowRight":
            case "Shift":
            case "Win":
            case "OS":
            case "Meta":
            case "Control":
                break;

            case "Up":
            case "ArrowUp":
                event.preventDefault();
                event.stopPropagation();
                this.navigateUp();
                if (event.target === this.navEl && this.alwaysOpen) {
                    this.navEl.focus();
                }
                break;
            case "Down":
            case "ArrowDown":
                event.preventDefault();
                event.stopPropagation();
                this.navigateDown();
                if (event.target === this.navEl && this.alwaysOpen) {
                    this.navEl.focus();
                }
                break;

        }

        // Do we need to search
        if (this.enteredText != this.inputElement.value) {
            if (this.enteredText) { // Clear selected option only if we searched before
                this.selectOption(false);
            }
            this.enteredText = this.inputElement.value;

            var delay = this.searchDelay || false;
            var self = this;

            if (delay) {
                clearTimeout(this._timeout);
                this._timeout = window.setTimeout(function () {
                    self.search();
                    delete self._timeout;
                }, delay);
            } else {
                this.search();

            }


        }
    }
    handleStatesOnSearch() {
        var optgroupOptions = this.navEl.querySelectorAll('[optgroup-link]');
        if (optgroupOptions) {
            optgroupOptions.forEach((item) => {
                if (item.nextElementSibling && !item.nextElementSibling.hasAttribute('optgroup-link') && item.nextElementSibling.hasAttribute('optgroup-link-parent')) {
                    this.optgroupSelection(item.nextElementSibling);
                }
            })
        }

        if (this.navEl.querySelectorAll('a[active]').length == this.navEl.querySelectorAll('a[index]:not([optgroup-link])').length) {
            this.toggleSelectAllButton(true);
        }
        this.setIndeterminateState();
    }
    /**
     * Callback to handle `keydown` event on input field.
     * @param {Object} event event object
     */
    handleKeyDown(event) {
        // Select item when user presses enter
        // We do that on keydown not keyup otherwise we face issue with submitting data:
        // submit fires on keydown, if we set new value on keyup (which fires a bit later than keydown) new value would be set AFTER submitting
        if (event.key === "Enter") {
            if (this.selected && this.selected.hasAttribute('index') && !this.selected.hasAttribute('disabled')) {
                this.choseOption(this.selected);
                if (!this.alwaysOpen && !this.multiple) {
                    this.close();
                    this.overlayEl.focus();
                }
            }

            if (this.selected && this.selected.tagName.toLowerCase() === 'a' && this.selected.classList.contains("select-all")) {
                if (this.enteredText.length) {
                    this.toggleAllSearchResults(event);
                } else {
                    this.toggleAll(event);
                }
            }
        }
        else if (event.target === this.inputElement && event.key === "Tab") {
            if ((event.shiftKey || !this.clear || this.clearIconElement.hidden)) {
                !this.alwaysOpen && this.close();     // Always Open condition check to prevent it from closing When pressing TAB/SHIFT+TAB.
            }
            else {
                this.clearIconElement.focus();
                event.preventDefault();
            }
        }
        else if (event.target === this.overlayEl || event.target === this.navEl) {
            if (event.key === "Up" || event.key === "ArrowUp" || event.key === "Down" || event.key === "ArrowDown") {
                event.preventDefault();
            }
            else if (event.key === "Tab" && !this.alwaysOpen) {  //Tab was pressed
                this.close();
            }
        }

        return true;
    }

    /**
     * Method to navigate upward in the list when 'up' arrow key is pressed.
     */
    navigateUp() {
        var prev, selected;

        if (!this.data || !this.data.length) {
            return;
        }

        // Get the prev option
        selected = this.selected;

        if (!isThing(selected)) {
            this.navEl.querySelectorAll('a').forEach(function (el) {
                // (el.hasAttribute('index') || el.hasAttribute('checkbox-link')) => to skip the Labels in Select Dropdown options as it neither has an index, nor a checkbox-link attribute.
                if (!el.hasAttribute('disabled') && (el.hasAttribute('index') || el.hasAttribute('checkbox-link')) && isVisible(el)) {
                    prev = el;
                }
            });
        } else {
            prev = (function (element, result) {
                var currentEl = element.previousElementSibling;

                while (currentEl) {
                    if (!currentEl.hasAttribute('disabled') && (currentEl.hasAttribute('index') || currentEl.hasAttribute('checkbox-link')) && isVisible(currentEl)) {
                        result.push(currentEl);
                    }
                    currentEl = currentEl.previousElementSibling;
                }

                return result.length ? result[0] : null;
                //selected.prevAll(':not([disabled]):visible:first');

            }(selected, []));
        }

        if (isThing(prev)) {
            this.selectOption(prev, true);
        }
    }

    /**
     * Method to navigate downward in the list when 'down' arrow key is pressed.
     */
    navigateDown() {
        var next, selected;

        if (!this.data || !this.data.length) {
            return;
        }

        // Get the next option
        selected = this.selected;

        if (!isThing(selected)) {
            var children = this.navEl.querySelectorAll('a');
            var len = children.length;
            for (var i = 0; i < len; i++) {
                if (!children[i].hasAttribute('disabled') && (children[i].hasAttribute('index') || children[i].hasAttribute('checkbox-link')) && isVisible(children[i])) {
                    next = children[i];
                    break;
                }
            }

        } else {
            next = (function (element, result) {
                var currentEl = element.nextElementSibling;

                while (currentEl) {
                    if (!currentEl.hasAttribute('disabled') && (currentEl.hasAttribute('index') || currentEl.hasAttribute('checkbox-link')) && isVisible(currentEl)) {
                        result.push(currentEl);
                    }
                    currentEl = currentEl.nextElementSibling;
                }

                return result.length ? result[0] : null;
                //selected.nextAll(':not([disabled]):visible:first');

            }(selected, []));
        }

        if (next && isThing(next)) {
            this.selectOption(next, true);
        }
    }

    /**
     * Method to choose an option in the list when it is clicked.
     * @param {HTMLAnchorElement} anchorEl option that was clicked.
     */
    choseOption(anchorEl) {
        if (anchorEl.hasAttribute('optgroup-link')) {
            this.onoptgroupclick(anchorEl);
            return;
        }
        var index, checkbox;

        if (!this.multiple) {
            this.navEl.querySelectorAll('[active]').forEach(function (item) {
                item.removeAttribute('active');
            });
        }

        if (isThing(anchorEl)) {
            checkbox = anchorEl.querySelector('input');
            anchorEl.setAttribute('active', '');
            index = anchorEl.getAttribute('index');

            if (this.multiple) {
                // Add list of selected options
                this.mapSelection(anchorEl.getAttribute('index'));

                // Activate or not an option
                if (typeof this.selectedMap[index] != 'undefined') {
                    anchorEl.setAttribute('active', '');
                    if (checkbox) {
                        checkbox.checked = true;
                    }
                } else {
                    anchorEl.removeAttribute('active');
                    if (checkbox) {
                        checkbox.checked = false;
                    }
                }
            } else {
                this.selectOption(anchorEl, false);
                this.selectEl.selectedIndex = index;
            }

            // To fix ZSUI-1047
            if (this._isRendered) {
                if (!this.alwaysOpen) {
                    this.fire('change', {}, this.selectEl);
                }

                this.fire('change', { bubbles: true });
            }

            if (this.multiple) {
                this.setIndeterminateState();
                this.optgroupSelection(anchorEl);
                return;
            }
        }
    }

    /**
     * Method to select/unselect options in the corresponding `<select>` element and maintain selectMap[] Array containing list of selected options.
     * @param {number} index index of option to be mapped.
     * @param {boolean} forceSelectUnselect forces selection/unselection of an option.
     */
    mapSelection(index, forceSelectUnselect) {
        if (!this.selectedMap) {
            this.selectedMap = {};
        }

        if (!index) {
            return;
        }

        if (typeof forceSelectUnselect != 'undefined') {
            this.selectEl.options[index].selected = forceSelectUnselect;
            if (!forceSelectUnselect) {
                delete this.selectedMap[index];
            }
            return;
        }

        if (typeof this.selectedMap[index] != 'undefined') {
            this._allSelected = false;
            this.toggleSelectAllButton();
            this.selectEl.options[index].selected = false;
            if (this.selectEl.options[index].hasAttribute('selected')) {
                this.selectEl.options[index].removeAttribute('selected');
            }
            delete this.selectedMap[index];
        } else {
            this.selectEl.options[index].selected = true;
            this.selectedMap[index] = index;

            if (this.selectEl.length == Object.keys(this.selectedMap).length) {
                this._allSelected = true;
                this.toggleSelectAllButton();
            }

            // If all search results are selected
            if (this.enteredText.length) {
                if (this.navEl.querySelectorAll('a[active]').length == this.navEl.querySelectorAll('a[index]:not([optgroup-link])').length) {
                    this.toggleSelectAllButton(true);
                } else {
                    this.toggleSelectAllButton(false);
                }
            }
        }
    }

    /**
     * Method to open dropdown
     */
    open() {
        if (!isThing(this.selectEl)) {
            return;
        }

        // Reset search
        if (this.enteredText) {
            this.enteredText = '';
            this.inputElement.value = '';
            this.search();
        }

        /**
         * `beforeopen` event will be dispatched whenever dropdown is about to open.
         * @event beforeopen
         * @type {Event}
         * @memberof Select
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.innerHTML = '<select><option value="1">one</option></select><option value="2">two</option></select>';
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.addEventListener('beforeopen',function(){
                        console.log("Dropdown about to open");
                    });
         */
        this.fire('beforeopen');

        if (this.selectEl.selectedIndex != -1) {
            // also scrolls to the selected item
            var elm = this.data.filter(function (one) {
                return one.id === this.selectEl.selectedIndex;
            }, this);
            elm = elm[0];
            if (elm) {
                this.activateOption(elm, true)
            }

        }


        this.setContainerPosition();

        //if(this.defaultInputFocus){ }

        this.inputElement.focus();

        this.overlayEl.setAttribute('tabindex', '-1');

        /**
         * `open` event will be dispatched whenever dropdown is opened.
         * @event open
         * @type {Event}
         * @memberof Select
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.innerHTML = '<select><option value="1">one</option></select><option value="2">two</option></select>';
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.addEventListener('open',function(){
                        console.log("Dropdown opened");
                    });
         */
        this.fire('open');
    }

    /**
     * Method to set position of container element depending on whether `flippable` mode is active/inactive.
     */
    setContainerPosition() {

        // Adjust width to fix a strange issue
        /*this.selectEl.style.visibility = 'hidden';
        this.selectEl.style.display = '';*/
        var rect = this.overlayEl.getBoundingClientRect();

        this.containerEl.style.display = '';

        if (!this.flippable) {

            // this.containerEl.style.width = this.overlayEl.offsetWidth + 4 + 'px';
            this.containerEl.style.width = this.overlayEl.offsetWidth + 'px';

            this.containerEl.style.top = (this.labelElement ? (rect.top - this.getBoundingClientRect().top) : this.mode === 'action' ? 0 : -5) + 'px';
            this.containerEl.style.left = 1 + 'px';

        } else {
            if (this.containerEl.parentElement !== document.body) {
                document.body.appendChild(this.containerEl);

                this.on('input', this, this.inputElement, false, false);

                // Fix clear icon's position in flippable mode
                if (this.clearIconElement && this.mode !== 'action') {
                    this.clearIconElement.style.position = 'absolute';
                    this.clearIconElement.style.background = '#fff';
                    this.clearIconElement.style.zIndex = '2';
                    this.clearIconElement.style.top = '50%';
                    this.clearIconElement.style.right = '0.25em';
                }
            }

            // Reset left coordinate to recalculate width from leftmost end
            this.containerEl.style.left = "";

            // Apply width first to wrap the content and get correct height
            this.containerEl.style.width = this.overlayEl.offsetWidth + 4 + 'px';

            var left = rect.left + window.pageXOffset;
            var top = rect.top + window.pageYOffset + 1;

            // If container is expected to go outside viewport, adjust top and left coordinates
            if ((rect.left + this.containerEl.offsetWidth) > window.innerWidth) {
                left = left + rect.width - this.containerEl.offsetWidth - 1;
            }

            if ((rect.top + rect.height + this.containerEl.offsetHeight) > window.innerHeight) {
                top = top + rect.height - this.containerEl.offsetHeight;
            }

            this.containerEl.style.top = top + 'px';
            this.containerEl.style.left = left + 'px';

            // Fix flickering of container during search in flippable mode
            if (isVisible(this.inputElement)) {
                this.containerEl.style.minHeight = this.containerEl.offsetHeight + 'px';
            }
        }


        /*this.selectEl.style.visibility = '';
        this.selectEl.style.display = 'none';*/
    }

    /**
     * Method to close dropdown
     */
    close() {

        if (!isVisible(this.containerEl)) {
            return;
        }

        /**
         * `beforeclose` event will be dispatched whenever dropdown is about to close.
         * @event beforeclose
         * @type {Event}
         * @memberof Select
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.innerHTML = '<select><option value="1">one</option></select><option value="2">two</option></select>';
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.addEventListener('beforeclose',function(){
                        console.log("Dropdown about to close");
                    });
         */
        this.fire("beforeclose");

        this.containerEl.style.display = 'none';

        var self = this;
        setTimeout(function () { // we need this because otherwise submit on keydown is blocked
            self.inputElement && self.inputElement.blur();
        }, 0);

        this.overlayEl.setAttribute('tabindex', '0');

        /**
         * `close` event will be dispatched whenever dropdown is closed.
         * @event close
         * @type {Event}
         * @memberof Select
         * @example var dropdown = document.createElement('p',{is:'zs-select'});
                    dropdown.innerHTML = '<select><option value="1">one</option></select><option value="2">two</option></select>';
                    dropdown.classList.add('zs-field');
                    dropdown.classList.add('zs-select');
                    dropdown.addEventListener('close',function(){
                        console.log("Dropdown closed");
                    });
         */
        this.fire("close");
    }

    /**
     * Method that loops through selected options in the list to form a string to be displayed on overlay.
     * @returns {string} String to be displayed on overlay.
     */
    select() {
        var val;
        if (this.multiple) {
            val = [];
            for (var i in this.selectedMap) {
                var elm = this.data.filter(function (one) {
                    return one.id == i;
                }, this);

                elm = elm[0];
                if (elm) {
                    val.push(elm.text);
                }

                // val.push(this.data[i].text);
            }

            val = val.join(', ');
        } else if (this.data[this.selectEl.selectedIndex] && this.data[this.selectEl.selectedIndex].text) {
            var elm = this.data.filter(function (one) {
                return (one.id === this.selectEl.selectedIndex) && !one.isPlaceholder;
            }, this);

            elm = elm[0];
            if (elm) {
                val = elm.text;
            }
        }

        this.updateOverlayText.call(this, val);

        return val;
    }

    /**
     * Method to update overlay text when a selection is changed. Typically overridden while implementing custom placeholder.
     * @param {string} val string to be set as overlay text.
     */
    updateOverlayText(val) {
        if (!val) {
            val = this.emptyText;
            this.overlayEl.classList.add("zs-placeholder-active");
        }
        else {
            this.overlayEl.classList.remove("zs-placeholder-active");
        }

        val = val.replace(new RegExp('&#160;', 'g'), '');
        this.overlayEl.textContent = val;
    }

    /**
     * Method to handle document click.
     * @param {Object} event event object
     */
    documentClick(event) {
        if (this.overlayEl.contains(event.target)) {
            // my overlay click
            return;
        }
        if (!isVisible(this.containerEl)) {
            // I am not active yet.
            return;
        }
        if (this.containerEl.contains(event.target) || this.alwaysOpen) {

        } else {
            this.close();
        }
    }

    /**
     * Method to read options from native `<select>` element and store their respective details in data[] Array.
     */
    readOptions() {
        var i;
        if (!isThing(this.selectEl) || !this.selectEl.options) { // options is an array-like object
            return;
        }

        this.data = [];
        this.navEl.innerHTML = '';

        var count = 0;
        var len = this.selectEl.children.length;

        var optgroups = this.selectEl.querySelectorAll('optgroup');

        if (optgroups) {
            optgroups.forEach(optgroup => {
                if (optgroup.hasAttribute('disabled')) {
                    var children = optgroup.querySelectorAll('option');
                    children.forEach(child => {
                        child.setAttribute('disabled', '');
                    });
                }
            })
        }

        for (i = 0; i < len; i++) {
            var child = this.selectEl.children[i];

            if (child.tagName.toLowerCase() === "optgroup") { // in case select contains optgroup
                let optgroupId = child.hasAttribute('group-header') ? null : 'opt-' + i;
                let dataObj = {
                    text: child.getAttribute('label'),
                    value: child.getAttribute('value'),
                    group: true,
                    isDisabled: child.hasAttribute('disabled')
                };
                if (optgroupId) {
                    dataObj.id = optgroupId;
                }
                this.data.push(dataObj);

                for (var k = 0; k < child.children.length; k++) {
                    this.data.push({
                        id: count++,
                        text: child.children[k].text,
                        value: child.children[k].value,
                        optgroupItem: true,
                        isDisabled: child.children[k].hasAttribute('disabled'),
                        title: child.children[k].hasAttribute('title') && child.children[k].getAttribute('title'),
                        optgroupParentId: optgroupId
                    });
                }
            } else {
                this.data.push({
                    id: count++,
                    text: child.text,
                    value: child.value,
                    isDisabled: child.hasAttribute('disabled'),
                    isPlaceholder: child.hasAttribute('placeholder'),
                    title: child.hasAttribute('title') && child.getAttribute('title')
                });
            }
        }

        this.renderOptions({
            data: this.data
        });

        // Logic to show or hide search dropdown
        if (this.selectEl.options.length >= this.minOptionsForSearch) {
            this.inputElement.parentElement.style.display = '';
            this.containerEl.style.minHeight = this.containerEl.offsetHeight + 'px';
        } else {
            this.inputElement.parentElement.style.display = 'none';
            // Accessibility in case where search field is hidden. When options < minOptions, Overlay would hear for key events.
            if (this.alwaysOpen) {
                this.navEl.setAttribute('tabindex', '0');
                this.on(['keyup', 'keydown'], this, this.navEl, false, false);
            }
            else {
                this.on(['keyup', 'keydown'], this, this.overlayEl, false, false);
            }
        }
    }

    /**
     * Method to render options in the list from the data[] Array.
     * @param {Object} params params object
     * @param params.data If present, provides the data[] Array containing details of each option, using which options are rendered in the list.
     * @param params.start If present, specifies the starting index from which options are rendered from data[] Array.
     * @param params.highlight If present, specified keyword to be highlighted.
     * @param params.selectFirst If present, indicates that the first option in the list is to be highlighted.
     */
    renderOptions(params) {
        this.off(['scroll'], this, this.navEl);

        this.navEl.querySelectorAll('a').forEach(function (item) {
            this.off(['focus'], this, item);
        }.bind(this));

        var anchorEl,
            data = params.data || [],
            start = params.start || 0,
            highlight = params.highlight,
            selectFirst = params.selectFirst,
            max = start + (data.length < this.maxOptionsVisible ? data.length : this.maxOptionsVisible);


        if (this.addSelectAll && start === 0 && data.length) { // we need to render selectAll button only once
            anchorEl = document.createElement('a');
            anchorEl.classList.add("zs-link-action");
            anchorEl.setAttribute('href', '#');
            anchorEl.setAttribute('onclick', 'return false;');

            if (this.addCheckboxes) {
                anchorEl.setAttribute("checkbox-link", "");
                anchorEl.innerHTML = '<span class="zs-checkbox"><input tabindex="-1" type="checkbox"><span checkbox zs-dropdown-option></span></span>';
            } else {
                anchorEl.innerHTML = '<span zs-dropdown-option></span>';
            }

            anchorEl.querySelector("[zs-dropdown-option]").textContent = this.selectAllText;

            var inputEl = anchorEl.querySelector('input');

            if (inputEl) {
                inputEl.addEventListener("change", function (e) {
                    e.stopPropagation();
                });
            }

            if (this.selectedMap && (Object.keys(this.selectedMap).length === this.data.length)) {
                if (inputEl) {
                    inputEl.checked = true;
                } else {
                    anchorEl.querySelector("[zs-dropdown-option]").textContent = this.deselectAllText;
                }
            }

            anchorEl.classList.add('select-all');

            anchorEl.setAttribute('tabindex', '-1');

            this.navEl.appendChild(anchorEl);

            this.on(['click'/*, 'touch'*/], this, anchorEl, false, false);
        }

        for (var i = start; i < max; i++) {
            if (!data[i]) { // when we have items count that cannot be divided to this.options.maxOptionsVisible
                break;
            }

            anchorEl = document.createElement('a');
            anchorEl.classList.add("zs-link-action");
            anchorEl.setAttribute('href', '#');
            anchorEl.setAttribute('onclick', 'return false;');
            anchorEl.setAttribute('tabindex', '-1');

            if (typeof data[i].id !== 'undefined') {
                anchorEl.setAttribute('index', data[i].id);
            }

            // Skip adding checkbox for an optgroup heading since checking it would not select all options under it.
            if (this.addCheckboxes && !data[i].group) {
                anchorEl.setAttribute("checkbox-link", "");
                anchorEl.innerHTML = `<span class="zs-checkbox" ${data[i].isDisabled ? 'disabled' : ''}><input tabindex="-1" type="checkbox"><span checkbox zs-dropdown-option></span></span>`;
            } else if (this.addCheckboxes && data[i].group && data[i].id) {
                anchorEl.setAttribute('optgroup-link', '');
                anchorEl.innerHTML = `<span class="zs-checkbox" ${data[i].isDisabled ? 'disabled' : ''}><input tabindex="-1" type="checkbox"><span checkbox zs-dropdown-option></span></span>`;
            }
            else {
                anchorEl.innerHTML = '<span zs-dropdown-option></span>';
            }

            anchorEl.querySelector("[zs-dropdown-option]").textContent = data[i].text;

            if (data[i].title) {
                anchorEl.setAttribute('title', data[i].title);
            }

            if (data[i].optgroupItem) {
                anchorEl.setAttribute('indent-text', '');
                if (data[i].optgroupParentId) {
                    let parent = data.filter(x => x.id === data[i].optgroupParentId);
                    if (parent[0] && parent[0].isDisabled && !anchorEl.hasAttribute('disabled')) {
                        anchorEl.setAttribute('disabled', '');
                        if (anchorEl.querySelector('.zs-checkbox')) {
                            anchorEl.querySelector('.zs-checkbox').setAttribute('disabled', '');
                        }
                    }
                    anchorEl.setAttribute('optgroup-link-parent', data[i].optgroupParentId);
                }
            }

            if (data[i].isDisabled && !anchorEl.hasAttribute('disabled')) {
                anchorEl.setAttribute('disabled', '');
            }

            // Hide the placeholder's data so that, it does not appear when we open the dropdown or while searching
            if (data[i].isPlaceholder) {
                anchorEl.setAttribute('placeholder', '');
                anchorEl.style.display = 'none';
            }

            this.navEl.appendChild(anchorEl);
            //add hr tag before optgroup label
            if (typeof data[i].id == 'undefined' && data[i].group && i !== start) {
                this.addHorizontalRule(anchorEl);
            }

            if (typeof data[i].id !== 'undefined') {
                this.on(['click', 'touch'], this, anchorEl, false, false);
                this.on(['mouseover'], this, anchorEl, false, false);
            }


            // We do not highlight optgroup headings
            if (highlight && !data[i].group) {
                this.highlight(true, anchorEl, highlight);
            }

            var inputEl = anchorEl.querySelector('input');

            // we need to update because of cleaning after search
            if (this.multiple) {
                if (this.selectedMap && this.selectedMap[data[i].id] !== undefined) {
                    anchorEl.setAttribute('active', '');
                    if (inputEl) {
                        inputEl.checked = true;
                    }
                }
            } else {
                if (this.selectEl.selectedIndex === data[i].id) {
                    anchorEl.setAttribute('active', '');
                    if (inputEl) {
                        inputEl.checked = true;
                    }
                }
            }
        }

        if (start < 1) {
            this.navEl.scrollTop = 0;
        }

        if (selectFirst) {
            this.selectOption(this.navEl.querySelectorAll('a[index]')[0], true);
        }

        // If all search results are pre-selected
        if (this.enteredText.length && data.length) {
            if (this.navEl.querySelectorAll('a[active]').length == this.navEl.querySelectorAll('a[index]:not([optgroup-link])').length) {
                this.toggleSelectAllButton(true);
            } else {
                this.toggleSelectAllButton(false);
            }
        }

        if (max >= data.length) {
            return;
        }

        var renderOptions = this.renderOptions.bind(this, {
            data: data,
            start: max,
            highlight: highlight,
            // selectFirst: selectFirst
        });

        this._handleNavScroll = function () {
            if (this.navEl.scrollTop + this.navEl.offsetHeight < this.navEl.scrollHeight - 10) { // give a little room to begin loading earlier
                return;
            }
            renderOptions();
            this._markSelectedOptions(true);
        };

        this._handleFocusToLastOption = function () {
            renderOptions();
            this._markSelectedOptions(true);
        }

        this.on(['scroll'], this, this.navEl, false, false);

        this.on(['focus'], this, this.navEl.querySelector('a:last-child'), false, false);
    }
    addHorizontalRule(anchorEl) {
        let hr = document.createElement('hr');
        anchorEl.parentElement.insertBefore(hr, anchorEl);
    }
    /**
     * Method to toggle all options in the list during search
     * @param {Object} event event object
     */
    toggleAllSearchResults(event) {
        var selectAllEl = this.navEl.querySelector('a');
        var inputEl = selectAllEl.querySelector('input');
        var checked;

        if (event.target.tagName && event.target.tagName.toLowerCase() === 'input' && event.target.type === 'checkbox') {
            checked = event.target.checked;
        } else {
            if (inputEl) {
                checked = !inputEl.checked;
            } else {
                checked = (selectAllEl.textContent === this.selectAllText);
            }
        }

        this.navEl.querySelectorAll('a').forEach(function (item) {

            inputEl = item.querySelector('input');

            if (!item.getAttribute('index') || item.hasAttribute('optgroup-link')) {
                return;
            }
            if (inputEl) {
                inputEl.indeterminate = false;
            }
            if (!checked) {
                item.removeAttribute('active');
                if (inputEl) {
                    inputEl.checked = false;
                }
            } else {
                if (!item.hasAttribute('optgroup-link')) {
                    item.setAttribute('active', '');
                }
                if (inputEl) {
                    inputEl.checked = true;
                }
            }

            var index = item.getAttribute('index');

            this.selectEl.options[index].selected = checked;

            if ((typeof this.selectedMap[index] != 'undefined') && !checked) {
                delete this.selectedMap[index];
            } else {
                this.selectedMap[index] = index;
            }

            if (this.selectEl.length == Object.keys(this.selectedMap).length) {
                this._allSelected = true;
            } else {
                this._allSelected = false;
            }
            this.optgroupSelection(item);

        }.bind(this));

        this.toggleSelectAllButton(checked);
        this.setIndeterminateState();

        if (!this.alwaysOpen) {
            this.fire('change', {}, this.selectEl);
        }

        this.fire('change', { bubbles: true });
    }

    /**
     * Method to toggle state of all options in the list.
     * @param {Object} event event object
     */
    toggleAll(event) {

        // Select All click while search
        if (this.enteredText.length) {
            this.toggleAllSearchResults(event);
            return;
        }

        var _this = this;

        this.navEl.querySelectorAll('a').forEach(function (item) {
            var inputEl = item.querySelector('input');
            if (!item.getAttribute('index')) {
                return;
            }
            if (inputEl && !item.hasAttribute('disabled')) {
                inputEl.indeterminate = false;
            }
            if (_this._allSelected) {
                if (!item.hasAttribute('disabled')) { item.removeAttribute('active'); }
                if (inputEl && !item.hasAttribute('disabled')) {
                    inputEl.checked = false;
                }
                _this.optgroupSelection(item);
            } else {
                if (!item.hasAttribute('optgroup-link') && !item.hasAttribute('disabled')) {
                    item.setAttribute('active', '');
                }
                if (inputEl && !item.hasAttribute('disabled')) {
                    inputEl.checked = true;
                }
                _this.optgroupSelection(item);
            }
        });

        var options = this.selectEl.options;

        this.selectedMap = {};

        for (var i = 0; i < options.length; i++) {
            if (options[i].disabled && options[i].selected) {
                this.selectedMap[i] = i;
            } else {
                if (this._allSelected) {
                    options[i].selected = false;
                } else {
                    if (!options[i].disabled) {
                        options[i].selected = true;
                        this.selectedMap[i] = i;
                    }
                }
            }
        }

        this._allSelected = !this._allSelected;
        this.toggleSelectAllButton();
        this.setIndeterminateState();

        if (!this.alwaysOpen) {
            this.fire('change', {}, this.selectEl);
        }

        this.fire('change', { bubbles: true });
    }

    /**
     * Method to handle option click.
     * @param {Object} event event object
     */
    optionClick(event) {
        var anchorEl = event.currentTarget;

        if ((anchorEl && anchorEl.tagName && (anchorEl.tagName.toLowerCase() === "mark" || anchorEl.tagName.toLowerCase() === "span"))) {
            anchorEl = anchorEl.parentElement;
        }

        if (!(anchorEl && anchorEl.tagName && (anchorEl.tagName.toLowerCase() === 'a' && anchorEl.hasAttribute('index') && this.navEl.contains(anchorEl)))) {
            return;
        }

        this.choseOption(anchorEl);
        if (this.alwaysOpen) {
            return;
        }

        if (this.multiple) {
            return;
        }

        this.close();
    }

    /**
     * Selects optgroup checkbox according to its options selected.
     * @param {HTMLElement} anchorEl anchor whose optgroup needs to be selected or deselected
     */
    optgroupSelection(anchorEl) {
        if (!anchorEl.hasAttribute('optgroup-link-parent')) {
            return;
        }
        var optgroupParent = this.querySelector('[index="' + anchorEl.getAttribute('optgroup-link-parent') + '"]')
        var optgroupParentInput = optgroupParent.querySelector('input[type="checkbox"]');
        if (!optgroupParentInput) {
            return;
        }
        var optgroupChildren = this.querySelectorAll('[optgroup-link-parent = "' + anchorEl.getAttribute('optgroup-link-parent') + '"]');
        var optgroupActiveChildren = this.querySelectorAll('[active][optgroup-link-parent = "' + anchorEl.getAttribute('optgroup-link-parent') + '"]');
        if (optgroupChildren.length == optgroupActiveChildren.length) {
            optgroupParentInput.checked = true;
            optgroupParentInput.indeterminate = false;
        } else if (!optgroupActiveChildren.length || optgroupActiveChildren.length == 0) {
            optgroupParentInput.checked = false;
            optgroupParentInput.indeterminate = false;
        } else {
            optgroupParentInput.checked = false;
            optgroupParentInput.indeterminate = true;
        }
    }
    /**
     * Callback triggered when an option is hovered.
     * @param {Object} event event object
     */
    optionMouseOver(event) {

        if (this.alwaysOpen) {
            this.selectOption(null, false);
            return;
        }

        var anchorEl = event.target;
        this.selectOption(anchorEl, false);
    }

    /**
     * Method to mark an option as selected upon mouse hover.
     * @param {HTMLAnchorElement} anchorEl Currently hovered option in the list.
     * @param {boolean} scrollTo specifies whether to focus on anchorEl.
     */
    selectOption(anchorEl, scrollTo) {
        if (isThing(this.selected)) {
            this.selected.removeAttribute('hover');
            this.selected = null;
        }

        if (isThing(anchorEl)) {
            this.selected = anchorEl;
            this.selected.setAttribute('hover', '');

            // Scroll to the element
            if (this.isTablet) { // avoid keyboard changing on ipad
                return;
            }

            if (scrollTo) {
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                // A Temporary Fix for Safari as the options are experimental in nature. A test case is added in Select Unit Tests which would fail in case the options create an issue.
                isSafari ? anchorEl.scrollIntoView({ block: "nearest", inline: "nearest" }) : anchorEl.focus();
                if (isVisible(this.inputElement.parentElement)) {
                    this.inputElement.focus();
                }
                else {
                    this.overlayEl.focus();
                }
                this.inputElement.value = this.inputElement.value;
            }
            return;
        }
    }

    /**
     * Method to toggle the state of select all option.
     * @param {boolean} checked specifies whether to explicitly check/uncheck the select all option.
     */
    toggleSelectAllButton(checked) {
        if (!this.addSelectAll) {
            return;
        }

        var elm = this.navEl.querySelector('a');

        if (!elm || elm.getAttribute('index')) {
            return;
        }
        var inputEl = elm.querySelector('input');

        if (typeof checked !== 'undefined') {
            if (this.addCheckboxes) {
                if (inputEl) {
                    inputEl.checked = checked ? true : false;
                }
            } else {
                elm.querySelector("[zs-dropdown-option]").textContent = checked ? this.deselectAllText : this.selectAllText;
            }
        } else {
            if (this.addCheckboxes) {
                if (inputEl) {
                    inputEl.checked = this._allSelected ? true : false;
                }
            } else {
                elm.querySelector("[zs-dropdown-option]").textContent = this._allSelected ? this.deselectAllText : this.selectAllText;
            }
        }
    }
    /**
     * Method to setindeterminate state for select-all option.
     */
    setIndeterminateState(el) {
        var el = el || this.navEl.querySelector('a.zs-link-action.select-all input');
        if (!el) {
            return;
        }
        var allOptions = this.navEl.querySelectorAll('a[index]:not([optgroup-link])');
        var allActiveOptions = this.navEl.querySelectorAll('a[index][active]:not([optgroup-link])');
        el.indeterminate = (allActiveOptions.length && allActiveOptions.length !== allOptions.length) ? true : false;
    }
    /**
     * Method to highlight/unhighlight text in an option.
     * @param {boolen} on specifies whether to highlight or unhighlight the keyword.
     * @param {HTMLAnchorElement} el Element on which highlight is to be performed.
     * @param {string} keyword keyword to be highlighted.
     */
    highlight(on, el, keyword) {

        // Remove highlight from all elements
        if (!on && !el) {
            this.navEl.querySelectorAll('mark').forEach(function (item) {
                item.parentElement.innerHTML = item.parentElement.textContent;
            });
            return;
        }

        if (!isThing(el)) {
            return;
        }

        if (on) {
            var keywordLower = keyword.toLowerCase();
            var stringToSearch = el.textContent.toLowerCase();
            var original = el.querySelector("[zs-dropdown-option]").textContent;
            var index = stringToSearch.indexOf(keywordLower);
            var offset = keywordLower.length;
            var text = '';

            while (index > -1) {
                text += original.substr(0, index) + '<mark>' + original.substr(index, offset) + '</mark>';
                stringToSearch = stringToSearch.substr(index + offset, stringToSearch.length - 1);
                original = original.substr(index + offset, original.length - 1);
                index = stringToSearch.indexOf(keywordLower);
            }
            text += original;

            el.querySelector("[zs-dropdown-option]").innerHTML = text;
        } else {
            el.querySelector("[zs-dropdown-option]").innerHTML = el.textContent;
        }
    }

    /**
     * Method to perform match operation while searching.
     * @param {Object} option an object containing option details.
     * @param {string} keyword keyword based on which search is performed.
     * @param {boolean} returnIndex specifies whether to return index of the match location.
     * @returns {boolean|number} Returns true/false keyword is found or, index of keyword location.
     */
    match(option, keyword, returnIndex) {

        if (!option || !keyword || !option.text) {
            return false;
        }

        // Add optgroup headings
        if (option.group) {
            return true;
        }

        if (returnIndex) {
            return option.text.indexOf(keyword);
        } else {
            return option.text.toLowerCase().indexOf(keyword.toLowerCase()) != -1;
        }
    }

    /**
     * Method to search for options in the list based on keyword passed.
     * @param {string} keyword keyword based on which search is performed
     */
    search(keyword) {

        keyword = keyword || this.inputElement.value;

        while (this.navEl.firstChild) {
            this.navEl.removeChild(this.navEl.firstChild);
        }

        if (!keyword) {
            this.renderOptions({
                data: this.data
            });
            this.handleStatesOnSearch();
            return;
        }

        var data = [], option, match = this.match;
        if (typeof this.match == 'function') {
            match = this.match;
        } else {
            console.warn("match() function missing.Search cannot proceed");
            return;
        }

        for (var i = 0; i < this.data.length; i++) {
            option = this.data[i];
            if (match.call(this, option, keyword)) {
                data.push(option);
            }
        }

        // Remove empty optgroup categories from filtered list
        for (var i = data.length - 1; i >= 0; i--) {
            if (i > 0 && data[i].group && data[i - 1].group) {
                data.splice(i - 1, 1);
            } else if (data[i].group && (i == data.length - 1)) {
                data.splice(i, 1);
            }
        }

        this.renderOptions({
            data: data,
            highlight: keyword,
            selectFirst: true
        });

        // No record found
        if (data.length == 0) {
            var noResultEl = document.createElement("p");
            noResultEl.textContent = this.noRecordsText;
            noResultEl.classList.add("no-search-results");

            if (this.navEl.childElementCount == 0) {
                this.navEl.appendChild(noResultEl);
                // this.containerEl.style.minHeight = ''; // minHeight needed in flippable mode, hence commenting it.
            }
        }
        this.handleStatesOnSearch();

    }

    /**
     * Method to mark an option as active.
     * @param {Object} option an object containing option details.
     * @param {boolean} scrollTo specifies if activated option should be focused.
     */
    activateOption(option, scrollTo) {
        var anchorEl = this.locateOption(option);

        if (!this.multiple) {
            this.navEl.querySelectorAll('[active]').forEach(function (item) {
                item.removeAttribute('active');
            });
        } else {
            if (anchorEl) {
                this.mapSelection(anchorEl.getAttribute('index'), true);
            }
        }

        if (isThing(anchorEl)) {
            anchorEl.setAttribute('active', '');

            if (scrollTo) {
                anchorEl.focus();
            }
        }
    }

    /**
     * Method to locate option in the list from its `id`.
     * @param {Object} option
     * @returns {HTMLElement} Anchor `<a>` element from the list having `index` attribute equivalent to `option.id`.
     */
    locateOption(option) {
        return this.navEl.querySelector('a[index="' + option.id + '"]');
    }

    /**
     * Method to clear all selected options
     * @memberOf Select
     * @example var dropdown = document.createElement('p',{is:'zs-select'});
                dropdown.classList.add('zs-field');
                dropdown.classList.add('zs-select');
                dropdown.innerHTML = '<select multiple><option value="1" selected>one</option></select>';
                document.body.appendChild(dropdown);
                dropdown.clearAllSelection();
    */
    clearAllSelection() {
        var self = this;

        if (!this.multiple) {
            return;
        }

        // Clear search keyword and trigger change event to mark selected options
        this.inputElement.value = "";
        this.fire("change", {}, this.inputElement);

        var selectedOptions = this.navEl.querySelectorAll("[active]");

        selectedOptions.forEach(function (item) {
            self.choseOption(item);
        });
        this.clearAllOptgroups();
    }
    /**
     * Method to clear all optgroup selections
     */
    clearAllOptgroups() {
        var selectedOptGroup = this.navEl.querySelectorAll("[optgroup-link]");
        if (!selectedOptGroup.length) {
            return;
        }
        selectedOptGroup.forEach((item) => {
            item.querySelector('input[type="checkbox"]').checked = false;
            item.querySelector('input[type="checkbox"]').indeterminate = false;
        })
    }
    /**
     * Method to get overlay's text.
     * @returns {string} overlay's text content.
     */
    getOverlayText() {
        return this.overlayEl.textContent
    }

    /**
     * Method to get virtual selectEl's width.
     * @returns {string} virtual selectEl's width.
     */
    getSelectVirtualWidth() {
        let virtualSelect = this.parentEl.cloneNode(true);
        virtualSelect.style.display = "inline-block";
        virtualSelect.style.opacity = 0;
        document.body.appendChild(virtualSelect);
        return new Promise(function (resolve, reject) {
            virtualSelect.addEventListener('ready', function () {
                virtualSelect.selectEl.style.display = 'block';
                let offSetWidth = virtualSelect.selectEl.offsetWidth + 'px';
                virtualSelect.destroy();
                resolve(offSetWidth);
            })
        });
    }
}

Select.is = 'zs-select';
Select.tagName = 'p';

export default Select;
