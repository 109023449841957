import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { LineageConstants } from 'src/app/mdm/constants/mdm-common-constants';

@Component({
  selector: 'app-golden-value-address-cell-renderer',
  templateUrl: './golden-value-address-cell-renderer.component.html'
})
export class GoldenValueAddressCellRendererComponent {

  params: any;
  data: any;
  isSource: boolean;
  isRules: boolean;
  environment: any;
  address: any;
  lineageConstants = LineageConstants;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;
    this.isSource = this.params.source_values;
    this.isRules = this.params.survivorship_values;
    this.environment = this.params.environment;

  }


}
