<div class="payer-merge-unmerge-component" #mergeUnmergeWrapper>
    <div class="
      zs-padding-2
      zs-bg-standard
      zs-box-shadow
      zs-border-color-default
    ">

        <div class="zs-padding-2-0-2-0 zs-row zs-margin-0-1-0-0">
            <div>
                <div class="zs-grid-5cols">
                    <div class="zs-col-1 zs-col-span-3 profile-name zs-grid-5cols">
                        <div class="zs-row-1 zs-col-1 zs-size-xxl">
                            <span class="zs-margin-0-0-0-1 zs-icon zs-size-xxl zs-icon-field-unit"></span>
                        </div>
                        <div class="zs-margin-1-0-0-0 zs-row-1 zs-col-2 zs-col-span-4">
                            <h4 *ngIf="golden?.PBM_NAME?.value" class="zs-margin-0">
                                {{golden?.PBM_NAME?.value}}
                            </h4>
                            <h4 *ngIf="golden?.MCO_NAME?.value" class="zs-margin-0">
                                {{golden?.MCO_NAME?.value}}
                            </h4>
                            <h4 *ngIf="golden?.PAYER_NAME?.value && this.pageDetails.entity ===  PayerWrapperConstants.PAYER"
                                class="zs-margin-0">
                                {{golden?.PAYER_NAME?.value}}
                            </h4>
                            <h4 *ngIf="golden?.PLAN_NAME?.value && this.pageDetails.entity ===  PayerWrapperConstants.PLAN"
                                class="zs-margin-0">
                                {{golden?.PLAN_NAME?.value}}
                            </h4>

                            <div class="zs-display-flex zs-margin-1-0-0-0">
                                <p class="
                    zs-size-extra-small zs-font-weight-bold zs-text-uppercase
                  ">
                                    MDMID
                                </p>
                                <p class="zs-margin-0-0-1-1 zs-body zs-size-extra-small">
                                    {{golden?.MDM_ID?.value}}
                                </p>
                            </div>
                            <div class="tag">
                                <app-zs-tag nonclickable="true">
                                    <span tag-value translate> {{pageDetails.entity}} </span>
                                </app-zs-tag>

                            </div>
                        </div>

                    </div>

                    <div class="
              zs-col-4
              zs-col-span-2
              zs-margin-1-0-0-0
              zs-layout-flex
              zs-flex-justify-flex-end
              zs-flex-wrap
            ">
                        <button [hidden]="!((mergeProfiles?.length > 1) && isMergeSelected)" class="
                zs-margin-1-0-0-0 zs-button
                solid-buttons
                zs-button-solid zs-border-rounded-0
              " (click)="mergeAll()">
                            Merge All
                        </button>
                        <zs-tabs-container class="zs-margin-0-0-0-1 zs-toggle-tabs">
                            <div class="
                  tabs-container
                  zs-margin-1-0-0-0 zs-layout-flex
                  toggle-tabs
                ">
                                <zs-tab class="zs-tab zs-active" target-id="merge" active>
                                    <button (click)="onTabChange(mergeVariableConstant)"
                                        class="zs-button solid-buttons zs-border-rounded-0">
                                        <span translate> MERGE </span>
                                    </button>
                                </zs-tab>
                                <zs-tab class="zs-tab" target-id="unmerge">
                                    <button (click)="onTabChange(unmergeVariableConstant)"
                                        class="zs-button solid-buttons zs-border-rounded-0">
                                        <span translate> UNMERGE </span>
                                    </button>
                                </zs-tab>
                            </div>
                        </zs-tabs-container>
                    </div>
                </div>
                <div class="zs-margin-0-0-0-0 zs-float-right zs-row">
                    <div class="matches-count zs-text-caption">

                    </div>
                </div>
                <br />
                <div source-id="merge" active cdkDropListGroup>
                    <div class="zs-row zs-margin-2-1-0-0">
                        <div #detailsTable class="details-table-container" cdkDropList
                            cdkDropListOrientation="horizontal" id="dropList" cdkDropListConnectedTo="dragList"
                            (cdkDropListDropped)="drop($event)">
                            <app-payer-merge-unmerge-details tableId="mergePageTable1" *ngIf="isMergeSelected"
                                [profiles]="mergeProfiles" [wrapperElement]="wrapperElement" onMergePage="true">
                            </app-payer-merge-unmerge-details>
                        </div>
                    </div>
                </div>
                <div source-id="unmerge" class="unmerge-details-table">
                    <div class="zs-row zs-margin-2-1-0-0">
                        <div #detailsTable class="details-table-container">
                            <app-payer-merge-unmerge-details *ngIf="!isMergeSelected" [profiles]="unMergeProfiles"
                                [wrapperElement]="wrapperElement"
                                onUnmergePage="true"></app-payer-merge-unmerge-details>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>