import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { DCRStatus, MDMCommonConstants, MDMWrapperConstants } from '../../constants/mdm-common-constants';
import { QueryParams } from '../../constants/profile-constants';
import { ActivatedRoute } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'

@Component({
  selector: 'app-dcr-details',
  templateUrl: './dcr-details.component.html',
  styleUrls: ['./dcr-details.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DcrDetailsComponent implements OnInit {
  dcr_details: any;
  comments: string;
  DCRStatus = DCRStatus;
  showSubmitLoader: Boolean = false;
  dcr_id: string;
  page: any = 1;
  pageSize: any = 10;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: object;
  defaultColDef: { [x: string]: string | boolean; };
  // public domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  noRowsTemplate: string;
  rowData: any[] = [];
  domLayout = "autoHeight";
  MDMCommonConstants = MDMCommonConstants;
  isHcp: boolean;
  isLoading: boolean = true;
  colDefs: ColDef[] = [
    {
      headerName: 'DCR Line Name', field: 'DCR_LINE_NAME', sortingOrder: ['asc', 'desc']
    },
    {
      headerName: 'Field Name', field: 'FIELD_TO_BE_CHANGED', sortingOrder: ['asc', 'desc']
    },
    {
      headerName: 'New Value', field: 'NEW_VALUE', sortingOrder: ['asc', 'desc']
    },
    {
      headerName: 'Old Value', field: 'CURRENT_VALUE', sortingOrder: ['asc', 'desc']
    },
  ]
  constructor(private service: MDMService, private toaster: ToasterNotificationService,
    private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      [AgGridColumnDefsConstants.WRAP_HEADER_TEXT]: true,
      [AgGridColumnDefsConstants.AUTO_HEADER_HEIGHT]: true,
      defaultColDef: {
        sortable: true,
        unSortIcon: false
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.setColumnDef();
    this.dcr_id = this.route.snapshot.paramMap.get(QueryParams.ID);
    this.getDcrByid();
  }
  getDcrByid() {
    this.service.getDcrDetailsById(this.dcr_id).subscribe(
      (data) => {
        this.dcr_details = data.data[0];
        this.getChangeRequestLines(this.dcr_details.DCR_NAME);
        this.isHcp = this.dcr_details.entitytype == MDMCommonConstants.HCP ? true : false;
      },
      (exception) => {
        this.isLoading = false;
        this.toaster.showError(exception.error.message.name.replace(/_/g, ' '), '')
      })
  }
  updateDcr(status, dcr_id) {
    if (this.comments !== null) {
      this.showSubmitLoader = true;
      const payload = {
        status: status,
        comment: this.comments,
        dcr_id: dcr_id,
        dcr_name: this.dcr_details.DCR_NAME,
        object_api_name: this.dcr_details.OBJECT_API_NAME,
        change_request_type: this.dcr_details.CHANGE_REQUEST_TYPE,
        change_request_sub_type: this.dcr_details.CHANGE_REQUEST_SUB_TYPE,
        crm_id: this.dcr_details.CRM_ID,
        crm_affln_id: '',
        crm_addr_id: this.dcr_details.CRM_ADDR_ID,
        crm_parent_request_id: this.dcr_details.CRM_PARENT_REQUEST_ID

      }

      this.service.updateDcrById(payload)
        .subscribe(
          (data) => {
            this.getDcrByid();
            this.showSubmitLoader = false;
            if (status == DCRStatus.APPROVED) {
              this.toaster.showSuccess('Successfully Updated DCR', '');
            }
            else if (status == DCRStatus.CANCELLED) {
              this.toaster.showSuccess('Successfully Rejected DCR', '');
            }
            else if (status == DCRStatus.HOMEOFFICE) {
              this.toaster.showSuccess('Successfully Moved to HQ Approval', '');
            }
            this.comments = '';

          },
          (exception) => {
            this.showSubmitLoader = false;
            this.toaster.showError(exception.error.message.name.replace(/_/g, ' '), '')
          })
    }
  }
  onGridReady(params) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    /* Edge 17039 changes starts
     in case of routing from Suspected Matches Grid, Setting activeTab to "Suspected Matches" */
    this.service.setActiveTab(MDMWrapperConstants.DCR_MANAGEMENT);
    // Edge 17039 changes ends
  }
  setColumnDef() {
    this.colDefs.forEach((item) => {
      item[AgGridColumnDefsConstants.TOOLTIP_FIELD] = item.field;
      item[AgGridColumnDefsConstants.WRAP_TEXT] = true;
      item[AgGridColumnDefsConstants.AUTO_HEIGHT] = true;
      // item[AgGridColumnDefsConstants.MAX_WIDTH] = 750;
      // item[AgGridColumnDefsConstants.MIN_WIDTH] = 500;

      // item[AgGridColumnDefsConstants.MAX_WIDTH] = item['field'] == 'request_created_by' ? 500 : item['field'] == 'dcr_name' ? 180 : item['field'] == 'data_steward_comments' ? 200 : 110;
    }
    )
  }
  getChangeRequestLines(dcr_name) {
    this.service.getChangeRequestLine(dcr_name).subscribe((data) => {
      this.rowData = data.data;
      this.isLoading = false;
    }, (exception) => {
      this.isLoading = false;
      this.toaster.showError(exception.error.message.name.replace(/_/g, ' '), '')

    }
    )
  }
  setAutoHeight() {
    this.gridApi.setDomLayout('autoHeight');
    // auto height will get the grid to fill the height of the contents,
    // so the grid div should have no height set, the height is dynamic.
    (document.querySelector<HTMLElement>('#myGrid')! as any).style.height = '';
  }
  getProfile() {
    this.service
      .getLineageData(
        this.dcr_details.mdm_id,
        this.dcr_details.entitytype,
        MDMCommonConstants.Y
      ).subscribe((data) => {
      },
        (exception) => {
          this.toaster.showError(exception.error.message.name.replace(/_/g, ' '), '')

        })
  }
}
