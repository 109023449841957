<div
  class="zs-message zs-margin-0-0-1-0 zs-warning"
  *ngIf="refreshIndicator"
  translate
>
  {{ ui_labels.DATA_REFRESH_IS_IN_PROGRESS_LABEL }}
</div>

<div>
  <section class="">
    <div class="zs-row zs-master-style">
      <div
        class="zs-full-height zs-width-100 zs-margin-2-0-0-0 zs-col-md-9 zs-bg-standard"
      >
        <app-matches
          (selectedFilterChanged)="filterChanged($event)"
        ></app-matches>
      </div>
    </div>
  </section>
</div>
