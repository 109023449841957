import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZsFilterService {
  private subject = new Subject<any>();

  setAppliedFilterData(appliedFilters: any) {
      this.subject.next(appliedFilters);
  }

  getAppliedFilterData(): Observable<any> {
      return this.subject.asObservable();
  }
}
