export function containsSpecialCharacters(input: any, flag?: boolean): boolean {
  const specialCharacters = /[!$%^*()+{}":>]/;
  const hasSpecialCharacters = (str: string): boolean =>
    specialCharacters.test(str);
  if (Array.isArray(input)) {
    // If input is an array
    for (let element of input) {
      if (hasSpecialCharacters(element)) {
        return true; // Return true if any element contains special characters
      }
    }
    return false; // Return false if no elements contain special characters
  } else {
    return hasSpecialCharacters(input);
  }

}
