<span class="center-align">
  <span *ngIf="percent">{{data.PERCENT}}%</span>
  <a *ngIf="isMdmId && !percent" [routerLink]="[routeLink, data.MSTR_MDM_ID]">{{data.MSTR_MDM_ID}}</a>
  <a *ngIf="!isMdmId && !isSourceColumn && !percent && filter === MDMCommonConstants.HCP"
    [routerLink]="[routeLink, data.MSTR_MDM_ID]">{{data.MSTR_NAME}}</a>
  <a *ngIf="!isMdmId && !isSourceColumn && !percent && filter === MDMCommonConstants.HCO"
    [routerLink]="[routeLink, data.MSTR_MDM_ID]">{{data.MSTR_ACCT_NM}}</a>
  <div *ngIf="isSourceColumn" class="zs-display-flex source-col">
    <span *ngFor="let source of data.SOURCES; let i=index"
      [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
      class="zs-badge-counter zs-badge-counter-new zs-margin-0-0-0.5-0.5">{{ source.trim().toUpperCase() }}</span>
  </div>
</span>