<!--Profile Page Header-->
<div class="details-header">
  <div appZsLoader class="profile-details-header" [isVisible]="lineageLoading"
    class="zs-padding-2-2-1-2 zs-margin-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
    <div *ngIf="!golden && !lineageLoading">
      <span class="zs-icon zs-size-l zs-icon-error-circle-fill zs-text-warning"></span>
      {{ canNotFindProfileLabel }}
    </div>
    <div *ngIf="golden" class="zs-display-flex">
      <div class="zs-width-10">
        <span [ngClass]="
            isHco
              ? 'zs-icon zs-icon-xxlarge zs-icon-field-unit'
              : 'zs-icon zs-icon-xxlarge zs-icon-user'
          "></span>
      </div>
      <div class="zs-width-40">
        <span *ngIf="!isHco">
          <h4 class="zs-margin-0 zs-display-inline-block name-width zs-cursor-pointer">
            {{ golden.FRST_NM.value }} {{ golden.LAST_NM.value }}</h4>
          <zs-tooltip class="zs-tooltip" position="bottom" [attr.offset-y]="5">
            <zs-tooltip-content class="zs-size-xs">
              {{ golden.FRST_NM.value }} {{ golden.LAST_NM.value }}
            </zs-tooltip-content>
          </zs-tooltip>
        </span>
        <span *ngIf="isHco">
          <h4 class="zs-margin-0 zs-display-inline-block name-width">
            {{ golden.ACCT_NM.value.length >= maxLengthForEllipses ?
            (golden.ACCT_NM.value | slice: 0:maxLengthForEllipses) + '...' : golden.ACCT_NM.value }}
          </h4>
          <zs-tooltip class="zs-tooltip" position="bottom" [attr.offset-y]="5">
            <zs-tooltip-content class="zs-size-xs">
              {{ golden.ACCT_NM.value }}
            </zs-tooltip-content>
          </zs-tooltip>
        </span>
        <div class="zs-display-flex zs-margin-1-0-0-0">
          <p class="zs-size-extra-small zs-font-weight-bold zs-text-uppercase">
            {{ mdmIdLabel }}
          </p>
          <p class="zs-margin-0-0-1-1 zs-size-extra-small">
            {{ golden.MDM_ID.value }}
          </p>
        </div>
        <div class="zs-display-flex">
          <div class="hcp-hco-tag">
            <app-zs-tag *ngIf="!isHco" nonclickable="true">
              <span tag-value translate>{{ ui_labels.HCP }}</span>
            </app-zs-tag>
            <app-zs-tag *ngIf="isHco" nonclickable="true">
              <span tag-value translate>{{ ui_labels.HCO }}</span>
            </app-zs-tag>
          </div>
          <!--Disabling show lineage checkbox. Since there will be new UI as per mockup for show lineage Refer : EDGE-16483-->
          <div class="show-lineage-field zs-display-flex" *ngIf="!mdmId">
            <label class=" zs-checkbox">
              <input type="checkbox" [(ngModel)]="showLineage" />
              <span checkbox></span>
            </label>
            <span>{{ showLineageLabel }}</span>
          </div>
        </div>
      </div>
      <div class="zs-width-50 zs-position-relative">
        <div class="zs-grid-1cols zs-grid-2rows">
          <div class="zs-col-1 zs-row-1">
            <span *ngIf="!mdmId">
              <button *ngIf="!isHco && !_returnToHco"
                class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-0-1-1-0 button-ellipses float-right"
                title="{{ ui_labels.MERGE_UNMERGE | translate }}" [routerLink]="[
                '/mdm/all-records/hcp/profile',
                golden.id.value,
                'merge-unmerge'
              ]" translate>
                {{ ui_labels.MERGE_UNMERGE }}
              </button>
            </span>
            <button *ngIf="isHco || _returnToHco"
              class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-0-1-1-0 button-ellipses float-right"
              title="{{ ui_labels.MERGE_UNMERGE | translate }}" [routerLink]="[
                '/mdm/all-records/hco/profile',
                golden.id.value,
                'merge-unmerge'
              ]" translate>
              {{ ui_labels.MERGE_UNMERGE }}
            </button>
            <button *ngIf="!showLineage"
              class="zs-button zs-border-rounded-0 zs-margin-0-1-1-0 button-ellipses float-right" title="{{
                isViewMoreDetails
                  ? viewLessDetailsLabel
                  : viewMoreDetailsLabel
              }}" (click)="
                isViewMoreDetails
                  ? viewPrimaryProfileDetails()
                  : viewMoreProfileDetails()
              ">
              {{
              isViewMoreDetails
              ? viewLessDetailsLabel
              : viewMoreDetailsLabel
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLineage" class="zs-padding-2-0.5-1.5-0" appZsLoader [isVisible]="detailsLoading">
      <div [hidden]="!rowData || rowData.length === 0" class="zs-ag-grid zs-custom-scrollbar" style="overflow-y: auto; max-height: 500px;">
        <ag-grid-angular class="ag-grid-class ag-theme-alpine" [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
        </ag-grid-angular>
      </div>

      <div class="text-align-right">
        <app-zs-pagination [itemCount]="totalRecords" [currentPage]="page" [pageSize]="pageSize"
          (pageChange)="pageChanged($event)">
        </app-zs-pagination>
      </div>
    </div>
  </div>

  <!--Profile page card layout-->
  <div *ngIf="!showLineage" class="zs-grid-3cols zs-grid-2rows">
    <!--Profile/Account details card-->
    <div *ngIf="golden" appZsLoader [isVisible]="lineageLoading" class="zs-margin-1 zs-row-1 zs-col-span-2">
      <app-zs-card class="details-card">
        <div card-header class="zs-padding-2">
          <span class="card-heading zs-display-block">
            <div *ngIf="isHco">
              <h4>{{ account_details_label }}</h4>
            </div>
            <div *ngIf="!isHco">
              <h4>{{ profile_details_label }}</h4>
            </div>
          </span>
        </div>
        <div card-section class="zs-padding-2 card-section">
          <div class="zs-grid-3cols">

            <div *ngFor="
                let key of isViewMoreDetails
                  ? display_profile_details
                  : display_primary_profile_details
              " class="field-details zs-grid-4cols zs-grid-1rows">
              <div class="zs-col-1 zs-row-1 zs-col-span-3">
                <p class="zs-font-weight-bold profile-detail-label">
                  {{
                  golden[key].display !== ''
                  ? detailTableMapping.hasOwnProperty(
                  golden[key].display.trim()
                  )
                  ? detailTableMapping[golden[key].display.trim()]
                  : golden[key].display
                  : key
                  }}
                </p>
                <ng-container *ngIf="[].constructor.isArray(golden[key].value)">
                  <span *ngFor="let item of golden[key].value; let i = index">
                    <span *ngIf="i > 0 && i < golden[key].value.length" class="zs-margin-0-1-0-0">,</span>
                    <span *ngIf="item">{{ item }}</span>
                  </span>
                </ng-container>
                <ng-container *ngIf="![].constructor.isArray(golden[key].value)">
                  <div *ngIf="golden[key] && golden[key].value" class="profile-applied-value">
                    {{ golden[key].value }}
                  </div>
                </ng-container>
              </div>
              <div class="zs-col-4 zs-row-1">
                <span [hidden]="
                    !(
                      golden[key] &&
                      golden[key].desc &&
                      golden[key].desc !== ''
                    )
                  " class="zs-margin-0-0-0-1">
                  <span class="zs-icon zs-icon-info zs-size-s">
                    <zs-tooltip offset-y="10">
                      {{ golden[key].desc }}
                    </zs-tooltip>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </app-zs-card>
    </div>

    <!--Identifier details card-->
    <div *ngIf="golden" appZsLoader [isVisible]="lineageLoading" class="zs-margin-1 zs-row-1 zs-col-3">
      <app-zs-card class="details-card">
        <div card-header class="zs-padding-2">
          <span class="card-heading zs-display-block">
            <h4>{{ identifier_label }}</h4>
          </span>
        </div>
        <div card-section class="zs-padding-2 card-section">
          <div *ngFor="
              let key of isViewMoreDetails
                ? display_identifier_details
                : display_primary_identifier_details
            " class="field-details zs-grid-4cols zs-grid-1rows">
            <div class="zs-col-1 zs-row-1 zs-col-span-3">
              <p class="zs-font-weight-bold profile-detail-label icon-margin">
                {{
                golden.IDENTIFIERS[key].display !== ''
                ? detailTableMapping.hasOwnProperty(
                golden.IDENTIFIERS[key].display.trim()
                )
                ? detailTableMapping[
                golden.IDENTIFIERS[key].display.trim()
                ]
                : golden.IDENTIFIERS[key].display
                : key
                }}
              </p>
              <ng-container *ngIf="
                  [].constructor.isArray(golden.IDENTIFIERS[key].value) &&
                  golden.IDENTIFIERS[key].value.length > 0
                ">
                <span *ngFor="
                    let item of golden.IDENTIFIERS[key].value;
                    let i = index
                  ">
                  <span *ngIf="i > 0 && i < golden.IDENTIFIERS[key].value.length" class="zs-margin-0-1-0-0">,</span>
                  <span *ngIf="item">{{ item }}</span>
                </span>
              </ng-container>
              <ng-container *ngIf="![].constructor.isArray(golden.IDENTIFIERS[key].value)">
                <div *ngIf="
                    golden.IDENTIFIERS[key] && golden.IDENTIFIERS[key].value
                  " class="profile-applied-value">
                  {{ golden.IDENTIFIERS[key].value }}
                </div>
              </ng-container>
            </div>
            <div class="zs-col-3 zs-row-1">
              <span [hidden]="
                  !(
                    golden.IDENTIFIERS[key] &&
                    golden.IDENTIFIERS[key].desc &&
                    golden.IDENTIFIERS[key].desc !== ''
                  )
                " class="zs-margin-0-0-0-1">
                <span class="zs-icon zs-icon-info zs-size-s">
                  <zs-tooltip offset-y="10">
                    {{ golden.IDENTIFIERS[key].desc }}
                  </zs-tooltip>
                </span>
              </span>
            </div>
          </div>
        </div>
      </app-zs-card>
    </div>

    <!--Communication details card-->
    <div *ngIf="golden" appZsLoader [isVisible]="lineageLoading" class="zs-margin-1 zs-row-2 zs-col-3">
      <app-zs-card class="details-card">
        <div card-header class="zs-padding-2">
          <span class="card-heading zs-display-block">
            <h4>{{ communication_label }}</h4>
          </span>
        </div>
        <div card-section class="zs-padding-2 card-section">
          <div *ngFor="let key of display_communication_details; let i = index" class="field-details">
            <div>
              <p class="zs-font-weight-bold profile-detail-label">
                {{
                golden.COMMUNICATIONS[i].COMM_TYPE !== ''
                ? detailTableMapping.hasOwnProperty(
                golden.COMMUNICATIONS[i].COMM_TYPE
                )
                ? detailTableMapping[golden.COMMUNICATIONS[i].COMM_TYPE]
                : golden.COMMUNICATIONS[i].COMM_TYPE
                : golden.COMMUNICATIONS[i].COMM_TYPE
                }}
              </p>
              <p class="profile-applied-value">
                {{ golden.COMMUNICATIONS[i].COMM_VAL }}
              </p>
            </div>
          </div>
          <div *ngIf="display_communication_details.length === 0">
            <div *ngFor="let key of communication_keys" class="field-details">
              <div>
                <p class="zs-font-weight-bold profile-detail-label">
                  {{
                  key !== ''
                  ? detailTableMapping.hasOwnProperty(key)
                  ? detailTableMapping[key]
                  : key
                  : key
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </app-zs-card>
    </div>

    <!--Address details card-->
    <div *ngIf="golden" appZsLoader [isVisible]="lineageLoading" class="zs-margin-1 zs-row-2 zs-col-span-2">
      <app-zs-card class="details-card">
        <div card-header class="zs-padding-2">
          <span class="card-heading zs-display-block">
            <h4>
              {{ address_label }}
              <span *ngIf="isViewMoreDetails && display_address_details.length > 0">
                <a (click)="decreaseAddressIndex()">
                  <span class="zs-icon zs-icon-arrow-left zs-size-extra-large"></span>
                </a>
                <span class="zs-size-extra-small zs-font-style-italic" translate [translateParams]="{
                    current_address_index: this.current_address_index,
                    max_address_index: this.max_address_index
                  }">
                  {{ ui_labels.ADDRESS_OF }}</span>
                <a (click)="increaseAddressIndex()">
                  <span class="zs-icon zs-icon-arrow-right zs-size-extra-large"></span>
                </a>
              </span>
            </h4>
          </span>
        </div>
        <div card-section class="zs-padding-2 card-section">
          <span *ngIf="
              (golden.ADDRESSES[current_address_index - 1].PRI_ADDR_FLG.value === yConstant) && display_primary_address_details.length > 0
            " class="zs-badge-text zs-bg-info-inverse zs-text-info badge-font zs-margin-0-0-1-1" translate>{{
            ui_labels1.PRIMARY }}</span>
          <div class="zs-grid-3cols">
            <div *ngFor="
                let key of isViewMoreDetails
                  ? display_address_details
                  : display_primary_address_details
              " class="field-details zs-grid-4cols zs-grid-1rows">
              <div class="zs-col-1 zs-row-1 zs-col-span-4">
                <p class="zs-font-weight-bold profile-detail-label">
                  {{
                  golden.ADDRESSES[current_address_index - 1][key].display !== '' ?
                  detailTableMapping.hasOwnProperty(golden.ADDRESSES[current_address_index - 1][key].display.trim())
                  ? detailTableMapping[golden.ADDRESSES[current_address_index - 1][key].display.trim()]
                  : golden.ADDRESSES[current_address_index - 1][key].display
                  : key
                  }}
                </p>
                <ng-container *ngIf="
                    ![].constructor.isArray(
                      golden.ADDRESSES[current_address_index - 1][key].value
                    )
                  ">
                  <div *ngIf="
                      golden.ADDRESSES[current_address_index - 1][key] &&
                      golden.ADDRESSES[current_address_index - 1][key].value
                    " class="profile-applied-value">
                    {{ golden.ADDRESSES[current_address_index - 1][key].value }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </app-zs-card>
    </div>
  </div>
</div>