import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import Loader from '../../../../node_modules/zsui/src/loader/loader.m.js';
Loader.register();
@Directive({
  selector: '[appZsLoader]'
})
export class ZsLoaderDirective implements OnChanges {
  nativeEl: any;
  loaderWrapper = null;
  loaderDiv = null;
  @Input() isVisible: Boolean;
  @Input() isInverse = true;
  @Input() customstyle = '';
  @Input() isWrapper = true;

  constructor(el: ElementRef) {
    this.nativeEl = el.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    const $this = this;
    if (changes.isVisible && changes.isVisible.currentValue === true) {
      let wrapperDiv;
      if ($this.isWrapper) {
        $this.nativeEl.style.position = 'relative';
        $this.nativeEl.style.opacity = '0.75';
        wrapperDiv = document.createElement('div');
        wrapperDiv.style.cssText = `
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      ` ;
      } else {
        $this.nativeEl.style.verticalAlign = 'unset';
      }
      const loaderDiv = document.createElement('zs-loader');
      loaderDiv.style.margin = 'auto';
      loaderDiv.classList.add('zs-loader');
      if ($this.isInverse && $this.isVisible) {
        loaderDiv.classList.add('zs-inverse');
      }
      if ($this.customstyle !== '') {
        loaderDiv.style.cssText = $this.customstyle;
      }
      if ($this.isWrapper) {
        wrapperDiv.appendChild(loaderDiv);
        $this.nativeEl.prepend(wrapperDiv);
        $this.loaderDiv = loaderDiv;
        $this.loaderWrapper = wrapperDiv;
      } else {
        $this.nativeEl.prepend(loaderDiv);
        $this.loaderDiv = loaderDiv;
      }
    } else if (changes.isVisible && changes.isVisible.currentValue === false &&
      ($this.loaderDiv !== null || $this.loaderWrapper !== null)) {
      if ($this.isWrapper) {
        $this.nativeEl.style.position = null;
        $this.nativeEl.style.opacity = null;
      } else {
        $this.nativeEl.style.verticalAlign = null;
      }
      $this.nativeEl.removeChild($this.nativeEl.firstChild);
      $this.loaderDiv = null;
      $this.loaderWrapper = null;
    } else if (changes.isInverse && changes.isInverse.currentValue && $this.loaderDiv !== null) {
      if (changes.isInverse.currentValue === true) {
        $this.loaderDiv.classList.add('zs-inverse');
      } else {
        $this.loaderDiv.classList.remove('zs-inverse');
      }
    }
  }
}
