import DatePicker from 'zsui/src/datePicker/datePicker.m';

export default class CustomDatePicker extends DatePicker {
	constructor(...args) {
		const _ = super(...args);
		return _;
	}

  onclick(event) {
    super.onclick(event);
    if (event.target === this.clearIconElement) {
        this.value = '';
        this.fire('change');
      }
  }
}
