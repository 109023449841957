import { ToasterDataService } from './toaster-data.service';
import { ToastMessage } from './toaster-service.model';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BackendUserMessages } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { RDHMessageFactory } from 'src/app/shared/rdh-api-messages-translation/rdh-message-factory';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';

@Component({
  selector: 'app-toaster-service',
  templateUrl: './toaster-service.component.html',
  styleUrls: ['./toaster-service.component.less']
})
export class ToasterServiceComponent implements OnInit {

  toastMessages: any;
  ui_labels = BackendUserMessages;
  toasters: ToastMessage[] = [];

  constructor(public toasterDataService: ToasterDataService,
    private _translate: TranslateService) {
    this.toastMessages = toasterDataService.SubscribeMessages().subscribe(message => {
      if (message !== undefined) {
        this.ShowToaster(message);
      }
    });
  }

  ngOnInit() { }

  ShowToaster(message: any) {
    if (message.timeout === undefined) {
      message.timeout = 10000;
    }

    if (message.showClose === undefined) {
      message.showClose = true;
    }

    if (message.type === ToasterTypeConstants.SUCCESS) {
      message.backgroundStyle = 'zs-bg-success zs-text-inverse';
      message.iconStyle = 'zs-text-inverse';
      message.icon = 'zs-icon-check-circle-fill';
    } else if (message.type === ToasterTypeConstants.ERROR) {
      message.backgroundStyle = 'zs-bg-error zs-text-inverse';
      message.iconStyle = 'zs-text-inverse';
      message.icon = 'zs-icon-error-triangle-fill';
    } else if (message.type === ToasterTypeConstants.WARNING) {
      message.backgroundStyle = 'zs-bg-warning zs-text-inverse';
      message.iconStyle = 'zs-text-inverse';
      message.icon = 'zs-icon-error-circle-fill';
    } else if (message.type === ToasterTypeConstants.INFO) {
      message.backgroundStyle = 'zs-bg-info zs-text-inverse';
      message.iconStyle = 'zs-text-inverse';
      message.icon = 'zs-icon-info-fill';
    } else {
      message.backgroundStyle = 'zs-bg-error zs-text-inverse';
      message.iconStyle = 'zs-text-inverse';
      message.icon = 'zs-icon-error-triangle-fill';
    }

    const obj = new RDHMessageFactory(this._translate, message.description).getMessage();
    message.description = obj.translate();

    this.toasters.push(message);

    setTimeout(() => this.closeToaster(message), message.timeout);

  }

  closeToaster(toaster: any) {
    this.toasters = this.toasters.filter(toast => toast !== toaster);
  }

}
