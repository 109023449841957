<div class="multi-select">
  <ng-container *ngIf="{value:(dataSource)} as filteredData;">
      <ng-container
        *ngIf="{filteredData: (dataSource | filterTable : [this.displayLabelKey] : this.searchText : false)} as Variable">

        <div class="filter-container zs-search-dropdown zs-menu nav-list " container=""
          [ngStyle]="{'display': this.showDropdown ? 'block' : 'none', 'min-height': Variable.filteredData.length >= this.minOptionsForSearch?'17.44em':none}">
          <a (click)="clearAllFilters()" class="clear-filter" *ngIf="showClearAllOption"
            translate>{{ui_labels.CLEAR_ALL}}</a>
          <p class="zs-input-icon zs-icon-search">
            <input type="text" class="zs-input" [(ngModel)]="this.searchText"
              (input)="onSearchChange($event.target.value)">
            <a class="zs-icon zs-icon-remove" clear="" (click)="clearSearchText()"
              [ngStyle]="{'display': this.searchText.length > 0 ? 'block' : 'none'}"
              style="position: absolute; background: white; z-index: 2;"></a></p>
          <!-- Added infinity scroll to filter -->
          <nav options="" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10"
            (scrolled)="onScroll()" [scrollWindow]="false">
            <ng-container (mouseover)="hover=true;" (mouseleave)="hover=false"
              *ngFor="let item of Variable.filteredData">
              <a (click)="this.HandleOptionSelect(item)" index="0" [attr.active]="item.IsSelected?true:null"
                [attr.hover]="hover?true:null">
                <ng-container *ngIf="this.enableMultiSelect">
                  <span class="zs-checkbox">
                    <input type="checkbox" [(ngModel)]="item.IsSelected">

                    <span [innerHTML]="item[this.displayLabelKey] | highlightText: this.searchText"></span>
                  </span>
                </ng-container>
                <ng-container *ngIf="!this.enableMultiSelect">
                  <span>
                    <span [innerHTML]="item[this.displayLabelKey] | highlightText: this.searchText"></span>
                  </span>
                </ng-container>
                <zs-tooltip *ngIf="tooltipVisible" position="bottom">
                  <zs-tooltip-content class="zs-size-xs">
                      {{item[this.displayLabelKey]}}
                  </zs-tooltip-content>
                </zs-tooltip>
              </a>
            </ng-container>
          </nav>
        </div>
      </ng-container>
  </ng-container>
</div>
