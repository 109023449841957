import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParameterCustomEncoderService {

  constructor() { }

  encodeParameter(parameter: any): any {
    return encodeURIComponent(parameter);
  }

  decodeParameter(parameter: any): any {
    return decodeURIComponent(parameter);
  }
}

