import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { NavigationIcon, NavigationTabs, RouterDetailsConstant, RouterLinkMapper } from '../constants';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from '../models/environment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { app_labels } from '../constants/ui_labels_translation_mapping';

@Injectable({
  providedIn: 'root'
})
export class NavigationTabDropdownChangeService {
  private subject = new BehaviorSubject<any>(null);
  navigationTabsDetails = [];
  navigationTabsInDataTab = [];
  bottomNavigationList = [];
  navigationTabs = [];
  ui_labels = app_labels;
  environment: EnvironmentConfig;
  navigationTabsGlobalizationMapper = new Map<string, string>();
  isMasterDataTabAdded = false;

  constructor(private _translate: TranslateService,
    private config: AppConfig) {

    const translated_labels = this._translate.instant(
      [
        this.ui_labels.HOME,
        this.ui_labels.DRIVER_DATA,
        this.ui_labels.BUSINESS_RULES,
        this.ui_labels.PROCESS_MONITORING,
        this.ui_labels.AUDIT_LOG,
        this.ui_labels.ADMIN,
        this.ui_labels.SITE_ADMIN,
        this.ui_labels.IMPORT_EXPORT,
        this.ui_labels.MASTER,
        this.ui_labels.DATA,
        this.ui_labels.SETTINGS,
        this.ui_labels.PROCESSES,
        this.ui_labels.DATA_QUERY,
        this.ui_labels.MASTER_DATA,
        this.ui_labels.DATA_CENTER,
        this.ui_labels.ADAPTORS,
        this.ui_labels.BUSINESS_WORKFLOWS
      ]
    );

    this.navigationTabsGlobalizationMapper[NavigationTabs.HOME] = translated_labels[this.ui_labels.HOME];
    this.navigationTabsGlobalizationMapper[NavigationTabs.DRIVER_DATA] = translated_labels[this.ui_labels.DRIVER_DATA];
    this.navigationTabsGlobalizationMapper[NavigationTabs.BUSINESS_RULES] = translated_labels[this.ui_labels.BUSINESS_RULES];
    this.navigationTabsGlobalizationMapper[NavigationTabs.DATA_CENTER] = translated_labels[this.ui_labels.DATA_CENTER];
    this.navigationTabsGlobalizationMapper[NavigationTabs.DATA_QUERY] = translated_labels[this.ui_labels.DATA_QUERY];
    this.navigationTabsGlobalizationMapper[NavigationTabs.AUDIT_LOG] = translated_labels[this.ui_labels.AUDIT_LOG];
    this.navigationTabsGlobalizationMapper[NavigationTabs.ADMIN] = translated_labels[this.ui_labels.ADMIN];
    this.navigationTabsGlobalizationMapper[NavigationTabs.SITE_ADMIN] = translated_labels[this.ui_labels.SITE_ADMIN];
    this.navigationTabsGlobalizationMapper[NavigationTabs.IMPORT_EXPORT] = translated_labels[this.ui_labels.IMPORT_EXPORT];
    this.navigationTabsGlobalizationMapper[NavigationTabs.MASTER] = translated_labels[this.ui_labels.MASTER];
    this.navigationTabsGlobalizationMapper[NavigationTabs.DATA] = translated_labels[this.ui_labels.DATA];
    this.navigationTabsGlobalizationMapper[NavigationTabs.SETTINGS] = translated_labels[this.ui_labels.SETTINGS];
    this.navigationTabsGlobalizationMapper[NavigationTabs.MASTER_DATA] = translated_labels[this.ui_labels.MASTER_DATA];
    this.navigationTabsGlobalizationMapper[NavigationTabs.ADAPTORS] = translated_labels[this.ui_labels.ADAPTORS];
    this.navigationTabsGlobalizationMapper[NavigationTabs.BUISSNESS_WORKFLOW] = translated_labels[this.ui_labels.BUSINESS_WORKFLOWS];

    this.environment = this.config.getConfigObject();

  }

  setNavigationTabsListFromDropdown(navigationTabsList) {
    this.navigationTabs = navigationTabsList;
  }

  getNavigationTabsListFromDropdown() {
    return this.navigationTabs;
  }

  setPreselectedNavigationTabInDropdown(navigationTab: string) {
    if (navigationTab === null) {
      this.subject.next(navigationTab);
    } else {
      this.subject.next(this.navigationTabsGlobalizationMapper[navigationTab]);
    }
  }

  getPreselectedNavigationTabInDropdown(): Observable<any> {
    return this.subject.asObservable();
  }


  getNavigationTabsFromUserRoles(user) {
    if (user.MasterDataSteward && !(user.Approver || user.BRMAdmin || user.BRMUser || user.DataSteward
      || user.Extracter || user.Ingester || user.Migrator || user.OpsAnalyst || user.OpsAnalystInternal
      || user.SiteAdmin || user.Admin) && !this.isMasterDataTabAdded) {
      this.isMasterDataTabAdded = true;
      this.navigationTabsDetails.push({
        [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.MASTER],
        [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.MASTER_DATA],
        [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.MASTER_DATA,
        [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.MASTER_DATA]
      });
    } else {
      if (this.navigationTabsDetails.length === 0) {
        if (user.Admin || user.Approver || user.BRMAdmin || user.BRMUser || user.DataSteward || user.Extracter || user.HQUser ||
          user.Ingester || user.MasterDataSteward || user.OpsAnalyst || user.OpsAnalystInternal) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.HOME],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.HOME],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.HOME,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.HOME]
          });
        }
        if (user.OpsAnalyst || user.Admin || user.OpsAnalystInternal) {
          const isHQUser = user.HQUser && !user.Admin && (user.OpsAnalystInternal || user.OpsAnalyst);
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: isHQUser ? RouterLinkMapper[NavigationTabs.FLOWS] :
             RouterLinkMapper[NavigationTabs.FLOWS],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.BUISSNESS_WORKFLOW],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.BUISSNESS_WORKFLOW,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.BUISSNESS_WORKFLOW]
          });
        }
        if (user.Ingester || user.Extracter || user.DataSteward || user.MasterDataSteward || user.Admin) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: '#',
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.DATA],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.DATA,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.DATA]
          });
        }
        if (user.BRMAdmin || user.BRMUser || user.Admin) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.BUSINESS_RULES],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.BUSINESS_RULES],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.BUSINESS_RULES,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.BUSINESS_RULES]
          });
        }
        if ((user.Ingester || user.Extracter) && !(user.Admin) && !user.HQUser) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.ADAPTORS],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.ADAPTORS],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.ADAPTORS,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.ADAPTORS]
          });
        }
        if (user.Admin) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.ADMIN],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.SETTINGS],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.SETTINGS,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.SETTINGS]
          });
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.AUDIT_LOG],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.AUDIT_LOG],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.AUDIT_LOG,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.AUDIT_LOG]
          });
        }
        if (user.SiteAdmin) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.SITE_ADMIN],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.SITE_ADMIN],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.SITE_ADMIN,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.HOME]
          });
        }
        if (user.Migrator) {
          this.navigationTabsDetails.push({
            [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.IMPORT_EXPORT],
            [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.IMPORT_EXPORT],
            [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationTabs.IMPORT_EXPORT,
            [RouterDetailsConstant.NAVIGATION_ICON]: NavigationIcon[NavigationTabs.HOME]
          });
        }
      }
    }
    return this.navigationTabsDetails;
  }

  getNavigationTabsOfData(user) {
    if (this.navigationTabsInDataTab.length === 0) {
      if (user.Ingester || user.Admin) {
        this.navigationTabsInDataTab.push({
          [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.DATA_CENTER],
          [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.DATA_CENTER],
          [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationIcon[NavigationTabs.DATA_CENTER]
        });
      }
      if (user.Extracter || user.Admin) {
        this.navigationTabsInDataTab.push({
          [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.DATA_QUERY],
          [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.DATA_QUERY],
          [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationIcon[NavigationTabs.DATA_QUERY]
        });
      }
      if (user.DataSteward || user.Admin) {
        this.navigationTabsInDataTab.push({
          [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.DRIVER_DATA],
          [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.DRIVER_DATA],
          [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationIcon[NavigationTabs.DRIVER_DATA]
        });
      }
      if ((user.MasterDataSteward || user.Admin) && !this.isMasterDataTabAdded) {
        this.navigationTabsInDataTab.push({
          [RouterDetailsConstant.ROUTER_LINK]: RouterLinkMapper[NavigationTabs.MASTER],
          [RouterDetailsConstant.NAVIGATION_TAB_NAME]: this.navigationTabsGlobalizationMapper[NavigationTabs.MASTER_DATA],
          [RouterDetailsConstant.NAVIGATION_TAB_VALUE]: NavigationIcon[NavigationTabs.MASTER_DATA]
        });
      }
    }
    return this.navigationTabsInDataTab;
  }

  getNavigationIconsByTabName(tabName) {
    const navigationTabName = Object.keys(this.navigationTabsGlobalizationMapper)
      .find(key => this.navigationTabsGlobalizationMapper[key] === tabName);

    if (navigationTabName !== undefined) {
      return NavigationIcon[navigationTabName];
    }
    return '';
  }

  getNavigationTabsInDropdownFromNavigationTabsDetails(navigationTabsDetails) {
    const navigationTabs = [];
    for (let i = this.environment.maxNavigationTabsCount; i < navigationTabsDetails.length; i++) {
      navigationTabs.push(navigationTabsDetails[i]['navigationTabValue']);
    }
    return navigationTabs;
  }
}
