import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MDMActivityLog,
  MDMCommonConstants,
  MDMWrapperConstants,
} from '../constants/mdm-common-constants';
import {
  MDMProfileTabNameConstants,
  QueryParams,
} from '../constants/profile-constants';
import { Subscription, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { mdm_labels } from '../constants/ui-translation';

@Component({
  selector: 'mdm-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('zsTabs', { read: ElementRef }) zsTabs: ElementRef;
  @ViewChild('detailstable', { read: ElementRef }) detailstable: ElementRef;
  activeBreadcrumbTab;
  mergeScreenActivated = false;
  noProfileError = false;
  isHco = false;
  MDMWrapperConstants = MDMWrapperConstants;
  suspectedCount: number;

  refreshIndicator$: any;
  refreshIndicator = false;
  MDMCommonConstants = MDMCommonConstants;

  MDMProfileTabNameConstants = MDMProfileTabNameConstants;
  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.PROFILE_TABS;
  profile_lables = mdm_labels.ALL_RECORDS.PROFILE;
  allRecordsLabel = mdm_labels.MDM_WRAPPER.ALL_RECORDS;
  suspectedRecordsLabel = mdm_labels.MDM_WRAPPER.SUSPECTED_MATCHES;
  ellipsesLengthForTabs = 10;
  canNotFindLastViewedProfileMessage: string;
  translated_labels: any;
  error_label: string;
  hcpHierarchy: string;
  hcoHierarchy: string;
  hcpDetails: string;
  hcoDetails: string;
  logs: string;

  commmon_labels = commmon_labels;

  urlParamsSubscriber: Subscription;
  profileNameWithMdmId = '';
  profileDetails: string;
  toShowLimit = MDMCommonConstants.PROFILE_BREADCRUM_SHOW_LIMIT;
  environment: EnvironmentConfig;
  profileBreadcrumDetailsSubscriber: Subscription;
  refreshMdmIdSubscription: Subscription;
  mdmId: string;
  returnToProfileLabel: string;
  suspectedMatchesLabel: string;
  currentRoute = '';
  showReturnToProfile = false;
  tempVal = true;
  _returnId = '';
  activeTab = MDMProfileTabNameConstants.SUSPECTED_MATCHES;
  returnProfileNameWithId: string;

  constructor(
    private service: MDMService,
    private route: ActivatedRoute,
    private router: Router,
    private _translate: TranslateService,
    private config: AppConfig,
    private _toasterDataService: ToasterDataService
  ) {
    this.environment = this.config.getConfigObject();
    this.translated_labels = this._translate.instant([
      this.profile_lables.CAN_NOT_FIND_LAST_VISITE_PROFILE,
      this.commmon_labels.ERROR_WITH_COLON,
      this.ui_labels.RETURN_TO_PROFILE,
      this.ui_labels.SUSPECTED_MATCHES,
      this.ui_labels.HCP_HIERARCHY,
      this.ui_labels.HCO_HIERARCHY,
      this.ui_labels.HCP_DETAILS,
      this.ui_labels.HCO_DETAILS,
      this.ui_labels.LOGS
    ]);
    this.canNotFindLastViewedProfileMessage = this.translated_labels[
      this.profile_lables.CAN_NOT_FIND_LAST_VISITE_PROFILE
    ];
    this.error_label = this.translated_labels[
      this.commmon_labels.ERROR_WITH_COLON
    ];
    this.returnToProfileLabel = this.translated_labels[
      this.ui_labels.RETURN_TO_PROFILE
    ];
    this.suspectedMatchesLabel = this.translated_labels[
      this.ui_labels.SUSPECTED_MATCHES
    ];
    this.hcpHierarchy = this.translated_labels[
      this.ui_labels.HCP_HIERARCHY
    ];
    this.hcoHierarchy = this.translated_labels[
      this.ui_labels.HCO_HIERARCHY
    ];
    this.hcpDetails = this.translated_labels[
      this.ui_labels.HCP_DETAILS
    ];
    this.hcoDetails = this.translated_labels[
      this.ui_labels.HCO_DETAILS
    ];
    this.logs = this.translated_labels[
      this.ui_labels.LOGS
    ];

    this.isHco = window.location.href.indexOf('/hco/') >= 0;

    let id = '';
    this.urlParamsSubscriber = route.params.subscribe((params) => {
      id = params[QueryParams.ID];
      this.mdmId = id;
      this.getProfileNameById(id);
      this.showReturnToProfile = this.router.url.includes(MDMActivityLog.MERGE);
    });

    this.profileBreadcrumDetailsSubscriber = this.service
      .getProfileBreadcrumID()
      .subscribe((id) => {
        if (id) {
          this.getProfileNameById(id);
        }
      });

    if (id !== MDMCommonConstants.UNKNOWN && this.isHco) {
      if (
        sessionStorage.getItem(MDMCommonConstants.LAST_VISITED_PROFILE_TYPE) ===
        MDMCommonConstants.HCO
      ) {
        sessionStorage.setItem(MDMCommonConstants.LAST_VISITED_HCO, id);
      }
    } else if (id !== MDMCommonConstants.UNKNOWN && !this.isHco) {
      if (
        sessionStorage.getItem(MDMCommonConstants.LAST_VISITED_PROFILE_TYPE) ===
        MDMCommonConstants.HCP
      ) {
        sessionStorage.setItem(MDMCommonConstants.LAST_VISITED_HCP, id);
      }
    } else {
      this.noProfileError = true;
    }
  }

  getProfileNameById(id) {
    this.service
      .getProfileNameById(
        id,
        this.isHco ? MDMCommonConstants.HCO : MDMCommonConstants.HCP
      )
      .subscribe(
        (res: any) => {
          const name =
            (res.name && res.name.length) > this.toShowLimit
              ? res.name.substr(0, this.toShowLimit) + '...'
              : res.name;
          this.profileNameWithMdmId = name + ' (' + res.mdm_id + ')';
        },
        (exception) => {
          this.router.navigate(['/mdm/all-records/search'], {
            queryParams: { isProfileExists: 'false' },
          });
        }
      );
  }

  goToProfile() {
    this.service.refreshAffiliationTab(this._returnId);
    this.getEntityTypeById(this._returnId);
    this.tempVal = false;
  }

  getEntityTypeById(id) {
    this.service.getEntityTypeById(id).subscribe(
      (result: any) => {
        this.router.navigate([
          result === MDMCommonConstants.HCO
            ? '/mdm/all-records/hco/profile'
            : '/mdm/all-records/hcp/profile',
          id,
        ]);
      },
      (exception) => {
        this._toasterDataService.addMessageToToaster(exception.error.message);
      }
    );
  }
  ngOnInit() {
    /* Edge 17039 changes starts
      Getting the value of activeTab to set Breadcrumbs
      then resetting the value of active tab to default*/
    this.activeBreadcrumbTab = this.service.getActiveTab();
    // set mergeScreenActivated to false
    this.service.mergeScreenActivated(this.mergeScreenActivated);
    this.service.resetActiveTab();
    // Edge 17039 changes ends
    this.refreshIndicator$ = interval(10 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.service.getRefreshIndicator())
      )
      .subscribe(
        (res) => {
          this.refreshIndicator = res;
        },
        (exception) => {
          this._toasterDataService.addMessageToToaster(exception.error.message);
        }
      );
    this.route.queryParams.subscribe((params) => {
      this._returnId = params[QueryParams.RETURN_ID];
      const returnName = params[QueryParams.RETURN_NAME];
      this.returnProfileNameWithId = returnName + ' (' + this._returnId + ')';
    });
    this.refreshMdmIdSubscription = this.service
      .getRefreshMdmId()
      .subscribe((mdmId) => {
        if (mdmId) {
          this.mdmId = mdmId;
        }
      });
  }

  setSelectedTab(selectedTabName) {
    this.activeTab = selectedTabName;
  }

  suspectedMatchesCount(value) {
    this.suspectedCount = value.length;
  }
  ngOnDestroy() {
    this.refreshIndicator$.unsubscribe();
    this.urlParamsSubscriber.unsubscribe();
    if (this.profileBreadcrumDetailsSubscriber) {
      this.profileBreadcrumDetailsSubscriber.unsubscribe();
    }
    if (this.refreshMdmIdSubscription) {
      this.refreshMdmIdSubscription.unsubscribe();
    }
  }
}
