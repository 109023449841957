<zs-card class="zs-card"
    [flippable]="isFlippable" [flipped]="isFlipped" [filter]="filter" [ngStyle]="customStyle">
    <header> 
        <ng-content select="[card-header]"></ng-content> 
    </header>
    <section>
        <ng-content select="[card-section]"></ng-content>
    </section>
    <footer>
        <ng-content select="[card-footer]"></ng-content>
    </footer>
</zs-card>
