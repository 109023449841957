export class MultiSelectDropDown {
    isSelected: boolean;
    displayLabel: string;
    value: any;
}

export class MultiSelectIndexedDropDown {
    indexId: any;
    isSelected: boolean;
    displayLabel: string;
    value: any;
}
