import { Component, OnInit, Input } from '@angular/core';
import { mdm_labels } from 'src/app/mdm/constants/ui-translation';
import { MDMAddressFieldConstants, MDMCommonConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mdm-address',
  templateUrl: './address.component.html'
 })
export class AddressComponent implements OnInit {

    @Input() address: any;
    @Input() showPrimaryLabel = true;

  ui_labels = mdm_labels.ALL_RECORDS.PROFILE.DETAILS_TABLE;
  primaryLabel: string;
  translated_labels: any;
  addressToDisplay = [];
  MDMAddressFieldConstants = MDMAddressFieldConstants;
  MDMCommonConstants = MDMCommonConstants;
  isNonFillerAttributesHidden: boolean;

  constructor(private _translate: TranslateService) {
    this.translated_labels = this._translate.instant(
      [
        this.ui_labels.PRIMARY,
      ]);
      this.primaryLabel = this.translated_labels[this.ui_labels.PRIMARY];
  }

  ngOnInit() {
    const mandatoryAddressAttributes = [this.MDMAddressFieldConstants.ADDR_LN1, this.MDMAddressFieldConstants.ADDR_LN2,
      this.MDMAddressFieldConstants.CITY, this.MDMAddressFieldConstants.ST, this.MDMAddressFieldConstants.ZIP_CD];

    const nonFillerAddressAttributes = [this.MDMAddressFieldConstants.ADDR_LN1, this.MDMAddressFieldConstants.ADDR_LN2,
      this.MDMAddressFieldConstants.CITY, this.MDMAddressFieldConstants.ST, this.MDMAddressFieldConstants.ZIP_CD,
      this.MDMAddressFieldConstants.ZIP_CD_EXTN, this.MDMAddressFieldConstants.CNTRY, this.MDMAddressFieldConstants.LAT,
      this.MDMAddressFieldConstants.LNGTD, this.MDMAddressFieldConstants.VERFN_STAT];

      mandatoryAddressAttributes.forEach(key => {
          if ((this.address[key] && this.address[key].to_display && this.address[key].value
            && this.address[key].to_display === this.MDMCommonConstants.Y)) {
              this.addressToDisplay.push(this.address[key].value);
            }
      });

    for (const key in this.address) {
      if (nonFillerAddressAttributes.indexOf(key) === -1 &&
      this.address[key].value && this.address[key].to_display && this.address[key].to_display === this.MDMCommonConstants.Y) {
        this.addressToDisplay.push(this.address[key].value);
      }
    }
  }

}
