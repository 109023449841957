import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MDMActivityLog } from '../../../constants/mdm-common-constants';
import { PayerService } from '../payer-services/payer.service';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { Location } from '@angular/common';
import { CommonService } from 'src/app/mdm/services/common.service';
import { MDMCommonConstants, PayerWrapperConstants } from '../../../../../app/mdm/constants/mdm-common-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payer-merge-unmerge',
  templateUrl: './payer-merge-unmerge.component.html',
  styleUrls: ['./payer-merge-unmerge.component.less']
})
export class PayerMergeUnmergeComponent implements OnInit {
  isMergeSelected = true;
  keys = [];
  mergeProfiles: any[];
  unMergeProfiles: any;
  onUnmergePage = true;
  onMergePage = true;
  golden: any;
  LineageSubscribe: any;
  mergeProfileSubscribe: any;
  pageDetails: any;
  PayerWrapperConstants = PayerWrapperConstants;
  constructor(private payerService: PayerService, private service: MDMService,
    private toaster: ToasterNotificationService,
    private commonService: CommonService,
    private router: Router,
    private _location: Location) {
    this.payerService.setMergeArray();
  }

  @ViewChild('mergeUnmergeWrapper') wrapperElement: ElementRef;
  @ViewChild('detailsTable', { read: ElementRef }) detailsTable: ElementRef;
  mergeVariableConstant = MDMActivityLog.MERGE;
  unmergeVariableConstant = MDMActivityLog.UNMERGE;

  ngOnInit(): void {
    this.pageDetails = this.commonService.getFromSessionStorage('pageDetails');
    this.LineageSubscribe = this.payerService.LineageDetails$.subscribe((data) => {
      this.golden = data.golden;
      this.unMergeProfiles = data.profiles;
    })
    this.mergeProfileSubscribe = this.payerService.mergeArray$.subscribe((data) => {
      this.mergeProfiles = data;
    })
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.payerService.addToNewArray(event.item.data);
    }
  }

  mergeAll() {
    const master_id = this.golden.MDM_ID.value;
    const SlaveId = this.mergeProfiles.map((ele) => ele.MDM_ID.value)
    switch (this.pageDetails.entity) {
      case PayerWrapperConstants.PBM:
        this.PBMMergeAll(master_id, SlaveId);
        break;
      case PayerWrapperConstants.MCO:
        this.MCOMergeAll(master_id, SlaveId);
        break;
      case PayerWrapperConstants.PLAN:
        this.PLANMergeAll(master_id, SlaveId);
        break;
      case PayerWrapperConstants.PAYER:
        this.PAYERMergeAll(master_id, SlaveId);
        break;
    }


  }
  onTabChange(value: string) {
    if (value === this.mergeVariableConstant) {
      this.isMergeSelected = true;
    } else {
      this.isMergeSelected = false;
    }
  }
  PBMMergeAll(master_id, SlaveId) {
    this.service.postPBMProfileMerge(master_id, SlaveId).subscribe((data: any) => {
      this.payerService.setMergeArray();
      this.payerService.getPBMSuspectedMatches(data.masterId);
      this.payerService.getPBMLineageData(data.masterId);
      this.payerService.getPBMProfileByid(data.masterId);
      this.refreshTabs(MDMActivityLog.MERGE, data.masterId);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  MCOMergeAll(master_id, SlaveId) {
    this.service.postMCOProfileMerge(master_id, SlaveId).subscribe((data: any) => {
      this.payerService.setMergeArray();
      this.payerService.getMCOSuspectedMatches(data.masterId);
      this.payerService.getMCOLineageData(data.masterId);
      this.payerService.getMCOProfileByid(data.masterId);
      this.refreshTabs(MDMActivityLog.MERGE, data.masterId);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  PLANMergeAll(master_id, SlaveId) {
    this.service.postPLANProfileMerge(master_id, SlaveId).subscribe((data: any) => {
      this.payerService.setMergeArray();
      this.payerService.getPLANSuspectedMatches(data.masterId);
      this.payerService.getPLANLineageData(data.masterId);
      this.payerService.getPLANProfileByid(data.masterId);
      this.refreshTabs(MDMActivityLog.MERGE, data.masterId);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  PAYERMergeAll(master_id, SlaveId) {
    this.service.postPAYERProfileMerge(master_id, SlaveId).subscribe((data: any) => {
      this.payerService.setMergeArray();
      this.payerService.getPAYERSuspectedMatches(data.masterId);
      this.payerService.getPAYERLineageData(data.masterId);
      this.payerService.getPAYERProfileByid(data.masterId);
      this.refreshTabs(MDMActivityLog.MERGE, data.masterId);
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  refreshTabs(operation = null, id) {
    const profileUrlParts = this._location.path().split('/');
    profileUrlParts[profileUrlParts.length - 2] = id;
    const finalProfileUrl = profileUrlParts.join('/');
    this.router.navigate([finalProfileUrl]).then(() => {
      if (operation === MDMActivityLog.MERGE) {
        this.toaster.showSuccess('Merged All Records', '');
      }
    });
  }
  ngOnDestroy(): void {
    this.LineageSubscribe.unsubscribe();
    this.mergeProfileSubscribe.unsubscribe();
  }
}
