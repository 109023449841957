import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MDMCommonConstants } from 'src/app/mdm/constants/mdm-common-constants';
import { url } from '../../constants/api-urls';

@Component({
  selector: 'app-activities-cell-renderer',
  templateUrl: './activities-cell-renderer.component.html',
  styleUrls: ['./activities-cell-renderer.component.less'],
})
export class ActivitiesCellRendererComponent
  implements ICellRendererAngularComp {
  constructor() {}

  ngOnInit(): void {}
  params: any;
  data: any;
  isMdmId: boolean;
  routeLink: string;
  filter: string;
  mdmCommonConstants = MDMCommonConstants;
  mdmAllRecordsHcoProfile = url.allRecordsHcoProfile;
  mdmAllRecordsHcpProfile = url.allRecordsHcpProfile;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;
    this.routeLink =
      this.params.data.ATVY_TYPE === this.mdmCommonConstants.HCO
        ? this.mdmAllRecordsHcoProfile
        : this.mdmAllRecordsHcpProfile;
  }

  refresh(params) {
    return true;
  }
}
