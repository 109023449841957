import { Component, Input, OnInit } from '@angular/core';
import Card from '../../../../node_modules/zsui/src/card/card.m.js';
Card.register();
@Component({
  selector: 'app-zs-card',
  templateUrl: './zs-card.component.html',
  styleUrls: ['./zs-card.component.less']
})
export class ZsCardComponent implements OnInit {

  @Input() isFlippable: boolean;
  @Input() isFlipped: boolean;
  @Input() filter: string;
  @Input() customStyle: any;
  constructor() { }

  ngOnInit(): void {
  }

}
