import { MDMCommonConstants } from "src/app/mdm/constants/mdm-common-constants";

export class MDMConfiguration {
    id: number;
    name: string;
    hostname: string;
    username: string;
    password: string;
    database_name: string;
    schema_name: string;
    search_engine_key: string;
    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.hostname = undefined;
        this.username = undefined;
        this.password = undefined;
        this.database_name = undefined;
        this.schema_name = undefined;
        this.search_engine_key = '';
    }
}

export class EntityTypeCount {
    hco: Set<number>;
    hcp: Set<number>;
    constructor() {
        this.hco = new Set<number>();
        this.hcp = new Set<number>();
    }
}

export class MDMAttributes {
    SRC_FLD: string;
    TGT_FLD: string;
    TO_DISP: string;
    GROUP_NAME: string;
    ETY_TYPE: string;
    NEW_ATTRIBUTE: boolean;
    IS_VALUE_CHANGED: boolean;
    IS_USER_CREATED: boolean;
    IS_PRIMARY: boolean;

    constructor(SRC_FLD, TGT_FLD, TO_DISP, GROUP_NAME, ETY_TYPE, NEW_ATTRIBUTE, IS_VALUE_CHANGED, IS_USER_CREATED, IS_PRIMARY) {
        this.SRC_FLD = SRC_FLD;
        this.TGT_FLD = TGT_FLD;
        this.TO_DISP = TO_DISP;
        this.GROUP_NAME = GROUP_NAME;
        this.NEW_ATTRIBUTE = NEW_ATTRIBUTE;
        this.IS_VALUE_CHANGED = IS_VALUE_CHANGED;
        this.ETY_TYPE = ETY_TYPE;
        this.IS_USER_CREATED = IS_USER_CREATED;
        this.IS_PRIMARY = IS_PRIMARY;
    }
}

export const MDMConstants = {
    VALUE_REQUIRED: 'Value Required',
    HOSTNAME: 'Hostname',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    DATABASE_NAME: 'Database Name',
    SCHEMA_NAME: 'Schema Name',
    SEARCH_ENGINE_KEY: 'Search Engine Key',
    TO_DISP: 'TO_DISP',
    IS_VALUE_CHANGED: 'IS_VALUE_CHANGED',
    NEW_ATTRIBUTE: 'NEW_ATTRIBUTE',
    PROFILE: 'PROFILE',
    SRC_FLD: 'SRC_FLD',
    TGT_FLD: 'TGT_FLD',
    GROUP_NAME: 'GROUP_NAME',
    ATTRIBUTE_CONFIGURATION: 'Attributes Configuration',
    ADD_NEW_ATTRIBUTES: 'Add New Attributes',
    FETCH_ATTRIBUTES: 'Fetch Attributes',
    ATTRIBUTE_NAME: 'Name',
    DISPLAY_ON_PROFILE: 'Display on Profile',
    IS_PRIMARY: 'IS_PRIMARY',
    DISPLAY_AS_PRIMARY: 'Display as Primary',
    ALIAS_NAME: 'Alias Name',
    ATTRIBUTE_GROUP_NAME: 'Group Name',
    ATTRIBUTE_ETY_TYPE: 'Entity Type',
    ACTION: 'Action',
    MDM_CONFIGURATION: 'MDM Configuration Details',
    SITE_ADMIN: 'Site Admin',
    ERROR_WITH_COLON: 'Error:',
    SUCCESS: 'Success',
    ENVIRONMENTS: 'Environments',
    NO_ENVIRONMENTS: 'No Environments to display',
    MDM: 'MDM',
    EDIT_MDM_CONFIG: 'Edit MDM Configuration',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    OK: 'Ok',
    WARNING_MESSAGE: 'You have unsaved changes on this page. Are you sure you want to leave the page?',
    SEARCH_ATTRIBUTES: 'Search attributes',
    BACK_TO_MDM_CONFIGURATION_LISTING: 'Back to MDM Configuration Listing',
    FIELDS_SHOULD_NOT_BE_EMPTY: 'Fields should not be empty',
    MDM_CONFIGURATION_EDITED_SUCCESSFULLY: 'MDM Configuration edited successfully',
    MDM_CONFIGURATION_ADDED_SUCCESSFULLY: 'MDM Configuration added successfully',
    FORM_SUBMITTED: 'Form Submitted',
    YES: 'Yes',
    PROFILE_LABEL: 'Profile'
};


export const showToDisplayValues = [
    {
        value: MDMCommonConstants.Y,
        displayLabel: 'Yes'
    },
    {
        value: MDMCommonConstants.N,
        displayLabel: 'No'
    }
];

export const showToPrimaryDisplayValues = [
    {
        value: MDMCommonConstants.Y,
        displayLabel: 'Yes'
    },
    {
        value: MDMCommonConstants.N,
        displayLabel: 'No'
    }
];


export const showGroupNameValues = [
    {
        value: 'PROFILE',
        displayLabel: 'Profile'
    },
    {
        value: 'IDENTIFIER',
        displayLabel: 'Identifier'
    },
    {
        value: 'ADDRESS',
        displayLabel: 'Address'
    }
];

export const showEntityTypeValues = [
    {
        value: 'HCP',
        displayLabel: 'HCP'
    },
    {
        value: 'HCO',
        displayLabel: 'HCO'
    },
    {
        value: 'ALL',
        displayLabel: 'All'
    }
];

export const GroupNames = {
    PROFILE: 'PROFILE',
    IDENTIFIER: 'IDENTIFIER',
    ADDRESS: 'ADDRESS'
};

export const MDMEntityTypes = {
    HCP: 'HCP',
    HCO: 'HCO',
    ALL: 'ALL'
};

export const MDMRouteConstants = {
    ENVIRONMENT_ID: 'environmentId'
};
