<span style="cursor:pointer ;" *ngIf="DCR_NAME" [routerLink]="['/mdm/dcr-management', data.DCR_NAME]"
    class="zs-counter">
    {{ data.DCR_NAME }}
</span>
<span *ngIf="REQUEST_CREATED_BY" class="zs-counter">
    {{ data.REQUEST_CREATED_BY }}
</span>
<span *ngIf="CHANGE_REQUEST_TYPE">
    <span *ngIf="data.CHANGE_REQUEST_TYPE == 'Edit_vod'">
        EDIT
    </span>
    <span *ngIf="data.CHANGE_REQUEST_TYPE == 'New_vod'">
        NEW
    </span>
</span>
<span *ngIf="CRM_SYNC_FLAG">

    <span
        *ngIf="data.DCR_STATUS == DCRStatus.APPROVED || data.DCR_STATUS == DCRStatus.SUBMITTED || data.DCR_STATUS == DCRStatus.PROCESSED || data.DCR_STATUS == DCRStatus.CANCELLED">
        Yes
    </span>
    <span *ngIf="data.DCR_STATUS == DCRStatus.PENDING ">
        No
    </span>
</span>
<span *ngIf="MDM_SYNC_FLAG">
    <span
        *ngIf="data.DCR_STATUS == DCRStatus.APPROVED || data.DCR_STATUS == DCRStatus.SUBMITTED || data.DCR_STATUS == DCRStatus.PENDING">
        No
    </span>

    <span *ngIf="data.DCR_STATUS == DCRStatus.PROCESSED ">
        Yes
    </span>
    <span *ngIf="data.DCR_STATUS == DCRStatus.CANCELLED ">
        NA
    </span>
</span>

<span *ngIf="status">
    <span class="zs-badge-text zs-size-small zs-bg-info-inverse  zs-text-info"
        *ngIf="data.DCR_STATUS == DCRStatus.PROCESSED">Processed</span>
    <span class="zs-badge-text zs-size-small zs-bg-success-inverse zs-text-success"
        *ngIf="data.DCR_STATUS == DCRStatus.APPROVED || data.DCR_STATUS == DCRStatus.SUBMITTED  ">Approved</span>
    <span class="zs-badge-text zs-size-small zs-bg-error-inverse zs-text-error"
        *ngIf="data.DCR_STATUS == DCRStatus.CANCELLED ">Cancelled</span>
    <span class="zs-badge-text zs-size-small zs-bg-warning-inverse zs-text-warning"
        *ngIf="data.DCR_STATUS == DCRStatus.PENDING && (data.IS_HOME_OFFICE == '-' || data.IS_HOME_OFFICE == 'N') ">Pending</span>
    <span class="zs-badge-text zs-size-small zs-bg-warning-inverse zs-text-warning"
        *ngIf="data.DCR_STATUS == DCRStatus.PENDING && data.IS_HOME_OFFICE == 'Y' "> Pending HQ Approval</span>
</span>