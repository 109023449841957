<div appZsLoader [isVisible]="loading" class="zs-margin-1-0-0-0">
	<div *ngIf="(!affiliationData || affiliationData.length == 0) && !loading" class="affiliation-type">
		<span class="zs-icon zs-icon-warning  zs-icon-color-warning"></span> 
		{{ translationMessagesMapping[affiliationType] }}
	</div>
	
	  <div class="tree-wrapper">
		<app-tree [treeData]="affiliationData" [isHco]="isHco" [openedProfileID]='_id' 
		[profileDetail]="profileDetail" [affiliationType]="affiliationType"
		[affiliationTab]="profileType" [level]="0" [topMostParentLength]="affiliationData.length"></app-tree>
	  </div>
  </div>