<zs-tabs-container class="zs-tabs zs-tabs-solid">
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PAYER)" class="zs-tab" [targetId]="PayerWrapperConstants.PAYER"
        [attr.active]="activeTab === PayerWrapperConstants.PAYER ? true : null" *ngIf="isVisible.PAYER">{{PayerWrapperConstants.PAYER}}</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PLAN)" class="zs-tab" [targetId]="PayerWrapperConstants.PLAN"
        [attr.active]="activeTab === PayerWrapperConstants.PLAN ? true : null" *ngIf="isVisible.PLAN">{{PayerWrapperConstants.PLAN}}</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.PBM)" class="zs-tab" [targetId]="PayerWrapperConstants.PBM"
        [attr.active]="activeTab === PayerWrapperConstants.PBM ? true : null" *ngIf="isVisible.PBM">{{PayerWrapperConstants.PBM}}</zs-tab>
    <zs-tab (tabclick)="onTabClick(PayerWrapperConstants.MCO)" class="zs-tab" [targetId]="PayerWrapperConstants.MCO"
        [attr.active]="activeTab === PayerWrapperConstants.MCO ? true : null" *ngIf="isVisible.MCO">{{PayerWrapperConstants.MCO}}</zs-tab>
</zs-tabs-container>
<div [attr.source-id]="PayerWrapperConstants.PAYER"
    [attr.active]="activeTab === PayerWrapperConstants.PAYER ? true : null">
    <div class="zs-padding-1">
        <app-payer [tableColumns]="tableColumns" *ngIf="tableColumns && activeTab === PayerWrapperConstants.PAYER">
        </app-payer>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.PLAN"
    [attr.active]="activeTab === PayerWrapperConstants.PLAN ? true : null">
    <div class="zs-padding-1">
        <app-plan [tableColumns]="tableColumns" *ngIf="activeTab === PayerWrapperConstants.PLAN"></app-plan>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.PBM" [attr.active]="activeTab === PayerWrapperConstants.PBM ? true : null">
    <div class="zs-padding-1">
        <app-pbm [tableColumns]="tableColumns" *ngIf="activeTab === PayerWrapperConstants.PBM"></app-pbm>
    </div>
</div>
<div [attr.source-id]="PayerWrapperConstants.MCO" [attr.active]="activeTab === PayerWrapperConstants.MCO ? true : null">
    <div class="zs-padding-1">
        <app-mco [tableColumns]="tableColumns" *ngIf="activeTab === PayerWrapperConstants.MCO"></app-mco>
    </div>
</div>