import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDMService } from 'src/app/mdm/mdm.service';
import { PayerWrapperConstants } from '../../../../../../src/app/mdm/constants/mdm-common-constants';
import { PayerService } from '../../../payer/profile/payer-services/payer.service';
import { CommonService } from '../../../services/common.service';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { ColDef } from 'ag-grid-community';
import { LineageCellRendererComponent } from 'src/app/mdm/profile/details/lineage-cell-renderer/lineage-cell-renderer.component';
import { GoldenValueAddressCellRendererComponent } from 'src/app/mdm/profile/details/golden-value-address-cell-renderer/golden-value-address-cell-renderer.component';
import {
  DetailsConstants,
  DetailsSortableColumnList,
  ExcludeDetailsColumnList,
  LineageConstants,
  MDMAddressFieldConstants,
  MDMCommonConstants,
  MaxDetailsWidthColumnList,
  ProfileDetailsTableConstants,
  LineageTableConstant
} from 'src/app/mdm/constants/mdm-common-constants';
import { AgGridSortService } from 'src/app/shared/services/ag-grid-sort.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'


@Component({
  selector: 'app-payer-profile-details',
  templateUrl: './payer-profile-details.component.html',
  styleUrls: ['./payer-profile-details.component.less'],
})
export class PayerProfileDetailsComponent implements OnInit {
  page_details?: any;
  PayerWrapperConstants = PayerWrapperConstants;
  is_mergeScreenActive = false;
  goldenRecord: any;
  payerList = [
    {
      name: 'FALCON COMMUNITY HEALTH PLAN INC-COMM',
      start_date: '18/01/2020',
    },
    {
      name: 'FALLON COMMUNITY HEALTH PLAN INC-COMM',
      start_date: '18/01/2020',
    },
  ];
  id_: any;
  profileDetails: any;
  colDefs: ColDef[] = [];
  rowData = []
  showLineageLabel = 'Show Lineage'
  page = 1;
  pageSize: number;
  totalRecords: number;
  frameworkComponents: object;
  gridOptions: object;
  defaultColDef: { [x: string]: string | boolean; };
  environment: EnvironmentConfig;
  showLineage: boolean;
  LineageTableConstant = LineageTableConstant;
  detailsApiResponseConstants = DetailsConstants;
  gridApi: any;
  isGoldenPresent = false;
  isProfilePresent = false;
  isLineageSet = false;
  profileDetailsSubscribe: any;
  profileLoadingSubscribe: any;
  LineageSubscribe: any;
  profileLoading: boolean;
  constructor(
    private router: Router,
    private payerService: PayerService,
    private commonService: CommonService,
    private service: MDMService,
    private route: ActivatedRoute,
    private _agGridSort: AgGridSortService,
    private config: AppConfig,
    private toaster: ToasterNotificationService,
  ) {
    const route_state = this.router.getCurrentNavigation()!.extras.state;
    if (route_state) {
      this.page_details = route_state;
      this.commonService.setToSessionStorage('pageDetails', route_state);
    } else {
      this.page_details = this.commonService.getFromSessionStorage('pageDetails')
    }
    this.environment = this.config.getConfigObject();
    this.payerService.profileLoading$.next(true);

  }

  ngOnInit(): void {
    this.showLineage = false;
    this.isLineageSet = false;

    //get the ID from the URL
    this.route.params.subscribe(params => {
      this.id_ = params['id'];
      this.initLoad();
    });


    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      defaultColDef: {
        sortable: true,
        unSortIcon: false
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.setColumnDefs();

    this.frameworkComponents = {
      lineageCellRenderer: LineageCellRendererComponent,
      goldenValueAddressCellRendererComponent: GoldenValueAddressCellRendererComponent,
    };


  }
  initLoad() {
    //based on entity call Lineage API
    if (this.page_details.entity == PayerWrapperConstants.PBM) {
      this.getPBMLineageData();
    } else if (this.page_details.entity == PayerWrapperConstants.MCO) {
      this.getMCOLineageData();
    } else if (this.page_details.entity == PayerWrapperConstants.PAYER) {
      this.getPAYERLineageData();
    } else if (this.page_details.entity == PayerWrapperConstants.PLAN) {
      this.getPLANLineageData();
    }
    this.profileDetailsSubscribe = this.payerService.profileDetails$.subscribe((data) => {
      this.profileDetails = data;
    })
    this.profileLoadingSubscribe = this.payerService.profileLoading$.subscribe((loading) => {
      this.profileLoading = loading;
    });

    this.LineageSubscribe = this.payerService.LineageDetails$.subscribe((data) => {
      const profiles = data.profiles;
      this.goldenRecord = data.golden;
      if (profiles != '' && !this.isLineageSet) {
        this.setLineageDetials(this.goldenRecord, profiles);
      }
    })
  }
  //navigates to merge unmerge page
  navigate() {
    this.router.navigate([
      'mdm/all-records/payer/profile/' + this.id_ + '/merge-unmerge',
    ]);
  }
  //gets the PBM profile details
  getProfileByid() {
    this.service.getPBMDetailsById(this.id_).subscribe((data) => {
      this.profileDetails = data;
    }, (exception) => {
      this.toaster.showError(exception.error.message.name, '');
    })
  }
  setColumnDef() {
    this.colDefs.forEach((item) => {
      item[AgGridColumnDefsConstants.TOOLTIP_FIELD] = '';
      item[AgGridColumnDefsConstants.WRAP_TEXT] = true;
      item[AgGridColumnDefsConstants.AUTO_HEIGHT] = true;
    }
    )
  }
  setColumnDefs() {
    this.colDefs = [];
    for (const [key, value] of Object.entries(this.LineageTableConstant)) {
      const detailsColDef = {
        [AgGridColumnDefsConstants.HEADER_NAME]: value,
        [AgGridColumnDefsConstants.FIELD]: key,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: value,
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.WRAP_TEXT]: true,
        [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
        [AgGridColumnDefsConstants.CELL_STYLE]: { 'white-space': 'normal', 'overflow-wrap': 'break-word' },
        [AgGridColumnDefsConstants.MAX_WIDTH]: MaxDetailsWidthColumnList.includes(
          key
        )
          ? 190
          : 500,
        [AgGridColumnDefsConstants.SORTABLE]: DetailsSortableColumnList.includes(
          key
        ),
        [AgGridColumnDefsConstants.UNSORT_ICON]: DetailsSortableColumnList.includes(
          key
        ),
        [AgGridColumnDefsConstants.COMPARATOR]: (valueOne, valueTwo) => {
          return this._agGridSort.stringValueComparator(valueOne, valueTwo);
        },
        [AgGridColumnDefsConstants.CELL_RENDERER]: ExcludeDetailsColumnList.includes(
          key
        )
          ? LineageCellRendererComponent
          : key === this.detailsApiResponseConstants.GOLDEN_VALUE ? GoldenValueAddressCellRendererComponent : '',
        [AgGridColumnDefsConstants.CELL_RENDERER_PARAMS]: {
          source_values: key === this.detailsApiResponseConstants.SOURCE_VALUES,
          environment: this.environment,
          survivorship_values:
            key === this.detailsApiResponseConstants.SURVIVORSHIP_RULES
        },
      };
      this.colDefs.push(detailsColDef);
    }

  }
  onGridReady(params) {
    this.gridApi = params.api;

  }
  getPBMLineageData() {
    this.payerService.getPBMLineageData(this.id_);
  }
  getMCOLineageData() {
    this.payerService.getMCOLineageData(this.id_);
  }
  getPAYERLineageData() {
    this.payerService.getPAYERLineageData(this.id_);
  }
  getPLANLineageData() {
    this.payerService.getPLANLineageData(this.id_);
  }
  setLineageDetials(goldenProfile, profiles) {
    this.isLineageSet = true;
    const dataArr = [];
    const sourceName = [];
    profiles.forEach((profile) => { sourceName.push(profile[LineageConstants.SRC_NM].value[0]); });
    for (const element in goldenProfile) {
      if (goldenProfile[element].display && goldenProfile[element].desc) {
        const attributeName = this.getAttributeName(element, goldenProfile);
        const goldenValue = this.getGoldenValue(element, goldenProfile);
        const survivorshipRules = this.getsurvivorshipRules(element, goldenProfile);
        const sourceValueDict = this.getSourceValues(element, profiles)
        dataArr.push({
          ATTRIBUTES: attributeName,
          GOLDEN_VALUE: goldenValue,
          SURVIVORSHIP_RULES: survivorshipRules,
          SOURCE_NAME: sourceName,
          SOURCE_DICT: sourceValueDict,
        });
      }
    }
    this.rowData = this.rowData.concat(JSON.parse(JSON.stringify(dataArr)));
  }
  getAttributeName(element, golden) {
    return golden[element].display;
  }
  getGoldenValue(element, goldenProfile) {
    return goldenProfile[element].value
  }
  getsurvivorshipRules(element, goldenProfile) {
    if (goldenProfile[element].desc) {
      return goldenProfile[element].desc
    }
  }
  getSourceValues(element, profiles) {
    const src = []
    profiles.forEach((item) => {
      if (Array.isArray(item[element].value)) {
        src.push(
          { "key": item['SRC_NM'].value[0], "value": item[element].value[0] }
        )
      } else {
        src.push(
          { "key": item['SRC_NM'].value[0], "value": item[element].value }
        )
      }
    })
    return src
  }
  getID(arr) {
    if (arr) {
      arr = arr.filter((item) => {
        if (item) {
          return item
        }
      })
      return arr.join(', ')
    }
  }
  ngOnDestroy(): void {
    this.profileDetailsSubscribe.unsubscribe();
    this.LineageSubscribe.unsubscribe();
    this.profileLoadingSubscribe.unsubscribe();
  }
}
