<div *ngIf="!is_mergeScreenActive" appZsLoader class="profile-details-header" [isVisible]="profileLoading"
    class="zs-padding-2-2-1-2 zs-margin-2-1-1-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
    <div class="zs-display-flex" *ngIf="!profileLoading">
        <div class="zs-width-10">
            <span class="zs-icon zs-icon-xxlarge zs-icon-field-unit"></span>
        </div>
        <div class="zs-width-40">
            <span>
                <h4 class="zs-margin-0 zs-display-inline-block name-width zs-cursor-pointer">
                    {{profileDetails?.name}} </h4>
                <zs-tooltip class="zs-tooltip" position="bottom" [attr.offset-y]="5">
                    <zs-tooltip-content class="zs-size-xs">
                        {{profileDetails?.name}}
                    </zs-tooltip-content>
                </zs-tooltip>
            </span>

            <div class="zs-display-flex zs-margin-1-0-0-0">
                <p class="zs-size-extra-small zs-font-weight-bold zs-text-uppercase">
                    MDM ID
                </p>
                <p class="zs-margin-0-0-1-1 zs-size-extra-small">
                    {{profileDetails?.mdm_id}}
                </p>
            </div>
            <div class="zs-display-flex">
                <div class="hcp-hco-tag">
                    <app-zs-tag nonclickable="true">
                        <span tag-value translate> {{this.page_details.entity}} </span>
                    </app-zs-tag>
                </div>
                <div class="show-lineage-field zs-display-flex" *ngIf="!mdmId">
                    <label class=" zs-checkbox">
                        <input type="checkbox" [(ngModel)]="showLineage" />
                        <span checkbox></span>
                    </label>
                    <span>{{ showLineageLabel }}</span>
                </div>
            </div>
        </div>
        <div class="zs-width-50 zs-position-relative">
            <div class="zs-grid-1cols zs-grid-2rows">
                <div class="zs-col-1 zs-row-1" style="text-align: end;">
                    <span>
                        <button
                            class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-0-1-1-0 button-ellipses float-right"
                            title="MERGE/UNMERGE " [routerLink]="[
                '/mdm/all-records/payer/profile',
                goldenRecord?.MDM_ID?.value,
                'merge-unmerge'
              ]" translate>
                            MERGE/UNMERGE
                        </button>
                    </span>

                </div>
            </div>
        </div>
    </div>

</div>
<div [isVisible]=profileLoading appZsLoader class="details" *ngIf="!showLineage && !profileLoading">
    <!-- payer card -->
    <div *ngIf="!is_mergeScreenActive && page_details.entity == PayerWrapperConstants.PAYER"
        class="profile-details-header" style="height:70vh"
        class="zs-padding-2-2-1-2 zs-margin-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
        <span class="card-heading zs-display-block">
            <div>
                <h4>PAYER DETAILS</h4>
            </div>

        </span>
        <div class="payer-details">
            <p><span class="detail-header"> PAYER MDM ID : </span>{{goldenRecord?.MDM_ID?.value}}</p>
            <p><span class="detail-header"> PAYER ID : </span>{{getID(goldenRecord?.PAYER_ID?.value)}}</p>
            <p><span class="detail-header"> PAYER NAME : </span>{{goldenRecord?.PAYER_NAME?.value}}</p>
            <p><span class="detail-header"> PAYER CHANNEL : </span>{{goldenRecord?.PAYER_CHANNEL?.value}}</p>
            <p><span class="detail-header"> SOURCES: </span>
                <span *ngFor="let source of goldenRecord?.SRC_NM?.value; let i=index"
                    [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
                    class="zs-badge-counter zs-badge-counter-new">{{ source.trim().toUpperCase() }}</span>
            </p>
            <p><span class="detail-header"> RECORD START DATE :</span> {{goldenRecord?.STRT_DT?.value}}</p>
        </div>
    </div>

    <!-- plan card -->
    <div *ngIf="!is_mergeScreenActive && page_details.entity == PayerWrapperConstants.PLAN"
        class="profile-details-header" style="height:70vh"
        class="zs-padding-2-2-1-2 zs-margin-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
        <span class="card-heading zs-display-block">
            <div>
                <h4>PLAN DETAILS</h4>
            </div>
        </span>
        <div class="payer-details">
            <p><span class="detail-header"> PLAN MDM ID : </span>{{goldenRecord?.MDM_ID?.value}}</p>
            <p><span class="detail-header"> PLAN ID : </span>{{getID(goldenRecord?.PLAN_ID?.value)}}</p>
            <p><span class="detail-header"> PLAN NAME : </span>{{goldenRecord?.PLAN_NAME?.value}}</p>
            <p><span class="detail-header"> PLAN TYPE : </span>{{goldenRecord?.PLAN_TYPE?.value}}</p>
            <p><span class="detail-header"> PAYER CHANNEL : </span>{{goldenRecord?.PAYER_CHANNEl?.value}}</p>
            <p><span class="detail-header"> PAYER NAME : </span>{{goldenRecord?.PAYER_NAME?.value}}</p>
            <p><span class="detail-header"> SOURCES: </span>
                <span *ngFor="let source of goldenRecord?.SRC_NM?.value; let i=index"
                    [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
                    class="zs-badge-counter zs-badge-counter-new">{{ source.trim().toUpperCase() }}</span>
            </p>
            <p><span class="detail-header"> RECORD START DATE : </span>{{goldenRecord?.STRT_DT?.value}}</p>
            <!-- <p><span class="detail-header"> PAYERS</span></p> -->
            <!-- <p *ngFor="let payer of payerList;let i = index">
                {{i+1}}.<a>{{payer.name}}</a> <br>
                Start Date :<span class="zs-padding-0-0-0-1">{{payer.start_date}}</span>
            </p> -->
        </div>
    </div>
    <!-- PBM card -->
    <div *ngIf="!is_mergeScreenActive && page_details.entity == PayerWrapperConstants.PBM"
        class="profile-details-header" style="height:70vh"
        class="zs-padding-2-2-1-2 zs-margin-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
        <span class="card-heading zs-display-block">
            <div>
                <h4>PBM DETAILS</h4>
            </div>

        </span>
        <div class="payer-details">
            <p><span class="detail-header"> PBM MDM ID : </span>{{goldenRecord?.MDM_ID?.value}}</p>
            <p><span class="detail-header"> PBM ID : </span>{{getID(goldenRecord?.PBM_ID?.value)}}</p>
            <p><span class="detail-header"> PBM NAME : </span>{{goldenRecord?.PBM_NAME?.value}}</p>
            <p><span class="detail-header"> CHANNEL : </span>{{goldenRecord?.PBM_CHANNEL?.value}}</p>
            <p><span class="detail-header"> SOURCES: </span>
                <span *ngFor="let source of goldenRecord?.SRC_NM?.value; let i=index"
                    [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
                    class="zs-badge-counter zs-badge-counter-new">{{ source.trim().toUpperCase() }}</span>
            </p>
            <p><span class="detail-header"> RECORD START DATE :</span> {{goldenRecord?.STRT_DT?.value}}</p>

        </div>
    </div>
    <!-- MCO card -->
    <div *ngIf="!is_mergeScreenActive && page_details.entity == PayerWrapperConstants.MCO"
        class="profile-details-header" style="height:70vh"
        class="zs-padding-2-2-1-2 zs-margin-1 zs-bg-standard zs-box-shadow zs-border-color-default zs-col-span-3">
        <span class="card-heading zs-display-block">
            <div>
                <h4>MCO DETAILS</h4>
            </div>

        </span>
        <div class="payer-details">
            <p><span class="detail-header"> MCO MDM ID :</span>{{goldenRecord?.MDM_ID?.value}}</p>
            <p><span class="detail-header"> MCO ID:</span>{{getID(goldenRecord?.MCO_ID?.value)}} </p>
            <p><span class="detail-header"> MCO NAME : </span>{{goldenRecord?.MCO_NAME?.value}}</p>
            <p><span class="detail-header"> RECORD START DATE : </span>{{goldenRecord?.STRT_DT?.value}}</p>
            <p><span class="detail-header"> SOURCES: </span>
                <span *ngFor="let source of goldenRecord?.SRC_NM?.value; let i=index"
                    [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
                    class="zs-badge-counter zs-badge-counter-new">{{ source.trim().toUpperCase() }}</span>
            </p>

        </div>
    </div>
</div>

<div *ngIf="showLineage" class="ag-grid-component">
    <div class="grid-table-loader">
        <div class="zs-ag-grid zs-custom-scrollbar zs-margin-1">
            <ag-grid-angular style="height: 100vh;width: 100% ;" class="ag-grid-class ag-theme-alpine"
                [columnDefs]="colDefs" [rowData]="rowData" [gridOptions]="gridOptions" [suppressCellSelection]="true"
                [enableCellTextSelection]="true" [frameworkComponents]="frameworkComponents"
                [suppressLoadingOverlay]="true" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>