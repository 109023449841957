import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoles, app_name } from './constants';

import { IdmDetailsService } from './idm-details.service';
import { Injectable } from '@angular/core';
import { NavigationTabDropdownChangeService } from './shared/services/navigation-tab-dropdown-change.service';
import { RevoCoreService } from './revo-core/revo-core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  navTabdropdownSubscriber: any;
  user: any;
  constructor(private _revoCoreService: RevoCoreService,  private _idmDetailsService: IdmDetailsService, private _router: Router,
    private _navigationTabService: NavigationTabDropdownChangeService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>|boolean  {
      const isUserAuthorized = next.data.isUserAuthorized;
      const navigationTabNameFromURL = next.data.navigationTabName;
      const isVisible = next.data.visibility;
      if (isUserAuthorized !== undefined) {
        return new Promise(response => {
          const userRoles = this._idmDetailsService.get_roles();
          const expectedRoles = next.data.expectedRoles as Array<string>;
          let isAuthorizedFlag = false;
          for (const role of expectedRoles) {
            if (userRoles[role] === true && isVisible === true) {
                this.setNavigationSelectTabInDropdown(navigationTabNameFromURL);
                isAuthorizedFlag = true;
                response(true);
            }
          }
          if ( !isAuthorizedFlag ) {
            this.navigateToDefaultPage(userRoles);
          }
          response(false);
        });
      } else if (isUserAuthorized === undefined) {
        return new Promise(response => {this._revoCoreService.get_user_session()
          .subscribe(
            result => {
              this._idmDetailsService.set_roles(result['roles'], result['username']);
              this.user = this._idmDetailsService.get_roles();
              const expectedRoles = next.data.expectedRoles as Array<string>;
              const notRequiredRoles = next.data.notRequiredRoles as Array<string>;
              let authorized = false;
              if (expectedRoles !== undefined) {
                for (const role of expectedRoles) {
                  if (result['roles'].indexOf(app_name + '.' + role) > -1) {
                    authorized = true;
                    break;
                  }
                }
              } else {
                authorized = true;
              }

              if (notRequiredRoles !== undefined && authorized && !this.user.Admin) {
                for (const role of notRequiredRoles) {
                  if (result['roles'].indexOf(app_name + '.' + role) > -1) {
                    authorized = false;
                    break;
                  }
                }
              }

              if (authorized) {
                  this.setNavigationSelectTabInDropdown(navigationTabNameFromURL);
              }

              if ( !authorized ) {
                this.navigateToDefaultPage(result['roles']);
              }

              response(authorized);
            },
            exception => {
              console.log(exception);
              response(false);
            });
          });
      }

  }

  navigateToDefaultPage(roles) {
    if (roles.hasOwnProperty(app_name + '.' + AppRoles.SITE_ADMIN) > -1) {
      /*this._router.navigate(['/site-admin']);*/
      this._router.navigate(['/home']);
    } else if (roles.hasOwnProperty(app_name + '.' + AppRoles.MIGRATOR) > -1) {
      /*this._router.navigate(['/import-export']);*/
      this._router.navigate(['/home']);
    } else {
      this._router.navigate(['/home'], { queryParams: { isForcedBrowsing: true}});
    }
  }

  setNavigationSelectTabInDropdown(navigationTabNameFromURL) {
    let navigationTabsInDropdownList = [];
    navigationTabsInDropdownList = this._navigationTabService.getNavigationTabsListFromDropdown();
    if (navigationTabsInDropdownList.length === 0) {
      let navigationTabsDetails = [];
      navigationTabsDetails = this._navigationTabService.getNavigationTabsFromUserRoles(this._idmDetailsService.get_roles());
      navigationTabsInDropdownList =
        this._navigationTabService.getNavigationTabsInDropdownFromNavigationTabsDetails(navigationTabsDetails);
    }
    if (navigationTabsInDropdownList.length > 0) {
      let isTabFromNavDropdown = false;
      for (const tab of navigationTabNameFromURL) {
        if (navigationTabsInDropdownList.indexOf(tab) > -1) {
          isTabFromNavDropdown = true;
          this.navTabdropdownSubscriber = this._navigationTabService.setPreselectedNavigationTabInDropdown(tab);
          break;
        }
      }
      if (isTabFromNavDropdown === false) {
        this.navTabdropdownSubscriber = this._navigationTabService.setPreselectedNavigationTabInDropdown(null);
      }
    }
  }

  ngOnDestroy() {
    this.navTabdropdownSubscriber.unsubscribe();
  }

}
