export const url = {
    refreshIndicator: '/mdm/refreshIndicator',
    ProfileStats: '/mdm/ProfileStats',
    DashboardMatches: '/mdm/DashboardMatches',
    statsProfileDetails: '/mdm/statsProfileDetails',
    ActivityLog: '/mdm/ActivityLog',
    ProfComp: '/mdm/ProfComp',
    AdvSearch: '/mdm/AdvSearch',
    SugMatches: '/mdm/SugMatches',
    ProfileLineage: '/mdm/ProfileLineage',
    SearchProf: '/mdm/SearchProf',
    ActivityLogProfile: '/mdm/ActivityLogProfile',
    Merge: '/mdm/Merge',
    NotaMatch: '/mdm/NotaMatch',
    Unmerge: '/mdm/Unmerge',
    Affiliation: '/mdm/Affiliation',
    getSearchEngineKey: '/mdm/getSearchEngineKey',
    getProfileNameById: '/mdm/get_profile_name_by_id',
    getEntityTypeById: '/mdm/get_entity_type_by_id',
    hcpProfile: '/mdm/all-records/hcp/profile',
    hcoProfile: '/mdm/all-records/hco/profile',
    allRecordsHcoProfile: '/mdm/all-records/hco/profile',
    allRecordsHcpProfile: '/mdm/all-records/hcp/profile',
    mdmActivityLogFilterValues: '/mdm/mdm_activity_log_filter_value',
    getSuspectedProfileNames: '/mdm/suspected-profile-names',
    getSuspectedProfileMetadata: '/mdm/suspected-profile-metadata',
    dcrManagement: '/mdm/dcr-management',
    dcrDetails: '/mdm/dcr-details-by-id',
    updateDcr: '/mdm/update-dcr-by-id',
    dcrSummary: '/mdm/dcr-summary',
    dcrToExcel: '/mdm/dcr-to-excel',
    dcrChangeRequestLine: '/mdm/dcr-change-request-lines',
    dcrFilterData: '/mdm/dcr-filter-data',
    dcrSync: '/mdm/sync-dcr',
    dcrCount: '/mdm/dcr-table-count'
};
export const PAYER_ENDPOINTS = {
    PBM_SUSPECTED_MATCHES: '/mdm/pbm/DashboardMatches',
    PBM_DETAILS_BY_ID: '/mdm/pbm/get_profile_by_id',
    PBM_SUGGESTED_MATCHES: '/mdm/pbm/SugMatches',
    PBM_ProfileLineage: '/mdm/pbm/ProfileLineage',
    PBM_ProfileMerge: '/mdm/pbm/Merge',
    PBM_ProfileUnMerge: '/mdm/pbm/Unmerge',
    PBM_ProfileNotaMatch: '/mdm/pbm/NotaMatch',
    PBM_Records: '/mdm/pbm/AdvSearch',
    PBM_SUSPECTED_PROFILE_NAMES: '/mdm/pbm/suspected-profile-names',
    PBM_SUSPECTED_PROFILE_METADATA: '/mdm/pbm/suspected-profile-metadata',
    MCO_SUSPECTED_MATCHES: '/mdm/mco/DashboardMatches',
    MCO_Records: '/mdm/mco/AdvSearch',
    MCO_DETAILS_BY_ID: '/mdm/mco/get_profile_by_id',
    MCO_SUGGESTED_MATCHES: '/mdm/mco/SugMatches',
    MCO_ProfileLineage: '/mdm/mco/ProfileLineage',
    MCO_SUSPECTED_PROFILE_NAMES: '/mdm/mco/suspected-profile-names',
    MCO_SUSPECTED_PROFILE_METADATA: '/mdm/mco/suspected-profile-metadata',
    MCO_ProfileMerge: '/mdm/mco/Merge',
    MCO_ProfileUnMerge: '/mdm/mco/Unmerge',
    MCO_ProfileNotaMatch: '/mdm/mco/NotaMatch',
    PAYER_SUSPECTED_MATCHES: '/mdm/payer/DashboardMatches',
    PAYER_DETAILS_BY_ID: '/mdm/payer/get_profile_by_id',
    PAYER_SUGGESTED_MATCHES: '/mdm/payer/SugMatches',
    PAYER_ProfileLineage: '/mdm/payer/ProfileLineage',
    PAYER_ProfileMerge: '/mdm/payer/Merge',
    PAYER_ProfileUnMerge: '/mdm/payer/Unmerge',
    PAYER_ProfileNotaMatch: '/mdm/payer/NotaMatch',
    PAYER_Records: '/mdm/payer/AdvSearch',
    PAYER_SUSPECTED_PROFILE_NAMES: '/mdm/payer/suspected-profile-names',
    PAYER_SUSPECTED_PROFILE_METADATA: '/mdm/payer/suspected-profile-metadata',
    PLAN_SUSPECTED_MATCHES: '/mdm/plan/DashboardMatches',
    PLAN_DETAILS_BY_ID: '/mdm/plan/get_profile_by_id',
    PLAN_SUGGESTED_MATCHES: '/mdm/plan/SugMatches',
    PLAN_ProfileLineage: '/mdm/plan/ProfileLineage',
    PLAN_ProfileMerge: '/mdm/plan/Merge',
    PLAN_ProfileUnMerge: '/mdm/plan/Unmerge',
    PLAN_ProfileNotaMatch: '/mdm/plan/NotaMatch',
    PLAN_Records: '/mdm/plan/AdvSearch',
    PLAN_SUSPECTED_PROFILE_NAMES: '/mdm/plan/suspected-profile-names',
    PLAN_SUSPECTED_PROFILE_METADATA: '/mdm/plan/suspected-profile-metadata',

}
