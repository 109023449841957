export class ValueChange {
  Id: string;
  HeaderName: string;
  Value: any;
}

export class Headers {
  Name: string;
  Type: HeaderType;
  TypeMeta: TypeMetaData;
  DisplayName: string;
  IsFilterable: boolean;
  FilterValues: Array<FilterData>;
  IsEditable: boolean;
  DropdownOptions: Array<string>;
  IsAutoComplete: boolean;
  IsReffered: boolean;
  RefferedIDKey: string;
  IsPrimaryKey: boolean;
  IsRequired: boolean;
  IsVisible: boolean;
  IsSort: boolean;
  SortOrder: string;
  DqmChecks: object;
  isDqmCheck: boolean;
}

export enum HeaderType {
  Input,
  Select,
  Autocomplete,
  Readonly,
}

export class FilterData {
  Label: string;
  IsSelected: boolean;
  Value: string;
}

export class AppliedFiltersData {
  column_name: string;
  value_list: Array<string>;
}

export class selectedFilterOptionData {
  filterId: string;
  filterText: string;
  off_set: number;
  IsResetFilterOptions: boolean;
}

export class selectedFilterColumnData {
  columnId: string;
  off_set: number;
  tableName: string;
}

export class TypeMetaData {
  SelectOptions: Array<string>;
  IsPrimaryKey: boolean;
  IsForeign: boolean;
  IsReferenced: boolean;
  ReferenceKeyDdSchemaId: number;
}

export class TableMeta {
  IsAddSelectBox: boolean;
  RowIDKey: string;
}

export class DriverDataAndFilterServiceRequest {
  object_id: number;
  table_name: string;
  filters: Array<AppliedFiltersData>;
  page_size: number;
  page_number: number;
  column_name: string;
  offset: number;
  search_string: string;
  version_name: string;
  sort_order: string;
  sort_column: string;
  time_period_id: string;
}

export class AutoCompleteForeignKeyRequest {
  referred_column_id: number;
  value: any;
  object_id: number;
  time_period_id: string;
}

export class AutoCompleteDataServiceRequest {
  search_text: string;
  referred_column_id: string;
  offset: number;
  time_period_id: string;
}

export class SelectedFilterOptionEmitData {
  value: string;
  rdh_primary_key: number;
  referenceKeyId: string;
}

export class SelectedAutoCompleteData {
  label: string;
  value: string;
}
