import { Component, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { common_labels, revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

import { EventEmitter } from '@angular/core';
import { FormValidationService } from '../form-validation-service/form-validation-service';
import { Subscription } from 'rxjs';
import TextareaField from '../../../../node_modules/zsui/src/textarea/textarea.m.js';
import { TranslateService } from '@ngx-translate/core';

TextareaField.register();

@Component({
  selector: 'app-zs-textarea',
  templateUrl: './zs-textarea.component.html',
  styleUrls: ['./zs-textarea.component.less']
})
export class ZsTextareaComponent implements OnInit, OnDestroy {

  @Input() hint: string;
  @Input() placeholder: string;
  @Input() modelValue: string;
  @Input() validateFunction: Function;
  @Input() inErrorState = false;
  @Input() formValidationCallbackFunction: Function;
  @Input() required = false;
  @Output() modelValueChange = new EventEmitter(true);
  @Output() inErrorStateChange = new EventEmitter(true);
  @Input() touched = false;
  @Input() customStyle: object;
  @Input() disabled = false;
  @Output() cursorPositionChange = new EventEmitter(true);
  @Input() charLimit = 500;
  @Input() isReadonly = false;

  formValidationSubscription: Subscription;
  errorMessage: any;
  errorClass = '';

  translated_labels: any;
  validation_labels = revo_core_labels.VALIDATION_LABELS;
  common_labels = common_labels;

  caretPos = 0;

  getCaretPos(oField) {
    const zsTextArea = oField.querySelectorAll('textArea')[0];
    if (zsTextArea.selectionStart || zsTextArea.selectionStart === '0') {
      this.caretPos = zsTextArea.selectionStart;
    }
    if (this.cursorPositionChange) {
      this.cursorPositionChange.emit(this.caretPos);
    }
  }

  constructor(private _formValidationService: FormValidationService,
    private _translate: TranslateService) {
    this.translated_labels = this._translate.instant(
      [
        this.validation_labels.VALUE_REQUIRED
      ]
    );
    this.errorMessage = this.translated_labels[this.validation_labels.VALUE_REQUIRED];
  }

  ngOnInit() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription = this._formValidationService.getMessage().subscribe(
        message => {
          this.touched = true;
          this.validate();

          this.formValidationCallbackFunction(this.inErrorState);
        });
    }
  }

  validate() {
    this.errorClass = '';
    this.errorMessage = '';
    this.inErrorState = false;

    if (this.checkForRequiredField()) {
      this.errorClass = 'zs-error';
      this.errorMessage = this.translated_labels[this.validation_labels.VALUE_REQUIRED];
      this.inErrorState = true;
    } else if (this.validateFunction) {
      const message = this.validateFunction(this.modelValue);
      this.checkForError(message);
    } else {
      this.resetTextBox();
    }
    this.inErrorStateChange.emit(this.inErrorState);
    this.modelValueChange.emit(this.modelValue);
  }

  checkForRequiredField() {
    if (this.required !== undefined && this.required) {
      if (this.modelValue === undefined || (this.modelValue !== undefined && this.modelValue !== null && this.modelValue.length === 0)) {
        return true;
      }
      return false;
    }
  }

  checkForError(errorMessage) {
    if (errorMessage && errorMessage.length > 0) {
      this.errorClass = 'zs-error';
      this.errorMessage = errorMessage;
      this.inErrorState = true;
      this.inErrorStateChange.emit(this.inErrorState);
    }
  }

  resetTextBox() {
    this.errorClass = '';
    this.errorMessage = '';
    this.inErrorState = false;
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }

  onInput($event) {
    this.touched = true;
    this.modelValue = $event.target.value;
    this.validate();
  }
}
