import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applyEllipses'
})
export class ApplyEllipses implements PipeTransform {

  transform(text: string, lengthLimit?: number): string {
    if (text && text.length > lengthLimit) {
      return (text.slice(0, lengthLimit) + "...");
    }
    return text;
  }

}
