export const app_name = 'mdm';
export const read_access = 'READ';
export const write_access = 'WRITE';
export const homePagePath = '/home';

export const AppRoles = {
    INGESTER: 'Ingester',
    EXTRACTER: 'Extracter',
    ADMIN: 'Admin',
    SITE_ADMIN: 'SiteAdmin',
    OPS_ANALYST: 'OpsAnalyst',
    OPS_ANALYST_INTERNAL: 'OpsAnalystInternal',
    DATA_STEWARD: 'DataSteward',
    BRM_ADMIN: 'BRMAdmin',
    BRM_USER: 'BRMUser',
    MIGRATOR: 'Migrator',
    APPROVER: 'Approver',
    MasterDataSteward: 'MasterDataSteward',
    HQ_USER: 'HQUser',
    DCR_STEWARD: 'DCRSteward'
};

export const IDMDetailsConstants = {
    USERNAME: 'username',
};
