<a class="router-link" *ngIf="isMdmIdColumn" [routerLink]="['/mdm/all-records/' + profileType + '/profile', mdmId]"
    target="_blank" rel="noopener noreferrer">{{mdmId}}</a>
<a class="router-link" *ngIf="isNameColumn" [routerLink]="['/mdm/all-records/' + profileType + '/profile', mdmId]"
    target="_blank" rel="noopener noreferrer">{{mdmName}}</a>
<span *ngIf="isSourcesColumn">
    <span *ngFor="let source of mdmSources"
        [ngStyle]="{'background-color': environment.mdmSourceColorCodes[source.trim().toLowerCase()]}"
        class="zs-badge-counter zs-border-rounded-0">
        {{ source.trim().toUpperCase() }}
    </span>
</span>