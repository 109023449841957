import { Pipe, PipeTransform } from '@angular/core';
import { searchSliceDataPipeConstants } from '../constants/search-constants';

@Pipe({
  name: 'sliceData'
})
export class SliceDataPipe implements PipeTransform {

  transform(value: any, type: string, toShowLimit: number): any {
    if (value.addressLine1 === undefined) {
      value.addressLine1 = '';
    }
    if (value.addressLine2 === undefined) {
      value.addressLine2 = '';
    }
    if (type === searchSliceDataPipeConstants.ADDRESS_LINE) {
      if (value.addressLine1 && value.addressLine2 && value.addressLine1.length + value.addressLine2.length + 1 > toShowLimit) {
        return (value.addressLine1 + ", " + value.addressLine2).substr(0, toShowLimit) + "...";
      } else if (value.addressLine1 && !value.addressLine2) {
        if (value.addressLine1.length + 1 > toShowLimit) {
          return (value.addressLine1 + ", ").substr(0, toShowLimit) + "...";
        } else {
          return value.addressLine1 + ", ";
        }
      } else if (value.addressLine2 && !value.addressLine1) {
        if (value.addressLine2.length + 1 > toShowLimit) {
          return (value.addressLine2 + ", ").substr(0, toShowLimit) + "...";
        } else {
          return value.addressLine2 + ", ";
        }
      } else if(value.addressLine1 === ''){
        return value.addressLine2;
      }  else if(value.addressLine1 === ''){
        return value.addressLine1 + ", " + value.addressLine2;
      }
    } else if (type === searchSliceDataPipeConstants.ADDRESS_ZIP_CODE) {
      if (value.city === undefined) {
        value.city = '';
      }
      if (value.st === undefined) {
        value.st = '';
      }
      if (value.zip === undefined) {
        value.zip = '';
      }
      if (value.city && value.st && value.zip && value.city.length + value.st.length + value.zip.length + 1 > toShowLimit) {
			  return (value.city + ", " + value.st + " " + value.zip).substr(0, toShowLimit) + "...";
		  } else if (value.city === '') {
			  return value.st +  " " + value.zip;
		  } else {
        return value.city + ", " + value.st +  " " + value.zip;
      }
    } 
  }
}
