import { Component, OnInit, Input } from '@angular/core';
import Tag from '../../../../node_modules/zsui/src/tag/tag.m.js';
Tag.register();
const tag = Tag.create();
document.body.appendChild(tag);

@Component({
  selector: 'app-zs-tag',
  templateUrl: './zs-tag.component.html',
  styleUrls: ['./zs-tag.component.less']
})
export class ZsTagComponent implements OnInit {

  constructor() { }
  @Input() value = '';
  @Input() dismissable = false;
  @Input() nonclickable = false;
  @Input() customClass = '';
  @Input() onCloseCallBackFunction: Function;
  @Input() validatefunctionArgs = [];

  ngOnInit(): void {
  }

  onRemove() {
    if (this.onCloseCallBackFunction) {
      this.onCloseCallBackFunction(...this.validatefunctionArgs);
    }
  }
}
