import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { DateTimeConstants } from '../../../../shared/constants';
import { searchSliceDataPipeConstants } from '../../../constants/search-constants';
import { mdm_labels } from '../../../constants/ui-translation';
import {
  SearchConstants
} from '../../../constants/search-constants';

import {
  MDMCommonConstants, PayerWrapperConstants
} from '../../../constants/mdm-common-constants';
import { Pagination } from 'src/app/shared/models/pagination.models';
import { Router } from '@angular/router';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { containsSpecialCharacters } from 'src/app/shared/utils/string-check-utils';

@Component({
  selector: 'app-payer-records',
  templateUrl: './payer-records.component.html',
  styleUrls: ['./payer-records.component.less']
})
export class PayerRecordsComponent implements OnInit {
  filters = [
    {
      applied: true,
      show: true,
      keepTagVisible: false,
      label: 'PAYER',
      fields: [
        {
          type: 'text',
          displayLabel: 'ID',
          label: 'MDM_ID',
          applied: true,
          selectedValue: '',
        },
        {
          type: 'text',
          displayLabel: 'NAME',
          label: 'NAME',
          applied: true,
          selectedValue: '',
        },
      ],
    },
  ];
  keepFlagsVisible = false;
  MDMCommonConstants = MDMCommonConstants;
  searchConstants = SearchConstants;
  showTags = false;
  profileNameLabellength = 17;
  dateTimeConstants = DateTimeConstants;
  searchSliceDataPipeConstants = searchSliceDataPipeConstants;
  ui_labels = mdm_labels.ALL_RECORDS.SEARCH;
  environment: EnvironmentConfig;
  paginationData: Pagination = {
    ItemCount: 0,
    CurrentPage: 1,
    CustomStyle: ''
  };

  PayerWrapperConstants = PayerWrapperConstants;
  PayerRecords: any;
  recordCount: number = 0;
  loading: boolean = true;
  page = 1;
  pageSize: number;
  checkSpecialCharacter: boolean = false;

  constructor(
    private config: AppConfig,
    private router: Router,
    private service: MDMService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();

  }

  ngOnInit(): void {
    this.pageSize = this.environment.pageSize;
    this.getPayerRecords();

  }

  flipAccordion(showAccordion) {
    this.filters.forEach((filter) => (filter.show = showAccordion));
    if (showAccordion) {
      if (this.showTags) {
        this.filters.forEach((filter) =>
          (filter.keepTagVisible = true));
        this.keepFlagsVisible = true;
      }
    }
  }

  goToProfile(profile: any) {
    this.router.navigate(['/mdm/all-records/payer/profile/', profile.MDM_ID], {
      state: { entity: PayerWrapperConstants.PAYER, return_page: 'suspected' },
    });
  }
  getPayerRecords() {
    const payload = {
      filters: {
        [PayerWrapperConstants.PAYER]: {}
      },
      page: this.page,
      pageSize: this.pageSize
    }
    this.filters[0].fields.forEach((ele) => {
      if (ele.selectedValue != '') {
        payload.filters[PayerWrapperConstants.PAYER][ele.label] = ele.selectedValue
      }
    })
    this.service.getPayerRecords(payload).subscribe((resp: any) => {
      this.PayerRecords = resp.data;
      this.recordCount = resp.totalRecords;
      this.loading = false;
    }, (exception) => {
      this.loading = false;
      this.toaster.showError(exception.error.message.name, '');

    })
  }
  applyFilters() {
    const inputArray = this.filters[0].fields
      .filter((item) => item.selectedValue !== undefined)
      .map((item) => item.selectedValue);
    this.checkSpecialCharacter = containsSpecialCharacters(inputArray, true);
    if (!this.checkSpecialCharacter) {
      this.getPayerRecords();
    }
  }
  pageChange(event) {
    this.page = event;
    this.getPayerRecords();
  }
}
