export const APIResponseMessageType = {
  RDH_GENERIC_MESSAGE: 'RDHGenericMessage',
  RDH_MULTIPLE_MESSAGE: 'RDHMultipleMessages',
  RDH_ASYNC_JOBS_Error_Message: 'RDHAsyncJobsErrorMessages',
  RDH_VALIDATION_ERROR_MESSAGE: 'RDHRequestValidationErrorMessage',
};

export const APIResponseMessageConstants = {
  MESSAGE_TYPE: 'message_type',
};
