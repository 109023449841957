<div class="zs-master-style zs-display-flex datepicker-flex-direction">
    <label *ngIf="label" class="datepicker-label">
        {{label}}
    </label>
    <p-is-zs-data-picker class="zs-date-picker zs-margin-0" #zsDatePicker [flippable]="true"
        [dateValue]="selectedDateValue" [disabled]="isDisabled" [flippable]="flippable" (beforeOpen)="beforeOpen()"
        [hideOnScroll]=true [ngStyle]="customStyle" (change)="onChange($event)" [clear]="isClearVisible"
        [minDate]="minDateValue">
    </p-is-zs-data-picker>
    <p *ngIf="touched" [ngClass]="inputClass" [ngStyle]="errorSpanStyle" class="message-margin zs-message">
        {{errorMessage}}</p>
</div>